import React, { useContext, useEffect, useState } from "react";
import { app as firebase } from "../../../firebase";
import AuthContext from "../../../contexts/auth";
import { signedPreAgreement } from "../../../services/service";
import { isElement, isEmpty } from "lodash";

const UploadAgreement = (props) => {
  const { jobid, doc } = props;
  console.log("doc", doc);
  const context = useContext(AuthContext);
  const [preAgreementPDF, setPreAgreementPDF] = useState({});
  const [pdfUrl, setPdfUrl] = useState(doc);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadError, setUploadError] = useState("");

  useEffect(() => {
    !isEmpty(doc) && setPdfUrl(doc);
  }, [doc]);

  const getToken = () => {
    return firebase.auth().currentUser.getIdToken(true);
  };

  const handleReport = (e, preview, ref) => {
    if (!e.target.files[0]) {
      return <React.Fragment />;
    } else {
      setPreAgreementPDF({
        [preview]: URL.createObjectURL(e.target.files[0]),
        [ref]: e.target.files[0],
      });
    }
  };

  const uploadReportToFirebase = () => {
    const fileName = preAgreementPDF.pdfref.name;
    let storageRef = firebase.storage().ref();
    let inspectorJobsRef = storageRef
      .child(context.user.email)
      .child("signedPreInspectionAgreement");
    let reportURLRef = inspectorJobsRef.child(fileName);
    let reportUploadTask = reportURLRef.put(preAgreementPDF.pdfref);

    reportUploadTask.on(
      "state_changed",
      (snapshot) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadStatus(progress > 10 && Number.parseInt(progress));
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        setUploadError(
          "There was an error uploading the file, please try it again"
        );
        console.log(error);
      },
      () => {
        reportUploadTask.snapshot.ref
          .getDownloadURL()
          .then((fileUrl) => {
            getToken().then((res) => {
              signedPreAgreement(res, jobid, fileUrl).then((res) => {
                setPdfUrl(fileUrl);
                setPreAgreementPDF({});
              });
            });
          })
          .catch((error) => {
            setUploadError(
              "There was an error uploading the file, please try it again"
            );
            console.log(error);

            if (typeof callbackFail !== "undefined") {
              //  callbackFail(error);
              console.log(error);
            }
          });
      }
    );
  };

  return (
    <>
      <div className="file-field input-field row pt2">
        <div className="col s12 m9">
          <div className="btn waves-effect green">
            <span>
              <i className="material-icons left">upload</i>Upload Agreement
            </span>
            <input
              className="btn mt-5"
              type="file"
              onChange={(e) => {
                handleReport(e, "pdfreview", "pdfref");
              }}
              accept="application/pdf"
              placeholder="Upload agreement"
            />
          </div>
          <div className="file-path-wrapper">
            <input className="file-path validate" type="text" />
            {uploadStatus && (
              <div
                className="uploadProgress green-text"
                style={{ width: "100%" }}
              >
                {uploadStatus}% Complete
              </div>
            )}
          </div>
        </div>
        <div className="col s12 m3">
          {!!preAgreementPDF.pdfref ? (
            <div className="center">
              <button
                style={{ height: "auto" }}
                type="submit"
                onClick={uploadReportToFirebase}
                className="btn mt-1"
                disabled={!!uploadStatus}
              >
                Confirm
              </button>
            </div>
          ) : !isEmpty(pdfUrl) ? (
            <a
              className="infoDoc"
              href={pdfUrl}
              target="_blank"
              rel="noreferrer"
            >
              <span className="infoLink">
                {" "}
                <i className="material-icons infoIcon">information</i> Review your
                uploaded document
              </span>
            </a>
          ) : (
            <></>
          )}
        </div>
      </div>

      {!!uploadError && <div className="row red-text">{uploadError}</div>}
    </>
  );
};

export default UploadAgreement;
