import React from "react";
import InspectorPrivateComponent from "../../../components/InspectorPrivateComponent";
import ActiveJobsComponent from "../../../components/InspectorDomain/ActiveJobs/ActiveJobs";

class ActiveJobs extends React.Component {
  render() {
    return (
      <InspectorPrivateComponent>
        <ActiveJobsComponent />
      </InspectorPrivateComponent>
    );
  }
}

export default ActiveJobs;
