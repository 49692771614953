import Materialize from "materialize-css";

const Toast = (text, classes) => {
  return Materialize.toast({
    html: text,
    classes: typeof classes !== "undefined" ? `rounded ${classes}` : "rounded",
  });
};

const getTimeOptions = (time, withLabel = false, labelOnly = false) => {
  if (time === "early_morning") return withLabel ? "Early Morning 07:00 AM" : labelOnly ? "early_morning" : "07:00 AM";
  if (time === "morning") return withLabel ? "Morning 09:00 AM" : labelOnly ? "morning" : "09:00 AM";
  if (time === "afternoon") return withLabel ? "Afternoon 12:00 PM" : labelOnly ? "afernoon" : "12:00 PM";
  if (time === "late_afternoon")
    return withLabel ? "Late Afternoon 03:00 PM" : labelOnly ? "late_afternoon" : "03:00 PM";
};

const selectJobInTable = (currentJobId, selectedRowId, jobSelectedId, updateRowId, updateJob, jobRequestList) => {
  const selected = document.getElementById(selectedRowId);
  const jobSelected = document.getElementById(jobSelectedId);

  if (currentJobId) {
    if (!!selected) {
      selected.classList.remove("active-row");
    }
    if (!!jobSelected) {
      jobSelected.classList.add("active-row");
    }
    updateRowId(jobSelectedId);
  } else {
    if (!!jobSelected) {
      jobSelected.classList.add("active-row");
    }
    updateRowId(jobSelectedId);
  }
  for (let job of jobRequestList) {
    if (job.jobid === jobSelectedId) {
      updateJob(job);
    }
  }
};

const calculateInspectorPayout = (value) => {
  let newValue = value;
  if (typeof value === "string") {
    newValue = +value;
  }
  return +(newValue * 0.87).toFixed(2);
}

const parseQueryParam = (queryParam) => {
  let searchParams = new URLSearchParams(window.location.search);
  return decodeURIComponent(searchParams.get(queryParam));
}

export { getTimeOptions, Toast, selectJobInTable, calculateInspectorPayout, parseQueryParam };
