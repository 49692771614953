import React from 'react'
import Loader from '../../../components/Loader'
import InspectorCard from "./InspectorCard";
import "./InspectorList.css";

const NoInspector = () => {
  return <div style={{textAlign:'center'}}>
    <img src={require('../../../assets/undraw_empty_result.svg')} alt='No results' style={{width:'250px'}}/>
    <p className='mb-0' style={{ fontSize: '1.4rem', color: 'slategray' }}>No results found.</p>
    <p className='mt-0' style={{fontSize:'1.3rem'}}>Sorry we are not covered in your area yet.</p>
  </div>
}

// temporary filter to only show inspectors who has InterNachi certification
const getInterNachiOnly = (inspectors) => {
  return inspectors
    .filter(inspector => inspector.certification?.toLowerCase().indexOf('internachi') >= 0)
}

export default (props) => {
  const getInspectorList = () => {
    return getInterNachiOnly(props.inspectors)
      .map((e, i) => {
        return (
          <InspectorCard
            inspector={e}
            key={i}
            index={i}
            selectedInspectors={props.selectedInspectors}
            setSelectedInspectors={props.setSelectedInspectors}
          />
        );
      })
  };

  if(!props.inspectors) {
    return <NoInspector />
  }

  if(!props.inspectors.length) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return <>
    <div className="inspectorList">
      { getInspectorList() }
    </div>
  </>
}