import React from "react";
import "./dashboard.css";
import { Link } from "react-router-dom";
import DashboardForInspections from "../../../components/UserDomain/Dashboard/Inspections";
import AuthContext from "../../../contexts/auth";
import AuthWrapper from "../../../components/AuthWrapper";
import Loader from "../../../components/Loader";
import {LoadTutorial} from '../../../services/helper'

export default class InspectionsDashboard extends React.Component {
  state = {
    loading: true
  };

  componentDidMount = () => {
    LoadTutorial()
  }

  Success = () => {
    return (
      <>
        <DashboardForInspections />
      </>
    );
  };

  Fail = () => {
    if (!this.state.loading)
      return (
        <div className="row valign-wrapper" style={{ height: "70vh" }}>
          <div className="center-align" style={{ width: "100%" }}>
            <h4>Oops, Looks like you're not logged in.</h4>
            <Link
              to="/login"
              className="btn housky-primary-complimentary-bg"
              style={{ padding: "1rem 4rem", height: "auto" }}
            >
              Log In
            </Link>
          </div>
        </div>
      );
    else {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    }
    return <Loader />;
  };
  render() {
    return (
      <AuthContext.Consumer>
        {user => {
          if (!user.user) {
            return <></>
          }

          return (
            <AuthWrapper
              user={user}
              success={<this.Success/>}
              fail={<this.Fail/>}
            />
          );
        }}
      </AuthContext.Consumer>
    );
  }
}
