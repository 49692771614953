import axios from "axios";
import { ipInfoDefault } from "../utils/constants";
const prodRoot = "https://housky.herokuapp.com/";
const localRoot = "http://localhost:8002/";
const qaRoot = "https://nothousky.herokuapp.com/";
const userEndpointBase = "users";
const inspectorEndpointBase = "inspector";
const inspectorInfoEndpointBase = "inspector_info";
const inspectorCheckEndpointBase = "inspector_check";
const inspectionCompanyEndpointBase = "inspection_company";
const houseEndpointBase = "house";
const houseDetailEndpointBase = "house_detail";
const jobsEndpointBase = "job";
const reportEndpointBase = "report";
const houskyPublicEndpointBase = "housky_public";
const privatePricesEndpointBase = "housky_prices";
const promotionEndpointBase = "promotion";
const inspectorRecordsEndpointBase = "inspector_records";
const userRecordsEndpointBase = "user_records";
const adminEndpointBase = "admin";
const stripeInspectorEndpointBase = "inspector-stripe";
const stripeUserEndpointBase = "user-stripe";
const openJobsEndpointBase = "open_jobs";
const contractEndpointBase = "contract";
const searchInspectorByStateRoute = "/searchInspector/state";
const searchInspectorByNameRoute = "/searchInspector/name";
const getLocationByZipcodeRoute = "/location";

const getRoot = () => {
  //return localRoot;
  const env = process.env.REACT_APP_ENV;
  switch (env) {
    case "LOCAL":
      return localRoot;
    case "QA":
      return qaRoot;
    case "PROD":
      return prodRoot;
    default:
      return qaRoot;
  }
};

const root = getRoot();

// USER
const createUser = (body = {}) => {
  return axios({
    url: userEndpointBase,
    method: "post",
    baseURL: root,
    data: body,
  });
};

const getUser = (token) => {
  return axios({
    url: `${userEndpointBase}/?token=${token}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const updateUser = (body = {}) => {
  return axios({
    url: userEndpointBase,
    method: "put",
    baseURL: root,
    data: body,
  });
};

// INSPECTOR
const createInspector = (body = {}) => {
  return axios({
    url: inspectorEndpointBase,
    method: "post",
    baseURL: root,
    data: body,
  });
};

const createInspectorStep1 = (body = {}) => {
  return axios({
    url: "inspector/CreateInspector/step1",
    method: "post",
    baseURL: root,
    data: body,
  });
};

const createInspectorStep2 = (body = {}) => {
  return axios({
    url: "inspector/CreateInspector/step2",
    method: "put",
    baseURL: root,
    data: body,
  });
};

const createInspectorStep3 = (body = {}) => {
  return axios({
    url: "inspector/CreateInspector/step3",
    method: "put",
    baseURL: root,
    data: body,
  });
};

const createInspectorStep4 = (body = {}) => {
  return axios({
    url: "inspector/CreateInspector/step4",
    method: "put",
    baseURL: root,
    data: body,
  });
};

const getInspectorsByInspector = (token) => {
  return axios({
    url: `${inspectorEndpointBase}/inspector/?token=${token}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const getInspectorPhotoUrl = (token) => {
  return axios({
    url: `${inspectorEndpointBase}/photoUrl/?token=${token}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const updateInspectorCalculatorParams = (token, body) => {
  return axios({
    url: `${inspectorEndpointBase}/calculatorParams/?token=${token}`,
    method: "put",
    baseURL: root,
    data: body,
  });
};

const updateShowPriceRange = (token, body) => {
  return axios({
    url: `${inspectorEndpointBase}/showPriceRange/?token=${token}`,
    method: "put",
    baseURL: root,
    data: body,
  });
};

const getInspectorCalculatorParams = (token) => {
  return axios({
    url: `${inspectorEndpointBase}/calculatorParams/?token=${token}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const getInspectorsByInspectorId = (id) => {
  return axios({
    url: `getInspector/${id}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const getInfoByInspectorId = (id) => {
  return axios({
    url: `getInspector/${id}`,
    method: "get",
    baseURL: root,
  });
};

const getInspectorToolsByInspectorId = (inspectorId) => {
  return axios({
    url: `getInspector/inspectorTools/${inspectorId}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const getAllToolsWithInspectorToken = (token) => {
  return axios({
    url: `${inspectorEndpointBase}/allTools/?token=${token}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const getInspectorIdByToken = (token) => {
  return axios({
    url: `${inspectorEndpointBase}/get_inspector_id/?token=${token}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const updateInspectorTools = (token, body) => {
  return axios({
    url: `${inspectorEndpointBase}/updateTools/?token=${token}`,
    method: "put",
    baseURL: root,
    data: body,
  });
};

const getInspectorCustomizedUrlNameByInspectorToken = (token) => {
  return axios({
    url: `${inspectorEndpointBase}/customizedUrlName/?token=${token}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const getInspectorIdByCustomizedUrlName = (customizedName, token) => {
  return axios({
    url: `${inspectorEndpointBase}/inspectorIdByCustomizedUrlName/${customizedName}/?token=${token}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const updateCustomizedUrlName = (token, body) => {
  return axios({
    url: `${inspectorEndpointBase}/customizedUrlName/?token=${token}`,
    method: "put",
    baseURL: root,
    data: body,
  });
};

const checkIsComplete = (token) => {
  return axios({
    url: `${inspectorEndpointBase}/isComplete/?token=${token}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const updateInspector = (body = {}) => {
  return axios({
    url: inspectorEndpointBase,
    method: "put",
    baseURL: root,
    data: body,
  });
};

const updateInspectorUser = (body = {}) => {
  return axios({
    url: `${inspectorEndpointBase}/user`,
    method: "put",
    baseURL: root,
    data: body,
  });
};

const updateInspectorUserImage = (body = {}) => {
  return axios({
    url: `${inspectorEndpointBase}/userImage`,
    method: "put",
    baseURL: root,
    data: body,
  });
};

const updateInspectorAddress = (body = {}) => {
  return axios({
    url: `${inspectorEndpointBase}/address`,
    method: "put",
    baseURL: root,
    data: body,
  });
};

const updateInspectorLicenses = (body = {}) => {
  return axios({
    url: `${inspectorEndpointBase}/licenses`,
    method: "put",
    baseURL: root,
    data: body,
  });
};

const updateInspectorCompanyInfo = (body = {}) => {
  return axios({
    url: `${inspectorEndpointBase}/companyinfo`,
    method: "put",
    baseURL: root,
    data: body,
  });
};

const updateSampleReport = (body = {}) => {
  return axios({
    url: `${inspectorEndpointBase}/samplereport`,
    method: "put",
    baseURL: root,
    data: body,
  });
};

const updatePersonalToS = (body = {}) => {
  return axios({
    url: `${inspectorEndpointBase}/personal_tos`,
    method: "put",
    baseURL: root,
    data: body,
  });
};

const updateGallery = (body = {}) => {
  return axios({
    url: `${inspectorEndpointBase}/gallery`,
    method: "put",
    baseURL: root,
    data: body,
  });
};

const accountManagement = (body = {}) => {
  return axios({
    url: `${inspectorEndpointBase}/active`,
    method: "put",
    baseURL: root,
    data: body,
  });
};

// INSPECTOR PUB
const checkInspector = (i_email) => {
  return axios({
    url: `${inspectorCheckEndpointBase}/?i_email=${i_email}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

// INSPECTOR USER
const getInspectors = (token, state) => {
  return axios({
    url: `${inspectorInfoEndpointBase}/?token=${token}&state=${state}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    res.data.forEach((e) => {
      let features = [];
      if (e.inspection_features) {
        e.inspection_features.forEach((e) => {
          if (e.value) features.push(e.name);
        });
      }
      return (e.features = features);
    });
    return res.data;
  });
};

// INSPECTION COMPANY
const createInspectionCompany = (body = {}) => {
  return axios({
    url: inspectionCompanyEndpointBase,
    method: "post",
    baseURL: root,
    data: body,
  });
};

const checkInspectorCompany = (phoneNumber) => {
  return axios({
    url: `${inspectionCompanyEndpointBase}/getCompanyByPhoneNumber?phoneNumber=${phoneNumber}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const getInspectionCompany = () => {
  return axios({
    url: inspectionCompanyEndpointBase,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const updateInspectionCompany = ({
  companyid,
  comp_name,
  comp_address_one,
  comp_address_two,
  comp_city,
  comp_state,
  comp_zipcode,
  comp_email,
  comp_area_code,
  comp_cell_number,
}) => {
  return axios({
    url: `${inspectionCompanyEndpointBase}/${companyid}`,
    method: "put",
    baseURL: root,
    data: {
      comp_name,
      comp_address_one,
      comp_address_two,
      comp_city,
      comp_state,
      comp_zipcode,
      comp_email,
      comp_area_code,
      comp_cell_number,
    },
  });
};

// HOUSE
const createHouse = (body = {}) => {
  return axios({
    url: houseEndpointBase,
    method: "post",
    baseURL: root,
    data: body,
  });
};

const deleteHouse = (houseid) => {
  return axios({
    url: `${houseEndpointBase}/?houseid=${houseid}`,
    method: "delete",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const updateFullHouse = (houseData, houseDetailData) => {
  return axios({
    url: `${houseEndpointBase}/full_house`,
    method: "put",
    baseURL: root,
    data: {
      houseData,
      houseDetailData,
    },
  });
};
// HOUSE DETAIL
const createDetail = (body = {}) => {
  return axios({
    url: houseDetailEndpointBase,
    method: "post",
    baseURL: root,
    data: body,
  });
};

const deleteDetail = (house_detailid) => {
  return axios({
    url: `${houseDetailEndpointBase}/?house_detailid=${house_detailid}`,
    method: "delete",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

// JOB
const createUserJob = (body = {}) => {
  return axios({
    url: jobsEndpointBase,
    method: "post",
    baseURL: root,
    data: body,
  });
};

const getJobRequests = (inspectorToken) => {
  return axios({
    url: `${jobsEndpointBase}_inspector/restricted/?token=${inspectorToken}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const getJobHistory = (inspectorToken) => {
  return axios({
    url: `${jobsEndpointBase}_inspector/history/?token=${inspectorToken}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const getInspectionReport = (reportid, inspectorToken) => {
  return axios({
    url: `${reportEndpointBase}_inspector/inspector/${reportid}/?token=${inspectorToken}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const getActiveJobs = (inspectorToken) => {
  return axios({
    url: `${jobsEndpointBase}_inspector/accepted/?token=${inspectorToken}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const acceptJobByInspector = (jobData, inspectorToken) => {
  return axios({
    url: `${jobsEndpointBase}_inspector/?token=${inspectorToken}`,
    data: jobData,
    method: "put",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const cancelJob = (jobid, inspectorToken) => {
  return axios({
    url: `${jobsEndpointBase}_inspector/cancel/?token=${inspectorToken}`,
    method: "put",
    baseURL: root,
    data: {
      jobid,
    },
  });
};

const removeJobFromInspectorDashboard = (jobid, userToken) => {
  return axios({
    url: `${jobsEndpointBase}_inspector/inspector_remove_job/${jobid}/?token=${userToken}`,
    method: "put",
    baseURL: root,
  });
};

const acceptPreInspectionAgreementByInspector = (jobid, userToken) => {
  return axios({
    url: `${jobsEndpointBase}_inspector/inspector_accept_agreement/${jobid}/?token=${userToken}`,
    method: "put",
    baseURL: root,
  });
};

const cancelJobByUser = (jobid, userToken) => {
  return axios({
    url: `${jobsEndpointBase}/cancel/?token=${userToken}`,
    method: "put",
    baseURL: root,
    data: {
      jobid,
    },
  });
};

// const getPublicJobRequests = () => {
//   return axios({
//     url: `${jobsEndpointBase}/inspector`,
//     method: "get",
//     baseURL: root
//   });
// };

const getInspectorbyToken = (token) => {
  return axios({
    url: `${inspectorEndpointBase}/inspector/?token=${token}`,
    method: "get",
    baseURL: root,
  });
};

const getUserJobs = (userid, token) => {
  return axios({
    url: `${jobsEndpointBase}/user/${userid}?token=${token}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const signedPreAgreement = (userToken, jobid, url) => {
  return axios({
    url: `${jobsEndpointBase}/signup-agreement/?token=${userToken}`,
    baseURL: root,
    data: {
      url,
      jobid,
    },
    method: "put",
  });
};

//REPORT

const createJobReport = (reportData, inspectorToken) => {
  return axios({
    url: `${reportEndpointBase}_inspector/?token=${inspectorToken}`,
    data: reportData,
    method: "post",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const updateInspectorReport = (inspectorToken, reportToUpdate) => {
  return axios({
    url: `${reportEndpointBase}_inspector/?token=${inspectorToken}`,
    method: "put",
    baseURL: root,
    data: reportToUpdate,
  });
};

const updateInspectorAgreement = (inspectorToken, jobid, preAgreementUrl) => {
  return axios({
    url: `${jobsEndpointBase}_inspector/upload_pre_agreement/${jobid}/?token=${inspectorToken}`,
    method: "put",
    baseURL: root,
    data: { pre_agreement_url: preAgreementUrl },
  });
};

const setReportToComplete = (inspectorToken, reportid) => {
  return axios({
    url: `${reportEndpointBase}_inspector/complete/?token=${inspectorToken}`,
    baseURL: root,
    data: {
      report: reportid,
    },
    method: "put",
  });
};

// USER REPORT

const getReportByUser = (userToken, reportId) => {
  return axios({
    url: `${reportEndpointBase}/${reportId}/?token=${userToken}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

// GET REPORT'S PDF
const getReportPdf = (userToken, reportId) => {
  return axios({
    url: `${reportEndpointBase}/pdf/${reportId}/?token=${userToken}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

// HOUSKY PUB
const getPrices = () => {
  return axios({
    url: `${houskyPublicEndpointBase}/prices`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

//PROMOTION
const createPromotion = (promoData, adminToken) => {
  return axios({
    url: `${promotionEndpointBase}/?token=${adminToken}`,
    method: "post",
    baseURL: root,
    data: promoData,
  });
};

const getPromotions = (adminToken) => {
  return axios({
    url: `${promotionEndpointBase}/all/?token=${adminToken}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const updatePromotion = (promotionid, promoData, adminToken) => {
  return axios({
    url: `${promotionEndpointBase}/?token=${adminToken}&promotionid=${promotionid}`,
    method: "put",
    baseURL: root,
    data: promoData,
  });
};

//INSPECTOR RECORDS
const getAllInspectorRecords = (adminToken) => {
  return axios({
    url: `${inspectorRecordsEndpointBase}/all/?token=${adminToken}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const updateInspectorRecordsPayment = (adminToken, recordToUpdate) => {
  return axios({
    url: `${inspectorRecordsEndpointBase}/?token=${adminToken}`,
    method: "put",
    baseURL: root,
    data: recordToUpdate,
  });
};

//HOUSKY ADMIN
const getHouskyAdminPrices = (adminToken) => {
  return axios({
    url: `${privatePricesEndpointBase}/?token=${adminToken}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const updateHouskyAdminPrice = (priceData, adminToken) => {
  return axios({
    url: `${privatePricesEndpointBase}/?token=${adminToken}`,
    method: "put",
    baseURL: root,
    data: priceData,
  });
};

//USER RECORDS

const getAllUserRecords = (adminToken) => {
  return axios({
    url: `${userRecordsEndpointBase}/all/?token=${adminToken}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const getAdminUser = (adminToken) => {
  return axios({
    url: `${adminEndpointBase}/?token=${adminToken}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

//USPS SERVICES
// REMOVED FOR NOW AS USPS GIVES FALSE NEGATIVE ON SOME ADDRESSES
// IE 601 US-206, Hillsborough Township, NJ 08844
// const checkAddress = (address_one, address_two, city, state, zipcode) => {
//   return axios({
//     method: "get",
//     url: `${houskyPublicEndpointBase}/location/?address_one=${address_one}&address_two=${address_two}&city=${city}&state=${state}&zipcode=${zipcode}`,
//     baseURL: root,
//   }).then((res) => {
//     return res.data;
//   });
// };

// DARK SKY

const getForecast = (lat, lng) => {
  return axios({
    method: "get",
    url: `${houskyPublicEndpointBase}/weather/?lat=${lat}&lng=${lng}`,
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

// GOOGLE MAP

const getInspectorManagement = (token) => {
  return axios({
    url: `${inspectorEndpointBase}/getManagement/?token=${token}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const getLocationFromAddress = (address_one, city, state, zipcode) => {
  return axios({
    url: `${houskyPublicEndpointBase}/getLocationFromAddress/?address_one=${address_one}&city=${city}&state=${state}&zipcode=${zipcode}`,
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const getAddress = (lat, lng) => {
  return axios({
    url: `${houskyPublicEndpointBase}/getAddress/?lat=${lat}&lng=${lng}`,
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

//// STRIPE
const inspectorHasStripe = (inspectorToken) => {
  return axios({
    url: `${stripeInspectorEndpointBase}/account/get/?token=${inspectorToken}`,
    method: "get",
    baseURL: root,
  }).then((res) => {
    return res.data;
  });
};

const authorizeStripe = (code, inspectorToken) => {
  return axios({
    url: `${stripeInspectorEndpointBase}/account/post/?token=${inspectorToken}`,
    method: "post",
    baseURL: root,
    data: {
      code,
    },
  }).then((res) => {
    return res.data;
  });
};

const getUserPaymentStatus = (jobid, userToken) => {
  return axios({
    url: `${stripeUserEndpointBase}/${jobid}/get/?token=${userToken}`,
    method: "get",
    baseURL: root,
  }).then((res) => res.data);
};

const getJobPaymentIntent = (jobid, inspectorid, token) => {
  return axios({
    url: `${stripeUserEndpointBase}/${jobid}/${inspectorid}/get/?token=${token}`,
    method: "get",
    baseURL: root,
  }).then((res) => res.data);
};

const postJobConfirmPayment = (jobid, inspectorid, paymentIntent, token) => {
  return axios({
    url: `${stripeUserEndpointBase}/${jobid}/${inspectorid}/confirmation/?token=${token}`,
    method: "post",
    baseURL: root,
    data: {
      payment_intent: paymentIntent,
    },
  }).then((res) => res.data);
};

const confirmPayment = (jobid, userToken) => {
  return axios({
    url: `${stripeUserEndpointBase}/${jobid}/confirmation/?token=${userToken}`,
    method: "get",
    baseURL: root,
  });
};

const releaseEscrow = (jobid, userToken) => {
  return axios({
    url: `${stripeUserEndpointBase}/${jobid}/release-escrow/?token=${userToken}`,
    method: "post",
    baseURL: root,
  });
};

const getInspectorsByAdmin = (adminToken) => {
  return axios({
    url: `${adminEndpointBase}/get/inspectors/?token=${adminToken}`,
    method: "get",
    baseURL: root,
  });
};

const getAllTools = (adminToken) => {
  return axios({
    url: `${adminEndpointBase}/get/tools/?token=${adminToken}`,
    method: "get",
    baseURL: root,
  });
};

const updateTool = (adminToken, tool) => {
  return axios({
    url: `${adminEndpointBase}/update/tool/?token=${adminToken}`,
    method: "put",
    baseURL: root,
    data: tool,
  });
};

const deleteTool = (adminToken, toolId) => {
  return axios({
    url: `${adminEndpointBase}/delete/tool/?token=${adminToken}`,
    method: "delete",
    baseURL: root,
    data: {
      toolId,
    },
  });
};

const createTool = (adminToken, tool) => {
  return axios({
    url: `${adminEndpointBase}/create/tool/?token=${adminToken}`,
    method: "post",
    baseURL: root,
    data: tool,
  });
};

const getInspectorProfile = (idToken, inspectorId) => {
  return axios({
    url: `${adminEndpointBase}/get/inspector/${inspectorId}/?token=${idToken}`,
    method: "get",
    baseURL: root,
  });
};

/// only gets the id, email, phone number and name
const getInspectorBasicProfile = (idToken, inspectorId) => {
  return axios({
    url: `${adminEndpointBase}/get/inspectorBasicInfo/${inspectorId}/?token=${idToken}`,
    method: "get",
    baseURL: root,
  });
};

const getReviews = (companyId) => {
  return axios({
    url: `reviews/get/${companyId}`,
    method: "get",
    baseURL: root,
  });
};

const upsertReviews = (companyId, review) => {
  return axios({
    url: `reviews/upsert/${companyId}`,
    method: "post",
    baseURL: root,
    data: review,
  });
};

const upsertInspectorNotes = (token, inspectorId, notes) => {
  return axios({
    url: `${adminEndpointBase}/upsertInspectorNotes/${inspectorId}?token=${token}`,
    method: "post",
    baseURL: root,
    data: notes,
  });
};

const getReviewByJobId = (jobid) => {
  return axios({
    url: `reviews/getReview/${jobid}`,
    method: "get",
    baseURL: root,
  });
};

const getInspectorSkillsReviews = (inspectorId) => {
  return axios({
    url: `reviews/inspector_averages/${inspectorId}`,
    method: "get",
    baseURL: root,
  });
}

const createInspectorJobReview = (inspectorId, jobId, payload) => {
  return axios({
    url: `reviews/inspector/${inspectorId}/job/${jobId}`,
    method: "post",
    baseURL: root,
    data: payload,
  });
};

const updateHouskyCertification = (idToken, inspectorId) => {
  return axios({
    url: `${adminEndpointBase}/update/inspector/certification/${inspectorId}/?token=${idToken}`,
    method: "get",
    baseURL: root,
  });
};

const ipLookup = () => {
  // Check `ipInfoDefault` constant at constant.js file to see its structure
  return axios({
    url: `https://ipapi.co/json/`,
    method: "get",
    baseURL: root,
  })
    .then((res) => {
      // This is triggered in the case we are not visit the page from US
      if (res.data.country_code !== "US") {
        return ipInfoDefault;
      }
      return res.data;
    })
    .catch(() => {
      return {
        postal: 10004,
        region_code: "NY",
        latitude: 40.7128,
        longitude: -74.006,
      };
    });
};

const getLoginLink = (token) => {
  return axios({
    url: `${stripeInspectorEndpointBase}/account/get/login/?token=${token}`,
    method: "get",
    baseURL: root,
  });
};

const getOpenJobs = (token, filter = "pending") => {
  return axios({
    url: `${openJobsEndpointBase}/get/?token=${token}&&filter=${filter}`,
    method: "get",
    baseURL: root,
  });
};

const getPendingJobs = (token) => {
  return axios({
    url: `admin/get/pendingJobs/?token=${token}`,
    method: "get",
    baseURL: root,
  });
};

const signContract = (token, contract_id, contractBody) => {
  return axios({
    url: `${contractEndpointBase}/${contract_id}?token=${token}`,
    method: "put",
    baseURL: root,
    data: contractBody,
  });
};

// ****************** new landing page **************************//

const searchInspector = async (searchTerm) => {
  const isZipcode = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(searchTerm);
  if (isZipcode) {
    return await searchInspectorByZipcode(searchTerm);
  } else {
    return await searchInspectorByName(searchTerm);
  }
};

const sortInspectorByDistance = (inspectors, latitude, longitude) => {
  let result = inspectors
    .map((d) => {
      let features = [];
      if (d.inspection_features) {
        d.inspection_features.forEach((f) => {
          if (f.value) features.push(f.name);
        });
        d.features = features;
      }
      d.distance =
        Math.pow(d.latitude - latitude, 2) +
        Math.pow(d.longitude - longitude, 2);
      return d;
    })
    .sort((a, b) => {
      if (a.distance < b.distance) return -1;
      if (a.distance > b.distance) return 1;
      return 0;
    });

  return result;
};

// SEARCH INSPECTOR BY name or company name
const searchInspectorByName = async (name) => {
  try {
    const locationInfo = await ipLookup();
    if (locationInfo && locationInfo.region_code) {
      const res = await axios({
        url: `${searchInspectorByNameRoute}`,
        method: "post",
        baseURL: root,
        data: {
          name,
          state: locationInfo.region_code,
          limit: 30,
        },
      });

      return sortInspectorByDistance(
        res.data,
        locationInfo.latitude,
        locationInfo.longitude
      );
    } else {
      return [];
    }
  } catch (e) {
    console.log("error while getting inspectors by name", e);
    return [];
  }
};

// SEARCH INSPECTOR BY Zipcode
const searchInspectorByZipcode = async (zipcode) => {
  try {
    const res = await axios({
      url: `${searchInspectorByStateRoute}`,
      method: "post",
      baseURL: root,
      data: {
        zipcode: zipcode,
        limit: 30,
      },
    });
    res.data.forEach((e) => {
      let features = [];
      if (e.inspection_features) {
        e.inspection_features.forEach((e) => {
          if (e.value) features.push(e.name);
        });
      }
      return (e.features = features);
    });
    return res.data;
  } catch (e) {
    console.log("error while searching inspector by zipcode", e);
    return [];
  }
};

const getLocationInfo = async (zipcode) => {
  try {
    const url = `${getLocationByZipcodeRoute}/${zipcode}`;
    const res = await axios({
      url,
      method: "get",
      baseURL: root,
    });
    return res.data;
  } catch (e) {
    return null;
  }
};

export {
  root,
  createUser,
  getUser,
  updateUser,
  getInspectors,
  checkIsComplete,
  getInspectorsByInspector,
  updateInspector,
  updateInspectorUser,
  updateInspectorAddress,
  updateInspectorLicenses,
  updateInspectorCompanyInfo,
  updateSampleReport,
  updatePersonalToS,
  checkInspector,
  updateGallery,
  accountManagement,
  createInspector,
  createInspectionCompany,
  getInspectionCompany,
  createHouse,
  deleteHouse,
  createDetail,
  deleteDetail,
  createUserJob,
  getJobRequests,
  getActiveJobs,
  acceptJobByInspector,
  updateInspectionCompany,
  getInspectorbyToken,
  getJobHistory,
  getInspectionReport,
  getUserJobs,
  createJobReport,
  updateInspectorReport,
  updateInspectorAgreement,
  updateFullHouse,
  getReportByUser,
  getReportPdf,
  getPrices,
  setReportToComplete,
  createPromotion,
  getPromotions,
  updatePromotion,
  getAllInspectorRecords,
  updateInspectorRecordsPayment,
  createInspectorJobReview,
  getHouskyAdminPrices,
  updateHouskyAdminPrice,
  getAllUserRecords,
  getAdminUser,
  cancelJob,
  removeJobFromInspectorDashboard,
  acceptPreInspectionAgreementByInspector,
  getForecast,
  getInspectorManagement,
  getLocationFromAddress,
  getAddress,
  cancelJobByUser,
  inspectorHasStripe,
  authorizeStripe,
  getUserPaymentStatus,
  ipLookup,
  confirmPayment,
  getInspectorsByAdmin,
  getInspectorProfile,
  updateHouskyCertification,
  getLoginLink,
  getOpenJobs,
  signContract,
  searchInspector,
  searchInspectorByZipcode,
  getLocationInfo,
  updateInspectorUserImage,
  createInspectorStep1,
  createInspectorStep2,
  createInspectorStep3,
  createInspectorStep4,
  checkInspectorCompany,
  getReviews,
  upsertReviews,
  getInspectorsByInspectorId,
  getAllTools,
  updateTool,
  deleteTool,
  createTool,
  getInspectorPhotoUrl,
  updateInspectorCalculatorParams,
  getInspectorCalculatorParams,
  getInspectorCustomizedUrlNameByInspectorToken,
  getInspectorIdByCustomizedUrlName,
  updateCustomizedUrlName,
  getInspectorToolsByInspectorId,
  getAllToolsWithInspectorToken,
  updateInspectorTools,
  updateShowPriceRange,
  signedPreAgreement,
  getInspectorIdByToken,
  getJobPaymentIntent,
  postJobConfirmPayment,
  releaseEscrow,
  getReviewByJobId,
  getInfoByInspectorId,
  getInspectorSkillsReviews,
  getPendingJobs,
  getInspectorBasicProfile,
  upsertInspectorNotes
};
