import React from 'react'
import { Link } from 'react-router-dom'

export default (props) =>{
  return <div style={{backgroundColor:'#FADA5E', color:'white'}}>
    <h5 className='m-0 p-2'>
      {!props.isActive.housky_certified ? <p className='m-0'>Your account is currently in process of verification. Please give us 1-3 days to verify your account. Meanwhile, please go to Profile-Settings to complete the rest of your profile, such as profile picture, bios, etc</p>
      : !props.isActive.account_active ? <p className='m-0'>Your account is currently frozen and you will not recieve new job requests. Please visit your <Link to='/account'>Settings</Link> to reactive your account.</p> : ""}
    </h5>
  </div>
}