import React from "react";
import { getAllInspectorRecords, getInspectionReport, getHouskyAdminPrices } from "../../../services/service";
import { inspectorApp as firebase } from "../../../firebase";
import AuthContext from "../../../contexts/auth";
import InspectorRecordsRow from "../InspectorRecordsRow/InspectorRecordsRow";
import SelectedInspectorRecord from "../SelectedInspectorRecord/SelectedInspectorRecord";
class InspectorRecords extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      idToken: null,
      allInspectorRecords: [],
      selectedInspectorRecord: {},
      housky_prices: [],
      house_types: []
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        this.setState({ idToken });
        return getAllInspectorRecords(idToken);
      })
      .then(data => {
        this.setState({ allInspectorRecords: data });
        return getHouskyAdminPrices(this.state.idToken);
      })
      .then(data => {
        this.setState({ housky_prices: data.housky_prices, house_types: data.house_types });
      })
      .catch(err => {
        console.log(err.request.responseURL);
        console.log(err.message);
      });
  }

  handleSelectInspectorRecord = e => {
    if (
      this.state.selectedInspectorRecord.inspector_recordid &&
      this.state.selectedInspectorRecord.inspector_recordid === e.target.parentNode.id
    ) {
      this.setState({ selectedInspectorRecord: {} });
      return;
    }
    for (let inspectorRecord of this.state.allInspectorRecords) {
      if (inspectorRecord.inspector_recordid === e.target.parentNode.id) {
        this.setState({ selectedInspectorRecord: { ...inspectorRecord } });
      }
    }
  };

  handleSeeReport = () => {
    getInspectionReport(this.state.selectedInspectorRecord.reportid, this.state.idToken).then(report => {
      window.open(report.pdf_url, "_blank");
    });
  };

  getInspectorRecords = selectedInspectorRecordId => {
    getAllInspectorRecords(this.state.idToken).then(data => {
      let newSelectedInspectorRecord = {};
      for (let record of data) {
        if (record.inspector_recordid === selectedInspectorRecordId) {
          newSelectedInspectorRecord = record;
        }
      }
      this.setState({
        allInspectorRecords: data,
        selectedInspectorRecord: newSelectedInspectorRecord
      });
    });
  };

  render() {
    const { selectedInspectorRecord } = this.state;
    return (
      <div style={{ minHeight: "95vh" }}>
        <div className="container mt-5 pt-5">
          <div className="card-panel grey lighten-5">
            <table className="striped centered highlight mb-5">
              <thead>
                <tr>
                  <th>Inspector Name</th>
                  <th>Paid</th>
                  <th>Amount</th>
                  <th>User Name</th>
                  <th>Inspector Email</th>
                </tr>
              </thead>
              <tbody>
                {this.state.allInspectorRecords.length > 0 ? (
                  this.state.allInspectorRecords.map(e => {
                    return (
                      <InspectorRecordsRow
                        key={e.inspector_recordid}
                        inspectorRecordsData={e}
                        clickEvent={this.handleSelectInspectorRecord}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5">{`No records yet`}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* DIV for filter will go here */}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {selectedInspectorRecord.inspector_recordid ? (
            <SelectedInspectorRecord
              housePrices={this.state.housky_prices}
              houseTypes={this.state.house_types}
              inspectorRecord={selectedInspectorRecord}
              handleSeeReport={this.handleSeeReport}
              idToken={this.state.idToken}
              getInspectorRecords={this.getInspectorRecords}
            />
          ) : (
            <div className="card-panel grey lighten-5">
              <label>
                <h5 className="center-align">Select a record to see more options</h5>
              </label>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default InspectorRecords;
