import React from "react";
import Materialize from "materialize-css/dist/js/materialize.min.js";

class Foundation extends React.Component {
  componentDidMount() {
    let elem = document.querySelectorAll("select");
    Materialize.FormSelect.init(elem, {
      classes: ""
    });
  }
  render() {
    const { foundation } = this.props;
    return (
      <div className="input-field col s6">
        <select
          id="foundation"
          name="foundation"
          value={foundation}
          onChange={this.props.handleChange}
        >
          <option value='Select Foundation' disabled>Select Foundation</option>
            <option value="Not Applicable">Not Applicable</option>
            <option value="Basement(Finished)">Basement(Finished)</option>
            <option value="Basement(Partial)">Basement(Partial)</option>
            <option value="Basement(Unfinished)">Basement(Unfinished)</option>
            <option value="Basement(Finished) & Crawlspace">Basement(Finished) & Crawlspace</option>
            <option value="Basement(Unfinished) & Crawlspace">Basement(Unfinished) & Crawlspace</option>
            <option value="Crawlspace">Crawlspace</option>
            <option value="Piers/Pilings">Piers/Pilings</option>
            <option value="Slab">Slab</option>
            <option value="Other">Other</option>
        </select>
        <label htmlFor="foundation">Foundation</label>
      </div>
    );
  }
}

export default Foundation;
