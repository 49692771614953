import React from 'react';
import EvaluationDots from "../../../containers/MainDomain/IndividualPage/EvaluationDots";

import styles from './InspectorSkillsEvaluations.module.css'

const defaultProps = {
  communication: 0,
  expertise: 0,
  reportQuality: 0,
  detailOriented: 0,
  handleEvaluations: () => {},
}

const InspectorSkillsEvaluations =
  ({
     communication = defaultProps.communication,
     expertise = defaultProps.expertise,
     reportQuality = defaultProps.reportQuality,
     detailOriented = defaultProps.detailOriented,
     handleEvaluations = defaultProps.handleEvaluations,
   }) => {
    return (
      <div className={styles.inspectorEvaluationsContainer}>
        <div className={styles.sectionName}>Skill/Ability</div>
        <div className={styles.evaluationItem}>
          <span className={styles.itemTitle}>Communication</span>
          <span className={styles.itemRatingDots}>
            <EvaluationDots
              score={communication}
              skillEvaluated={"communication"}
              disabled={false}
              handlePassUp={handleEvaluations}
            />
          </span>
        </div>
        <div className={styles.evaluationItem}>
          <span className={styles.itemTitle}>Expertise</span>
          <span className={styles.itemRatingDots}>
            <EvaluationDots
              score={expertise}
              skillEvaluated={"expertise"}
              disabled={false}
              handlePassUp={handleEvaluations}
            />
          </span>
        </div>
        <div className={styles.evaluationItem}>
          <span className={styles.itemTitle}>Report Quality</span>
          <span className={styles.itemRatingDots}>
            <EvaluationDots
              score={reportQuality}
              skillEvaluated={"reportQuality"}
              disabled={false}
              handlePassUp={handleEvaluations}
            />
          </span>
        </div>
        <div className={styles.evaluationItem}>
          <span className={styles.itemTitle}>Detail Oriented</span>
          <span className={styles.itemRatingDots}>
            <EvaluationDots
              score={detailOriented}
              skillEvaluated={"detailOriented"}
              disabled={false}
              handlePassUp={handleEvaluations}
            />
          </span>
        </div>
      </div>
    );
  }

export default InspectorSkillsEvaluations;
