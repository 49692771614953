import React, {useEffect, useState} from 'react';
import PaymentFormWrapper from "../../GeneralComponents/PaymentForm/PaymentForm";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import {app as userFirebase} from "../../../firebase";
import {getJobPaymentIntent} from "../../../services/service";

const INITIAL_STATE = {
  paymentIntent: {},
  isLoadingPaymentIntent: false,
  userToken: "",
}

const AcceptPaymentButton = ({job, inspectorid}) => {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    let elems = document.querySelectorAll(".modal");
    Materialize.Modal.init(elems, {inDuration: "450"});
    Materialize.updateTextFields();
  }, []);

  const handleOnClick = (event) => {
    event.preventDefault();

    setState({
      ...state,
      isLoadingPaymentIntent: true,
    });

    userFirebase.auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        getJobPaymentIntent(job.jobid, inspectorid, idToken)
          .then((data) => {
            setState({
              ...state,
              paymentIntent: data['payment_intent'],
              isLoadingPaymentIntent: false,
              userToken: idToken,
            });
          })
          .catch((error) => {
            console.error('Error getting payment intent', error);
            setState({
              ...state,
              isLoadingPaymentIntent: false,
              userToken: idToken,
            });
          });
      });
  }

  const renderCircularLoading = () => {
    return (
      <div className="loading-cart">
        <div className="preloader-wrapper big active">
          <div className="spinner-layer spinner-green-only">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>
            <div className="gap-patch">
              <div className="circle"></div>
            </div>
            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
        <span>Preparing your cart...</span>
      </div>
    );
  }

  return (
    <>
      <button
        data-target="checkout-job-modal"
        className="btn waves-effect modal-trigger"
        onClick={handleOnClick}
      >
        ACCEPT AND PAY
      </button>
      <div
        id="checkout-job-modal"
        className="modal"
      >
        {
          state.isLoadingPaymentIntent &&
          renderCircularLoading()
        }
        {
         !state.isLoadingPaymentIntent &&
          <PaymentFormWrapper
            job={job}
            inspectorid={inspectorid}
            paymentIntent={state.paymentIntent}
            userToken={state.userToken}
          />
        }
      </div>
    </>
  );
}

export default AcceptPaymentButton;
