import _ from 'lodash';
import moment from 'moment';
import {calculateInspectorPayout} from "../../../utils/utils";

function getInspectorCost(job) {
    const inspector_offer = !!job.inspector_offer ? job.inspector_offer : {};
    const cost = !!inspector_offer.cost ? inspector_offer.cost : 0;
    return calculateInspectorPayout(cost);
}

function mapData(dataArray) {
    return dataArray.map(data => {
        return {
            date: data.eventinfo[0].unix,
            inspection_cost: getInspectorCost(data)
        }
    });
}

function groupDataByDate(dataArray) {
    return _.groupBy(dataArray, (data) => {
        return moment(data.date, "x").format('MM/DD/YYYY');
    })
}

function convertDataForChart(dataGroupedByDate) {
    let result = {};
    const getTotalEarn = (xs) => {
        const foo = xs.reduce((totalCost, x) => {
            totalCost += x.inspection_cost;
            return totalCost;
        }, 0);
        return foo
    };

    Object.keys(dataGroupedByDate)
      .forEach(key => {
          result[key] = {
              totalEarn: getTotalEarn(dataGroupedByDate[key]),
              totalNumberOfInspection: dataGroupedByDate[key].length
          }
      });
    return result;
}

export function generateDataForTotalCostGraph(dataArray) {
    const mappedData = mapData(dataArray);
    const groupedData = groupDataByDate(mappedData);
    const data = convertDataForChart(groupedData);
    let result = [];

    Object.keys(data).forEach(key => {
        result.push({
            time: key,
            totalEarn: data[key].totalEarn,
            totalNumberOfInspection: data[key].totalNumberOfInspection
        });
    })
    return result;
}
