import React from 'react'
import './terms.css'
export default () => {
  return <>
    <div className='row nav-offset'>
    </div>
    <div className='term-background'>
      <div className='container pt-5'>
        <div className='inner-container'>
          <div className='row mt-1 mb-0 p-2 pb-5 content-background'>
            <div className='col s12'>
              <div>
                <h3><b>Housky&#39;s Booking Protection</b></h3>
              </div>
            </div>
            <article>
              <section>
                <p>Housky&#39;s Booking Protection</p>
                <p>
                  Though it&#39;s rare, occasionally an inspector may need to reschedule or even cancel at the last minute. If that
                  happens to you, we&#39;ve got your back: every booking made through Housky includes Booking Protection.
                  Here&#39;s what that means:
              </p>
              </section>
            </article>
            <article>
              <h5><b>
                Rescheduling
              </b></h5>
              <section>
                <p>
                  If an inspector cannot perform an inspection on your agreed inspection request date for some reason (most
                  of the case will be due to weather related reason as it is an important factor to perform a decent inspection),
                  the inspector will try to reach out and reschedule to a different date with you.
                </p>
              </section>
              <section>
                <p>
                  If you are not happy with the date the inspector tries to reschedule and want a different inspector, please
                  email us, we will help you cancel your request and find a different inspector.
                </p>
              </section>
            </article>
            <article>
              <h5><b>
                We&#39;ll Give You a Refund
              </b></h5>
              <section>
                <p>
                  If an inspector cancels your agreed inspection request within three days of your service start date, we&#39;ll give
                  you a refund. We’ll also provide a $20 credit to help cover the cost of the new service.
                </p>
              </section>
              <section>
                <p>
                  If the booking is cancelled by the home inspector due to a lack of information or cooperation from the home
                  owner (current or prospective), then the owner will have to cover the full cost of any new reservation.
                </p>
              </section>
            </article>
            <article>
              <h5><b>We can help you find a new Home Inspector</b></h5>
              <section>
                <a href="mailto:info@housky.io">Email</a> or call us—we can help you find a great replacement.
              </section>
            </article>
          </div></div>
      </div>
    </div>
  </>
}