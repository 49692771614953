import React from 'react'
import './home.css'
import SearchBar from "../../../components/GeneralComponents/SearchBar/SearchBar";
import HomePageCard from './HomePageCard';
import {useHistory} from "react-router-dom";

export default () => {

  const history = useHistory();

  const cardContents = [
    {
      img_src: require('../../../assets/Home_Buyer_Icon2.png'),
      title: 'Home Buyers',
      intro: 'A good home inspector will save you thousands of dollars on your investment.',
      buttonTitle: 'Learn More',
      path: 'learning_center'
    },
    {
      img_src: require('../../../assets/HomeSeller_Icon.png'),
      title: 'Home Sellers',
      intro: 'A home inspection will make you aware of the issues with your house before listing it on the market helping you avoid surprises in the sale.',
      buttonTitle: 'Learn More',
      path: 'https://report.housky.io'
    },
    {
      img_src: require('../../../assets/Home_Inspector_Icon_HP.png'),
      title: 'Inspectors',
      intro: 'Find out how Housky can help you grow your business and assist you along the way.',
      buttonTitle: 'Learn More',
      path: 'https://inspector.housky.io'
    }
  ]

  const searchNearMe = () => {
    history.push(`/search?query=`);
  };

  return(
    <div>   
      <div className="main-domain-home__banner-container">
        <div className="main-domain-home__searchBar-container">
          <div>
            <span>Search Home Inspector Near You</span>
            <span>Discover Home Inspection Cost</span>
            <span>Schedule Home Inspection Online</span>
            <SearchBar />
            <div className="main-domain-home__quickSearchContainer">
              <div onClick={searchNearMe}>
                Inspectors near me
              </div>
            </div>
          </div>
        </div>
        <div className="main-domain-home__home-page-icon-container">
          <img src={require('../../../assets/hp-banner-icon.png')}/>
        </div>
      </div>
      <div className="main-domain-home__card-container">
        <div id="section-title">
          <span>Housky connects you with the right home inspector and facilitates the home inspection process.</span>
        </div>
        <div id="card-rows">
          {
            cardContents.map((card, index) => {
              return <HomePageCard
                  key={index}
                  title={card.title}
                  intro={card.intro}
                  buttonTitle={card.buttonTitle}
                  path={card.path}
                  src={ card.img_src }/>
            })
          }
        </div>
      </div>      
    </div>
  )
}