import AppointmentInfo from "../AppointmentInfo/AppointmentInfo";
import HomeDetails from "../HomeDetails/HomeDetails";
import JobDetails from "../JobDetails/JobDetails";
import RadioButton from "../../GeneralComponents/RadioButton/RadioButton";
import React, { useEffect, useState, useRef } from "react";
import TimeOfDay from "../TimeOfDay/TimeOfDay";
import UserInfo from "../UserInfo/UserInfo";
import moment from "moment";
import "./SelectedJobs.css";
import InspectionAgreement from "../InspectionAgreement";
import ChatRoom from "../../GeneralComponents/ChatRoom";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

const SelectedJob = ({
  idToken,
  jobid,
  houseid,
  reportid,
  eventinfo,
  title,
  current_status,
  additional_features,
  job_type,
  address_one,
  address_two,
  city,
  state,
  zipcode,
  country,
  house_typeid,
  house_detailid,
  userid,
  first_name,
  last_name,
  email,
  area_code,
  cell_number,
  token,
  registration_date,
  bedroom,
  bathroom,
  sqft,
  year_built,
  parking,
  foundation,
  attic,
  description_text,
  house_type,
  timeSelected,
  price,
  radon,
  wdi,
  selectAppointmentTime,
  handleOtherDate,
  handleOtherTime,
  handleInspectorCost,
  handleAppointmentSubmit,
  handleSelectedOptions,
  handleCreateJobReport,
  handlePreAgreementUpload,
  handleRemoveJobFromDashboard,
  setNewCurrentStatus,
  isLoading,
  isActive,
  paid,
  closeJobDetail,
  inspector_offer,
  isOtherDate,
  isOtherTime,
  otherDate,
  otherTime,
  assigned_to_same_inspector,
  pre_inspection_agreement_url,
  signed_pre_inspection_agreement_url,
  pre_inspection_agreement_accepted,
  handleAcceptPreInspectionAgreement,
  price_range,
  inspectorId,
  report_status,
  report_pdf_url,
  calculatorParams
}) => {
  const accepted = current_status.toUpperCase() === "ACCEPTED";
  const completed = current_status.toUpperCase() === "COMPLETED";

  const houseInfo = {
    houseid,
    address_one,
    address_two,
    city,
    state,
    zipcode,
    country,
    house_typeid,
    house_detailid,
    bedroom,
    bathroom,
    sqft,
    year_built,
    parking,
    foundation,
    attic,
    description_text,
    house_type,
    radon,
    wdi,
  };
  const userInfo = {
    accepted,
    userid,
    first_name: `${first_name[0]}${first_name.slice(1).toLowerCase()}`,
    last_name: `${last_name[0]}${last_name.slice(1).toLowerCase()}`,
    email,
    area_code,
    cell_number,
  };
  const jobDetails = {
    jobid,
    wdi: additional_features.wdi,
    radon: additional_features.radon,
    wdiValue: calculatorParams?.WDIBase || 0,
    radonValue: calculatorParams?.radonBase || 0,
    current_status,
    house_typeid,
    house_type,
    prices: { price, radon, wdi },
    inspector_offer
  };
  const messageId = !isEmpty(inspector_offer) && !isEmpty(inspector_offer.message_id) ? inspector_offer.message_id : ''

  const [inspectorLocation, setInspectorLocation] = useState({});
  const [showId, setShowId] = useState(false);
  const [inspectorPriceRange, setInspectorPriceRange] = useState({});
  const [height, setHeight] = useState();
  const mediaMatch = window.matchMedia("(min-width: 600px)");
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current?.clientHeight);
    if (!isEmpty(inspectorId)) {
      setInspectorPriceRange(get(price_range, inspectorId, {}));
    }
  }, [jobid, inspectorId]);

  const renderPreInspectionAgreement = () => {
    if (accepted && assigned_to_same_inspector || completed) {
      return (
        <div className="col s12 m6">
          <div
            className="divider show-on-small hide-on-med-and-up"
            style={{ marginTop: "2rem", marginBottom: "1rem" }}
          />
          <InspectionAgreement
            jobId={jobid}
            handlePreAgreementUpload={handlePreAgreementUpload}
            preInspectionAgreementUrl={pre_inspection_agreement_url}
            signedPreInspectionAgreementUrl={
              signed_pre_inspection_agreement_url
            }
            preInspectionAgreementAccepted={pre_inspection_agreement_accepted}
            handleAcceptPreInspectionAgreement={
              handleAcceptPreInspectionAgreement
            }
            isLoading={isLoading}
            current_status={current_status}
          />
        </div>
      );
    }
    return <></>;
  };

  const renderHouseInfo = () => {
    if (accepted && assigned_to_same_inspector || completed) {
      return <HomeDetails {...houseInfo} />;
    }
    return <></>;
  };

  const renderLastUpdateDate = () => {
    let dateFormatted = "";
    if (
      typeof inspector_offer.updated_at !== "undefined" &&
      inspector_offer.updated_at !== ""
    ) {
      dateFormatted = `Last update at ${moment(
        inspector_offer.updated_at
      ).format("MM/DD/YYYY hh:mm:ss a")}`;
    }
    return <p>{dateFormatted}</p>;
  };

  const renderAppointmentInfo = () => {
    if (accepted || completed) {
      return (
        <>
          <div className="col s12 m6">
            <div
              className="divider show-on-small hide-on-med-and-up"
              style={{ marginTop: "2rem", marginBottom: "1rem" }}
            />
            <AppointmentInfo
              eventInfo={eventinfo}
              assignedToSameInspector={assigned_to_same_inspector}
              handleRemoveJobFromDashboard={handleRemoveJobFromDashboard}
              jobStatus={current_status}
            />
            {(assigned_to_same_inspector || completed) && (
              <JobDetails
                {...jobDetails}
                userInfo={userInfo}
                setNewCurrentStatus={setNewCurrentStatus}
              />
            )}
          </div>
        </>
      );
    } else {
      return (
        <form onSubmit={handleAppointmentSubmit}>
          <div className="col s12 m6">
            <div
              className="divider show-on-small hide-on-med-and-up"
              style={{ marginTop: "2rem", marginBottom: "1rem" }}
            />
            <p>
              <span>
                The client is available on these dates, select a date to confirm
                schedule
              </span>
            </p>
            <div className="col s12 pl-0">
              {eventinfo.map((e) => {
                return (
                  <p key={jobid + e.unix}>
                    <RadioButton
                      setName="time-slots"
                      setId={e.unix}
                      handleClick={selectAppointmentTime}
                      setText={moment(e.unix, "x").format("dddd, MMM Do YYYY")}
                    />
                  </p>
                );
              })}
              <p key="other-date">
                <RadioButton
                  setName="time-slots"
                  setId={"other-date"}
                  handleClick={selectAppointmentTime}
                  setText={"Other"}
                  setChecked={isOtherDate}
                />
              </p>
            </div>
            {isOtherDate && (
              <div className="col s12 pl-0">
                <div className="input-field">
                  <input
                    id="other-date"
                    value={otherDate}
                    type="date"
                    onChange={handleOtherDate}
                  />
                  <label className="active" htmlFor="other-date">
                    New date (mm / dd / yyyy)
                  </label>
                </div>
              </div>
            )}

            {timeSelected && !isOtherDate && (
              <>
                {eventinfo.map((e) => {
                  return (
                    e.unix === timeSelected.unix && (
                      <TimeOfDay
                        key={jobid + e.unix}
                        timeSelected={timeSelected}
                        timeOfDay={e}
                        otherTime={otherTime}
                        isOtherTime={isOtherTime}
                        handleSelectedOptions={handleSelectedOptions}
                        handleOtherTime={handleOtherTime}
                      />
                    )
                  );
                })}
              </>
            )}
            {!!timeSelected && !!isOtherDate && (
              <>
                <TimeOfDay
                  key={jobid + timeSelected.unix}
                  timeSelected={timeSelected}
                  timeOfDay={timeSelected}
                  otherTime={otherTime}
                  isOtherTime={isOtherTime}
                  handleSelectedOptions={handleSelectedOptions}
                  handleOtherTime={handleOtherTime}
                />
              </>
            )}

            <div className={`col s12 pl-0 ${isOtherTime ? "" : "mt-4"}`}>
              <div className="input-field relative">
                <span className="dollarBox">$</span>
                <input
                  id="final-quote"
                  placeholder=""
                  value={!!inspector_offer && !!inspector_offer.cost ? inspector_offer.cost : ''}
                  type="text"
                  /*   min={
                    !isEmpty(inspectorPriceRange) &&
                    get(inspectorPriceRange, "min", "")
                  }
                  max={
                    !isEmpty(inspectorPriceRange) &&
                    get(inspectorPriceRange, "max", "")
                  }*/
                  onChange={
                    typeof handleInspectorCost !== "undefined"
                      ? handleInspectorCost
                      : () => {}
                  }
                />

                <label className="active" htmlFor="final-quote">
                  Final quote{" "}
                  {!isEmpty(inspectorPriceRange) && (
                    <span>
                      ( ${inspectorPriceRange.min} - ${inspectorPriceRange.max}{" "}
                      )
                    </span>
                  )}
                </label>
              </div>
            </div>

            {isLoading && (
              <div className="col s12 p-0">
                <div className="col s12 p-0 progress">
                  <div className="indeterminate" />
                </div>
              </div>
            )}
            {!isActive ? (
              <React.Fragment>
                <button className="btn mt-2" disabled={true}>
                  Accept Request
                </button>
                <div>
                  <label>
                    Please set up Stripe before you can accept any job
                  </label>
                </div>
              </React.Fragment>
            ) : (
              <div>
                <button
                  className={`btn mt-5  ${isLoading ? "disabled" : ""}`}
                  type="submit"
                  // onClick={handleAppointmentSubmit}
                >
                  Accept Request
                </button>
                {renderLastUpdateDate()}
              </div>
            )}
          </div>
        </form>
      );
    }
  };

  const renderCreateReport = () => {
    if (!accepted) {
      return <></>;
    }

    if (!report_status) {
      return (
        <div className="col s12 m6">
          <div className={"pt-3"}>
            <h5>Create report</h5>
            <div>
              <button className={"btn"} onClick={handleCreateJobReport}>
                Create
              </button>
            </div>
          </div>
        </div>
      );
    } else if (report_status === "PENDING") {
      return (
        <div className="col s12 m6">
          <div className={"pt-3"}>
            <h5>Create report</h5>
            <div>
              <button className={"btn"} onClick={handleCreateJobReport}>
                Update
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col s12 m6">
          <div className={"pt-3"}>
            <h5>Check report</h5>
            <div>
              <a target="_blank" href={report_pdf_url}>See report</a>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="row">
      <div className="col s12 m8">
        <div
          style={{
            backgroundColor: "white",
            position: "relative",
          }}
          className="animate fadeInLeft one card-panel pb-5"
          id="jobDescription"
          ref={ref}
        >
          <div className="row">
            <div className="col s12">
              <p className="job-id-title text-bold">
                Job ID:{" "}
                <span className="job-id-title text-normal">
                  {!showId ? jobid.slice(0, 8) : jobid}{" "}
                  <button
                    className="btn-floating expandBtn"
                    onClick={() => {
                      setShowId(!showId);
                    }}
                  >
                    {!showId ? (
                      <i className="material-icons left">chevron_right</i>
                    ) : (
                      <i className="material-icons left">chevron_left</i>
                    )}
                  </button>
                </span>
              </p>
            </div>
          </div>
          <div className="divider" style={{ borderTop: "1px solid #bbb" }} />
          <div className="row">
            <div className="col s12 m6">
              <UserInfo
                userInfo={userInfo}
                houseInfo={houseInfo}
                jobDetails={jobDetails}
              />
              {renderHouseInfo()}
            </div>
            {renderAppointmentInfo()}
            {renderPreInspectionAgreement()}
            {renderCreateReport()}
            {typeof closeJobDetail !== "undefined" ? (
              <div className="closeButton" onClick={closeJobDetail}>
                <i className="material-icons">close</i>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="col s12 m4">
        <div
          id="shortChat"
          style={{
            backgroundColor: "white",
            position: "relative",
          }}
          className="animate fadeInLeft one card-panel"
        >
          <ChatRoom
            messageId={messageId}
            userId={inspectorId}
            profile="INSPECTOR"
            height={mediaMatch.matches ? height : null}
            disabled={!messageId || !['PENDING', 'ACCEPTED'].includes(current_status.toUpperCase())}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectedJob;
