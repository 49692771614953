let JobRequestContent = {
  tableHeaders: [
    "Date",
    "Client",
    "House Type",
    "Room/Bath",
    "Address",
    "Distance",
    "Pay",
  ],
  responsiveHeaders: ["show", "hide", "show", "hide", "show", "hide", "show"],
};

let ActiveJobsContent = {
  tableHeaders: [
    "Date",
    "Client",
    "House Type",
    "Room/Bath",
    "Address",
    "Distance",
    "Pay",
    "Signed",
  ],
};

let JobsHistoryContent = {
  tableHeaders: [
    "Date",
    "Client",
    "House Type",
    "Room/Bath",
    "Address",
    "Distance",
    "Pay",
  ],
};

export { JobRequestContent, ActiveJobsContent, JobsHistoryContent };
