import React from 'react';
import './RatingStars.css';
import {Link} from "react-router-dom";

const RatingStars = (props) => {
    const getScorePercentage = () => {
        const starTotal = 5;
        const starPercentage = ( props.score / starTotal) * 100;
        const starPercentageRounded = `${(Math.round(starPercentage / 10) * 10)}%`;
        return starPercentageRounded.toString();
    }

    return(
        <div>
            <div className="rating-stars__outer">
                <div className="rating-stars__inner" style={{ width: getScorePercentage() }}></div>
            </div>
        </div>
        )
};

export default RatingStars;