import React from "react";
import InspectorProfileCard from "../InspectorProfileCard/InspectorProfileCard";

class UserReportInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true
    };
  }

  render() {
    const {
      address_one,
      city,
      state,
      zipcode,
      year_built,
      sqft,
      bedroom,
      bathroom,
      foundation,
      attic,
      parking,
      inspector_first_name,
      inspector_last_name,
      i_email,
      i_area_code,
      i_cell_number,
      photoid,
      comp_name
    } = this.props.report;

    return (
      <div className="container pt-5 mt-5">
        <div className="row">
          <div className="col">
            <h5 id="address_one" style={{textTransform:'capitalize'}}>{address_one}</h5>
            <h6 style={{textTransform:'capitalize'}}>
              {city}, {state} {zipcode}
            </h6>
          </div>
        </div>
        <div className="divider"></div>
        <div className="section">
          <div className="row mb-0">
            <div className="col s5">
              <label>
                <h6>Bedroom</h6>
              </label>
              <p id="bedroom">{bedroom}</p>
            </div>
            <div className="col s4">
              <label>
                <h6>Bathroom</h6>
              </label>
              <p id="bathroom">{bathroom}</p>
            </div>
            <div className="col s3">
              <label>
                <h6>Square Ft</h6>
              </label>
              <p id="sqft">{sqft} sqft</p>
            </div>
          </div>
          <div className="row mb-0">
            <div className="col s5">
              <label>
                <h6>Year Built</h6>
              </label>
              <p id="year_built">{year_built}</p>
            </div>
            <div className="col s5">
              <label>
                <h6>Parking</h6>
              </label>
              <p id="parking">{parking}</p>
            </div>
          </div>
          <div className="row mb-0">
            <div className="col s12 m8 l5">
              <label>
                <h6>Foundation</h6>
              </label>
              <p id="foundation">{foundation}</p>
            </div>
            <div className="col s12 m6 l5">
              <label>
                <h6>Attic</h6>
              </label>
              <p id="attic">{attic}</p>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div style={{ display: "flex", justifyContent: "center", marginBottom:'40px' }}>
          <InspectorProfileCard
            inspectorInfo={{
              inspector_first_name,
              inspector_last_name,
              i_email,
              i_area_code,
              i_cell_number,
              photoid,
              comp_name
            }}
          />
        </div>
      </div>
    );
  }
}

export default UserReportInfo;
