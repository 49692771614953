import React from 'react'
import './account.css'

export default (props) => {
  return <div id="reAuthModal" className="modal">
    <div className="modal-content">
      <h4>Please Enter Credientials</h4>
      <div className='row'>
        <form className="col s12">
          <div className="row mb-0">
            <div className="input-field col s12">
              <input id="reAuthEmail" type="text" value={props.reAuthEmail} onChange={props.handleChange} />
              <label className={props.reAuthEmail ? 'active' : ''} htmlFor="reAuthEmail">Old Email</label>
            </div>
            <div className="input-field col s12">
              <input id="reAuthPassword" type="password" value={props.reAuthPassword} onChange={props.handleChange} />
              <label htmlFor="reAuthPassword">Password</label>
            </div>
          </div>
        </form>
          <div className="modal-footer">
            <div className='row'>
              <div className='col s12 m6'>
            <button onClick={(e)=>{e.preventDefault()}} style={{width:'100%'}} className="my-1 modal-close housky-secondary-complimentary-bg btn">Cancel</button>
            </div>
            <div className='col s12 m6'>
            <button type='submit' onClick={props.handleReAuth} style={{width:'100%'}} className="my-1 offset-m2 modal-close housky-primary-complimentary-bg btn">Confirm</button>
          </div>
          </div>
          </div>
      </div>
    </div>
  </div>
}