import React, {useEffect, useState} from 'react';
import cssStyles from './InspectorSignUp.module.css';
import Step1 from '../SignUp/InspectorSignUp_Step1';
import Step2 from '../SignUp/InspectorSignUp_Step2';
import Step3 from '../SignUp/InspectorSignUp_Step3';
import Step4 from '../SignUp/InspectorSignUp_Step4';
import Step5 from '../SignUp/InspectorSignUp_Step5';
import { inspectorApp as firebase } from "../../../firebase";
import {getInspectorIdByToken} from "../../../services/service";

const NewInspectorSignUp = () => {
    const [ currentStep, setCurrentStep ] = useState(1);
    const [ token, setToken ] = useState('') // this is to store the token for following steps
    const [ inspectorId, setInspectorId ] = useState('');
    const [ address, setAddress ] = useState({
      address: '',
      aptInfo: '',
      city: '',
      state: '',
      zip: ''
    });

    useEffect(() => {
      (
        async function() {
          const currentUser = await firebase.auth().currentUser;
          if (!!currentUser) {
            const token = await currentUser.getIdToken(true);
            const inspector = await getInspectorIdByToken(token);
            setToken(token);
            setInspectorId(inspector.inspectorid);
            setCurrentStep(2);
          }
        }
      )();
    }, [token]);

    const showStepPage = () => {
      if (currentStep === 1) {
        return (
          <Step1
            setToken={setToken}
            setInspectorId={setInspectorId}
          />
        );
      }
      if (currentStep === 2) {
        return (
          <Step2
            setCurrentStep={setCurrentStep}
            token={token}
            inspectorId={inspectorId}
            setAddress={setAddress}
          />
        );
      }
      if (currentStep === 3) {
        return (
          <Step3
            setCurrentStep={setCurrentStep}
            token={token}
            inspectorId={inspectorId}
          />
        );
      }
      if (currentStep === 4) {
        return (
          <Step4
            setCurrentStep={setCurrentStep}
            token={token}
            inspectorId={inspectorId}
            address={address}
          />
        );
      }
      if (currentStep === 5) {
        return (<Step5/>)
      }
    }

    const getProgressStepClassName = (step) => {
      if(currentStep > step) {
        return cssStyles.progressBarStepDone
      } else if (currentStep === step) {
        return cssStyles.progressBarStepCurrent
      } else {
        return ""
      }
    };

    return(
      <div className={cssStyles.pageContainer}>
        <div className={cssStyles.pageLeft}>
          {/*<div className={cssStyles.message}>*/}
          {/*  <span>We connect House Inspectors to those who need it.</span>*/}
          {/*</div>*/}
          <div>
            <img alt="" src={require('../../../assets/sign-up-home-2.png')}/>
          </div>
        </div>
        <div className={cssStyles.pageRight}>
          <div className={cssStyles.progressBarContainer}>
            <progress className={cssStyles.progressBar} value={currentStep - 1} max="4" />
            <div className={cssStyles.progressBarStep}>
              <div className={getProgressStepClassName(1)}>Basic Info</div>
              <div className={getProgressStepClassName(2)}>Address Info</div>
              <div className={getProgressStepClassName(3)}>Inspector Info</div>
              <div className={getProgressStepClassName(4)}>Company Info</div>
            </div>
          </div>
          <div className={cssStyles.formContainer}>
            { showStepPage() }
          </div>
        </div>
      </div>
      // </div>
    );
  }

  export default NewInspectorSignUp;
