import React from 'react';
import UploadFiles from "../../GeneralComponents/UploadFiles/UploadFiles";

import './styles.css';

const InspectionAgreement = (
  {
    jobiId, handlePreAgreementUpload, preInspectionAgreementUrl, signedPreInspectionAgreementUrl,
    preInspectionAgreementAccepted, handleAcceptPreInspectionAgreement, isLoading, current_status
  }
) => {

  const handleOnClick = (event) => {
    event.preventDefault();
    if (typeof handleAcceptPreInspectionAgreement !== "undefined") {
      handleAcceptPreInspectionAgreement();
    }
  }

  const renderInspectionAgreementPending = () => {
    if (!preInspectionAgreementAccepted && (["PENDING", "ACCEPTED"].includes(current_status.toUpperCase()))) {
      return (
        <>
          <UploadFiles
            folderName={'pre_inspection_agreements'}
            fileName={`pre_inspection_agreement_${jobiId}`}
            callbackSuccess={handlePreAgreementUpload}
          />
          {
            !!preInspectionAgreementUrl &&
            <a href={preInspectionAgreementUrl} target={"_blank"}>See inspection agreement file to sign</a>
          }
          {
            !!signedPreInspectionAgreementUrl &&
            <>
              <div className={"info-banner-container teal lighten-5"}>
                <i className={"material-icons"}>info_outline</i>
                <div>
                  <p>
                    <span>Customer already signed it.</span>
                  </p>
                  <p>
                    <a
                      href={signedPreInspectionAgreementUrl}
                      target={"_blank"}
                    >
                      See inspection agreement signed by customer
                    </a>
                  </p>
                </div>
              </div>
              {
                isLoading &&
                <div className="col s12 p-0">
                  <div className="col s12 p-0 progress">
                    <div className="indeterminate"/>
                  </div>
                </div>
              }
              {
                !isLoading &&
                <div>
                  <button className={'btn'} onClick={handleOnClick}>Accept agreement</button>
                </div>
              }
            </>
          }
        </>
      );
    }
    return <></>
  }

  const renderInspectionAgreementAccepted = () => {
    if (preInspectionAgreementAccepted || current_status === "COMPLETED") {
      return (
        <>
          <p>
            <span>Document already signed by customer</span>
          </p>
          <a href={signedPreInspectionAgreementUrl} target={"_blank"}>See agreement</a>
        </>
      );
    }

    return <></>;
  }

  return (
    <div className={'pt-3'}>
      <h5>Inspection Agreement</h5>
      {renderInspectionAgreementPending()}
      {renderInspectionAgreementAccepted()}
    </div>
  );
}

export default InspectionAgreement;
