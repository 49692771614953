import "./JobRequests.css";
import "./JobRequests.css";

import { Toast, selectJobInTable } from "../../../utils/utils";
import {
  acceptJobByInspector,
  checkIsComplete,
  getInspectorManagement,
  getInspectorbyToken,
  getJobRequests,
  removeJobFromInspectorDashboard,
  acceptPreInspectionAgreementByInspector,
  updateInspectorAgreement, getInspectorIdByToken
} from "../../../services/service";
import { inspectorApp as userFirebase } from "../../../firebase";
import AuthContext from "../../../contexts/auth";
import InspectorFilter from "../InspectorFilter/InspectorFilter";
import { Inspector as InspectorFirebase } from "../../../utils/firebase/firebase";
import IsActiveBanner from "./IsActiveBanner";
import IsCompleteBanner from "./IsCompleteBanner";
import { JobRequestContent } from "../PagesContent";
import Map from "../../GeneralComponents/GoogleMap/Map";
import React from "react";
import SelectedJob from "../SelectedJob/SelectedJob";
import JobRequestsTable from "./JobRequestsTable";
import moment from "moment";
import get from "lodash/get"
class JobRequests extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      ...InitialState,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    InspectorFirebase.getIdToken()
      .then((idToken) => {
        checkIsComplete(idToken).then((res) => {
          let errors = [];
          if (!res.inspection_company_id) errors.push("Inspection Company");
          if (!res.license_number_one) errors.push("License Number");
          if (!res.license_state_one) errors.push("License State");
          this.setState({ isComplete: errors, idToken });
        });
        getInspectorManagement(idToken).then((res) => {
          let stripeValue = false;
          if (res.stripeid) stripeValue = true;
          else {
            let errors = [...this.state.isComplete];
            errors.push("Payment Information");
            this.setState({ isComplete: errors });
          }
          this.setState({
            isActive: {
              housky_certified: res.housky_certified,
              account_active: res.account_active,
              stripeid: stripeValue,
            },
            lat: res.latitude,
            lng: res.longitude,
          });
        });
        return getJobRequests(idToken);
      })
      .then((data) => {
        this.setState({ jobRequests: data });
      })
      .catch((err) => {
        console.log(err.request.responseURL);
        console.log(err.message);
      });

      this.getInspectorData()
  };

  getToken = () => {
    return userFirebase.auth().currentUser.getIdToken(true);
  };

  getInspectorData = () => {
    this.getToken().then((res) => {
      getInspectorIdByToken(res).then((inspector) => {
        const { latitude, longitude } = inspector;
        this.setState({
          inspectorId: get(inspector, "inspectorid", ""),
          inspectorLocation: { lat: latitude, long: longitude },
          calculatorParams: get(inspector, "calculator_params", {})
        })
      });
    });
  };

  resetState = (...args) => {
    let newState = {};
    args.forEach((e) => {
      newState[e] = InitialState[e];
    });
    return newState;
  };

  selectJob = (jobId) => {
    const { jobSelected, selectedRowId } = this.state;
    let setSelectedRowId = () => {
      this.setState({ selectedRowId: jobId });
    };
    let updateSelectedJob = (job) => {
      const inspectorOffer = job?.inspector_offer ?? {};
      const date = !!inspectorOffer.date ? moment(inspectorOffer.date, 'MM/DD/YYYY') : "";
      this.setState({
        jobSelected: job,
        otherDate: !!inspectorOffer.date ? date.format("YYYY-MM-DD") : "",
        otherTime: !!inspectorOffer.time ? inspectorOffer.time : "",
        isOtherDate: !!inspectorOffer.date,
        isOtherTime: !!inspectorOffer.time,
        time: !!inspectorOffer.date ? {
          early_morning: true,
          morning: true,
          afternoon: true,
          late_afternoon: true,
          other_time: true,
          allDay: true,
          date: date.format("dddd, MMM Do YYYY"),
          unix: date.format("x"),
          start: date.format("x"),
          end: date.format("x"),
          title: "",
        } : {...this.resetState("time").time},
        selectedTimeOptions: !!inspectorOffer.date ? {
          ...this.resetState("selectedTimeOptions").selectedTimeOptions,
          other_time: true,
        } : {...this.resetState("selectedTimeOptions").selectedTimeOptions},
      });

      if (job.accepted && !job['assigned_to_same_inspector']) {
        Toast("This job was assigned to another inspector!", "red lighten-1");
      }
    };
    selectJobInTable(
      jobSelected.jobid,
      selectedRowId,
      jobId,
      setSelectedRowId,
      updateSelectedJob,
      this.state.jobRequests
    );
  };

  mapSelectJob = (id) => {
    const { jobSelected, selectedRowId } = this.state;
    const selected = document.getElementById(selectedRowId);
    const newSelected = document.getElementById(id);

    if (jobSelected.jobid) {
      if (!!selected) {
        selected.classList.remove("active-row");
      }
      if (!!newSelected) {
        newSelected.classList.add("active-row");
      }
      this.setState({ selectedRowId: id });
    } else {
      if (!!newSelected) {
        newSelected.classList.add("active-row");
      }
      this.setState({ selectedRowId: id });
    }

    for (let job of this.state.jobRequests) {
      if (job.jobid === id) {
        this.setState({
          jobSelected: job,
        });
      }
    }
  };

  handleInspectorCost = (event) => {
    var reg = /^\d+$/;
    const inspection_cost = event.target.value;
    if (reg.test(inspection_cost) || !inspection_cost) {
      this.setState({
        ...this.state,
        jobSelected: {
          ...this.state.jobSelected,
          inspector_offer: {
            ...this.state.jobSelected.inspector_offer,
            cost: inspection_cost,
          },
        }
      });
    }
  }

  selectAppointmentTime = (e) => {
    for (let times of this.state.jobSelected.eventinfo) {
      if (times.unix === e.target.id) {
        this.setState({
          time: { ...times},
          isOtherDate: false,
          ...this.resetState("selectedTimeOptions", "isLoading"),
        });
      } else if (e.target.id === "other-date") {
        this.setState({
          isOtherDate: true,
          ...this.resetState("selectedTimeOptions", "isLoading", "time"),
        });
      }
    }
  };

  handleOtherDate = (event) => {
    const dateValue = event.target.value;
    if (!!dateValue) {
      const date = moment(dateValue, 'YYYY-MM-DD');
      this.setState({
        otherDate: event.target.value,
        time: {
          early_morning: true,
          morning: true,
          afternoon: true,
          late_afternoon: true,
          other_time: true,
          allDay: true,
          date: date.format("dddd, MMM Do YYYY"),
          unix: date.format("x"),
          start: date.format("x"),
          end: date.format("x"),
          title: "",
        },
        ...this.resetState("selectedTimeOptions", "isLoading"),
      });
    } else {
      this.setState({
        ...this.resetState("selectedTimeOptions", "isLoading", "otherDate", "otherTime", "time"),
      });
    }
  };

  handleOtherTime = (value) => {
    this.setState({
      otherTime: value
    });
  };

  handleSelectedOptions = (value) => {
    let timeOptions = { ...InitialState.selectedTimeOptions };
    timeOptions[value] = true;
    this.setState({
      selectedTimeOptions: timeOptions,
      isOtherTime: value === 'other_time',
    });
  };

  handleAppointmentSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const { time, selectedTimeOptions } = this.state;
    const isSingleTimeSelected = Object.values(selectedTimeOptions).filter((e) => e).length === 1;
    const jobSelected = this.state.jobSelected;
    const inspectorOffer = !!jobSelected ? jobSelected.inspector_offer : {};
    const finalQuote = !!inspectorOffer.cost ? inspectorOffer.cost : 0;

    if (time && !!parseInt(finalQuote)) {
      if (isSingleTimeSelected) {
        const otherTime = this.state.otherTime
        const isOtherTime = this.state.isOtherTime

        // If other time was selected check that is not empty
        if (isOtherTime && !otherTime) {
          Toast("Please, write an hour for the appointment");
          this.setState({ isLoading: false });
          return;
        }

        // In case 'other time' was declared, we check if it's formatted correctly
        const regEx = new RegExp("^(1[0-2]|0?[1-9]):[0-5][0-9] (AM|PM)$");
        if (!!isOtherTime && !!otherTime && !regEx.test(otherTime)) {
          Toast("Invalid time, please use 12-hour format (11:00 AM)");
          this.setState({isLoading: false});
          return;
        }

        const {jobid} = this.state.jobSelected;
        let timeToSend = this.state.time;
        timeToSend.early_morning = selectedTimeOptions.early_morning;
        timeToSend.morning = selectedTimeOptions.morning;
        timeToSend.afternoon = selectedTimeOptions.afternoon;
        timeToSend.late_afternoon = selectedTimeOptions.late_afternoon;
        timeToSend.other_time = this.state.otherTime;
        const jobData = {
          jobid,
          eventinfo: JSON.stringify([timeToSend]),
          inspection_cost: this.state.jobSelected?.inspector_offer?.cost ?? 0,
        };
        getInspectorbyToken(this.state.idToken)
          .then((inspector) => {
            if (inspector.data.inspection_company_id) {
              acceptJobByInspector(jobData, this.state.idToken)
                .then((data) => {
                  if (data['job_accepted']) {
                    // Updating "Pay" column in table
                    const jobRequests = [...this.state.jobRequests];
                    const filteredJobs = [...this.state.filteredJobs];
                    for (let i = 0; i < jobRequests.length; i++) {
                      const job = jobRequests[i];
                      if (job.jobid === jobid) {
                        job.inspector_offer.cost = jobData.inspection_cost;
                        break;
                      }
                    }

                    for (let i = 0; i < filteredJobs.length; i++) {
                      const job = filteredJobs[i];
                      if (job.jobid === jobid) {
                        job.inspector_offer.cost = jobData.inspection_cost;
                        break;
                      }
                    }

                    this.setState({
                      ...this.resetState("isLoading"),
                      jobRequests,
                      filteredJobs,
                      jobSelected: {
                        ...this.state.jobSelected,
                        inspector_offer: {
                          ...this.state.jobSelected.inspector_offer,
                          updated_at: moment(new Date()),
                        }
                      }
                    });
                    Toast("Appointment and cost for this job were updated");
                  } else {
                    this.setState({
                      ...this.resetState("time", "jobSelected", "isLoading"),
                    });
                    Toast(data.message);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              this.setState({isLoading: false});
              Toast("Must have a company in your profile");
            }
          })
          .catch((err) => {
            Toast("There was an error, please try it again later.");
            console.log(err);
          });
      } else {
        this.setState({isLoading: false});
        Toast("Select a time");
      }
    } else if (!time) {
      this.setState({ isLoading: false });
      Toast("Select a time");
    } else {
      this.setState({ isLoading: false });
      Toast("Add your final quote");
    }
  };

  handlePreAgreementUpload = (preInspectionAgreementUrl) => {
    const {idToken} = this.state;
    const {jobid} = this.state.jobSelected;

    updateInspectorAgreement(idToken, jobid, preInspectionAgreementUrl)
      .then((data) => {
        this.setState({
          ...this.state,
          jobSelected: {
            ...this.state.jobSelected,
            pre_inspection_agreement_url: preInspectionAgreementUrl,
          }
        })
      })
      .catch((error) => {
        console.error('There was an error updating inspection agreement url', error);
      });
  };

  getTimeOptions = ({ early_morning, morning, afternoon, late_afternoon }) => {
    if (early_morning) return "07:00 AM";
    if (morning) return "09:00 AM";
    if (afternoon) return "12:00 PM";
    if (late_afternoon) return "03:00 PM";
  };

  setFilteredJobs = (filteredJobs) => {
    if (filteredJobs.length === 0) {
      Toast("No results");
      return;
    }
    if (this.state.jobSelected.jobid) {
      document
        .getElementById(this.state.selectedRowId)
        .classList.remove("active-row");
    }
    this.setState({ filteredJobs, jobSelected: {}, selectedRowId: "" });
  };

  handleRemoveJobFromDashboard = () => {
    const jobid = this.state.jobSelected.jobid;
    if (!!jobid) {
      removeJobFromInspectorDashboard(jobid, this.state.idToken)
        .then((data) => {
          const jobRequests = this.state.jobRequests.filter((job) => job.jobid !== jobid);
          this.setState({
            jobRequests: [...jobRequests],
            jobSelected: {},
          });
        })
        .catch((error) => {
          console.error('There was an error removing this job from the dashboard', error);
        });
    } else {
      console.error('No job selected');
    }
  }

  render() {
    let { jobSelected, jobRequests, filteredJobs } = this.state;
    let { tableHeaders, responsiveHeaders } = JobRequestContent;
    return (
      <React.Fragment>
        {this.state.isComplete.length > 0 ? (
          <IsCompleteBanner errors={this.state.isComplete} />
        ) : !this.state.isActive.housky_certified ||
          !this.state.isActive.account_active ? (
          <IsActiveBanner isActive={this.state.isActive} />
        ) : (
          <></>
        )}
        <div style={{ minHeight: "90vh" }} className="pb-5 mt3 pt-3 pl-5 pr-5">
          <InspectorFilter
            jobsList={jobRequests}
            setFilteredJobs={this.setFilteredJobs}
            clearFilter={() => {
              this.setState({ filteredJobs: [] });
            }}
          />
          <div className="row">
            <div className={`col s12 ${!!jobSelected.jobid ? 'l8' : ''}`}>
              <JobRequestsTable
                tableHeaders={tableHeaders}
                jobs={jobRequests.length > 0 && filteredJobs.length > 0 ? filteredJobs : jobRequests}
                inspectorLat={this.state.lat}
                inspectorLng={this.state.lng}
                responsiveHeaders={jobRequests.length > 0 && filteredJobs.length > 0 ? responsiveHeaders : []}
                onClickRow={this.selectJob}
              />
            </div>
            {this.state.jobRequests.length > 0 && !!jobSelected.jobid ? (
              <div className="col s12 l4">
                <div className="card table-height">
                  <Map
                    markers={this.state.jobRequests}
                    clickEvent={this.mapSelectJob}
                    lat={this.state.lat}
                    lng={this.state.lng}
                    job={jobSelected}
                    inspectorLocation={this.state.inspectorLocation}
                  />
                </div>
              </div>
            ) : (
              <React.Fragment/>
            )}
          </div>
          {jobSelected.jobid ? (
            <div className="divider"/>
          ) : (
            <div style={{ maginTop: "60px", marginBottom: "60px" }}/>
          )}
          {
            jobSelected.jobid && (
              <div className="row" style={{paddingTop: '0.5rem'}}>
                <div className="col s12 l12">
                  <SelectedJob
                    idToken={this.state.idToken}
                    isActive={this.state.isActive.stripeid}
                    selectAppointmentTime={this.selectAppointmentTime}
                    handleInspectorCost={this.handleInspectorCost}
                    handleAppointmentSubmit={this.handleAppointmentSubmit}
                    handleSelectedOptions={this.handleSelectedOptions}
                    handleOtherDate={this.handleOtherDate}
                    handleOtherTime={this.handleOtherTime}
                    handlePreAgreementUpload={this.handlePreAgreementUpload}
                    handleRemoveJobFromDashboard={this.handleRemoveJobFromDashboard}
                    timeSelected={this.state.time}
                    isOtherDate={this.state.isOtherDate}
                    isOtherTime={this.state.isOtherTime}
                    otherDate={this.state.otherDate}
                    otherTime={this.state.otherTime}
                    isLoading={this.state.isLoading}
                    inspectorLocation={this.state.inspectorLocation}
                    inspectorId = {this.state.inspectorId}
                    calculatorParams={this.state.calculatorParams}
                    {...jobSelected}
                  />
                </div>
                    {/* TODO: This has to be updated with real tip */}

                {/*
                  <div className="tipsBox">
                  <div className="card-panel">
                    <h5>Tips</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                      and scrambled it to make a type specimen book.
                    </p>
                  </div>
                </div>
                 */}

              </div>
            )
          }
        </div>
      </React.Fragment>
    );
  }
}

export default JobRequests;

const InitialState = {
  user: null,
  jobRequests: [],
  jobSelected: {},
  time: null,
  idToken: null,
  selectedRowId: "",
  selectedTimeOptions: {
    early_morning: false,
    morning: false,
    afternoon: false,
    late_afternoon: false,
    other_time: false,
  },
  filteredJobs: [],
  showFilter: false,
  isComplete: [],
  isActive: {
    housky_certified: true,
    account_active: true,
  },
  lat: 40.7128,
  lng: -74.006,
  isLoading: false,
  isOtherDate: false,
  isOtherTime: false,
  otherDate: "",
  otherTime: "",
  inspectorId: "",
  inspectorLocation: {},
  calculatorParams: {}
};
