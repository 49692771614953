import React from 'react'

import styles from './FormContainer.module.css'

export default (props) => {
  return <div data-aos="fade-left">
    <form className="col s12">
      <div className="row px-2 py-1" style={{ borderRadius: '5px', backgroundColor: 'white' }}>
        <h6 style={{ fontSize: '18px' }}>Address Information</h6 >
        {/* <p onClick={props.locationFromGeo} className='blue-text mouse-pointer m-0'>Locate me</p> */}
        {props.address_error ? <div><p data-aos="zoom-in" data-aos-duration="300" style={{ fontSize: '16px', color: 'red', textAlign: 'center' }}>Address not found. Please enter valid address.</p>
          {/*
          Address will always be 'valid' incorrect address will always be wildly wrong
          Address with typo will be translated by google and corrected.
          <p style={{ cursor: 'pointer', color: 'blue' }}
            onClick={props.handleCorrectAddress}>
            // address found doesnt exist nothing to place and will always be null, error here
            {props.found_address ? `Did you mean: ${props.found_address.address.one} ${props.found_address.address_route} ${props.found_address.address_state}, ${props.found_address.address_city} ${props.found_address.address_zipcode}?`
              : null}
          </p> */}
        </div> : <></>}
        <div className="input-field col s8">
          <input id="address_one" type="text" value={props.address_one} onChange={props.handleChange} />
          <label className={`${props.address_one ? 'active' : ''} required-field`} htmlFor="address_one">Address</label>
        </div>
        <div className="input-field col s4">
          <input id="address_two" type="text" value={props.address_two} onChange={props.handleChange} />
          <label className={props.address_two ? 'active' : ''} htmlFor="address_two">Apt/Suite #</label>
        </div>
        <div className="input-field col s4">
          <input id="city" type="text" value={props.city} onChange={props.handleChange} />
          <label className={`${props.city ? 'active' : ''} required-field`} htmlFor="city">City</label>
        </div>
        <div className="input-field col s4">
          <input style={{ textTransform: 'uppercase' }} id="formState" type="text" value={props.formState} onChange={props.handleState} />
          <label className={`${props.formState ? 'active' : ''} required-field`} htmlFor="formState">State</label>
        </div>
        <div className="input-field col s4">
          <input id="zipcode" type="text" value={props.zipcode} onChange={(e) => { props.handleNumRestrictedLength(e, 'zipcode', 6) }} />
          <label className={`${props.zipcode ? 'active' : ''} required-field`} htmlFor="zipcode">Zipcode</label>
        </div>
      </div>
      <div className="row px-2 py-1" style={{ borderRadius: '5px', backgroundColor: 'white' }}>
        <h6 style={{ fontSize: '18px' }}>House Detail</h6 >
        <div className="input-field col s8 offset-s2">
          <select className={`browser-default ${styles.formContainerSelect}`} id="houseType" name='houseType' value={props.houseType} onChange={props.handleSelectOptions}>
            <option value="Select House Type" disabled hidden>House Type</option>
            <option value="Condo">Condo</option>
            <option value="Town House">Town House</option>
            <option value="Single Family">Single Family</option>
            <option value="Two-Three Family">Two-Three Family</option>
            <option value="Mansion">Mansion</option>
          </select>
        </div>
        <div className="input-field col s4">
          <input id="bed" type="number" value={props.bedAmount} onChange={(e) => { props.handleNumRestrictedLength(e, 'bedAmount', 5) }} />
          <label className={props.bedAmount ? 'active' : ''} htmlFor="bed">Bed</label>
        </div>
        <div className="input-field col s4">
          <input id="bathroom" type="number" value={props.bathAmount} onChange={(e) => { props.handleNumRestrictedLength(e, 'bathAmount', 5) }} />
          <label className={props.bathAmount ? 'active' : ''} htmlFor="bathroom">Bathroom</label>
        </div>
        <div className="input-field col s4">
          <input id="sqft" type="number" value={props.sqftAmount} onChange={(e) => { props.handleNumRestrictedLength(e, 'sqftAmount', 15) }} />
          <label className={props.sqftAmount ? 'active required-field' : 'required-field'} htmlFor="sqft">SqFt</label>
        </div>
        <div className="input-field col s6">
          <input id="yearbuilt" type="number" value={props.yearbuilt} onChange={(e) => { props.handleNumRestrictedLength(e, 'yearbuilt', 5) }} />
          <label className={props.yearbuilt ? 'active required-field' : 'required-field'} htmlFor="yearbuilt">Year Built</label>
        </div>
        <div className="input-field col s6">
          <select className={`browser-default ${styles.formContainerSelect}`} id="parking" name='parking' value={props.parking} onChange={props.handleSelectOptions}>
            <option value="Select Parking" disabled hidden>Parking</option>
            <option value="Driveway">Driveway</option>
            <option value="Garage Parking">Garage</option>
            <option value="Parking Lot">Parking Lot</option>
            <option value="Street Parking">Street</option>
          </select>
        </div>
        <div className="input-field col s12">
          <select className={`browser-default ${styles.formContainerSelect}`} id="foundation" name='foundation' value={props.foundation} onChange={props.handleSelectOptions}>
            <option value='Select Foundation' disabled hidden>Foundation</option>
            <option value="Not Applicable">Not Applicable</option>
            <option value="Basement(Finished)">Basement(Finished)</option>
            <option value="Basement(Partial)">Basement(Partial)</option>
            <option value="Basement(Unfinished)">Basement(Unfinished)</option>
            <option value="Basement(Finished) & Crawlspace">Basement(Finished) & Crawlspace</option>
            <option value="Basement(Unfinished) & Crawlspace">Basement(Unfinished) & Crawlspace</option>
            <option value="Crawlspace">Crawlspace</option>
            <option value="Piers/Pilings">Piers/Pilings</option>
            <option value="Slab">Slab</option>
            <option value="Other">Other</option>
          </select>
        </div>
        {props.foundation === 'Other' ?
          <div className="input-field col s12">
            <input id="foundationOther" type="text" value={props.foundationOther} onChange={props.handleChange} />
            <label className={props.foundationOther ? 'active' : ''} htmlFor="foundationOther">Foundation Other</label>
          </div>
          :
          <></>}
        <div className="input-field col s12">
          <select className={`browser-default ${styles.formContainerSelect}`} id="attic" name='attic' value={props.attic} onChange={props.handleSelectOptions}>
            <option value="Select Attic" disabled hidden>Attic</option>
            <option value="Not Applicable">Not Applicable</option>
            <option value="Attic(Finished)">Attic(Finished)</option>
            <option value="Attic(Unfinished)">Attic(Unfinished)</option>
            <option value="Crawlspace">Crawlspace</option>
            <option value="Other">Other</option>
          </select>
        </div>
        {props.attic === 'Other' ?
          <div className={`input-field col s12 ${props.foundation === 'Other' ? '' : 'offset-m6'}`}>
            <input id="atticOther" type="text" value={props.atticOther} onChange={props.handleChange} />
            <label className={props.atticOther ? 'active' : ''} htmlFor="atticOther">Attic Other</label>
          </div>
          :
          <></>}
      </div>
      <div>
        <h6 style={{ fontSize: '18px' }}>Additional Inspection Features</h6 >
        <p>
          <label>
            <input id='radon' type="checkbox" defaultChecked={props.additional_features.radon} onChange={props.handleAdditional} />
            <span>Radon Test</span>
          </label>
        </p>
        <p>
          <label>
            <input id='wdi' type="checkbox" defaultChecked={props.additional_features.wdi} onChange={props.handleAdditional} />
            <span>Wood Destroying Insect(Termite) Inspection</span>
          </label>
        </p>
      </div>
    </form>
    <div className='row center' style={{ marginBottom: '80px' }}>
      <div className='col s12 m6'>
        <button className='btn my-1 py-1 housky-secondary-bg' style={{ height: 'unset', width: '100%' }} onClick={props.handleBack}>Cancel</button>
      </div>
      <div className='col s12 m6'>
        <button type='submit' className='btn my-1 py-1 offset-m2 housky-primary-bg' style={{ height: 'unset', width: '100%' }} onClick={props.handleNextPage}>Next - Confirm inspectors</button>
      </div>
    </div>
  </div>
}