import React from "react";
import PrivateComponent from "../../../components/PrivateComponent";
import InspectorRecordsComponent from "../../../components/AdminDomain/InspectorRecords/InspectorRecords";

class InspectorRecords extends React.Component {
  render() {
    return (
      <PrivateComponent>
        <InspectorRecordsComponent />
      </PrivateComponent>
    );
  }
}

export default InspectorRecords;
