import React, { useEffect } from "react";
import { useState } from "react";
import InspectorSkillsEvaluations from "../../GeneralComponents/InspectorSkillsEvaluations/InspectorSkillsEvaluations";
import {useHistory} from "react-router-dom";
import { createInspectorJobReview } from "../../../services/service";
import "./InspectorJobReview.css";

const InspectorJobReview = (props) => {
  const [state, setState] = useState({
    ...props,
    communication: 0,
    expertise: 0,
    reportQuality: 0,
    detailOriented: 0,
    observation: "",
    overall: 0.0,
  });
  const history = useHistory();

  useEffect(() => {
    const { communication, expertise, reportQuality, detailOriented } = state;
    const skillsTotal =
      communication + expertise + reportQuality + detailOriented;
    const averageTotal = +(skillsTotal / 4).toFixed(2);

    setState({
      ...state,
      overall: averageTotal,
    });
  }, [
    state.communication,
    state.expertise,
    state.reportQuality,
    state.detailOriented,
  ]);

  const handleEvaluations = (skillEvaluated, score) => {
    setState((state) => ({
      ...state,
      [skillEvaluated]: score,
    }));
  };

  const handleTextArea = (event) => {
    setState({
      ...state,
      observation: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!!state.inspectorId && !!state.jobId) {
      const data = {
        inspectorid: state.inspectorId,
        jobid: state.jobId,
        communication_rate: state.communication,
        expertise_rate: state.expertise,
        report_quality_rate: state.reportQuality,
        oriented_rate: state.detailOriented,
        observation: state.observation,
      };

      createInspectorJobReview(state.inspectorId, state.jobId, data)
        .then((data) => {
          //submitSuccess(data);
          history.push('/dashboard/inspections');
        })
        .catch((error) => {
          // submitErrorCallback(error);
        });
    }
  };

  const isValidForm = () => {
    return (
      !!state.inspectorId &&
      !!state.jobId &&
      state.communication +
        state.expertise +
        state.reportQuality +
        state.detailOriented >
        0
    );
  };

  return (
    <div className="container">
      <div className="row">
        <h3 className="title">Inspector Review</h3>
        <span>
          This feedback will be shared on the inspector's profile info, once you
          sumbit it
        </span>
      </div>
      <div className="row">
        <InspectorSkillsEvaluations
          communication={state.communication}
          expertise={state.expertise}
          reportQuality={state.reportQuality}
          detailOriented={state.detailOriented}
          handleEvaluations={handleEvaluations}
        />
      </div>
      <div className="row" style={{ display: "flex" }}>
        <div style={{ width: "35%" }}>
          <span style={{ fontSize: "14pt" }}>Overall</span>
        </div>
        <div>
          <span>{state.overall}</span>
        </div>
      </div>
      <div className="row">
        <span>
          Share your experience with this inspector to the Housky community
        </span>
        <div className="input-field s12">
          <textarea
            id="textarea1"
            className="materialize-textarea"
            onChange={handleTextArea}
          />
          <label htmlFor="textarea1">Leave us a comment...</label>
        </div>
      </div>
      <div className="row">
        <button
          className={`waves-effect waves-light btn ${
            !isValidForm() ? "disabled" : ""
          }`}
          onClick={handleSubmit}
          disabled={!isValidForm()}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default InspectorJobReview;
