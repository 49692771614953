import React from "react";

const HomeDetails = ({
  address_one,
  address_two,
  city,
  state,
  zipcode,
  bedroom,
  bathroom,
  house_type
}) => {
  return (
    <div className={'pt-3'}>
      <h5>Home</h5>
      <p>
        <span className="text-bold">Address:</span>{" "}
        {`${address_one}, ${city} ${state} ${zipcode}`}
      </p>
      <p>
        <span className="text-bold">Floor/Apt:</span> {address_two}
      </p>
      <p>
        <span className="text-bold">Bedrooms:</span> {bedroom}
      </p>
      <p>
        {" "}
        <span className="text-bold">Bathrooms:</span> {bathroom}
      </p>
      <p>
        <span className="text-bold">Home Type:</span> {house_type}
      </p>
    </div>
  );
};

export default HomeDetails;
