import React from "react";

const CheckoutSummary = (props) => {
  return (
    <div
      className={`col s12 center`}
      style={{
        backgroundColor: "rgb(240, 240, 240)",
      }}
    >
      <h5 className='mt-0'>Order Summary</h5>
      <div className="row">
        <h5>Address Information</h5>
        <div className="subText">
          <h6>
            {props.address_one} {props.address_two}
          </h6>
          <h6>
            {props.formState}
            {props.formState ? "," : ""} {props.city} {props.zipcode}
          </h6>
        </div>
      </div>
      <div className="row mb-0">
        <div className="subText">
          <h5>Total</h5>
          <h6>${props.inspection_cost}</h6>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSummary;
