import React from "react";

const HouskyPricingRow = props => {
  const {
    housky_id,
    house_type,
    price,
    margin,
    beta,
    inspection_quality,
    processing_fee,
    wdi,
    radon
  } = props.houskyPricingData;
  const clickEvent = props.clickEvent;
  return (
    <tr style={{ cursor: "pointer" }} id={housky_id} onClick={clickEvent}>
      <td>{house_type}</td>
      <td>{price}</td>
      <td>{margin}</td>
      <td>{beta}</td>
      <td>{inspection_quality}</td>
      <td>{processing_fee}</td>
      <td>{wdi}</td>
      <td>{radon}</td>
    </tr>
  );
};

export default HouskyPricingRow;
