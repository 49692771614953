import React, {useEffect} from "react";
import Materialize from "materialize-css/dist/js/materialize.min.js";

export default function HouseTypes({ allHouseTypes, editHouseType, optionsWrapperClass, handleChange }) {
  const initSelect = () => {
    let elem = document.querySelectorAll("select");
    Materialize.FormSelect.init(elem, {
      classes: ""
    });
  }

  useEffect(() => {
    initSelect();
  }, [editHouseType]);

  return (
      <div className={`input-field col s3 ` + optionsWrapperClass && optionsWrapperClass}>
        <label className='active' htmlFor="houseTypes">House Type</label>
        <select id="houseTypes" name="houseTypes" value={editHouseType} onChange={handleChange}>
          <option key="0" value="All">
            All
          </option>
          {allHouseTypes.map(houseType => {
            return (
                <option key={houseType.house_typeid} value={houseType.house_type}>
                  {houseType.house_type}
                </option>
            );
          })}
        </select>
      </div>
  );
}
