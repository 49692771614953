import React, {useEffect, useState} from 'react';
import Materialize from "materialize-css/dist/js/materialize.min.js";
import Gallery from './UploadGallery';
import {
  getInspectorCustomizedUrlNameByInspectorToken,
  getInspectorIdByCustomizedUrlName,
  getInspectorPhotoUrl,
  updateCustomizedUrlName,
  updateGallery
} from '../../../services/service';
import { inspectorApp } from "../../../firebase";
import AccountInputs from '../../GeneralComponents/FormInputs/AccountInputs';
import styles from './UserInfo.module.css';
import {Inspector as InspectorFirebase} from "../../../utils/firebase/firebase";

export default function UserInfo(props) {

  const [ isTooltipVisible, setIsTooltipVisible ] = useState(false);
  const [ inspectorPhotoUrl, setInspectorPhotoUrl ] = useState('');
  const [ customizedUrlName, setCustomizedUrlName ] = useState('');
  const [ originalCustomizedUrlName, setOriginalCustomizedUrlName ] = useState('');
  const [ hasDuplicateUrlName, setHasDuplicateUrlName ] = useState(false);
  const [ customizedUrlNameEnabled, setCustomizedUrlNameEnabled ] = useState(false);

  const defaultSrc = ev => {
    ev.target.src = require("../../../assets/blank-profile-picture.png");
  };

  useEffect(() => {
    Materialize.CharacterCounter.init(document.getElementById('bio'));
    let elems = document.querySelectorAll('.modal');
    Materialize.Modal.init(elems, {});
  }, []);

  useEffect(() => {
    (
      async() => {
        try {
          const idToken = await InspectorFirebase.getIdToken();
          const data = await getInspectorCustomizedUrlNameByInspectorToken(idToken);
          setCustomizedUrlName(data.customized_url_name);
          setOriginalCustomizedUrlName(data.customized_url_name);
        } catch (e) {
          console.log(e);
        }
      }
    )();
  }, []);

  useEffect(() => {
    (
      async() => {
        try {
          const idToken = await InspectorFirebase.getIdToken();
          const data = await getInspectorPhotoUrl(idToken);
          setInspectorPhotoUrl(data.photoid);
        } catch (e) {
          console.log(e);
        }
      }
    )();
  }, [props.isUpdatePhotoModalVisible]);

  const removePhoto = (link, name) => {
    let collection = [...this.props.photo_collection]
    let ele = collection.indexOf(link)
    collection.splice(ele, 1)
    let body = {
      token: this.props.idToken,
      photo_collection: JSON.stringify(collection),
      inspectorid: this.props.inspectorid
    }
    const root = inspectorApp.storage().ref();
    const imgRef = root.child(
      `${this.props.i_email.toLowerCase()}/gallery/${name}`
    );
    imgRef.delete()
      .then(() => {
        updateGallery(body)
          .then(() => {
            this.props.getInspectorInfo()
          })
          .catch((err) => {
            Materialize.toast({ html: err, classes: 'rounded' })
          })
      })
      .catch((err) => {
        Materialize.toast({ html: err, classes: 'rounded' })
      })
  }

  const EditButton = () => {
    if(props.isDisabled) {
      return <button className='btn housky-primary-bg' onClick={props.handleEdit}>Update Information</button>;
    } else {
      return (
        <div>
          <button className='btn housky-primary-bg' onClick={props.handleEdit}>Cancel Update</button>
          <button style={{marginLeft: "10px"}} data-target="reAuthModal" type='submit' onClick={props.updateUser} className='btn housky-primary-bg'>Confirm</button>
        </div>
      );

    }
  };

  const setCustomizedUrlNameButton = () => {
    if(props.isDisabled) {
      return <button className='btn right housky-primary-bg' onClick={props.handleEdit}>Update Information</button>;
    }
  };

  const Avatar = () => {
    const src = inspectorPhotoUrl ? inspectorPhotoUrl : require("../../../assets/blank-profile-picture.png")
    const onIconClicked = () => {
      window.open("https://inspector.housky.io/profilePicture", "_blank");
    };
    if(!props.isDisabled) {
      return (
        <img 
          className='z-depth-2' 
          onError={defaultSrc} 
          src={src} 
          alt='Profile' 
          style={{ height: '150px', width: '150px', borderRadius: '50%'}}
        />
      )
    } else {
      return (
        <div style={{display: 'flex'}}>
          <img
            className='z-depth-2'
            onError={defaultSrc}
            src={src}
            alt='Profile'
            style={{ height: '150px', width: '150px', borderRadius: '50%', cursor: 'pointer'}}
            onClick={props.toggleUpdatePhotoModalVisibility}
          />
          <span className={styles.tooltipContainer}>
            { isTooltipVisible && <span className={styles.tooltip}>Click on the icon to see some tips on how to take a great profile picture</span>}
            <span onMouseOver={() => setIsTooltipVisible(true)} onMouseLeave={() => setIsTooltipVisible(false)} className={styles.icon}>
              <i onClick={onIconClicked} data-position="bottom" data-tooltip="I am a tooltip" className={styles.icon} className="material-icons tooltipped">info</i>
            </span>
          </span>
        </div>
      )
    }
  };

  const ResetPassword = () => {
    if(props.isDisabled) {
      return (
        <div className='row col s12'>
          <h5>Password</h5>
          <button data-target="rePasswordModal" style={{ height: 'auto' }} type='submit' className={`modal-trigger btn housky-primary-complimentary-bg`}>Change Password</button>
        </div>
      );
    } else {
      return null;
    }
  };

  const Languages = () => {
    return (
      <div className='row'>
        <h5>Languages</h5>
        {props.languageOptions.map((ele, i) => {
          return <div className='col s6 m4 mb-1' key={i}>
            <label>
              <input type="checkbox" disabled={ele.name === 'English' ? true : props.isDisabled ? true : false} checked={ele.name === 'English' ? true : ele.value} onChange={(e) => { props.handleCheckbox(e, ele.name, 'languageOptions') }} />
              <span>{ele.name}</span>
            </label>
          </div>
        })}
      </div>
    );
  };

  const PhotoGallery = () => {
    if(props.isDisabled) {
      return (
        <div>
          <div className='row' style={{ borderBottom: '1px dotted gray' }}>
            <h5>Photo Gallery</h5>
            {props.photo_collection ?
              props.photo_collection.map((e, i) => {
                return <div className='col s6' key={i}>
                  <img className='valign-wrapper' style={{ margin: 'auto', height: '200px', width: '100%', maxWidth: '200px' }}
                       src={e.url} alt={e.name} />
                  <div className='valign-wrapper'
                       onClick={() => { removePhoto(e.url, e.name) }}>
                    <i className="material-icons mouse-pointer"
                       style={{ margin: 'auto', fontSize: '2rem', padding: '1rem 0rem' }}>delete</i>
                  </div>
                </div>
              }) :
              <></>}
          </div>
          <Gallery
            idToken={props.idToken}
            i_email={props.i_email}
            photo_collection={props.photo_collection}
            inspectorid={props.inspectorid}
            getInspectorInfo={props.getInspectorInfo}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  /// return bool to indicate if the customized url name is already taken
  const checkDuplicatedCustomizedUrlName = async (token) => {
    const data = await getInspectorIdByCustomizedUrlName(customizedUrlName, token);
    if(data.inspectorid) {
      setHasDuplicateUrlName(true);
      setTimeout(() => {
        setHasDuplicateUrlName(false);
      }, 10000);
      return true;
    }
  };

  const onCustomizedUrlNameChange = (e) => {
    setCustomizedUrlName(e.target.value);
  };

  const onCustomizedUrlNameBlur = async () => {
    try {
      if(customizedUrlName && originalCustomizedUrlName && customizedUrlName.toUpperCase() !== originalCustomizedUrlName.toUpperCase()) {
        const idToken = await InspectorFirebase.getIdToken();
        await checkDuplicatedCustomizedUrlName(idToken);
      }
    } catch (e) {
      console.log('error!', e);
      setCustomizedUrlName(originalCustomizedUrlName);
    }
  };

  const onSetCustomizedUrlName = async () => {
    const body = {
      customizedUrlName
    };

    const token = await InspectorFirebase.getIdToken();

    try {
      const isCustomizedUrlNameTaken = await checkDuplicatedCustomizedUrlName(token)
      if(!isCustomizedUrlNameTaken) {
        await updateCustomizedUrlName(token, body);
        setOriginalCustomizedUrlName(customizedUrlName);
        setCustomizedUrlNameEnabled(false);
      }
    } catch (e) {
      console.log('error setting customized url name', e);
    }

  };

  const SetCustomizedUrlNameButton = () => {
    const cancel = () => {
      setCustomizedUrlNameEnabled(false);
      setCustomizedUrlName(originalCustomizedUrlName);
    };
    if(!customizedUrlNameEnabled) {
      return (
        <div className="row col s12" style={{marginTop: "10px"}}>
          <button className='btn housky-primary-bg' onClick={() => setCustomizedUrlNameEnabled(true)}>Set Customized Url Name</button>
        </div>
      );
    } else {
      return (
        <div className="row col s12" style={{marginTop: "10px"}}>
          <button className='btn housky-primary-bg' onClick={cancel}>Cancel</button>
          <button style={{marginLeft: "10px"}} onClick={onSetCustomizedUrlName} className='btn housky-primary-bg'>Confirm</button>
        </div>
      );

    }
  };

  const DuplicateCustomizedUrlNameError = () => {
    if(hasDuplicateUrlName) {
      return (
        <div className="col s12" style={{color: "red"}}>
          Oops, this is already taken, please try another one
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className='container pt-5 mt-5'>
      <div className="row pt-5">
        <div className="col s12">
          <h5>User Information</h5>
          <div className='row pt-5 mt-5'>
            <div className='col s6 m4' style={{ position: 'relative' }}>
              <Avatar />
            </div>
          </div>
          <div className="row">
            <AccountInputs
              name="First Name" size="col s6" isDisabled={props.isDisabled} isRequired={true} id="inspector_first_name" type="text" value={props.inspector_first_name} handleChange={props.handleChange} />
            <AccountInputs
              name="Last Name" size="col s6" isDisabled={props.isDisabled} isRequired={true} id="inspector_last_name" type="text" value={props.inspector_last_name} handleChange={props.handleChange} />
          </div>
          <div className="row ">
            <AccountInputs
              name="Email" size="col s6" isDisabled={props.isDisabled} isRequired={true} id="i_email" type="email" value={props.i_email} handleChange={props.handleChange} />
            <AccountInputs
              name="Cell Number" size="col s6" isDisabled={props.isDisabled} isRequired={true} id="temp_cell_number" type="text" value={props.isDisabled ? `(${props.i_area_code}) - ${props.i_cell_number}` : props.temp_cell_number} handleChange={props.handleChange} />
          </div>
          <div className='row'>
            <div className="input-field col s12">
              <textarea disabled={props.isDisabled ? true : false} id="bio" placeholder='Write something about yourself like your background, interests, or anything you want the customers to know to help them feel connected!' style={{ minHeight: '170px' }} className="materialize-textarea" value={props.bio ? props.bio : ""} onChange={props.handleBio} data-length="500"></textarea>
              <label className='active' htmlFor="bio">Bio</label>
            </div>
          </div>
          <div className="row col s12">
            <EditButton />
          </div>
          <div className="row col s12">
            <h5>Customized Url Name</h5>
            <div className="row">
              <AccountInputs onBlur={onCustomizedUrlNameBlur} name="Customized Url Name" size="col s12" isDisabled={!customizedUrlNameEnabled} type="text" id="customizedUrlNameInput" value={customizedUrlName || ""} handleChange={onCustomizedUrlNameChange} />
              <DuplicateCustomizedUrlNameError />
              <div className="col s12">
                <span style={{marginRight: "10px"}}>
                  {`Shareable customized individual page url:`}
                </span>
                <span>
                  <b>
                    { `housky.io/inspector/${customizedUrlName || props.inspectorid}` }
                  </b>
                </span>
              </div>
              <SetCustomizedUrlNameButton />
            </div>
          </div>
          <ResetPassword />
        </div>
      </div>
    </div>
  );
}