import React from 'react'
import {Link} from 'react-router-dom'
import {app, inspectorApp} from '../../firebase'

export default class ResetPassword extends React.Component{
  state = {
    email:'',
    reset : false,
    failed : false,
    type: 'user'
  }

  componentDidMount = () =>{
    if (window.location.href.split(".")[0].split("/")[2] === "inspector")
      this.setState({type:'inspector'})
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleReset = (e) =>{
    e.preventDefault() 
    let auth = app.auth()
    if(this.state.type === 'inspector')
     auth = inspectorApp.auth()
    auth.sendPasswordResetEmail(this.state.email).then(()=> {
      // Email
    }).catch((error)=> {
      // Error Handle
    });

    this.setState({reset:true})
  }


  FormBody = () =>{
    if(this.state.reset)
      return <div className='py-4'>
        <h6>Check your inbox for an email with the subject line "Reset your password for Housky."</h6>
        <h6>If your email is in our system you should receive a email from us. Please give the system a couple of minutes or check the Junk section if you still have not received it.</h6>
        <div className='center my-2'>
          <Link to='/login'>Login</Link>
        </div>
        <div className='center my-2'>
          <Link to='/signup'>Create an Account?</Link>
        </div>
      </div>
    else{
      return  <form className='py-4'>
        <label>Email</label>
        <input
          id='email'
          name="email"
          type="email"
          value={this.state.email}
          onChange={this.handleChange}
        ></input>
        <button className="btn mt-4 py-1 housky-primary-complimentary-bg" style={{ height: 'auto', width: '100%', color: 'black' }} onClick={this.handleReset}>
          Send Request
 </button>
        <div className='center my-2'>
          <Link to='/login'>Login</Link>
        </div>
        <div className='center my-2'>
          <Link to='/signup'>Create an Account?</Link>
        </div>
      </form>
    }
  }

  render(){
    return   <div style={{ backgroundColor: '#2F2F2F', minHeight:'65vh' }}>
    <div className='row mb-0' style={{ position: 'relative', zIndex: '2', paddingBottom:'100px' }}>
      <h5 className='white-text center-align mt-0' style={{ padding:'30px 0px' }}>Forgot Password</h5>
      <div className='col s12 m4 offset-m4' style={{ borderRadius: '10px', backgroundColor: 'white' }}>
        <div className='container'>
         <this.FormBody />
        </div>
      </div>
    </div>
  </div>
  }
}