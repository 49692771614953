import React, {useEffect, useState} from 'react'
import moment from 'moment'
import './InspectorCard.css'
import { useHistory } from 'react-router-dom';
import RatingStars from "../../../components/GeneralComponents/RatingStars/RatingStars";

export default ({ inspector, selectedInspectors, setSelectedInspectors }) => {

  let [ inspectorAdded, setInspectorAdded ] = useState(false);
  let [ showAddRemoveButton, setShowAddRemoveButton ] = useState(false);

  useEffect(() => {
    const selectedInspectorsRaw = window.localStorage.getItem('selectedInspectors');
    const selectedInspectors = JSON.parse(selectedInspectorsRaw);
    if(selectedInspectors) {
      const inspectedAlreadyAdded = selectedInspectors.find(i => i.id === inspector.inspectorid)
      setInspectorAdded(!!inspectedAlreadyAdded);
    }
  }, [selectedInspectors])

  const getInspectorPhotoSrc = () => {
    if(inspector.photoid){
      return inspector.photoid
    } else {
      return require('../../../assets/blank-profile-picture.png')
    }
  }

  const getInspectorName = () => {
    const inspectorFirstName = inspector.inspector_first_name.charAt(0) + inspector.inspector_first_name.slice(1).toLowerCase()
    var inspectorLastName = inspector.inspector_last_name.charAt(0) + inspector.inspector_last_name.slice(1).toLowerCase()
    if(inspectorLastName.length > 9){
      inspectorLastName = inspectorLastName.charAt(0).toUpperCase() + '.'
    }
    return `${inspectorFirstName} ${inspectorLastName}`
  }

  const getInspectorRating = () => {
    return inspector.google_reviews_score ? parseFloat(inspector.google_reviews_score).toFixed(1) : '0.0'
  }

  const getExperienceSection = () => {
    if(inspector.year_started) {
      const yearsOfExp = moment().diff(inspector.year_started, 'years');
      return <div id='inspectorExp'>{`Experience: ${yearsOfExp} yrs`}</div>
    }
  }

  const getInspectorLanguage = () => {
    return inspector.languages
  }

  const history = useHistory()
  const goToInspectorPage = () => {
    const id = inspector.customized_url_name || inspector.inspectorid;
    history.push(`/inspector/${id}`)
  }

  const getBadge = () => {
    let badges = [];
    if(inspector.certification?.toLowerCase().indexOf('ashi') >= 0) {
      badges.push({
        url: require("../../../assets/ashi.png"),
        title: 'ASHI Certified'
      })
    }
    if(inspector.certification?.toLowerCase().indexOf('internachi') >= 0) {
      badges.push({
        url: require("../../../assets/interNachi.png"),
        title: 'INTERNACHI Certified'
      })
    }

    return (
      <div style={{marginLeft: '5px'}}>
        {
          badges.map((badge, index) =>
              <span key={index} id={index} style={{marginLeft: "5px", verticalAlign: "bottom"}}>
                <img style={{height: "21px"}} src={badge.url} title={badge.title}/>
              </span>
          )
        }
      </div>
    );
  };

  const addToListClicked = (e) => {
    e.stopPropagation();
    if(inspectorAdded) {
      let newSelectorInspectors = selectedInspectors.filter(i => i.id !== inspector.inspectorid);
      setSelectedInspectors(newSelectorInspectors);
    } else {
      let newSelectorInspectors = [...selectedInspectors];
      newSelectorInspectors.push({
        id: inspector.inspectorid,
        imageUrl: inspector.photoid,
        firstName: inspector.inspector_first_name,
        lastName: inspector.inspector_last_name,
        companyName: inspector.comp_name,
        latitude: inspector.latitude,
        longitude: inspector.longitude,
        calculatorParams: inspector.calculator_params,
        googleReviewsScore: inspector.google_reviews_score
      });
      setSelectedInspectors(newSelectorInspectors);
    }
  };

  return (
    <div>
      <div className="inspector-card__box" onMouseEnter={() => setShowAddRemoveButton(true)} onMouseLeave={() => setShowAddRemoveButton(false)}>
        <div className="inspector-card__imgContainer" onClick={goToInspectorPage}>
          <div className={`inspector-card__buttonContainer ${inspectorAdded ? 'inspectorAdded' : 'inspectorNotAdded'}`}>
            {
              showAddRemoveButton &&
                <button onClick={addToListClicked}>
                  { inspectorAdded ? 'Remove' : 'Add' }
                </button>
            }
          </div>
          <img src={getInspectorPhotoSrc()}/>
        </div>
      </div>

      <div className="inspector-card__inspectorInfoContainer">
          <div>
            <span id='inspectorName' onClick={goToInspectorPage}>{getInspectorName()}</span>
          </div>
          { getExperienceSection() }
          <div style={{display: 'flex'}}>
            <div id='inspectorRating'>
              <span style={{paddingRight: '3px'}}><RatingStars score={getInspectorRating()}/></span>
              <a href={inspector.google_reviews_url} target="_blank" className="inspector-card__score">{ getInspectorRating() }</a>
            </div>
            {getBadge()}
          </div>
      </div>
    </div>
  )
}
