import React from 'react';
import './MissionCenter.css'

const MissionCenter = () => {
    return(
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} >
            <div className="mission-center__content" >
                <div className="mission-center__singleBx">
                    <div className="mission-center__title">Our Mission</div>
                    <div className="mission-center__body">
                        We are currently servicing New York City and central New Jersey.
                        Founded in 2019, Housky is a unique real estate portal dedicated to connecting home-owners with the
                        right home inspectors.
                    </div>
                </div>
                <div className="mission-center__imgBx">
                    <img src={require("../../../assets/house_icon.png")}/>
                </div>
                <div className="mission-center__singleBx">
                    <div className="mission-center__title">Our Story</div>
                    <div className="mission-center__body">
                        As first time home buyers we struggled to understand the home buying process. We realized that so
                        many home purchases go through without access to a high quality home inspector and decided to do
                        something about it. From this idea was born Housky. It helps you easily locate and schedule a
                        quality home inspector, all online.
                    </div>
                </div>
            </div>
            <div className="mission-center__inspectorIconBx">
                <img src={require('../../../assets/inspector_with_mag_glass.png')}/>
            </div>
        </div>
    )
};

export default MissionCenter;