import { confirmPayment, getUserPaymentStatus } from "../../../services/service";

import { CardElement } from "@stripe/react-stripe-js";
import CheckoutSummary from "../CheckoutSummary/CheckoutSummary";
import React from "react";
import { app as firebase } from "../../../firebase";
import { withRouter } from "react-router-dom";
import CancelJob from "../../../components/InspectorDomain/CancelJob/CancelJob";
import Materialize from "materialize-css/dist/js/materialize.min.js";

class Checkout extends React.Component {
  state = {
    idToken: "",
    jobStatus: {},
    orderSuccess: false,
    orderFail: false,
    orderFailMessage: "",
    isCancelJobActive: false,
  };

  componentDidMount() {
    const { jobid } = this.props.match.params;
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        this.setState({ idToken });
        getUserPaymentStatus(jobid, idToken).then((data) => {
          if (
            data.paymentIntentStatus &&
            (data.paymentIntentStatus === "requires_capture" || data.paymentIntentStatus === "succeeded")
          )
            this.setState({ jobStatus: data, orderSuccess: true });
          else this.setState({ jobStatus: data });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { jobid } = this.props.match.params;
    const { stripe, elements } = this.props;
    if (!stripe || !elements) return;
    const cardElement = elements.getElement(CardElement);
    stripe
      .confirmCardPayment(this.state.jobStatus.paymentIntent, {
        payment_method: {
          card: cardElement,
        },
      })
      .then((result) => {
        if (result.error) {
          this.setState({ orderFail: true, orderFailMessage: result.error.message });
        } else {
          if (result.paymentIntent.status === "succeeded" || result.paymentIntent.status === "requires_capture") {
            confirmPayment(jobid, this.state.idToken);
            this.setState({ orderSuccess: true, orderFail: false, orderFailMessage: "" });
          }
        }
      });
  };

  setNewCurrentStatus = () => {
    getUserPaymentStatus(this.props.match.params.jobid, this.state.idToken)
      .then((data) => {
        if (
          data.paymentIntentStatus &&
          (data.paymentIntentStatus === "requires_capture" || data.paymentIntentStatus === "succeeded")
        ) {
          data.job_info.current_status === "CANCELLED" &&
            Materialize.toast({
              html: "This job has been cancelled",
              classes: "rounded",
            });
          this.setState({ jobStatus: data, orderSuccess: true });
        } else this.setState({ jobStatus: data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    console.log(this.state);
    const CARD_ELEMENT_OPTIONS = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: "Helvetica Neue, Helvetica, sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };
    return (
      <div style={{ minHeight: "75vh" }} className="container">
        {this.state.jobStatus.job_info ? (
          this.state.jobStatus.job_info.current_status === "CANCELLED" ? (
            <h5 style={{ textAlign: "center" }}>This job has been cancelled</h5>
          ) : (
            <React.Fragment>
              {this.state.jobStatus.message && <p>{this.state.jobStatus.message}</p>}
              {this.state.jobStatus.job_info && <CheckoutSummary {...this.state.jobStatus.job_info} />}
              <div
                className="row"
                style={{
                  margin: "0",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "rgb(240, 240, 240)",
                  paddingBottom: "85px",
                }}
              >
                <div className="col s12 m6" style={{ marginLeft: "0", paddingTop: "1.2rem", paddingBottom: "1.2rem" }}>
                  {this.state.jobStatus.job_info ? (
                    this.state.orderSuccess ? (
                      <div style={{ textAlign: "center" }}>
                        <h5>Order Placed Successfully</h5>
                      </div>
                    ) : (
                      <React.Fragment>
                        <h5 style={{ textAlign: "center" }}>Payment Information</h5>
                        <div style={{ paddingBottom: "1.5rem" }}>
                          <p>
                            Your inspection request has been created and sent to Inspectors. Please note however that
                            inspectors are more likely to accept a job if they are guaranteed payment. By entering your
                            credit card information below, we will keep it with us in a secure environment. We will not
                            share your credit card information with the inspector and rest assured that your card will
                            not be charged until an inspector accepts the job.
                          </p>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                          <label>{this.state.orderFailMessage}</label>
                          <CardElement options={CARD_ELEMENT_OPTIONS} />
                          <button
                            className="btn-small"
                            type="submit"
                            disabled={!this.props.stripe}
                            style={{ marginTop: "1.2rem" }}
                          >
                            Complete Booking
                          </button>
                        </form>
                      </React.Fragment>
                    )
                  ) : (
                    <h5 style={{ textAlign: "center" }}>This job does not exist</h5>
                  )}
                </div>
              </div>
              {this.state.jobStatus.job_info && (
                <div
                  style={{
                    margin: "0",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "rgb(240, 240, 240)",
                    paddingBottom: "85px",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <button
                      className="btn-small "
                      onClick={() => {
                        this.setState({ isCancelJobActive: !this.state.isCancelJobActive });
                      }}
                    >
                      Cancel Job
                    </button>
                  </div>
                  {this.state.isCancelJobActive && (
                    <CancelJob
                      containerClass="col s12 m12 py-5"
                      jobId={this.props.match.params.jobid}
                      removeCancelJob={() => {
                        this.setState({ isCancelJobActive: !this.state.isCancelJobActive });
                      }}
                      setNewCurrentStatus={this.setNewCurrentStatus}
                    />
                  )}
                </div>
              )}
            </React.Fragment>
          )
        ) : null}
      </div>
    );
  }
}

export default withRouter(Checkout);
