import React, { Fragment } from "react";
import "./UserInfo.css";

const UserInfo = (props) => {
  const { first_name, last_name, email, area_code, cell_number, accepted } =
    props.userInfo;
  const {
    address_one,
    address_two,
    city,
    state,
    zipcode,
    bedroom,
    bathroom,
    house_type,
    sqft,
    year_built,
    foundation,
    attic,
  } = props.houseInfo;
  const { radon, wdi, radonValue, wdiValue } = props.jobDetails;
  return (
    <div>
      <h5>Client</h5>
      <p>
        <span className="text-bold">Name:</span> {`${first_name} ${last_name}`}
      </p>
      { accepted ? (
        <Fragment>
          <p>
            <span className="text-bold">Email:</span> {email.toLowerCase()}
          </p>
          <p>
            <span className="text-bold">Phone:</span>{" "}
            {`${area_code}-${String(cell_number).slice(0, 3)}-${String(
              cell_number
            ).slice(3)}`}
          </p>
        </Fragment>
      ) : (
        <Fragment>
          <p>
            <span className="text-bold">Address:</span>{" "}
            {`${address_one}, ${city} ${state} ${zipcode}`}
          </p>
          { address_two && (
            <p>
              <span className="text-bold">Floor/Apt:</span> {address_two}
            </p>
          )}
          <p>
            <span className="text-bold">Bedrooms:</span> {bedroom}
          </p>
          <p>
            {" "}
            <span className="text-bold">Bathrooms:</span> {bathroom}
          </p>
          <p>
            <span className="text-bold">Home Type:</span> {house_type}
          </p>
          <p>
            <span className="text-bold">Sqft:</span> {sqft}
          </p>
          <p>
            <span className="text-bold">Year Built:</span> {year_built}
          </p>

          <p>
            <span className="text-bold">Foundation:</span> { foundation || "Not Specified"}
          </p>
          <p>
            <span className="text-bold">Attic:</span> { attic || "Not Specified" }
          </p>
          {radon && (
            <p>
              <span className="text-bold">Radon:</span> ${radonValue}{" "}
              <span className="customBadge">Requested</span>
            </p>
          )}
          {wdi && (
            <p>
              <span className="text-bold">WDI:</span> ${wdiValue}{" "}
              <span className="customBadge">Requested</span>
            </p>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default UserInfo;
