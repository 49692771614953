import {
  getInspectorProfile,
  getReviews,
  updateHouskyCertification,
  upsertInspectorNotes,
  upsertReviews
} from "../../../services/service";

import { Inspector as InspectorFirebase } from "../../../utils/firebase/firebase";
import React, {useEffect, useState} from "react";
import { withRouter } from "react-router-dom";
import _ from 'lodash';

function InspectorInfo(props) {

  const [ inspector, setInspector ] = useState({});
  const [ idToken, setIdToken ] = useState('');
  const [ companyId, setCompanyId ] = useState('');
  const [ notes, setNotes ] = useState('');
  const [ reviews, setReviews ] = useState({
    google_reviews_url: '',
    google_reviews_count: '',
    google_reviews_score: '',
    yelp_reviews_url: '',
    yelp_reviews_count: '',
    yelp_reviews_score: ''
  });
  const [ isEditingReviews, setIsEditingReviews ] = useState(false);
  const [ isEditingNotes, setIsEditingNotes ] = useState(false);

  useEffect(() => {
    (
      async() => {
        try {
          const { inspectorId } = props.match.params;
          const idToken = await InspectorFirebase.getIdToken();
          const inspector = await getInspectorProfile(idToken, inspectorId);
          const companyId = inspector.data.companyid;
          const reviews = await getReviews(companyId);
          setIdToken(idToken);
          setInspector(inspector.data);
          setReviews(reviews.data);
          setCompanyId(companyId);
          setNotes(inspector.data?.notes);
        } catch (e) {
          console.log(e);
        }
      }
    )();
  }, []);

  const updateCertification = () => {
    const { inspectorId } = props.match.params;
    updateHouskyCertification(idToken, inspectorId).then((certification) => {
      const updatedInspector = _.cloneDeep(inspector);
      updatedInspector.housky_certified = certification.data.housky_certified;
      setInspector(updatedInspector);
    });
  };

  const {
    inspectorid,
    photoid,
    inspector_first_name,
    inspector_last_name,
    i_email,
    i_area_code,
    i_cell_number,
    license_number_one,
    comp_name,
    comp_cell_number,
    comp_area_code,
    comp_email,
    housky_certified,
    comp_address_one,
    comp_address_two,
    comp_city,
    comp_state,
    comp_zipcode,
    license_state_one,
    license_state_two,
    license_state_three,
    license_number_three,
    license_number_two,
  } = inspector;

  const onEditReviewsButtonClicked = async () => {
    if(isEditingReviews) {
      await upsertReviews(companyId, reviews)
    }
    setIsEditingReviews(!isEditingReviews);
  };

  const onEditNotesButtonClicked = async () => {
    if(isEditingNotes) {
      await upsertInspectorNotes(idToken, inspectorid, { notes })
    }
    setIsEditingNotes(!isEditingNotes);
  };

  const onReviewChanged = (e) => {
    if(isEditingReviews) {
      const key = e.target.name;
      let updatedReviews = {...reviews};
      updatedReviews[key] = e.target.value;
      setReviews(updatedReviews);
    }
  };

  const onNotesChanged = (e) => {
    if(isEditingNotes) {
      setNotes(e.target.value);
    }
  };

  return (
    <div style={{ minHeight: "100vh", marginTop: "3rem" }}>
      {inspectorid ? (
        <div className="row">
          <div className="col s4" style={{ display: "flex", justifyContent: "center" }}>
            {<img src={photoid} alt="profile" style={{ width: "75%" }} />}
          </div>
          <div className="col s8">
            <div>
              <h6 style={{ textDecoration: "underline" }}>Housky Certified</h6>
              <div className="switch">
                <label>
                  No
                  <input type="checkbox" checked={housky_certified} onChange={updateCertification} />
                  <span className="lever"></span>
                  Yes
                </label>
              </div>
            </div>
            <div>
              <h5 style={{ textDecoration: "underline" }}>Personal Information</h5>
              <div>
                <div>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
                    {`${inspector_first_name} ${inspector_last_name}`}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Email:</span> {i_email.toLowerCase()}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Phone:</span> {`${i_area_code} ${i_cell_number}`}
                  </p>
                </div>
                <div>
                  <h6 style={{ textDecoration: "underline" }}>Licenses:</h6>
                  <div>
                    <p>
                      <span style={{ fontWeight: "bold" }}>License State:</span> {license_state_one}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>License:</span> {license_number_one}
                    </p>
                  </div>
                  <div hidden={!license_number_two}>
                    <span>------------------------------</span>
                    <p>
                      <span style={{ fontWeight: "bold" }}>License State:</span> {license_state_two}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>License:</span> {license_number_two}
                    </p>
                  </div>
                  <div hidden={!license_number_three}>
                    <span>------------------------------</span>
                    <p>
                      <span style={{ fontWeight: "bold" }}>License State:</span> {license_state_three}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>License:</span> {license_number_three}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h5 style={{ textDecoration: "underline" }}>Company Information</h5>
              {comp_email ? (
                <div>
                  <div>
                    <p>
                      <span>Name:</span> {comp_name}
                    </p>
                    <p>
                      <span>Address:</span> {`${comp_address_one}, ${comp_address_two}, ${comp_city}, ${comp_state}, ${comp_zipcode}`}
                    </p>
                    <p>
                      <span>Phone:</span> {`${comp_area_code} ${comp_cell_number}`}
                    </p>
                    <p>
                      <span>Email:</span> {comp_email.toLowerCase()}
                    </p>
                  </div>
                </div>
              ) : (
                <p>No company in records</p>
              )}{" "}
            </div>
            <div>
              <h5 style={{ textDecoration: "underline" }}>Public Reviews Url</h5>
              <div>
                <div>
                  <div className='row'>
                    <div><b>Google:</b></div>
                    <div className='col s8'>
                      <div className='row'>
                        <div>Url:</div>
                        <input type='text' name='google_reviews_url' onChange={onReviewChanged} value={reviews.google_reviews_url || ''}/>
                      </div>
                      <div className='row'>
                        <div>Score:</div>
                        <input type='text' onChange={onReviewChanged} name='google_reviews_score' className='col s2' value={reviews.google_reviews_score || ''}/>
                      </div>
                      <div className='row'>
                        <div>Reviews Count:</div>
                        <input type='text' onChange={onReviewChanged} name='google_reviews_count' className='col s2' value={reviews.google_reviews_count || ''}/>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div><b>Yelp:</b></div> Coming Soon :)
                  </div>
                  <div className='row'>
                    <button onClick={onEditReviewsButtonClicked} className='btn-small' style={{marginRight: '10px'}}>
                      { isEditingReviews ? 'Submit' : 'Edit Reviews' }
                    </button>
                    { isEditingReviews ? <button onClick={() => setIsEditingReviews(false)} className='btn-small red'>Cancel</button> : '' }
                  </div>
                </div>
                <div>
                  <div className='row'>
                    <div className='col s8'>
                      <div className='row'>
                        <div>Notes:</div>
                        <input type='text' name='notes' onChange={onNotesChanged} value={notes || ''}/>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <button onClick={onEditNotesButtonClicked} className='btn-small' style={{marginRight: '10px'}}>
                      { isEditingNotes ? 'Submit' : 'Edit Notes' }
                    </button>
                    { isEditingNotes ? <button onClick={() => setIsEditingNotes(false)} className='btn-small red'>Cancel</button> : '' }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default withRouter(InspectorInfo);
