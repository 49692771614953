import React, {useEffect, useState} from 'react'
import BrandWhite from '../../assets/housky_logo-06-smaller.png'
import BrandIcon from '../../assets/housky_logo-07.png';
import {Link, useHistory} from 'react-router-dom'
import './navbar.css'
import Materialize from 'materialize-css'
import { closeNav } from '../../services/helper'
import DomainSelector from "../GeneralComponents/DomainSelector";
import RatingStars from "../GeneralComponents/RatingStars/RatingStars";

export default function({ domain, setDomain }) {
  let [isSticky, setIsSticky] = useState(false);
  let [selectedInspectors, setSelectedInspectors] = useState([]);
  let [ showList, setShowList ] = useState(false);

  const history = useHistory();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const elems = document.querySelectorAll('.sidenav');
    Materialize.Sidenav.init(elems, { edge: 'left' });
  }, [])

  useEffect(() => {
    const selectedInspectorsRaw = window.localStorage.getItem('selectedInspectors');
    const selectedInspectors = JSON.parse(selectedInspectorsRaw);
    if(selectedInspectors) {
      setSelectedInspectors(selectedInspectors);
    }
  }, []);

  useEffect(() => {
    const handler =  (e) => {
      let shoppingList = document.getElementById('shoppingList');
      let shoppingListBadge = document.getElementById('shoppingListBadge');
      if(shoppingList && !shoppingList.contains(e.target) && !shoppingListBadge.contains(e.target)) {
        setShowList(false);
      }
    }
    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, [])

  const handleScroll = () => {
    if (window.scrollY > 90 && window.location.pathname.split('/')[1]!=='terms') {
      setIsSticky(true);
    }
    else {
      setIsSticky(false);
    }
  }

  const setSelectedInspectorsInStorage = (inspectors) => {
    setSelectedInspectors(inspectors);
    window.localStorage.setItem('selectedInspectors', JSON.stringify(inspectors));
  };


  const deleteInspector = (inspector) => {
    let newSelectorInspectors = selectedInspectors.filter(i => i.id !== inspector.id);
    setSelectedInspectorsInStorage(newSelectorInspectors);
  };

  const schedule = () => {
    const env = process.env.REACT_APP_ENV;
    const selectedInspectorsEncoded = encodeURIComponent(JSON.stringify(selectedInspectors))
    switch (env) {
      case "LOCAL":
      case "QA":
        setDomain('user');
        history.push(`/schedule?selectedInspectors=${selectedInspectorsEncoded}`)
        break;
      case "PROD":
        window.location.href = `https://user.housky.io/schedule?selectedInspectors=${selectedInspectorsEncoded}`
        break;
    }
  }

  const goToUserDomain = () => {
    const env = process.env.REACT_APP_ENV;
    switch (env) {
      case "LOCAL":
      case "QA":
        setDomain('user');
        history.push(`/login`)
        break;
      case "PROD":
        window.location.href = `https://user.housky.io/login`;
        break;
    }
  }

  const shoppingListRow = (inspector, key) => {

    const normalizeName = (name) => {
      const lowerCaseName = name.toLowerCase();
      return lowerCaseName[0].toUpperCase() + lowerCaseName.slice(1)
    };

    const getInspectorPhotoSrc = () => {
      if(inspector.imageUrl){
        return inspector.imageUrl
      } else {
        return require('../../assets/blank-profile-picture.png')
      }
    }

    const getInspectorRating = () => {
      return inspector.googleReviewsScore ? parseFloat(inspector.googleReviewsScore).toFixed(1) : '0.0'
    }

    return (
      <div className="shoppingListRow" key={key}>
        <div className="inspectorImageContainer">
          <img src={getInspectorPhotoSrc()} />
        </div>
        <div className="name">
          {`${normalizeName(inspector.firstName)} ${inspector.lastName[0].toUpperCase()}.`}
          <span style={{marginTop: '5px', display: 'flex'}}>
            <RatingStars score={getInspectorRating()} />
            <span style={{alignSelf: 'center', marginLeft: '8px'}}>{ getInspectorRating() }</span>
          </span>
        </div>
        <div className="delete" onClick={() => deleteInspector(inspector)}>
          <i className="material-icons">close</i>
        </div>
      </div>
    );
  }

  const getShoppingCart = () => {
    if(selectedInspectors && selectedInspectors.length > 0) {
      return (
        <div>
          <div className="nav-links right shoppingCartContainer" onClick={() => setShowList(!showList)} id="shoppingListBadge">
            My Inspectors
            <span className="new badge" data-badge-caption="">{ selectedInspectors.length }</span>
          </div>
          { showList &&
            <div className="shoppingList" id="shoppingList">
              { selectedInspectors.map(shoppingListRow) }
              <div className="buttons">
                <button className="btn-small" onClick={schedule}>Proceed to Schedule</button>
              </div>
            </div>
          }
        </div>
      )
    } else {
      return (
        <div>
          <div className="nav-links right shoppingCartContainer" onClick={() => setShowList(!showList)}>
            My Inspectors
            <span className="new badge" data-badge-caption="">{ selectedInspectors.length }</span>
          </div>
          { showList &&
            <div className="shoppingList">
              <div style={{display: 'flex', justifyContent: 'center', color: 'black'}}>
                Add some inspectors to schedule
              </div>
              <div className="buttons">
                <button className="btn-small red" onClick={() => setShowList(false)}>Close</button>
              </div>
            </div>
          }
        </div>
      )
    }
  }

  if(window.location.pathname.indexOf('/search') >= 0) {
    return null;
  }

  return (
    <>
      <nav id='home-nav' className="sticked-nav" style={{ position: `${window.location.pathname === '/learning_center' || window.location.pathname.split('/')[1]==='terms' ? 'initial' : 'sticky'}` }}>
        <div className="nav-wrapper">
          <div className="hide-on-med-and-down">
            <Link to='/'>
              <img alt='Housky' className="brand-logo" src={BrandWhite} style={{ height: '100%', paddingLeft: '28px'}} />
            </Link>
          </div>
          <div className="hide-on-large-only">
            <a to='/'>
              <img alt='Housky' className="brand-logo left" src={BrandIcon}/>
            </a>
          </div>
          <a href="!#" data-target="mobile-nav" className="sidenav-trigger m-0 left"><i className={`${window.location.pathname === '/learning_center' ? 'black-text' : ''} material-icons`} style={{ color: `${isSticky ? 'black' : 'white'}` }}>menu</i></a>
          { getShoppingCart() }
          <div className="navLinksContainer hide-on-med-and-down right">
            <DomainSelector domain={domain} setDomain={setDomain} />
            <a className='nav-links' href='https://report.housky.io/'>Report Finder</a>
            <Link className='nav-links' style={{borderBottom: window.location.pathname ==='/learning_center' ? '2px solid #546A76': 'none'}} to='/learning_center'>Learning Center</Link>
            <a className='nav-links' href='https://inspector.housky.io/'>For Home Inspectors</a>
            <a className='nav-links mouse-pointer' onClick={goToUserDomain}>For Home Buyers</a>
          </div>
        </div>
      </nav>

      <ul className="sidenav" id="mobile-nav">
        <li><Link onClick={closeNav} to='/'>Home</Link></li>
        {/*<li><Link onClick={closeNav} to='/pricing'>PRICING</Link></li>*/}
        <li><a onClick={closeNav} href='https://report.housky.io'>Report Finder</a></li>
        <li><Link onClick={closeNav} to='/learning_center'>Learning Center</Link></li>
        <li><a href='https://inspector.housky.io/'>For Home Inspectors</a></li>
        <li><a onClick={goToUserDomain}>For Home Buyers</a></li>
      </ul>
    </>
  );
}
