import React from "react";
import "./Table.css";

const Table = ({
  tableHeaders = [],
  responsiveHeaders = [],
  tableClass = "",
  tableStyle = {},
  tHeadClass = "",
  tHeadStyle = {},
  thClass = "",
  thStyle = "",
  tableWrapperClass = "",
  tableWrapperStyle = {},
  emptyTableMessage,
  ...restProps
}) => {
  return (
    <div
      style={{ overflow: "auto", ...tableWrapperStyle }}
      className={`pb-5 card-panel table-height ` + tableWrapperClass}
    >
      <table style={{ ...tableStyle }} className={`` + tableClass}>
        <thead style={{ ...tHeadStyle }} className={`` + tHeadClass}>
          <tr>
            {tableHeaders.map((header, i) => {
              return (
                <th
                  key={i}
                  name={header}
                  id={header}
                  className={
                    `table-header ${
                      responsiveHeaders[i] === "hide" ? "hideHeader" : ""
                    }` + thClass
                  }
                  style={
                    i === 0
                      ? {
                          borderTopLeftRadius: "2px",
                          borderBottomLeftRadius: "2px",
                        }
                      : i === tableHeaders.length - 1
                      ? {
                          borderTopRightRadius: "2px",
                          borderBottomRightRadius: "2px",
                        }
                      : {}
                  }
                >
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {restProps.children}
          {renderEmptyRows(
            emptyTableMessage,
            tableHeaders.length,
            restProps.children ? restProps.children.length : 0
          )}
        </tbody>
      </table>
    </div>
  );
};

const renderEmptyRows = (
  emptyTableMessage = "",
  tableRowWidth = "",
  tableRowCount
) => {
  if (tableRowCount === 0) {
    return (
      <tr>
        <td colSpan={`${tableRowWidth}`}>{emptyTableMessage}</td>
      </tr>
    );
  }
  const emptyRowArray = [];
  for (let i = 0; i < 6 - tableRowCount; i++) {
    emptyRowArray.push(
      <tr key={i}>
        <td colSpan={`${tableRowWidth}`}></td>
      </tr>
    );
  }
  return emptyRowArray;
};

export default Table;
