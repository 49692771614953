import React from 'react'
import { inspectorApp } from "../../../firebase"
import Materialize from "materialize-css/dist/js/materialize.min.js";
import { updateSampleReport } from '../../../services/service'

export default class UploadGallery extends React.Component {
  state = {
    sampleReportPreview: '',
    sampleRef: '',
  }

  componentDidMount = () => {
    if (this.props.sample_report)
      this.setState({
        sampleReportPreview: this.props.sample_report
      })
  }

  handleLoad = (e, preview, ref) => {
    if (!e.target.files[0]) {
      return null;
    } else {
      this.setState({
        [preview]: URL.createObjectURL(e.target.files[0]),
        [ref]: e.target.files[0]
      });
    }
  };

  handleUpload = async (e) => {
    e.preventDefault()
    const root = inspectorApp.storage().ref();
    let url = null
    if (this.state.sampleRef.name) {
      const uploadSample = root.child(
        `${this.props.i_email.toLowerCase()}/SampleReport/SampleReport`
      );
      try {
        const snapshot = await uploadSample.put(this.state.sampleRef);
        url = await snapshot.ref.getDownloadURL();
      } catch (err) {
        console.log(err);
      }
      let body = {
        token: this.props.idToken,
        sample_report: url,
        inspectorid: this.props.inspectorid
      }
      updateSampleReport(body)
        .then(() => {
          this.setState({
            sampleRef: '',
          })
          this.props.getInspectorInfo()
        })
        .catch((err) => {
          Materialize.toast({ html: err, classes: 'rounded' })
        })
    }
  }

  render() {
    return <>
      <div className='row' style={{ borderBottom: '1px dotted gray' }}>
        <h5>Sample Report</h5>
        <div className='col s12' style={{ textAlign: 'center' }}>
          {this.state.sampleReportPreview ?
            <a style={{ fontSize: '1.5rem' }} href={this.state.sampleReportPreview} target="_blank" rel="noopener noreferrer">
              {this.state.sampleRef ? 'Preview Upload' :
                'View Report'}
            </a>
            : <></>}
        </div>
        <form>
          <div className='col s12'>
            <div className="file-field input-field">
              <div className="btn housky-secondary-complimentary-bg">
                <span>Select File</span>
                <input type="file" onChange={e => { this.handleLoad(e, "sampleReportPreview", "sampleRef"); }} />
              </div>
              <div className="file-path-wrapper">
                <input className="file-path validate gray-text-color" type="text" />
              </div>
            </div>
            {this.state.sampleRef ?
              <button className='btn housky-primary-bg mb-2' onClick={this.handleUpload} style={{ width: '100%', height: 'auto', padding: '0.8rem' }}> Upload Sample Report</button> : <></>}
          </div>
        </form>
      </div>
    </>
  }
}