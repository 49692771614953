import React from "react";
import {
  getHouskyAdminPrices,
  updateHouskyAdminPrice
} from "../../../services/service";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import { inspectorApp as firebase } from "../../../firebase";
import AuthContext from "../../../contexts/auth";
import HouskyPricingRow from "../HouskyPricingRow/HouskyPricingRow";
class HouskyPricing extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      allHouskyPrices: [],
      allHouseTypes: [],
      idToken: null,
      selectedPrice: {},
      canEditSelectedPrice: false,
      editPrice: "",
      editHouseTypeId: "",
      editHouseType: "",
      editMargin: "",
      editBeta: "",
      editInspectionQuality: "",
      editProcessingFee: "",
      editWdi: "",
      editRadon: ""
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        this.setState({ idToken });
        return getHouskyAdminPrices(idToken);
      })
      .then(data => {
        this.setState({
          allHouskyPrices: [...data.housky_prices],
          allHouseTypes: [...data.house_types]
        });
      })
      .catch(err => {
        console.log(err.request.responseURL);
        console.log(err.message);
      });
  }

  handleSelectPrice = e => {
    for (let price of this.state.allHouskyPrices) {
      if (`${price.housky_id}` === e.target.parentNode.id) {
        this.setState({ selectedPrice: { ...price } });
      }
    }
  };

  handleSelectedPriceEdit = e => {
    const {
      price,
      margin,
      beta,
      inspection_quality,
      processing_fee,
      wdi,
      radon,
      house_typeid,
      house_type
    } = this.state.selectedPrice;
    const { canEditSelectedPrice } = this.state;
    if (canEditSelectedPrice) {
      this.setState({
        editPrice: "",
        editHouseTypeId: "",
        editHouseType: "",
        editMargin: "",
        editBeta: "",
        editInspectionQuality: "",
        editProcessingFee: "",
        editWdi: "",
        editRadon: "",
        canEditSelectedPrice: false
      });
    } else
      this.setState({
        editPrice: price,
        editHouseTypeId: house_typeid,
        editHouseType: house_type,
        editMargin: margin,
        editBeta: beta,
        editInspectionQuality: inspection_quality,
        editProcessingFee: processing_fee,
        editWdi: wdi,
        editRadon: radon,
        canEditSelectedPrice: true
      });
  };

  handleEditPricingInput = e => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleUpdatePrice = e => {
    e.preventDefault();
    const {
      editPrice,
      editMargin,
      editBeta,
      editInspectionQuality,
      editProcessingFee,
      editWdi,
      editRadon,
      idToken
    } = this.state;
    const { housky_id, house_typeid } = this.state.selectedPrice;
    let priceData = {
      housky_id,
      house_typeid,
      price: editPrice,
      margin: editMargin,
      beta: editBeta,
      inspection_quality: editInspectionQuality,
      processing_fee: editProcessingFee,
      wdi: editWdi,
      radon: editRadon
    };

    updateHouskyAdminPrice(priceData, idToken)
      .then(data => {
        if (data.status >= 400 || data.status >= 500) {
          Materialize.toast({
            html: `Cannot update price`,
            classes: "rounded"
          });
        } else {
          return getHouskyAdminPrices(idToken);
        }
      })
      .then(({ housky_prices, house_types }) => {
        this.setState({
          allHouskyPrices: [...housky_prices],
          editPrice: "",
          editHouseTypeId: "",
          editHouseType: "",
          editMargin: "",
          editBeta: "",
          editInspectionQuality: "",
          editProcessingFee: "",
          editWdi: "",
          editRadon: "",
          canEditSelectedPrice: false,
          selectedPrice: {}
        });
        Materialize.toast({
          html: `Price Updated`,
          classes: "rounded"
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const { allHouskyPrices, canEditSelectedPrice, selectedPrice } = this.state;

    return (
      <div style={{ minHeight: "525px" }} className="container mt-5 pt-5">
        <div className="row">
          <div>
            <button
              className={`btn-small ${
                selectedPrice.housky_id ? "" : "disabled"
              }`}
              onClick={this.handleSelectedPriceEdit}
            >
              {canEditSelectedPrice ? "Cancel" : "Edit Price"}
            </button>
          </div>
        </div>
        {canEditSelectedPrice && this.editSelectedPriceForm()}
        <div className="card-panel mb-5 pb-5">
          <table className="striped centered highlight mb-5">
            <thead>
              <tr>
                <th>House Type</th>
                <th>Price</th>
                <th>Margin</th>
                <th>Beta</th>
                <th>Inspection Quality</th>
                <th>Procesesing Fee</th>
                <th>WDI</th>
                <th>Radon</th>
              </tr>
            </thead>
            <tbody>
              {allHouskyPrices.length > 0 ? (
                allHouskyPrices.map(e => {
                  return (
                    <HouskyPricingRow
                      key={e.housky_id}
                      houskyPricingData={e}
                      clickEvent={this.handleSelectPrice}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan="8">{`No prices yet`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  handleChange = e => {
    let { allHouseTypes } = this.state;
    for (let houseType of allHouseTypes) {
      if (houseType.house_type === e.target.value) {
        this.setState({
          editHouseTypeId: houseType.house_typeid,
          editHouseType: houseType.house_type
        });
      }
    }
  };

  editSelectedPriceForm = () => {
    const {
      editPrice,
      editMargin,
      editBeta,
      editInspectionQuality,
      editProcessingFee,
      editWdi,
      editRadon,
      editHouseType
    } = this.state;
    return (
      <div className="card-panel grey lighten-5">
        <form>
          <div className="row">
            <div className="input-field col s3">
              <input
                name="editPrice"
                value={editPrice}
                type="number"
                onChange={this.handleEditPricingInput}
              />
              <label className="active" htmlFor="editPrice">
                Price
              </label>
            </div>
            <div className="input-field col s3">
              <input
                placeholder={30}
                name="editMargin"
                value={editMargin}
                type="number"
                maxLength="3"
                onChange={this.handleEditPricingInput}
              />
              <label className="active" htmlFor="editMargin">
                Margin
              </label>
            </div>
            <div className="input-field col s3">
              <input
                placeholder={30}
                name="editBeta"
                value={editBeta}
                type="number"
                maxLength="3"
                onChange={this.handleEditPricingInput}
              />
              <label className="active" htmlFor="editBeta">
                Beta
              </label>
            </div>
            <div className="input-field col s3">
              <input
                disabled
                name="editeditHouseTypeBeta"
                defaultValue={editHouseType}
                type="text"
              />
              <label className="active" htmlFor="editBeta">
                House Type
              </label>
            </div>

            {/*<HouseTypes
              allHouseTypes={this.state.allHouseTypes}
              editHouseType={this.state.editHouseType}
              handleChange={this.handleChange}
            /> */}
            <div className="input-field col s3">
              <input
                placeholder="New User"
                name="editInspectionQuality"
                value={editInspectionQuality}
                type="number"
                onChange={this.handleEditPricingInput}
              />
              <label className="active" htmlFor="editInspectionQuality">
                Inspection Quality
              </label>
            </div>
            <div className="input-field col s3">
              <input
                placeholder="New User"
                name="editProcessingFee"
                value={editProcessingFee}
                type="number"
                onChange={this.handleEditPricingInput}
              />
              <label className="active" htmlFor="editProcessingFee">
                Processing Fee
              </label>
            </div>
            <div className="input-field col s3">
              <input
                placeholder="New User"
                name="editWdi"
                value={editWdi}
                type="number"
                onChange={this.handleEditPricingInput}
              />
              <label className="active" htmlFor="editWdi">
                WDI
              </label>
            </div>
            <div className="input-field col s3">
              <input
                placeholder="New User"
                name="editRadon"
                value={editRadon}
                type="number"
                onChange={this.handleEditPricingInput}
              />
              <label className="active" htmlFor="editRadon">
                Radon
              </label>
            </div>
            <div>
              <button onClick={this.handleUpdatePrice} className="btn-small">
                Update Code
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };
}

export default HouskyPricing;
