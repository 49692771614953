import React from "react";

const defaultSrc = ev => {
  ev.target.src = require("../../../assets/blank-profile-picture.png");
};

export default props => {
  const {
    photoid,
    inspector_first_name,
    inspector_last_name,
    i_email,
    i_area_code,
    i_cell_number,
    comp_name
  } = props.inspectorInfo;
  return (
    <div
      className="z-depth-2"
      style={{minHeight:'unset', 
    margin:'1.5rem'}}
    >
      <div className="row inspector-card-small pt-3">
        <div className="col s8 offset-s2 center">
          <img
            alt="profile"
            onError={defaultSrc}
            src={
              photoid
                ? photoid
                : require("../../../assets/blank-profile-picture.png")
            }
            className="inspector-profile-pic z-depth-2"
          />
        </div>
        <div className="col s12 center" style={{ padding: "10px 25px" }}>
          <div className="row">
            <div className="inspector-card-header">
              <span style={{ textTransform: "capitalize" }}>
                {inspector_first_name[0]}
              </span>
              <span style={{ textTransform: "lowercase" }}>
                {inspector_first_name.slice(1)}
              </span>
              <span> </span>
              <span style={{ textTransform: "capitalize" }}>
                {inspector_last_name[0]}
              </span>
              <span style={{ textTransform: "lowercase" }}>
                {inspector_last_name.slice(1)}
              </span>
            </div>
            <div className="inspector-card-text housky-primary-text" style={{textTransform:'uppercase'}}>
              {comp_name}
            </div>
          </div>
          <div className="row">
            <div className="inspector-card-text">{i_email}</div>
            <div className="inspector-card-text gray-text-color">
              {i_area_code}-
              {`${String(i_cell_number).slice(0, 3)}-${String(
                i_cell_number
              ).slice(3)}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
