import { Inspector as InspectorFirebase } from "../../../utils/firebase/firebase";
import React, {useEffect, useState} from "react";
import { Redirect } from "react-router-dom";
import FilterTable from "../../GeneralComponents/FilterTable/FilterTable";
import { getInspectorsByAdmin } from "../../../services/service";
import FilterTableHeader from "../../GeneralComponents/FilterTableHeader/FilterTableHeader";
import "./InspectorList.css";
import Filter from "../../GeneralComponents/AdminDomain/Filter";

function InspectorListComponent() {
  const [inspectorList, setInspectorList] = useState([]);
  const [selectedInspector, setSelectedInspector] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [inspectorListSortType, setInspectorListSortType] = useState();
  const [filteredInspectorList, setFilteredInspectorList] = useState();

  useEffect(() => {
    (
      async() => {
        window.scrollTo(0, 0);
        try {
          const idToken = await InspectorFirebase.getIdToken();
          const inspectors = await getInspectorsByAdmin(idToken);
          inspectors.data.sort((a, b) => {
            return new Date(b.registration_date) - new Date(a.registration_date)
          })
          setInspectorList(inspectors.data);
          setFilteredInspectorList(inspectors.data);
        } catch (e) {
          console.log(e);
        }
      }
    )();
  }, [])

  const selectInspector = (e) => {
    setSelectedInspector(e.target.parentNode.id);
    setRedirect(true);
  };

  const inspectorSortByActive = () => {
    if (!inspectorListSortType) {
      filteredInspectorList.sort((x, y) => {
        return x.housky_certified === y.housky_certified ? 0 : x.housky_certified ? -1 : 1;
      });
      setInspectorListSortType(true);
      setInspectorList(filteredInspectorList);
    } else {
      setInspectorListSortType(!inspectorListSortType);
      setInspectorList(filteredInspectorList.reverse());
    }
  };

  const arrowUp = <i className="material-icons">arrow_drop_up</i>;
  const arrowDown = <i className="material-icons">arrow_drop_down</i>;

  const tableHeaders = ["Name", "Email", "Company", "License State", "Register Date", "Housky Certified"].map((e, i, arr) => {
    return (
      <FilterTableHeader
        key={i}
        name={e}
        id={e}
        left={i === 0 ? true : false}
        right={arr.length - 1 === i ? true : false}
        center={arr.length - 1 === i ? true : false}
        header={e}
        clickEvent={arr.length - 1 === i ? inspectorSortByActive : null}
        icon={arr.length - 1 === i ? (inspectorListSortType === true ? arrowUp : arrowDown) : null}
      />
    );
  });

  const getRegistrationDate = (d) => {
    if(d) {
      return new Date(d).toLocaleDateString();
    }
  };

  return (
    <div>
      {redirect ? (
        <Redirect push to={`inspector/${selectedInspector}`} />
      ) : (
        <div>
          <div>
            <Filter inspectorList={inspectorList} setFilteredInspectorList={setFilteredInspectorList} />
          </div>
          <div style={{paddingLeft: '10px'}}>
            { `Total: ${inspectorList.length}` }
          </div>
          <FilterTable tableClass="striped centered hightlight" tableHeaders={tableHeaders}  emptyTableMessage="No results">
            {filteredInspectorList &&
            filteredInspectorList.map((e) => (
                <tr className="mouse-pointer" key={e.inspectorid} id={e.inspectorid} onClick={selectInspector}>
                  <td>{`${e.inspector_first_name} ${e.inspector_last_name}`}</td>
                  <td>{e.i_email}</td>
                  <td>{e.comp_name}</td>
                  <td>{e.license_state_one}</td>
                  <td>{getRegistrationDate(e.registration_date)}</td>
                  <td>{e.housky_certified ? "Yes" : "No"}</td>
                </tr>
              ))}
          </FilterTable>
        </div>
      )}
    </div>
  );
}

export default InspectorListComponent;