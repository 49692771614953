import React from 'react'

export default () => {
  return <div id='password-check'>
    <p>Password must contain the following:</p>
    <p id="letter" className="invalid">A <b>lowercase</b> letter</p>
    <p id="capital" className="invalid">A <b>uppercase</b> letter</p>
    <p id="number" className="invalid">A <b>number</b></p>
    <p id="symbol" className="invalid">A <b>special</b> character</p>
    <p id="length" className="invalid">Minimum <b>8 characters</b></p>
  </div>
}