import app from "firebase/app";
import "firebase/auth";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};
const inspectorConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_INSPECTOR_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_INSPECTOR_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_INSPECTOR_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_INSPECTOR_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_INSPECTOR_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_INSPECTOR_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_INSPECTOR_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_INSPECTOR_MEASUREMENTID,
};
const chatConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CHAT_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CHAT_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_CHAT_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_CHAT_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CHAT_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_CHAT_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_CHAT_MEASUREMENT_ID,
};

app.initializeApp(config);
const inspectorApp = app.initializeApp(inspectorConfig, "inspector");
const chatApp = app.initializeApp(chatConfig, "chat");
export { app, inspectorApp, chatApp };
