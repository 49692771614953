import React, {useState} from "react";
import moment from "moment";
import HouseTypes from "../FormSelectOptions/HouseTypes";
import _ from 'lodash';

export default function InspectorFilter({ jobsList, setFilteredJobs, resetFilteredJobs = (() => {}) }) {
  const [query, setQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [houseTypeFilter, setHouseTypeFilter] = useState('All');
  const [showFilter, setShowFilter] = useState(false);

  const houseTypes = [
    { house_typeid: 1, house_type: "Condo" },
    { house_typeid: 2, house_type: "Single Family" },
    { house_typeid: 3, house_type: "Town House" },
    { house_typeid: 4, house_type: "Two-Three Family" },
    { house_typeid: 5, house_type: "Mansion" }
  ];

  const search = () => {
    let filteredJobsByQuery = [];
    let filteredJobsByDate = [];
    let filteredJobsByHouseType = [];
    if(query) {
      jobsList.forEach(job => {
        if (
            job.first_name.toLowerCase().includes(query.toLowerCase()) ||
            job.last_name.toLowerCase().includes(query.toLowerCase())
        ) {
          filteredJobsByQuery.push(job);
        }
        if (job.email.toLowerCase().includes(query.toLowerCase())) {
          filteredJobsByQuery.push(job);
        }
        if (
            job.address_one.toLowerCase().includes(query.toLowerCase()) ||
            job.state.toLowerCase().includes(query.toLowerCase()) ||
            String(job.zipcode).includes(query)
        ) {
          filteredJobsByQuery.push(job);
        }
      });
    } else {
      filteredJobsByQuery = jobsList;
    }

    if(startDate && endDate) {
      jobsList.forEach(job => {
        for (let i = 0; i < job.eventinfo.length; i++) {
          const jobDate = moment(job.eventinfo[i].unix, "x").format('YYYY-MM-DD')
          if (moment(jobDate).isBetween(startDate, endDate, undefined, '[]')) {
            filteredJobsByDate.push(job);
            i = job.eventinfo.length;
          }
        }
      });
    } else {
      filteredJobsByDate = jobsList;
    }

    if(houseTypeFilter !== 'All') {
      jobsList.forEach(job => {
        if (job.house_type === houseTypeFilter) {
          filteredJobsByHouseType.push(job);
        }
      });
    } else {
      filteredJobsByHouseType = jobsList;
    }

    // get a intersection among the three filtered results to get the final result
    const filteredJobs = _.intersectionWith(filteredJobsByQuery, filteredJobsByDate, filteredJobsByHouseType, (x, y) => x.jobid === y.jobid );
    setFilteredJobs(filteredJobs);
  };

  const clearFilter = () => {
    setStartDate('');
    setEndDate('');
    setQuery('');
    setHouseTypeFilter('All');
    resetFilteredJobs();
  };

  const OnFilterButtonClicked = () => {
    clearFilter();
    setShowFilter(!showFilter);
  };

  return (
      <div className="row pb-0 mb-0">
        <div className='row hide-on-small-only'>
          <button
              onClick={OnFilterButtonClicked}
              className="btn-small m-1"
          >
            {showFilter ? "CLose filter" : "Quick filter"}
          </button>
          { showFilter &&
            <button
                className="btn-small m-1"
                onClick={() => {
                  clearFilter();
                }}
            >
              Clear filter
            </button>
          }
        </div>
        <div className='row show-on-small hide-on-med-and-up'>
          <button
            onClick={OnFilterButtonClicked}
            className="btn-small m-1"
          >
            {showFilter ? <i className="material-icons">close</i> : <i className="material-icons">filter_list</i>}
          </button>
          { showFilter &&
            <button
              className="btn-small m-1"
              onClick={() => {
                clearFilter();
              }}
            >
              <i className="material-icons">delete</i>
            </button>
          }
        </div>
        { showFilter &&
          <div className="row">
            <div className="input-field col s12 m3">
              <input
                id="query"
                placeholder='Email, address or name'
                value={query}
                type="text"
                onChange={ e => { setQuery(e.target.value) }}
              />
              <label className='active' htmlFor="query">Search</label>
            </div>
            <div className="input-field col s6 m3">
              <input
                type="date"
                name="startDate"
                value={startDate}
                onChange={ e => {setStartDate(e.target.value) }}
              />
              <label htmlFor="startDate">From</label>
            </div>
            <div className="input-field col s6 m3">
              <input
                type="date"
                name="endDate"
                value={endDate}
                onChange={e => { setEndDate(e.target.value) }}
              />
              <label htmlFor="endDate">To</label>
            </div>
            <HouseTypes
              optionsWrapperClass="input-field col s12 m3"
              allHouseTypes={houseTypes}
              editHouseType={houseTypeFilter}
              handleChange={ e => { setHouseTypeFilter(e.target.value) }}
            />
            <div className="s12">
              <button
                className="btn-small m-1 hide-on-small-only"
                onClick={search}
              >
                Search
              </button>
              <button
                className="btn-small m-1 show-on-small hide-on-med-and-up"
                onClick={search}
              >
                <i className="material-icons">search</i>
              </button>
            </div>
          </div>
        }
      </div>
  );
}
