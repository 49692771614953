import React from 'react'
import './account.css'
import PasswordCheck from '../../GeneralComponents/Password/PasswordCheck'
import ShowEye from '../../../assets/eye.svg'
import HideEye from '../../../assets/eye-slash.svg'

const focusPass = () => {
  if (document.getElementById("password-check"))
    document.getElementById("password-check").style.display = "block"
}

const blurPass = () => {
  if (document.getElementById("password-check"))
    document.getElementById("password-check").style.display = "none"
}

export default (props) => {
  return <div id="rePasswordModal" className="modal">
    <div className="modal-content">
      <h4>Please Enter Credientials</h4>
      <div className='row'>
        <form className="col s12">
          <div className="row mb-0">
            <div className="input-field col s6">
              <input id="reAuthEmailnp" type="text" value={props.reAuthEmail} onChange={props.handleChangeAuthEmail} />
              <label className={props.reAuthEmail ? 'active' : ''} htmlFor="reAuthEmail">Old Email</label>
            </div>
            <div className="input-field col s6">
              <input id="reAuthPasswordnp" type="password" value={props.reAuthPassword} onChange={props.handleChangeAuthPass} />
              <label htmlFor="reAuthPassword">Old Password</label>
            </div>
          </div>
          <div className="row mb-0">
            <div className="input-field col s12">
              <input id="password" 
              type={props.hidden ? 'password': 'text'} 
              onFocus={focusPass}
              onBlur={blurPass}
              value={props.password} 
              onChange={props.handleChange} />
              <label className={props.password ? 'active' : ''} htmlFor="password">New Password</label>
              <img src={props.hidden ? HideEye : ShowEye} onClick={props.toggleShow} alt='show/hide' style={{ height: '20px', position: 'absolute', top: '20px', right: '0px' }} />
              <PasswordCheck />
            </div>
            <div className="input-field col s12">
              <input id="confirmPassword" type={props.hidden ? 'password': 'text'} value={props.confirmPassword} onChange={props.handleChange} />
              <label htmlFor="confirmPassword">Confirm Password</label>

              <img src={props.hidden ? HideEye : ShowEye} onClick={props.toggleShow} alt='show/hide' style={{ height: '20px', position: 'absolute', top: '20px', right: '0px' }} />
            </div>
          </div>
        </form>
          <div className="modal-footer">
            <div className='row'>
            <div className='col s12 m6'>
            <button onClick={(e)=>{e.preventDefault()}} style={{width:'100%'}} className="my-1 modal-close housky-secondary-complimentary-bg btn">Cancel</button>
          </div>
              <div className='col s12 m6'>
            <button type='submit' onClick={props.handleReAuth} style={{width:'100%'}} className="my-1 modal-close housky-primary-complimentary-bg btn">Confirm</button>
            </div>
          </div>
            </div>
      </div>
    </div>
  </div>
}