import React from "react";
import InspectorJobReview from "./InspectorJobReview";

const Review = (props) => {
  const { inspectorId, jobId } = props.match.params;

  return <InspectorJobReview inspectorId={inspectorId} jobId={jobId} />;
};

export default Review;
