import React, {useEffect, useState} from 'react'
import cssStyles from './InspectorIndividualPage.module.css'
import {getInspectorsByInspectorId, getInspectorSkillsReviews, getReviews} from "../../../services/service";
import RatingStars from "../../../components/GeneralComponents/RatingStars/RatingStars";
import EvaluationDots from "./EvaluationDots";
import moment from "moment";
import {Link, useHistory} from "react-router-dom";
import BrandWhite from "../../../assets/housky_logo-06-smaller.png";
import BrandIcon from "../../../assets/housky_logo-07.png";
import DomainSelector from "../../../components/GeneralComponents/DomainSelector";
import {closeNav} from "../../../services/helper";
import Materialize from "materialize-css";

const IndividualPage = (props) => {

  const { inspectorId } = props.otherProps.match.params;
  const { domain, setDomain } = props;
  const [ inspectorInfo, setInspectorInfo ] = useState({});
  const [ reviews, setReviews ] = useState({});
  const [ showList, setShowList ] = useState(false);
  const [ skillsReviews, setSkillsReviews ] = useState({
    "communication_rate": 0,
    "expertise_rate": 0,
    "report_quality_rate": 0,
    "oriented_rate": 0
  });
  const [ selectedInspectors, setSelectedInspectors ] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const elems = document.querySelectorAll('.sidenav');
    Materialize.Sidenav.init(elems, { edge: 'left' });
  }, [])

  useEffect(() => {
    const selectedInspectorsRaw = window.localStorage.getItem('selectedInspectors');
    const selectedInspectors = JSON.parse(selectedInspectorsRaw);
    if(selectedInspectors) {
      setSelectedInspectors(selectedInspectors);
    }
  }, []);


  useEffect(() => {
    async function fetchData() {
      const getInspectorInfo = async () => {
        return getInspectorsByInspectorId(inspectorId);
      }

      const getInspectorReviews = async (companyId) => {
        return getReviews(companyId);
      }

      const getSkillsReviews = async () => {
        return getInspectorSkillsReviews(inspectorId);
      }

      let inspectorInfo = await getInspectorInfo();
      let reviewsData = await getInspectorReviews(inspectorInfo.companyid);
      let skillsReviews = await getSkillsReviews();

      setInspectorInfo(inspectorInfo);
      setReviews(reviewsData.data);
      setSkillsReviews(skillsReviews.data);
    }

    fetchData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200)
  }, []);

  const getIsSelected = () => {
    if(inspectorInfo.inspectorid) {
      return selectedInspectors.filter((inspector) => inspector.id === inspectorInfo.inspectorid).length > 0;
    } else {
      return selectedInspectors.filter((inspector) => inspector.id === inspectorId).length > 0;
    }
  };

  const isSelected = getIsSelected();

  const getInspectorPhotoSrc = () => {
    if(inspectorInfo.photoid){
      return inspectorInfo.photoid
    } else {
      return require('../../../assets/blank-profile-picture.png')
    }
  }

  const getInspectorBio = () => {
    if(inspectorInfo.bio && inspectorInfo.bio.length > 1){
      return inspectorInfo.bio
    } else {
      return "This inspector hasn't written any descriptions yet."
    }
  }

  const getGoogleReviewScore = () => {
    if(reviews){
      return parseFloat(reviews.google_reviews_score).toPrecision(2)
    } else {
      return '0.0'
    }
  }

  const getGoogleReviewCount = () => {
    if(reviews){
      return(reviews.google_reviews_count)
    } else {
      return null
    }
  }

  const getCertificationBadge = () => {
    if(inspectorInfo.certification_number){
      let badges = [];
      if(inspectorInfo.certification.toLowerCase().indexOf('ashi') >= 0) {
        badges.push({
          url: require("../../../assets/ashi.png"),
          title: 'ASHI Certified'
        });
      }
      if(inspectorInfo.certification.toLowerCase().indexOf('internachi') >= 0) {
        badges.push({
          url: require("../../../assets/interNachi.png"),
          title: 'INTERNACHI Certified'
        });
      }
      return (
        <div>
          <div>
            { badges.length ? `Certifications: ` : null }
          </div>
          {
            badges.map((badge, index) => <img src={badge.url} title={badge.title} key={index} />)
          }
        </div>
      );
    } else {
      return null
    }
  }

  const getExperienceSection = () => {
    if(inspectorInfo.year_started) {
      const yearsOfExp = moment().diff(inspectorInfo.year_started, 'years');
      return <div id='inspectorExp'>{`Home Inspection Experience:: ${yearsOfExp} Years`}</div>
    }
  }

  const getTools = () => {
    const tools = inspectorInfo.tools || [];
    const allTools = tools.map((tool, index) => {
      return (
        <div key={index} style={{display: "flex", flexFlow: "column", alignItems: "center"}}>
          <div style={{borderRadius: "50%", width: "100px", height: "100px", margin: "20px", overflow: "hidden"}}>
            <img style={{width: "100%"}} src={tool.imageUrl} />
          </div>
          <div style={{textAlign: "center", width: "100px"}}>{ tool.name }</div>
        </div>
      );
    })

    const toolsTitle = tools.length ? <div className={cssStyles.sectionName}>Tools</div> : null;

    return (
      <div  className={cssStyles.inspectorToolsContainer}>
        { toolsTitle }
        <div style={{display: "flex", flexFlow: "row wrap"}}>
          { allTools }
        </div>
      </div>
    );
  };

  const getPriceRange = () => {
    const { show_price_range, calculator_params } = inspectorInfo
    if(show_price_range && calculator_params && calculator_params.priceRangeMin && calculator_params.priceRangeMax) {
      return (
        <div className="row" className={cssStyles.priceRangeContainer}>
          <div className={cssStyles.sectionName}>
            Price Range
          </div>
          <progress style={{width:"70%"}} value={calculator_params.priceRangeMax} max={calculator_params.priceRangeMax*1.2}/>
          <div style={{width: "58%"}}>
            <div style={{display:"flex", justifyContent:"space-between", fontSize:"14pt"}}>
              <span>{`$${calculator_params.priceRangeMin}`}</span>
              <span>{`$${calculator_params.priceRangeMax}`}</span>
            </div>
          </div>
        </div>
      );
    }
  };

  const addInspectorToCart = (event) => {
    event.preventDefault();
    let newSelectedInspectors = [...selectedInspectors];
    if (isSelected) {
      newSelectedInspectors = selectedInspectors.filter((inspector) => inspector.id !== inspectorInfo.inspectorid);
    } else {
      newSelectedInspectors.push({
        id: inspectorInfo.inspectorid,
        firstName: inspectorInfo.inspector_first_name,
        lastName: inspectorInfo.inspector_last_name,
        companyName: inspectorInfo.comp_name,
        latitude: inspectorInfo.latitude,
        longitude: inspectorInfo.longitude,
        calculatorParams: inspectorInfo.calculator_params,
        googleReviewsScore: reviews.google_reviews_score,
        imageUrl: inspectorInfo.photoid,
      });
    }
    setSelectedInspectors([...newSelectedInspectors]);
    window.localStorage.setItem('selectedInspectors', JSON.stringify(newSelectedInspectors));
  }

  const shoppingListRow = (inspector, key) => {

    const normalizeName = (name) => {
      const lowerCaseName = name.toLowerCase();
      return lowerCaseName[0].toUpperCase() + lowerCaseName.slice(1)
    };

    const getInspectorPhotoSrc = () => {
      if(inspector.imageUrl){
        return inspector.imageUrl
      } else {
        return require('../../../assets/blank-profile-picture.png')
      }
    }

    const getInspectorRating = () => {
      return inspector.googleReviewsScore ? parseFloat(inspector.googleReviewsScore).toFixed(1) : '0.0'
    }

    const setSelectedInspectorsInStorage = (inspectors) => {
      setSelectedInspectors(inspectors);
      window.localStorage.setItem('selectedInspectors', JSON.stringify(inspectors));
    };

    const deleteInspector = (inspector) => {
      let newSelectorInspectors = selectedInspectors.filter(i => i.id !== inspector.id);
      setSelectedInspectorsInStorage(newSelectorInspectors);
    };

    return (
      <div className="shoppingListRow" key={key}>
        <div className="inspectorImageContainer">
          <img src={getInspectorPhotoSrc()} />
        </div>
        <div className="name">
          {`${normalizeName(inspector.firstName)} ${inspector.lastName[0].toUpperCase()}.`}
          <span style={{marginTop: '5px', display: 'flex'}}>
            <RatingStars score={getInspectorRating()} />
            <span style={{alignSelf: 'center', marginLeft: '8px'}}>{ getInspectorRating() }</span>
          </span>
        </div>
        <div className="delete" onClick={() => deleteInspector(inspector)}>
          <i className="material-icons">close</i>
        </div>
      </div>
    );
  }

  const schedule = () => {
    const env = process.env.REACT_APP_ENV;
    const selectedInspectorsEncoded = encodeURIComponent(JSON.stringify(selectedInspectors))
    switch (env) {
      case "LOCAL":
      case "QA":
        props.setDomain('user');
        history.push(`/schedule?selectedInspectors=${selectedInspectorsEncoded}`)
        break;
      case "PROD":
        window.location.href = `https://user.housky.io/schedule?selectedInspectors=${selectedInspectorsEncoded}`;
        break;
    }
  }

  const getShoppingCart = () => {
    if(selectedInspectors && selectedInspectors.length > 0) {
      return (
        <div>
          <div className="nav-links right shoppingCartContainer" onClick={() => setShowList(!showList)} id="shoppingListBadge">
            My Inspectors
            <span className="new badge" data-badge-caption="">{ selectedInspectors.length }</span>
          </div>
          { showList &&
            <div className="shoppingList" id="shoppingList">
              { selectedInspectors.map(shoppingListRow) }
              <div className="buttons">
                <button className="btn-small" onClick={schedule}>Proceed to Schedule</button>
              </div>
            </div>
          }
        </div>
      )
    } else {
      return (
        <div>
          <div className="nav-links right shoppingCartContainer" onClick={() => setShowList(!showList)}>
            My Inspectors
            <span className="new badge" data-badge-caption="">{ selectedInspectors.length }</span>
          </div>
          { showList &&
            <div className="shoppingList">
              <div style={{display: 'flex', justifyContent: 'center', color: 'black'}}>
                Add some inspectors to schedule
              </div>
              <div className="buttons">
                <button className="btn-small red" onClick={() => setShowList(false)}>Close</button>
              </div>
            </div>
          }
        </div>
      )
    }
  }

  const goToUserDomain = () => {
    const env = process.env.REACT_APP_ENV;
    switch (env) {
      case "LOCAL":
      case "QA":
        setDomain('user');
        history.push(`/login`)
        break;
      case "PROD":
        window.location.href = `https://user.housky.io/login`;
        break;
    }
  }

  const getGoogleReviewIcon = () => {
    if(reviews.google_reviews_score) {
      return (
        <div>
          <img className={cssStyles.googleIcon} src={require('../../../assets/googleIcon.png')} />
        </div>
      );
    }
  }

  return(
    <div>
      <>
        <nav id='home-nav' className="sticked-nav" style={{ position: `${window.location.pathname === '/learning_center' || window.location.pathname.split('/')[1]==='terms' ? 'initial' : 'sticky'}` }}>
          <div className="nav-wrapper">
            <div className="hide-on-med-and-down">
              <Link to='/'>
                <img alt='Housky' className="brand-logo" src={BrandWhite} style={{ height: '100%', paddingLeft: '28px'}} />
              </Link>
            </div>
            <div className="hide-on-large-only">
              <Link to='/'>
                <img alt='Housky' className="brand-logo left" src={BrandIcon}/>
              </Link>
            </div>
            <a href="!#" data-target="mobile-nav" className="sidenav-trigger m-0 left"><i className={`${window.location.pathname === '/learning_center' ? 'black-text' : ''} material-icons`} style={{ color: 'white' }}>menu</i></a>
            { getShoppingCart() }
            <div className="navLinksContainer hide-on-med-and-down right">
              <DomainSelector domain={domain} setDomain={setDomain} />
              <a className='nav-links' href='https://report.housky.io/'>Report Finder</a>
              <Link className='nav-links' style={{borderBottom: window.location.pathname ==='/learning_center' ? '2px solid #546A76': 'none'}} to='/learning_center'>Learning Center</Link>
              <a className='nav-links' href='https://inspector.housky.io/'>For Home Inspector</a>
              <a className='nav-links mouse-pointer' onClick={goToUserDomain}>For User</a>
            </div>
          </div>
        </nav>
        <ul className="sidenav" id="mobile-nav">
          <li><Link onClick={closeNav} to='/'>Home</Link></li>
          {/*<li><Link onClick={closeNav} to='/pricing'>PRICING</Link></li>*/}
          {/*<li><a onClick={closeNav} href='https://report.housky.io'>Report Finder</a></li>*/}
          <li><Link onClick={closeNav} to='/learning_center'>Learning Center</Link></li>
          {/*<li><a href='https://inspector.housky.io/'>For Home Inspector</a></li>*/}
          {/*<li><a onClick={goToUserDomain}>For User</a></li>*/}
        </ul>
      </>
      <div className={cssStyles.pageContainer}>
        <div className={cssStyles.leftSectionContainer}>
          <div>
            <div className={cssStyles.photoContainer}>
              <img src={ getInspectorPhotoSrc() }/>
            </div>
            <div className={cssStyles.certificationContainer}>
              { getCertificationBadge() }
            </div>
          </div>
          <div className={cssStyles.buttonsContainer}>
            <button className="browser-default" hidden={!inspectorInfo.sample_report}>
              <a href={inspectorInfo.sample_report} target='_blank'>SAMPLE REPORT</a>
            </button>
            <button className="browser-default" hidden={!inspectorInfo.personal_tos}>
              <a href={inspectorInfo.personal_tos} target='_blank'>TERMS & AGREEMENT</a>
            </button>
          </div>
        </div>
        <div className={cssStyles.midSectionContainer}>
          <div className={cssStyles.inspectorInfoContainer}>
            <div className={cssStyles.nameAndRating}>
              <span className={cssStyles.nameContainer}>{`${inspectorInfo.inspector_first_name} ${inspectorInfo.inspector_last_name}`}</span>
              <div style={{display:"flex"}}>
                <span className={cssStyles.ratingScoreContainer}>{getGoogleReviewScore()}</span>
                <span><RatingStars score={getGoogleReviewScore()} /></span>
                <span className={cssStyles.googleIconContainer}>
                  { getGoogleReviewIcon() }
                </span>
              </div>
            </div>
            <div className={cssStyles.company}>{ inspectorInfo.comp_name }</div>
            { getExperienceSection() }
            <div className={cssStyles.bio}>{ getInspectorBio() }</div>
          </div>
          <div  className={cssStyles.inspectorEvaluationsContainer}>
            <div className={cssStyles.sectionName}>Skill/Ability</div>
            <div className={cssStyles.evaluationItem}>
              <span className={cssStyles.itemTitle}>Communication</span>
              <span className={cssStyles.itemRatingDots}>
                              <EvaluationDots score={skillsReviews.communication_rate}/>
                          </span>
            </div>
            <div className={cssStyles.evaluationItem}>
              <span className={cssStyles.itemTitle}>Expertise</span>
              <span className={cssStyles.itemRatingDots}>
                              <EvaluationDots score={skillsReviews.expertise_rate}/>
                          </span>
            </div>
            <div className={cssStyles.evaluationItem}>
              <span className={cssStyles.itemTitle}>Report Quality</span>
              <span className={cssStyles.itemRatingDots}>
                              <EvaluationDots score={skillsReviews.report_quality_rate}/>
                          </span>
            </div>
            <div className={cssStyles.evaluationItem}>
              <span className={cssStyles.itemTitle}>Detail Oriented</span>
              <span className={cssStyles.itemRatingDots}>
                              <EvaluationDots score={skillsReviews.oriented_rate}/>
                          </span>
            </div>
          </div>
        </div>
        <div className={cssStyles.rightSectionContainer}>
          { getTools() }
          { getPriceRange() }
          <div className={cssStyles.cartButtonContainer}>
            <a className={`waves-light btn ${isSelected ? 'orange accent-4' : ''}`} onClick={addInspectorToCart}>
              <i className="material-icons left">
                {isSelected ? 'remove_shopping_cart' : 'add_shopping_cart'}
              </i>
              {isSelected ? "REMOVE INSPECTOR" : "ADD INSPECTOR"}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
};

export default IndividualPage;
