import React, {useEffect} from 'react';
import InspectorHomeBanner from '../../../components/InspectorDomain/Home/InspectorHomeBanner';
import InspectorHomeWhyUs from '../../../components/InspectorDomain/Home/InspectorHomeWhyUs';
import SignUpTutorial from "../../../components/InspectorDomain/Home/SignUpTutorial.js";

const InspectorHome = () => {

    return(
        <div>
            <InspectorHomeBanner />
            <SignUpTutorial />
            <InspectorHomeWhyUs />
        </div>
    )
}

export default InspectorHome;