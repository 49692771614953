import React from "react";

const PromoRow = props => {
  const { promotionid, code, description, discount, active } = props.promoData;
  const clickEvent = props.clickEvent;
  return (
    <tr style={{ cursor: "pointer" }} id={promotionid} onClick={clickEvent}>
      <td>{code}</td>
      <td>{description}</td>
      <td>{discount}%</td>
      <td>{active ? "Yes" : "No"}</td>
    </tr>
  );
};

export default PromoRow;
