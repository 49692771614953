import React from "react";
import InspectorPrivateComponent from "../../../components/InspectorPrivateComponent";
import JobHistoryComponent from "../../../components/InspectorDomain/JobHistory/JobHistory";

export default function ActiveJobs(props) {
  return (
    <InspectorPrivateComponent>
      <JobHistoryComponent {...props} />
    </InspectorPrivateComponent>
  )
}