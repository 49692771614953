import React from "react";
import {
  createPromotion,
  getPromotions,
  updatePromotion
} from "../../../services/service";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import { inspectorApp as firebase } from "../../../firebase";
import AuthContext from "../../../contexts/auth";
import PromoRow from "../PromoRow/PromoRow";
import Active from "../../InspectorDomain/FormSelectOptions/Active";

class Promotions extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      allPromoCodes: [],
      discountCode: "",
      discountDescription: "",
      discountValue: "",
      discountValueError: null,
      idToken: null,
      selectedPromo: {},
      isCreatePromoActive: false,
      canEditSelectedPromo: false,
      editDiscountCode: "",
      editDiscountValue: "",
      editDiscountDescription: "",
      editDiscountActive: "",
      editDiscountValueError: null
    };
  }

  componentDidMount() {
    this.runMaterializeFormSelect();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        this.setState({ idToken });
        return getPromotions(idToken);
      })
      .then(data => {
        this.setState({ allPromoCodes: data });
      })
      .catch(err => {
        console.log(err.request.responseURL);
        console.log(err.message);
      });
  }

  handleCreatePromoInput = e => {
    let { name, value } = e.target;
    if (name === "discountCode") {
      this.validatePromoCode(name, value);
      return;
    }
    if (name === "discountValue") {
      this.validatePromoValue(name, value);
      return;
    }
    this.setState({ [name]: value });
  };

  validatePromoCode = (name, value) => {
    value = value.trim();
    value = value.toUpperCase();
    console.log(name, value);
    this.setState({ [name]: value });
  };

  validatePromoValue = (name, value) => {
    value = value.trim();
    if (value > 100) {
      if (name === "discountValue") {
        this.setState({
          discountValueError: "Value has to be less than 100",
          [name]: value
        });
      } else {
        this.setState({
          editDiscountValueError: "Value has to be less than 100",
          [name]: value
        });
      }
    } else if (value < 0) {
      if (name === "discountValue") {
        this.setState({
          discountValueError: "Value has to be more than 0",
          [name]: value
        });
      } else {
        this.setState({
          editDiscountValueError: "Value has to be more than 0",
          [name]: value
        });
      }
    } else
      this.setState({
        discountValueError: null,
        editDiscountValueError: null,
        [name]: value
      });
  };

  handleCreatePromo = e => {
    e.preventDefault();
    const {
      discountCode,
      discountDescription,
      discountValue,
      idToken
    } = this.state;
    let promoData = {
      code: discountCode,
      description: discountDescription,
      discount: discountValue,
      active: true
    };

    createPromotion(promoData, idToken)
      .then(data => {
        if (data.status >= 400 || data.status >= 500) {
          Materialize.toast({
            html: `Cannot create promo code`,
            classes: "rounded"
          });
        } else {
          return getPromotions(idToken);
        }
      })
      .then(data => {
        this.setState({
          allPromoCodes: data,
          discountCode: "",
          discountDescription: "",
          discountValue: ""
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleSelectPromo = e => {
    for (let promo of this.state.allPromoCodes) {
      if (promo.promotionid === e.target.parentNode.id) {
        this.setState({ selectedPromo: { ...promo } });
      }
    }
  };

  handleSelectedPromoEdit = e => {
    const { discount, active, code, description } = this.state.selectedPromo;
    const { canEditSelectedPromo } = this.state;
    if (canEditSelectedPromo) {
      this.setState({
        editDiscountCode: "",
        editDiscountValue: "",
        editDiscountDescription: "",
        editDiscountActive: "",
        canEditSelectedPromo: false
      });
    } else
      this.setState({
        editDiscountCode: code,
        editDiscountValue: discount,
        editDiscountDescription: description,
        editDiscountActive: active,
        canEditSelectedPromo: true
      });
  };

  handleEditPromoInput = e => {
    let { name, value } = e.target;
    if (name === "editDiscountCode") {
      this.validatePromoCode(name, value);
      return;
    }
    if (name === "editDiscountValue") {
      this.validatePromoValue(name, value);
      return;
    }
    this.setState({ [name]: value });
  };

  handleUpdatePromo = e => {
    e.preventDefault();
    const {
      editDiscountCode,
      editDiscountValue,
      editDiscountDescription,
      editDiscountActive,
      idToken,
      canEditSelectedPromo
    } = this.state;
    const { promotionid } = this.state.selectedPromo;
    let promoData = {
      code: editDiscountCode,
      description: editDiscountDescription,
      discount: editDiscountValue,
      active: editDiscountActive
    };

    updatePromotion(promotionid, promoData, idToken)
      .then(data => {
        if (data.status >= 400 || data.status >= 500) {
          Materialize.toast({
            html: `Cannot update promo code`,
            classes: "rounded"
          });
        } else {
          return getPromotions(idToken);
        }
      })
      .then(data => {
        this.setState({
          allPromoCodes: data,
          editDiscountCode: "",
          editDiscountValue: "",
          editDiscountDescription: "",
          editDiscountActive: "",
          selectedPromo: {},
          canEditSelectedPromo: false
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const {
      isCreatePromoActive,
      canEditSelectedPromo,
      selectedPromo
    } = this.state;

    return (
      <div style={{ minHeight: "525px" }} className="container mt-5 pt-5">
        <div className="row">
          <div>
            <button
              onClick={e => {
                this.setState({ isCreatePromoActive: !isCreatePromoActive });
              }}
              className="btn-small"
            >
              {isCreatePromoActive ? "Cancel" : "Create Promo Code"}
            </button>
          </div>
          <div>
            <button
              className={`btn-small ${
                selectedPromo.promotionid ? "" : "disabled"
              }`}
              onClick={this.handleSelectedPromoEdit}
            >
              {canEditSelectedPromo ? "Cancel" : "Edit Promotion"}
            </button>
          </div>
        </div>
        {isCreatePromoActive && this.createPromotionForm()}
        {canEditSelectedPromo && this.editSelectedPromoForm()}
        <div className="card-panel mb-5 pb-5">
          <table className="striped centered highlight mb-5">
            <thead>
              <tr>
                <th>Code</th>
                <th>Description</th>
                <th>Discount</th>
                <th>Active</th>
              </tr>
            </thead>
            <tbody>
              {this.state.allPromoCodes.length > 0 ? (
                this.state.allPromoCodes.map(e => {
                  return (
                    <PromoRow
                      key={e.promotionid}
                      promoData={e}
                      clickEvent={this.handleSelectPromo}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan="4">{`No promotions yet`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  createPromotionForm = () => {
    const { discountCode, discountDescription, discountValue } = this.state;
    return (
      <div className="card-panel grey lighten-5">
        <h5>Create New Promo Code</h5>
        <form>
          <div className="row">
            <div className="input-field col s6">
              <input
                placeholder="GIVE-30"
                id="discountCode"
                name="discountCode"
                value={discountCode}
                type="text"
                onChange={this.handleCreatePromoInput}
              />
              <label className="active" htmlFor="discountCode">
                Code
              </label>
            </div>
            <div className="input-field col s6">
              <input
                placeholder={30}
                name="discountValue"
                value={discountValue}
                type="number"
                maxLength="3"
                onChange={this.handleCreatePromoInput}
              />
              {this.state.discountValueError && (
                <span className="helper-text">
                  {this.state.discountValueError}
                </span>
              )}
              <label className="active" htmlFor="discountValue">
                Discount (Percent)
              </label>
            </div>
            <div className="input-field col s12">
              <input
                placeholder="New User"
                name="discountDescription"
                value={discountDescription}
                type="text"
                onChange={this.handleCreatePromoInput}
              />
              <label className="active" htmlFor="discountDescription">
                Description
              </label>
            </div>
            <div>
              <button onClick={this.handleCreatePromo} className="btn-small">
                Create Code
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  editSelectedPromoForm = () => {
    const {
      editDiscountCode,
      editDiscountValue,
      editDiscountDescription,
      editDiscountActive
    } = this.state;
    return (
      <div className="card-panel grey lighten-5">
        <form>
          <div className="row">
            <div className="input-field col s4">
              <input
                name="editDiscountCode"
                value={editDiscountCode}
                type="text"
                onChange={this.handleEditPromoInput}
              />
              <label className="active" htmlFor="editDiscountCode">
                Code
              </label>
            </div>
            <div className="input-field col s4">
              <input
                placeholder={30}
                name="editDiscountValue"
                value={editDiscountValue}
                type="number"
                maxLength="3"
                onChange={this.handleEditPromoInput}
              />
              {this.state.editDiscountValueError && (
                <span className="helper-text">
                  {this.state.editDiscountValueError}
                </span>
              )}
              <label className="active" htmlFor="editDiscountValue">
                Discount (Percent)
              </label>
            </div>
            {
              <Active
                active={editDiscountActive}
                handleChange={e => {
                  let editDiscountActive = this.state.editDiscountActive;
                  editDiscountActive = e.target.value === "Yes" ? true : false;
                  this.setState({
                    editDiscountActive
                  });
                }}
              />
            }
            <div className="input-field col s12">
              <input
                placeholder="New User"
                name="editDiscountDescription"
                value={editDiscountDescription}
                type="text"
                onChange={this.handleEditPromoInput}
              />
              <label className="active" htmlFor="editDiscountDescription">
                Description
              </label>
            </div>
            <div>
              <button onClick={this.handleUpdatePromo} className="btn-small">
                Update Code
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  runMaterializeFormSelect = () => {
    let elem = document.querySelectorAll("select");
    Materialize.FormSelect.init(elem, {
      classes: ""
    });
  };

  renderActiveOptions = () => {
    this.runMaterializeFormSelect();
    return (
      <div className="input-field col s6">
        <select
          id="active"
          name="active"
          value={this.state.editDiscountActive ? "Yes" : "No"}
          onChange={e => {
            console.log(e.target.value);
          }}
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        <label className="active" htmlFor="Active">
          Active
        </label>
      </div>
    );
  };
}

export default Promotions;
