import React from 'react'
import { Link } from 'react-router-dom'

export default (props) =>{
  return <div style={{backgroundColor:'#C9564A', color:'white'}}>
    <h5 className='m-0 p-2'>Your profile is incomplete, and you will not be able to accept any jobs. Please visit <Link to='/account'>Settings</Link> and update the following:
      {props.errors.map((e,i)=>{
        return <span key={i}> {e}{i < props.errors.length - 2 ? ', ' : i < props.errors.length -1 ? ' and ' : ''}</span>
      })}
    </h5>
  </div>
}