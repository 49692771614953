import React from 'react';
import cssStyles from './InspectorHomePage.module.css'
import icon from "../../../assets/Free-SignUp-icon.png";
import {Link} from "react-router-dom";

const whyUsCardItems = [
    {
        icon: require('../../../assets/Free-SignUp-icon.png'),
        title: 'Free to Sign Up',
        body: "It won't cost you anything to immediately increase your web presence and get instant exposure in your local market."
    },
    {
        icon: require('../../../assets/Diversify-icon.png'),
        title: 'Diversify Your Client Base',
        body: "Use Housky as a new avenue for finding new clients who are looking for unbiased home inspectors."
    },
    {
        icon: require('../../../assets/New-Business-icon.png'),
        title: 'Grow Your Business',
        body: "We take care of marketing so you can focus on performing your home inspections."
    },
    {
        icon: require('../../../assets/Reputation-icon.png'),
        title: 'Build Your Reputation',
        body: "Get verified reviews from homeowners and demonstrate the importance of your certifications and credentials."
    },
    {
        icon: require('../../../assets/Profile-icon.png'),
        title: 'Personalized Web Profile',
        body: "Host your profile on Housky to showcase your bios, services, sample reports, pre-inspection agreements, price range, and more..."
    },

]

const InspectorHomeWhyUs = () => {
    const getSingleCard = (icon, title, body) => {
        return(
            <div className={cssStyles.card}>
                <div className={cssStyles.cardInner}>
                        <img src={icon} alt=""/>
                    <div className={cssStyles.cardContents}>
                        <div className={cssStyles.cardTitle}>{title}</div>
                        <div className={cssStyles.cardBody}>{body}</div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div style={{paddingTop: '5%'}}>
            <div className={cssStyles.row}>
                <div className={cssStyles.card}>
                    <div className={cssStyles.cardTitle}> —— BENEFITS FOR PROS</div>
                    <div className={cssStyles.cardBodyLarge}>Build your online presence to market your skills and services in your local area</div>
                </div>
                { getSingleCard(whyUsCardItems[0].icon, whyUsCardItems[0].title, whyUsCardItems[0].body) }
            </div>
            <div className={cssStyles.row}>
                { getSingleCard(whyUsCardItems[1].icon, whyUsCardItems[1].title, whyUsCardItems[1].body) }
                { getSingleCard(whyUsCardItems[2].icon, whyUsCardItems[2].title, whyUsCardItems[2].body) }
            </div>
            <div className={cssStyles.row}>
                { getSingleCard(whyUsCardItems[3].icon, whyUsCardItems[3].title, whyUsCardItems[3].body) }
                { getSingleCard(whyUsCardItems[4].icon, whyUsCardItems[4].title, whyUsCardItems[4].body) }
            </div>
            <div className={cssStyles.buttonBx}>
                <Link to="/signup"><button className="browser-default">Get Started</button></Link>
            </div>
        </div>
    )
}

export default InspectorHomeWhyUs;