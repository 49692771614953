import React, { useEffect, useState } from "react";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import "./contractAgreement.css";
import moment from "moment";
import { signContract } from "../../../services/service";

export default (props) => {
  const { report } = props;
  const [fullName, setFullName] = useState("");
  const [isSigned, setIsSigned] = useState(false);
  const signDate = moment().format("MM/DD/YYYY");
  useEffect(() => {
    let elems = document.querySelectorAll(".modal");
    Materialize.Modal.init(elems, { opacity: 0.5 });
  }, []);

  const signDocument = () => {
    signContract(props.idToken, report.contract_id, {
      signature_print: fullName,
      sign_date: signDate,
    }).then(() => {
      setIsSigned(true);
    });
  };
  return (
    <div className="row">
      <div className="col s12">
        {!report.signature_print && (
          <div id="termsandconditions" className="modal modal-fixed-footer">
            <div className="modal-content">
              <h5>Terms and conditions</h5>
              <div className="row mb-0">
                <div className="col s12">
                  <iframe
                    style={{ height: "70vh", width: "100%" }}
                    src={
                      report.personal_tos
                        ? report.personal_tos
                        : "https://firebasestorage.googleapis.com/v0/b/housky-inspectors.appspot.com/o/Housky%2Fhousky-terms-of-service.png?alt=media&token=94db2c6a-a321-457d-9d46-78c24115d736"
                    }
                    title="Terms of service"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="modal-footer" style={{ height: "unset" }}>
              <h6 style={{ textAlign: "left", fontWeight: "bold" }}>
                Sign Here
              </h6>
              <div className="row" style={{ width: "100%" }}>
                <div className="input-field col s8">
                  <input
                    placeholder="Sign your name"
                    id="fullName"
                    type="text"
                    onChange={(e) => {
                      setFullName(e.currentTarget.value);
                    }}
                  />
                  <label className="active" htmlFor="fullName">
                    Full Name
                  </label>
                </div>
                <div className="input-field col s4">
                  <input
                    value={signDate}
                    id="todayDate"
                    type="text"
                    disabled={true}
                  />
                  <label className="active" htmlFor="todayDate">
                    Today's Date
                  </label>
                </div>
              </div>
              <a href="#!" className="modal-close btn-flat">
                Cancel
              </a>
              <a
                href="#!"
                className={`${
                  fullName && "modal-close"
                } deep-purple darken-1 btn-flat white-text`}
                onClick={() => {
                  fullName
                    ? signDocument()
                    : Materialize.toast({
                        html: "Please sign your full name.",
                      });
                }}
              >
                Agree
              </a>
            </div>
          </div>
        )}
        <div style={{ textAlign: "center" }}>
          {isSigned || report.signature_print ? (
            <div>
              <h5>Document Signed!</h5>
              <a
                href={
                  report.personal_tos
                    ? report.personal_tos
                    : "https://firebasestorage.googleapis.com/v0/b/housky-inspectors.appspot.com/o/Housky%2Fhousky-terms-of-service.png?alt=media&token=94db2c6a-a321-457d-9d46-78c24115d736"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Review Terms Of Service
              </a>
            </div>
          ) : (
            <div>
              <h5>You have not signed the terms of service for this report.</h5>
              <a className="btn modal-trigger" href="#termsandconditions">
                Please Sign Here
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
