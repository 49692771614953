import React from 'react'
import './terms.css'

export default () => {
  return <>
    <div className='row nav-offset'>
    </div>
    <div className='term-background'>
      <div className='container pt-5'>
        <div className='inner-container'>
          <div className='row mt-1 mb-0 p-2 pb-5 content-background'>
            <div className='col s12'>
              <div>
                <h3><b>Housky Terms of Service</b></h3>
                <h6 className='gray-text'><i>Effective: April 11, 2020</i></h6>
              </div>
            </div>
            <article>
              <section className='py-1'>
                <strong>1. Acceptance of Terms; Modifications.</strong>
        These Terms of Service (the <strong>“Terms”</strong>) are a binding legal
agreement between you and Housky Inc, a company incorporated under the laws of New York with
a registered office at 134-26 Northern Blvd, Flushing NY 11354 (<strong>“Housky,”</strong> <strong>“we,”</strong> <strong>“us” and</strong> <strong>“our”).</strong>
The Terms govern your use of our software applications, resources and services to connect Home
Inspectors with people seeking home inspection services, facilitate purchase of home inspection
reports and arrange for home inspection services. (collectively, our <strong>“Housky Service”</strong>). The Terms
govern all use of the Housky Service, whether you access it from our website at <a href="https://housky.io" target="_blank" rel="noopener noreferrer">https://www.housky.io</a> (or any localized version) (the <strong>“Site”</strong>), our mobile websites, our online or
phone support offerings, or any other access point we make available to you.
<span style={{ textTransform: 'uppercase' }}>
                  BY AGREEING TO
                  THESE TERMS DURING THE ACCOUNT SIGN-UP PROCESS OR ACCESSING OR USING THE
                  HOUSKY SERVICE WITHOUT AN ACCOUNT, YOU ACCEPT THESE TERMS. IF YOU DO NOT
                  AGREE WITH THESE TERMS, YOU SHOULD NOT ACCEPT THEM, IN WHICH CASE YOU DO
                  NOT HAVE THE RIGHT TO USE THE HOUSKY SERVICE.
      </span>
              </section>
              <section className='py-1'>
                You understand and agree that we may change the Terms from time to time, and that any such
                changes will be effective when we post the modified Terms on the Housky Service, unless otherwise
                required by applicable law. Your continued access and use of the Housky Service after we post the
                modified Terms will constitute your consent to be bound by the modified Terms.
      </section>
            </article>
            <article>
              <strong>2. Housky Service.</strong>
              <section className='py-1'><u>2.1 Nature of the Housky Service.</u> The Housky Service consists of a desktop Web application, and
other related tools, support and services that homeowners (“Home Owners”) and prospective
homeowners (“Home Buyers”) (collectively with “Home Owners” the “Users”) and providers of home
inspection services (“Home Inspectors”) can use to find, communicate with and interact with each
other. We charge fees for some aspects of the Housky Service, as described below in Section 9.
</section>
              <section className='py-1'>
                <u>2.2 Housky does not provide Home Inspection Services.</u>Housky is a neutral venue for Home
              Owners and Home Inspectors. Housky is not a home inspector and does not provide home
              inspection services. Though we do our best to provide metrics for Users to judge the various
              qualifications of Home Inspectors, we make no representations or warranties about the quality of
              home inspection or other services provided by Home Inspectors or about your interactions and
              dealings with Users. Home Inspectors listed on Housky are not under the direction or control of
              Housky, and Home Inspectors determine in their own discretion how to provide services. Though we
              provide general guidance on our Site to help select Home Inspectors, Housky does not employ,
              recommend or endorse Inspectors or Users, and, to the maximum extent permitted by applicable
              law, we will not be responsible or liable for the performance or conduct of Users or Inspectors,
              whether online or offline. We do not screen Users. You should exercise caution and use your
              independent judgment before engaging an Inspector or otherwise interacting with Users via the Housky Service.
              Users and Home Inspectors are solely responsible for making decisions that are in
              the best interests of themselves and their house or business. For example, each Inspector using the
              Housky Service is responsible for keeping his or her own state inspector license current and we
              have no liability for deficiencies not uncovered by a home inspection.
            </section>
              <section className='py-1'>
                <u>2.3 Release.</u> Subject to Section 14 below, our liability for any claims, injuries, loss, harm
and/or damages arising from and/or in any way related to your interactions or dealings with
other users and the acts and/or omissions of Home Inspectors and Users, whether online or
offline, is limited to the amounts and obligations set forth in Section 9.6 below on
Cancellation and Refund. You acknowledge and agree that, to the maximum extent permitted
by the applicable law, YOUR USE AND/OR PROVISION OF HOME INSPECTION SERVICES IS
AT YOUR SOLE AND EXCLUSIVE RISK.
</section>
              <section className='py-1'>
                <u>2.4 Transactions are between Users and Home Inspectors.</u> The Housky Service may be used to find
and offer Home Inspection Services and to facilitate payment, but all transactions conducted via the
Housky Service are between Users and Home Inspectors. Except for the limited refunds specified in
Section 9.6, you agree that Housky has no liability for damages associated with Home Inspection
Services (which may include bodily injury and/or theft and damage to home) or resulting from any
other transactions between users of the Housky Service.
</section>
              <section className='py-1'>
                <u>2.5 Bookings.</u> Users and Home Inspectors transact with each other on the Housky Service when
they both agree to a “booking” that specifies the fees, inspection date and time, cancellation policy,
and other terms for provision of Home Inspection Services via the booking mechanism provided on
the Housky Service (a “Booking”). A Booking may be initiated by Users by selecting the type(s) of
Home Inspection Services to be provided and then following the prompts that appear on-screen. If
you are a User of the Housky Service and you initiate a Booking, you agree to pay for the Home
Inspection Services described in the Booking.
</section>
              <section className='py-1'>
                <u>2.6 Users are Solely Responsible for Evaluating Home Inspectors.</u> Users are solely responsible for
evaluating the suitability of Home Inspectors for the services they offer to provide. Please visit the <a href="https://housky.io/learning_center" target="_blank" rel="noopener noreferrer">Learning Center</a> for guidance about making informed decisions about engaging Home Inspectors.
Though Housky performs a limited review of Home Inspector profiles, Housky does not warrant that
any such screen is accurate, complete, conclusive or up-to-date. Similarly, Housky does not endorse
reviews of Home Inspectors by other Users that may be available via the Housky Service, and
Housky makes no commitments that such reviews are accurate or legitimate.
</section>
            </article>
            <article>
              <section className='py-1'>
                <u>3. Compliance with Applicable Law.</u> By accessing and using the Housky Service, you certify that
you: (1) are at least 18 years of age or the age of majority in your jurisdiction, whichever is higher,
and (2) will comply with all laws and regulations applicable to your activities conducted through, or
related to, the Housky Service.
          </section>
              <ul>
                <li className="term-list">
                  For Home Inspectors, this includes that you are legally eligible to provide Home Inspection
                  Services in the jurisdiction where you provide Home Inspection Services; that you have
                  complied and will comply with all laws and regulations that are applicable to you; that you
                  have obtained all business licenses and permits necessary to legally provide Home
                  Inspection Services; and that, when providing Home Inspection Services, you will comply
                  with local laws.
            </li>
              </ul>
              <section className='py-1'>
                You acknowledge that Housky is entitled to rely on these commitments from you, is not responsible
                to ensure that all users have complied with applicable laws and regulations, and will not be liable for
                a user’s failure to do so.
            </section>
            </article>
            <article>
              <strong>4. strongse of the Housky Service; Suspension</strong>
              <section className='py-1'>
                <u>4.1 Your Conduct on the Housky Service.</u> When you use the Housky Service, you agree:
          </section>
              <ul>
                <li className='term-list'>To use the Housky Service only in a lawful manner and only for its intended purposes.</li>
                <li className='term-list'>Not to submit viruses or other malicious code to or through the Housky Service.</li>
                <li className='term-list'>Not to use the Housky Service, or engage with other users of the Housky Service, for
purposes that violate the law.</li>
                <li className='term-list'>Not to use the Housky Service to arrange for the provision and purchase of services with
another user, then complete transactions for those services outside of the Housky Service.</li>
                <li className='term-list'>Not to use the Housky Service for purposes of competing with Housky or to promote other
products or services.</li>
                <li className='term-list'>Not to post reviews about Home Inspectors that aren’t based on your personal experience,
that are intentionally inaccurate or misleading, or that violate these Terms.</li>
                <li className='term-list'>Not to post content or materials that are pornographic, threatening, harassing, abusive, or
                defamatory, or that contain nudity or graphic violence, incite violence, violate intellectual
property rights, or violate the law or the legal rights (for example, privacy rights) of others.</li>
                <li className='term-list'>Not to post “spam” or other unauthorized commercial communications.</li>
                <li className='term-list'>To use the Housky Service only for your own purposes, and not to impersonate any other
person.</li>
                <li className='term-list'>Not to transfer or authorize the use of your account for the Housky Service by any other
person, or to engage in fraudulent transactions.</li>
                <li className='term-list'>Not to provide false information in your profile on, or registration for, the Housky Service, or
to create multiple or duplicate accounts.</li>
                <li className='term-list'>Not to interfere with our provision of, or any other user’s use of, the Housky Service.</li>
                <li className='term-list'>Not to solicit another user’s username and password for the Housky Service or any other
sensitive personal information, including bank details.</li>
              </ul>
            </article>
            <section className='py-1'>
              <u>4.2 Suspension and Termination.</u> You understand and agree that we have no obligation to provide
the Housky Service in any specific location or territory, nor to continue providing it once we have
begun. We reserve the right to suspend or terminate your access to the Housky Service: (1) if in our
discretion your conduct on the Site or Housky Service is inappropriate, unsafe, dishonest, or in
breach of these terms; or (2) if necessary in our discretion to protect Housky, its Users, or the public.
You may suspend or terminate your use of the Housky Service at any time and for any reason. If you
wish to deactivate your account, please contact Housky by emailing us at <a href="mailto:info@housky.io">info@housky.io</a>.
            </section>
            <article>
              <section className='py-1'>
                <strong>5. Registration; Account Security.</strong> In order to use some aspects of the Housky Service, you will be
required to create a username, password, and user profile. If you elect to use the Housky Service,
you agree to provide accurate information about yourself and keep this information up-to-date. You
agree not to impersonate anyone else and not to maintain more than one account (or, if Housky
suspends or terminates your account, not to create additional accounts). You are responsible for
maintaining the confidentiality of your username and password for the Housky Service, and you
            </section>
            </article>
            <article>
              <section className='py-1'>
                <strong>6. Privacy.</strong> Our collection and use of your personal information on the Housky Service is described
in our Privacy Statement. By accessing or using the Housky Service, you acknowledge that you
have read and understand the <a href="https://housky.io/terms/privacy" target="_blank" rel="noopener noreferrer">Privacy Statement.</a>
              </section>
            </article>
            <article>
              <section className='py-1'>
                <strong>7. Your Content</strong>
              </section>
              <ul>
                <li className='term-list'>7.1 Your Content. We may require or allow you (or someone else on your behalf) to submit or upload
                text, photographs, images, videos, reviews, information and materials to your profile on the Housky
                Service or otherwise in connection with using the Housky Service and/or participating in promotional
                campaigns we conduct on the Site (collectively, “Your Content”). For example, Home Inspectors are
                invited to create a profile page with a photograph and other information and to transmit photos of the
homes inspected to Users, while Users may submit reviews of Home Inspectors.</li>
                <li className='term-list'>7.2 License. Except for the limitations on our use and disclosure of personal information described in
                our Privacy Statement, to the maximum extent and duration permitted under any applicable law, you
                grant Housky an irrevocable, perpetual, non-exclusive, fully paid worldwide license to use, copy,
                perform, publicly display, reproduce, adapt, modify, transmit, broadcast, prepare derivative works of,
                and/or distribute Your Content in connection with providing and/or promoting the Housky Service,
and to sublicense these rights to third parties.</li>
                <li className='term-list'>7.3 Release. If your name, voice, image, persona, likeness, or performance is included in any of
                Your Content, you hereby waive, and release Housky and its users from, any claim or cause of
                action, whether known or unknown, for defamation, copyright infringement, invasion of the rights of
                privacy, publicity, or personality, or any similar claim arising out of the use of Your Content in
accordance with the license in Section 7.2 and the other provisions of these Terms.</li>
                <li className='term-list'>7.4 Your Representations and Warranties about Your Content. You represent and warrant that (1)
                you are the owner or licensor of Your Content, and that you have all rights, consents and
                permissions necessary to grant the license in Section 7.2 and make the release in Section 7.3 with
                respect to Your Content, (2) that you have any necessary consents and releases from individuals
                who appear or whose homes appear in Your Content; and (3) Your Content does not violate the law
or these Terms.</li>
                <li className='term-list'>7.5 Right to Remove or Screen Your Content. Though we are not obligated to do so, we reserve the
                right to monitor, screen, edit and/or remove Your Content on the Housky Service. Our enforcement
                of these Terms with respect to Your Content is at our discretion, and failure to enforce the Terms in
                one instance does not create a waiver of our right to enforce them in another instance. We have no
                obligation to retain or provide you with copies of Your Content, nor will we have any liability to you
                for any deletion, disclosure, loss or modification to Your Content. It is your sole responsibility to
maintain backup copies of Your Content.</li>
                <li className='term-list'>7.6 Reviews. The Housky Service may provide the ability to leave public and/or private reviews of
                Home Inspectors. You acknowledge that even private reviews may be shared with third parties in accordance with applicable law and our Privacy Statement and that Housky has no obligation to
                preserve or indefinitely store any reviews. If you are a Home Inspection Provider, we have no
                obligation to provide you with the content of any reviews about you submitted by other Users of the
                Housky Service, whether before or after termination of your account for the Housky Service. We will
                have no liability to you for any deletion, disclosure, loss or modification of these reviews. We reserve
the right to screen, edit or remove these reviews from the Housky Service at any time.</li>
              </ul>
            </article>
            <article>
              <ul>
                <li className='term-list'>7.1 Your Content. We may require or allow you (or someone else on your behalf) to submit or upload
                text, photographs, images, videos, reviews, information and materials to your profile on the Housky
                Service or otherwise in connection with using the Housky Service and/or participating in promotional
                campaigns we conduct on the Site (collectively, “Your Content”). For example, Home Inspectors are
                invited to create a profile page with a photograph and other information and to transmit photos of the
homes inspected to Users, while Users may submit reviews of Home Inspectors.</li>
                <li className='term-list'>7.2 License. Except for the limitations on our use and disclosure of personal information described in
                our Privacy Statement, to the maximum extent and duration permitted under any applicable law, you
                grant Housky an irrevocable, perpetual, non-exclusive, fully paid worldwide license to use, copy,
                perform, publicly display, reproduce, adapt, modify, transmit, broadcast, prepare derivative works of,
                and/or distribute Your Content in connection with providing and/or promoting the Housky Service,
and to sublicense these rights to third parties.</li>
                <li className='term-list'>7.3 Release. If your name, voice, image, persona, likeness, or performance is included in any of
                Your Content, you hereby waive, and release Housky and its users from, any claim or cause of
                action, whether known or unknown, for defamation, copyright infringement, invasion of the rights of
                privacy, publicity, or personality, or any similar claim arising out of the use of Your Content in
accordance with the license in Section 7.2 and the other provisions of these Terms.</li>
                <li className='term-list'>7.4 Your Representations and Warranties about Your Content. You represent and warrant that (1)
                you are the owner or licensor of Your Content, and that you have all rights, consents and
                permissions necessary to grant the license in Section 7.2 and make the release in Section 7.3 with
                respect to Your Content, (2) that you have any necessary consents and releases from individuals
                who appear or whose homes appear in Your Content; and (3) Your Content does not violate the law
or these Terms.</li>
                <li className='term-list'>7.5 Right to Remove or Screen Your Content. Though we are not obligated to do so, we reserve the
                right to monitor, screen, edit and/or remove Your Content on the Housky Service. Our enforcement
                of these Terms with respect to Your Content is at our discretion, and failure to enforce the Terms in
                one instance does not create a waiver of our right to enforce them in another instance. We have no
                obligation to retain or provide you with copies of Your Content, nor will we have any liability to you
                for any deletion, disclosure, loss or modification to Your Content. It is your sole responsibility to
maintain backup copies of Your Content.</li>
                <li className='term-list'>7.6 Reviews. The Housky Service may provide the ability to leave public and/or private reviews of
                Home Inspectors. You acknowledge that even private reviews may be shared with third parties in accordance with applicable law and our Privacy Statement and that Housky has no obligation to
                preserve or indefinitely store any reviews. If you are a Home Inspection Provider, we have no
                obligation to provide you with the content of any reviews about you submitted by other Users of the
                Housky Service, whether before or after termination of your account for the Housky Service. We will
                have no liability to you for any deletion, disclosure, loss or modification of these reviews. We reserve
the right to screen, edit or remove these reviews from the Housky Service at any time.</li>
              </ul>
            </article>
            <article>
              <section className='py-1'>
                <strong>8. Phone, Text and Mobile Communications.</strong>
              </section>
              <section className='py-1'>
                <u>8.1 Consent to Autodialed Text Messages and Phone Calls.</u> This section 8.1 applies only to users in
the United States. You consent to Housky communicating with you about the Housky Service by
SMS, text message, email and other electronic means, including autodialed text messages and
phone calls containing service information and/or marketing messages, even if your phone number
is on the do-not-call list. Your carrier&#39;s normal messaging, data and other rates and fees will apply to
these communications. You are not required to provide this consent to receive marketing messages
as a condition of purchasing anything or using the Housky Service, and you may opt-out of receiving
these messages and calls at any time as described in our Privacy Statement (though you may
continue to receive messages while Housky processes your request).
</section>
              <section className='py-1'>
                <u>8.2 Phone Number Changes.</u> In the event you deactivate a mobile phone number provided to us,
you agree to update your Housky account information promptly to ensure that messages are not
sent to the person who acquires your old number.
            </section>
            </article>
            <article>
              <section className='py-1'>
                <strong>9. Fees &amp; Payment.</strong>
              </section>
              <section className='py-1'>
                <u>9.1 Currency.</u> All fees, deductible amounts and other payments referenced on, or charged through,
the Housky Service are listed and payable in local currency.
            </section>
              <section className='py-1'>
                <u>9.2 Fees for Users.</u> Users may purchase Home Inspection Services from a Home Inspector by
completing a Booking as described in Section 2.5. If you are a User, you enter into a transaction with
the Home Inspector when you accept a Booking, and you agree to pay the total fees indicated in the
Booking. Except where otherwise specifically indicated on the Housky Service, fees for Home
Inspection Services are determined by Housky. As described in Sections 9.3 and 9.4, the total
amount Users are charged for a Booking may also include a service fee payable to Housky. Where
required by law, the amount charged will also be inclusive of applicable taxes. The Home Inspector,
not Housky, is responsible for performing the Home Inspection Services.
            </section>
              <section className='py-1'>
                <u>9.3 Fees for Home Inspectors.</u> Home Inspectors may agree to provide Home Inspection Services to
a User by agreeing to a Booking as described in Section 2.5. If you are a Home Inspector, you must
confirm the Booking before it expires or the User will have no obligation to complete the transaction.
Once the Booking is completed by both parties, you agree to honor the price set forth in your
Booking. The purchase of Home Inspection Services is a transaction between the User and the
Home Inspector. Housky’s role is to facilitate the transaction. We will (either directly or indirectly
through an authorized third party) collect payment from the User at the time of Booking and initiate
payment to the Home Inspector’s account 48 hours after completion of the Home Inspection Service
(when the inspection report is uploaded on the Housky platform for the User to access). Home
Inspectors are charged a service fee as described in Section 9.5, which we deduct before remitting payment to Home Inspectors. Where required by law, the amount charged will also be inclusive of
applicable taxes.
            </section>
              <section className='py-1'>
                <u>9.4 Taxes.</u> Except for taxes on Housky’s income and gross receipts or where Housky is otherwise
required to collect taxes, you acknowledge that you are solely responsible to pay any applicable
taxes that arise as a result of your purchase, provision, or use of Home Inspection Services via the
Housky Service. This includes, without limitation, any form of sales tax, or income tax on fees paid or
received by you through the Housky Service.
            </section>
              <section className='py-1'>
                <u>9.5 Service Fee.</u> Housky charges a service fee for providing our services to Users. The service fee is
included in the total amount that Users pay and subtracted from the amount remitted to Home
Inspectors. The service fee varies by location and will be subject to change from time to time at our
discretion.
            </section>
              <section className='py-1'>
                9.6 Cancellations &amp; Refunds.
            </section>
              <ul>
                <li className='term-list'><i>Reservation Protection.</i> As more fully described on Housky’s <a href="https://housky.io/terms/booking-protection" target="_blank" rel="noopener noreferrer">Reservation Protection</a> page,
Housky can help you find replacement Home Inspector when Home Inspector cancel
Bookings near the scheduled inspection date identified in the Booking. The availability of the
Reservation Protection depends on the timing of the cancellation and the type of home
inspection service provided; consult the <a href="https://housky.io/terms/booking-protection" target="_blank" rel="noopener noreferrer">Reservation Protection</a> page for details.</li>
                <li className='term-list'><i>Cancellations by Home Inspector.</i> If a Home Inspector cancels a inspection prior to or during
the scheduled inspection date identified in the Booking, we will refund the fees paid by the
Home Owner, as well as any service charge paid to Housky. If you are a Home Inspector, you
can appoint a substitute Home Inspector (as agreed by the Home Owner and so long as the
substitute has an active account on the Housky Service and has agreed in writing to provide
a home inspection service) by contacting Housky to modify the Booking. If you do not find a
substitute and repeatedly cancel accepted Bookings without justification, Housky may
terminate your account.</li>
                <li className='term-list'><i>Cancellations by Home Owner.</i> If a Home Owner cancels a Booking prior to the schedule
inspection date and time specified in a Booking, we will refund fees in accordance with the
cancellation policy on the Housky Service. All Home Inspectors and Home Owners are
required to acknowledge the cancellation policy prior to completing a Booking. For more
information about cancellation policies, please visit the <a href="https://housky.io/contact-us" target="_blank" rel="noopener noreferrer">Help Center.</a></li>
                <li className='term-list'><i>Force Majeure.</i> The cancellation policies described herein may not apply in the event of
certain emergency situations beyond the control of Home Inspectors and/or Home Owners
that make it impossible or impractical to perform agreed Bookings, such as evacuations
resulting from earthquake, hurricane, wildfire, flood, war, riots or other similar disaster. In
such cases Housky may, in its reasonable discretion, issue refunds under terms that vary
from the cancellation policy.</li>
                <li className='term-list'><i>Refunds for Failure to Perform.</i> If we determine in our reasonable discretion that a Home
Inspector has failed to provide Home Inspection Services as agreed with the Home Owner or
is otherwise in breach of these Terms, then we may, in our reasonable discretion, cancel a
Booking and/or issue a full or partial refund to a Home Owner.</li>
                <li className='term-list'><i>General Terms for Cancellations.</i> If you wish to cancel a Booking, you should use the
mechanisms available through the Housky Service to do so. For purposes of the policies and
terms in this Section 9.6, the date of cancellation is the date that a user cancels through the Housky Service, regardless of any separate communications between users outside of the
Housky Service.</li>
                <li className='term-list'><i>Payment Disputes;</i> Payment Outside of the Housky Service. Housky initiates payments to
Home Inspectors 48 hours after Home Inspectors upload the inspection report to the
Booking for the Home Owners to review. Once these amounts have been disbursed, any
further payment disputes are between the Home Owner and Home Inspector, and Housky
has no obligation to mediate or facilitate any resolution. Further, Housky has no
responsibility or liability with respect to any tips, bonuses, or other payments made outside
of the Housky Service.</li>
              </ul>
            </article>
            <article>
              <section className='py-1'>
                <strong>10. Background Checks and Identity Verifications.</strong>
              </section>
              <section className='py-1'>
                Housky may provide Users with access to third party consumer reporting agencies that perform,
among other things, personal identification verification services (“<strong>Identity Verification</strong>”) or criminal
records checks, sex offender registry checks, motor vehicle records checks and/or identification
verifications (collectively, “<strong>Background Checks</strong>”).
            </section>
              <section className='py-1'>
                If you undergo a Background Check or Identity Verification via the Housky Service, you hereby
                consent to the collection, use and disclosure of the information in the Background Check or Identity
                Verification, and you agree to provide complete and accurate information for your Background
                Check or Identity Verification. You understand and agree that Housky may, in its sole discretion,
                review and rely on the information in the Background Check or Identity Verification in deciding
                whether to suspend or terminate or investigate a complaint about a Service Provider, but also that
                we are not obligated to do so, and are not responsible or liable in any way in the event that any
                information in any Background Check or Identity Verification is not accurate, timely or complete. If
                you are the subject of a Background Check or Identity Verification, you may contact the applicable
                third-party consumer reporting agency to dispute the accuracy, timeliness or completeness of such
                information. Housky reserves the right to suspend or terminate your access to the Housky Service
                based on information in the Background Check or Identity Verification or for any other reason, or no
                reason, in our sole discretion.
            </section>
              <section className='py-1'>
                Users remain fully responsible to evaluate and investigate their Home Inspectors. <strong>Be aware of the
                following limitations in Background Checks: Except as otherwise expressly provided in these
                Terms or through the Service, Housky does not automatically run Background Checks on any
                Users. Background Checks may vary by type, breadth and depth. Records not available to
                third-party Background Check agencies will not be included in the results. Not all arrest logs
                and records, conviction and correction records, sex offender registries and motor vehicle
                records are available in all jurisdictions. In many jurisdictions there is a delay before arrest
                logs and records, conviction and correction records, sex offender registries and motor
                vehicle records are included in Background Checks. Juvenile records and offenses for
                minors may not appear in the public record and are therefore not included in the results.
                Dismissed cases, arrests not resulting in convictions, arrests or convictions in foreign
                countries and nolle prosequi (abandonment of prosecution) may not be reported. Traffic
                violations are not included unless a jurisdiction reports them as criminal offenses. In the jurisdictions where traffic violations are reported as criminal offenses, such traffic violations
may be included in the results as misdemeanors or felonies.</strong>
              </section>
            </article>
            <article>
              <section className='py-1'>
                <strong>11. Third Party Services, Links.</strong> The Housky Service may contain links to third party websites or
resources. You acknowledge and agree that we are not responsible or liable for: (i) the availability or
accuracy of such websites or resources; or (ii) the content, products, or services on or available from
such websites or resources. Links to such websites or resources do not imply any endorsement of
such websites or resources, or the content, products, or services available from such websites or
resources. You acknowledge sole responsibility for and assume all risk arising from your use of any
such websites or resources.
            </section>
            </article>
            <article>
              <section className='py-1'>
                <strong>
                  12. Intellectual Property.
              </strong>
              </section>
              <section className='py-1'>
                <u>12.1 Housky Service.</u> Housky and its licensors retain all right, title and interest in and to the Housky
Service, the technology and software used to provide it, all electronic documentation and content
available through the Housky Service (other than Your Content), and all intellectual property and
proprietary rights in the Housky Service and such technology, software, documentation and content.
Except for your rights to access and use the Housky Service set forth in these Terms, nothing in
these Terms licenses or conveys any of our intellectual property or proprietary rights to anyone,
including you. You agree that we will have a perpetual right to use and incorporate into the Housky
Service any feedback or suggestions for improvement that you provide to us concerning the Housky
Service, without any obligation of compensation.
            </section>
              <section className='py-1'>
                <u>12.2 Housky Trademarks.</u> Housky owns all rights in and to its trademarks, service marks, brand
names and logos (the “Housky Marks”). If you are a Service Provider, Housky grants you, for so
long as you are in good standing on the Housky Service, a limited, revocable, non-exclusive, non-
transferable license to use the Housky Marks solely in the form incorporated into goods, including
customizable marketing collateral (promo cards, signage etc.) in any manner specifically authorized
in writing via the Housky Service. As a condition of exercising such license, you agree that (1) all
goodwill associated with your use of the Housky Marks inures solely to the benefit of Housky, and
(2) such license immediately terminates upon your ceasing to be a Service Provider in good
standing, whether at your own option or because Housky suspends or terminates your rights to use
the Housky Service.
            </section>
            </article>
            <article>
              <section className='py-1'>
                <strong>13. Warranty Disclaimer for the Housky Service.</strong> The information and materials found on the
Housky Service, including text, graphics, information, links or other items, are provided &quot;as is&quot; and
&quot;as available.&quot; Reviews, profiles, advice, opinions, statements, offers, or other information or content
made available through the Housky Service, but not directly by Housky, are those of their respective
authors, who are solely responsible for such content.
<span style={{ textTransform: 'uppercase' }}>TO THE MAXIMUM EXTENT PERMITTED BY
                APPLICABLE LAW, HOUSKY DOES NOT: (1) WARRANT THE ACCURACY, ADEQUACY OR
                COMPLETENESS OF INFORMATION AND MATERIALS ON THE HOUSKY SERVICE; (2) ADOPT,
                ENDORSE OR ACCEPT RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY
                OPINION, ADVICE, OR STATEMENT MADE BY ANY PARTY OTHER THAN HOUSKY; (3)
                WARRANT THAT YOUR USE OF THE SERVICES WILL BE SECURE, FREE FROM COMPUTER
                VIRUSES, UNINTERRUPTED, ALWAYS AVAILABLE, ERROR-FREE OR WILL MEET YOUR
                REQUIREMENTS, OR THAT ANY DEFECTS IN THE HOUSKY SERVICE WILL BE CORRECTED.
                TO THE EXTENT PERMITTED BY APPLICABLE LAW, HOUSKY EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED OR STATUTORY, WITH RESPECT TO THE
                HOUSKY SERVICE, AND SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND
                ACCURACY. IN ADDITION AND WITHOUT LIMITING THE FOREGOING, WE MAKE NO
                REPRESENTATION OR WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                REGARDING THE SUITABILITY OF ANY SERVICE PROVIDER THAT OFFERS HOME
                INSPECTION SERVICES VIA THE HOUSKY SERVICE.
</span>
              </section>
            </article>
            <article>
              <section className='py-1'>
                <strong>14. Limitation of Liability.</strong>
              </section>
              <section className='py-1'>
                <u>14.1 Exclusion of Certain Types of Damages.</u> To the maximum extent permitted under applicable
law, in no event will Housky be liable to you for any indirect, special, incidental, or consequential
damages, or for any business losses, or loss of profit, revenue, contracts, data, goodwill or other
similar losses or expenses that arise out of or relate to the use of or inability to use the Housky
Service, including without limitation damages related to any information received from the Housky
Service, removal of your profile information or review (or other content) from the Housky Service,
any suspension or termination of your access to the Housky Service, or any failure, error, omission,
interruption, defect, delay in operation or transmission of the Housky Service, even if we are aware
of the possibility of any such damages, losses or expenses. <strong>SOME JURISDICTIONS DO NOT
                ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</strong>
              </section>
            </article>
            <article>
              <section className='py-1'>
                For questions or concerns about the Housky Service or these Terms, please contact Housky.
            </section>
            </article>
          </div>
        </div>
      </div>
    </div>
  </>
}