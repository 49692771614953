import React, {useEffect, useState} from "react";
import {
  getAllToolsWithInspectorToken,
  getInspectorToolsByInspectorId,
  updateInspectorTools
} from "../../../services/service";
import {Inspector as InspectorFirebase} from "../../../utils/firebase/firebase";
import style from './Tools.module.css';

export default (props) => {
  const { inspectorId } = props;

  const [ inspectorTools, setInspectorTools ] = useState([]);
  const [ allTools, setAllTools ] = useState([]);
  const [ isEditing, setIsEditing ] = useState(false);
  const [ token, setToken ] = useState('');

  useEffect(() => {
    (
      async () => {
        const idToken = await InspectorFirebase.getIdToken();
        const inspectorToolsResponse = await getInspectorToolsByInspectorId(inspectorId);
        const allToolsResponse = await getAllToolsWithInspectorToken(idToken);
        setInspectorTools(inspectorToolsResponse.tools || []);
        setAllTools(allToolsResponse || []);
        setToken(idToken);
      }
    )();
  }, []);

  const toolCard = (tool) => {
    const isToolAdded = inspectorTools.some(t => tool.tool_id === t.id);

    const addTool = () => {
      const newInspectorTools = [...inspectorTools];
      newInspectorTools.push({
        id: tool.tool_id,
        name: tool.tool_name,
        imageUrl: tool.tool_image_url
      });
      setInspectorTools(newInspectorTools);
    };

    const deleteTool = () => {
      const newInspectorTools = inspectorTools.filter(t => t.id !== tool.tool_id);
      setInspectorTools(newInspectorTools);
    };

    const getCheckMark = () => {
      if(isToolAdded) {
        return (
          <span style={{marginRight: "3px", color: "green"}} className="material-icons">
            done
          </span>
        )
      }
    };

    return (
      <div className={style.toolCardContainer}>
        <div className={style.toolImageContainer}>
          <img className={style.toolImage} src={tool.tool_image_url}/>
        </div>
        <div style={{display: "flex", justifyContent: 'center', margin: "5px 0"}}>
          { getCheckMark() }
          {tool.tool_name}
        </div>
        <div style={{display: "flex", justifyContent: 'space-around'}}>
          <button onClick={addTool} disabled={(isEditing && isToolAdded) || !isEditing} className='btn-small'>{ isToolAdded ? 'Added' : 'Add' }</button>
          <button onClick={deleteTool} disabled={(isEditing && !isToolAdded) || !isEditing} className='btn-small red'>Delete</button>
        </div>
      </div>
    );
  };

  const getToolCards = () => {
    return allTools.map(toolCard);
  };

  const editTools = async () => {
    if(isEditing) {
      const body = {
        inspectorTools: JSON.stringify(inspectorTools)
      }
      try {
        await updateInspectorTools(token, body);
        setInspectorTools(inspectorTools);
      } catch (e) {
        console.log("Error while updating inspector tools, error=", e);
      }
    }
    setIsEditing(!isEditing);
  };

  const getCancelButton = () => {
    const cancel = async () => {
      const inspectorToolsResponse = await getInspectorToolsByInspectorId(inspectorId);
      setInspectorTools(inspectorToolsResponse.tools);
      setIsEditing(false);
    };
    if(isEditing) {
      return <button style={{marginLeft: '10px'}} className='btn-small red' onClick={cancel}>Cancel</button>
    }
  };

  return (
    <div>
      <div className="row col s12">
        <h5>
          Add/Delete the following tools to show customers what you use.
        </h5>
        <h6>
          Anything missing? <a href='mailto:yifu@housky.io?subject=Inspector%20Add%20Tool%20Request' target="_blank" rel="noopener noreferrer" >Email us</a>
        </h6>
      </div>
      <div className='row col s12'>
        <div style={{display: "flex", justifyContent: 'left'}}>
          <button className='btn-small' onClick={editTools}>{isEditing ? "Save" : "Edit"}</button>
          { getCancelButton() }
        </div>
      </div>
      <div className="row col s12">
        <div className={style.toolCardsContainer}>
          {getToolCards()}
        </div>
      </div>
    </div>
  );
};