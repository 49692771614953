import React from "react";
import UserReportComonent from "../../../components/UserDomain/UserReport/UserReportComponent";
import AuthWrapper from "../../../components/AuthWrapper";
import AuthContext from "../../../contexts/auth";
import Loader from "../../../components/Loader";
import { Link } from "react-router-dom";

export default class HouseReport extends React.Component {
  state = {
    loading: true
  };

  Success = () => {
    return <UserReportComonent />;
  };

  Fail = () => {
    if (!this.state.loading)
      return (
        <div className="row valign-wrapper" style={{ height: "70vh" }}>
          <div className="center-align" style={{ width: "100%" }}>
            <h4>Oops, Looks like you're not logged in.</h4>
            <Link
              to="/login"
              className="btn housky-primary-complimentary-bg"
              style={{ padding: "1rem 4rem", height: "auto" }}
            >
              Log In
            </Link>
          </div>
        </div>
      );
    else {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    }
    return <Loader />;
  };
  render() {
    return (
      <>
        <AuthContext.Consumer>
          {user => {
            return (
              <AuthWrapper
                user={user}
                success={<this.Success user={user} />}
                fail={<this.Fail />}
              />
            );
          }}
        </AuthContext.Consumer>
      </>
    );
  }
}
