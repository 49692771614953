import React from "react";

const RadioButton = (props) => {
  const { setName, setId, handleClick, setText, setValue, setChecked } = props;
  const disabled = typeof props.disabled !== "undefined" ? props.disabled : false;
  return (
    <label>
      <input
        className="with-gap"
        type="radio"
        name={setName}
        value={setValue}
        id={setId}
        onChange={handleClick}
        disabled={disabled}
        checked={setChecked}
      />
      <span style={{cursor: `${disabled ? 'default' : 'pointer'}`}}>{setText}</span>
    </label>
  );
};

export default RadioButton;
