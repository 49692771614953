import React, {useEffect, useState} from 'react'
import { searchInspector } from '../../../services/service'
import Materialize from "materialize-css/dist/js/materialize.min.js";
import {closeNav} from '../../../services/helper'
import {Link, useHistory} from 'react-router-dom';
import BrandWhite from "../../../assets/housky_logo-06-smaller.png";
import SearchBar from "../../../components/GeneralComponents/SearchBar/SearchResultPageSearchBar";
import InspectorList from "./InspectorList";
import _ from "lodash";
import BrandIcon from "../../../assets/housky_logo-07.png";
import RatingStars from "../../../components/GeneralComponents/RatingStars/RatingStars";

export default function Search(props) {

  const history = useHistory();

  const getSearchTerm = () => {
    const params = new URLSearchParams(window.location.search);
    const searchTerm = params.get('query');
    return searchTerm;
  };

  const [ inspectors, setInspectors] = useState([]);
  const [ inspectorsSortedByDistance, setInspectorsSortedByDistance] = useState([]);
  const [ searchTerm, setSearchTerm] = useState(getSearchTerm());
  const [ sortByRating, setSortByRating] = useState(false);
  const [ selectedInspectors, setSelectedInspectors ] = useState([]);
  const [ showList, setShowList ] = useState(false);

  useEffect(() => {
    const elems = document.querySelectorAll('.sidenav');
    Materialize.Sidenav.init(elems, { edge: 'left' });
  }, [])

  useEffect(() => {
    (async() => {
      setSortByRating(false);
      await listInspectors(searchTerm);
    })();
  }, [searchTerm]);

  useEffect(() => {
    const selectedInspectorsRaw = window.localStorage.getItem('selectedInspectors');
    const selectedInspectors = JSON.parse(selectedInspectorsRaw);
    if(selectedInspectors) {
      setSelectedInspectors(selectedInspectors);
    }
  }, []);

  useEffect(() => {
    const handler =  (e) => {
      let shoppingList = document.getElementById('shoppingList');
      let shoppingListBadge = document.getElementById('shoppingListBadge');
      if(shoppingList && !shoppingList.contains(e.target) && !shoppingListBadge.contains(e.target)) {
        setShowList(false);
      }
    }
    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, [])

  const listInspectors = async (searchTerm) => {
    const inspectors = await searchInspector(searchTerm);
    if (inspectors.length > 0) {
      let select = document.querySelectorAll("select");
      Materialize.FormSelect.init(select, {
        classes: "",
      });
      Materialize.toast({ html: 'Inspectors are now organized by closest distance', classes: 'rounded' })

      setInspectors(inspectors);
      setInspectorsSortedByDistance(_.cloneDeep(inspectors));
    }
    else {
      setInspectors(false);
    }
  }

  const getSortText = () => {
    return sortByRating ? 'Sort By Distance' : "Sort By Rating";
  };

  const switchSortMethod = () => {
    if(!sortByRating) {
      const inspectorsSortedByRating =
        inspectors.sort((a, b) => {
          return b.google_reviews_score - a.google_reviews_score;
        })
      setSortByRating(true);
      setInspectors(inspectorsSortedByRating);
    } else {
      setSortByRating(false);
      setInspectors(_.cloneDeep(inspectorsSortedByDistance));
    }
  };

  const deleteInspector = (inspector) => {
    let newSelectorInspectors = selectedInspectors.filter(i => i.id !== inspector.id);
    setSelectedInspectorsInStorage(newSelectorInspectors);
  };

  const shoppingListRow = (inspector, key) => {

    const normalizeName = (name) => {
      const lowerCaseName = name.toLowerCase();
      return lowerCaseName[0].toUpperCase() + lowerCaseName.slice(1)
    };

    const getInspectorPhotoSrc = () => {
      if(inspector.imageUrl){
        return inspector.imageUrl
      } else {
        return require('../../../assets/blank-profile-picture.png')
      }
    }

    const getInspectorRating = () => {
      return inspector.googleReviewsScore ? parseFloat(inspector.googleReviewsScore).toFixed(1) : '0.0'
    }

    return (
      <div className="shoppingListRow" key={key}>
        <div className="inspectorImageContainer">
          <img src={getInspectorPhotoSrc()} />
        </div>
        <div className="name">
          {`${normalizeName(inspector.firstName)} ${inspector.lastName[0].toUpperCase()}.`}
          <span style={{marginTop: '5px', display: 'flex'}}>
            <RatingStars score={getInspectorRating()} />
            <span style={{alignSelf: 'center', marginLeft: '8px'}}>{ getInspectorRating() }</span>
          </span>
        </div>
        <div className="delete" onClick={() => deleteInspector(inspector)}>
          <i className="material-icons">close</i>
        </div>
      </div>
    );
  }

  const schedule = () => {
    const env = process.env.REACT_APP_ENV;
    const selectedInspectorsEncoded = encodeURIComponent(JSON.stringify(selectedInspectors))
    switch (env) {
      case "LOCAL":
      case "QA":
        props.setDomain('user');
        history.push(`/schedule?query=${searchTerm}&selectedInspectors=${selectedInspectorsEncoded}`)
        break;
      case "PROD":
        window.location.href = `https://user.housky.io/schedule?query=${searchTerm}&selectedInspectors=${selectedInspectorsEncoded}`;
        break;
    }
  }

  const getShoppingCart = () => {
    if(selectedInspectors && selectedInspectors.length > 0) {
      return (
        <div>
          <div className="nav-links right shoppingCartContainer" onClick={() => setShowList(!showList)} id="shoppingListBadge">
            My Inspectors
            <span className="new badge" data-badge-caption="">{ selectedInspectors.length }</span>
          </div>
          { showList &&
            <div className="shoppingList" id="shoppingList">
              { selectedInspectors.map(shoppingListRow) }
              <div className="buttons">
                <button className="btn-small" onClick={schedule}>Proceed to Schedule</button>
              </div>
            </div>
          }
        </div>
      )
    } else {
      return (
        <div>
          <div className="nav-links right shoppingCartContainer" onClick={() => setShowList(!showList)}>
            My Inspectors
            <span className="new badge" data-badge-caption="">{ selectedInspectors.length }</span>
          </div>
          { showList &&
          <div className="shoppingList">
            <div style={{display: 'flex', justifyContent: 'center', color: 'black'}}>
              Add some inspectors to schedule
            </div>
            <div className="buttons">
              <button className="btn-small red" onClick={() => setShowList(false)}>Close</button>
            </div>
          </div>
          }
        </div>
      )
    }
  }

  const setSelectedInspectorsInStorage = (inspectors) => {
    setSelectedInspectors(inspectors);
    window.localStorage.setItem('selectedInspectors', JSON.stringify(inspectors));
  };

  return (
    <div style={{ backgroundColor: '#F0F0F0', minHeight: '77vh' }}>
      <nav id='home-nav' className="sticked-nav" style={{ position: `${window.location.pathname === '/learning_center' || window.location.pathname.split('/')[1]==='terms' ? 'initial' : 'sticky'}` }}>
        <div className="nav-wrapper">
          <div className="hide-on-med-and-down">
            <Link to='/'>
              <img alt='Housky' className="brand-logo" src={BrandWhite} style={{ height: '100%', paddingLeft: '28px'}} />
            </Link>
          </div>
          <div className="hide-on-large-only">
            <Link to='/'>
              <img alt='Housky' className="brand-logo left" src={BrandIcon}/>
            </Link>
          </div>
          <a href="!#" data-target="mobile-nav" className="sidenav-trigger m-0 left"><i className={`${window.location.pathname === '/learning_center' ? 'black-text' : ''} material-icons`} style={{ color: 'white'}}>menu</i></a>
          { getShoppingCart() }
          <div className="navLinksContainer hide-on-med-and-down right">
            <Link className='nav-links' style={{borderBottom: window.location.pathname ==='/learning_center' ? '2px solid #546A76': 'none'}} to='/learning_center'>Learning Center</Link>
          </div>
        </div>
      </nav>

      <ul className="sidenav" id="mobile-nav">
        <li><Link onClick={closeNav} to='/'>Home</Link></li>
        <li><Link onClick={closeNav} to='/learning_center'>Learning Center</Link></li>
      </ul>
      <div className='row mb-0'>
        <div className='row' style={{ marginBottom: '80px' }}>
          <SearchBar initValue={searchTerm} onChange={setSearchTerm} />
          <span style={{display: "inline-block", paddingLeft: "30px", paddingTop: "10px", cursor: "pointer", color: "blue"}} onClick={switchSortMethod}>
            {getSortText()}
          </span>
          <div id="inspector-collection" className='col s12' style={{ marginTop: '30px', marginBottom: '50px' }}>
            <InspectorList
              inspectors={inspectors}
              selectedInspectors={selectedInspectors}
              setSelectedInspectors={setSelectedInspectorsInStorage}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
