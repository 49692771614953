import React from "react";
import GoogleMapReact from "google-map-react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import "./animate.css";

const markerStyle = {
  position: "absolute",
  width: "25px",
  transform: "translate(-50%, -100%)"
};

export default class SimpleMap extends React.Component {

  Marker = ({ house, lat, lng, clickEvent }) => {
    return (
      <img
        alt="Marker"
        onClick={(e) => {
          clickEvent(house.jobid);
        }}
        className={"mouse-pointer"}
        src={require("../../../assets/job-icon.svg")}
        style={markerStyle}
      />
    );
  };

  Home = ({ lat, lng, clickEvent }) => {
    return (
      <img
        alt="Home"
        onClick={(e) => {
          clickEvent("TEST");
        }}
        className="mouse-pointer houseIcon"
        src={require("../../../assets/home-icon.svg")}
        style={{...markerStyle, zIndex: "10"}}
      />
    );
  };
  render() {
    const lat =
      get(this.props.job, "latitude") !== undefined
        ? this.props.job.latitude
        : this.props.lat;
    const lng =
      get(this.props.job, "longitude") !== undefined
        ? this.props.job.longitude
        : this.props.lng;

    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_CLOUD_API }}
        center={{
          lat: lat,
          lng: lng,
        }}
        defaultZoom={12}
      >
        <this.Home
          lat={this.props.inspectorLocation.lat}
          lng={this.props.inspectorLocation.long}
          clickEvent={this.props.clickEvent}
        />
        {this.props.markers.map((e, i) => {
          return (
            <this.Marker
              house={e}
              lat={e.latitude}
              lng={e.longitude}
              key={i}
              clickEvent={this.props.clickEvent}
            />
          );
        })}
      </GoogleMapReact>
    );
  }
}
