import React from "react";
import toUpper from "lodash/toUpper";
import get from "lodash/get";
import InspectorCard from "./InspectorCard";
import ChatRoom from "../../GeneralComponents/ChatRoom";
import isEmpty from "lodash/isEmpty";

export const InspectorTab = (props) => {
  const { inspector, userId, messageId, current_status } = props;

  return (
    <div className="row infoTab">
      <div className="col s12 m8 l9">
        <div className="row chatRow">
          {!isEmpty(messageId) && (
            <div className="chatFrame">
              <ChatRoom
                userId={userId}
                messageId={messageId}
                profile="CLIENT"
                disabled={!['PENDING', 'ACCEPTED'].includes(current_status.toUpperCase())}
              />
            </div>
          )}
        </div>
      </div>
      <div className="col s12 m4 l3 inspectorCard">
        <InspectorCard inspector={inspector} />
      </div>
    </div>
  );
};
