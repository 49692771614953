import React from "react";
import InspectorPrivateComponent from "../../../components/InspectorPrivateComponent";
import InspectorReportComponent from "../../../components/InspectorDomain/InspectorReport/InspectorReport";

class InspectorReport extends React.Component {
  render() {
    return (
      <InspectorPrivateComponent>
        <InspectorReportComponent />
      </InspectorPrivateComponent>
    );
  }
}

export default InspectorReport;
