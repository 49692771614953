import React from 'react'

export default (props) => {
  return <div className='pt-4'>
    <div className={`col s12 ${props.bgColor}`} style={{borderRadius:'5px'}}>
      <h4 style={{maxWidth:'80%'}}>{props.header}</h4>
      <p className='pb-5 subText' style={{ fontSize: '16px', color: 'black', maxWidth: '75%' }}>
       {props.body}</p>
    </div>
    <div className='info-card-mascot' style={{position:'relative'}}>
      <img id="mascot" className={props.type} src={props.mascot} alt='mascot'/>
    </div>
  </div>
}