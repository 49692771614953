import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./styles.css";

const ChatMessage = (props) => {
  const {
    userId,
    senderPhotoUrl,
    receiptPhotoUrl,
    previousUid,
    profile,
    customerFullName,
    inspectorFullName,
  } = props;
  const { text, uid, createdAt } = props.message;
  const [date, setDate] = useState("");
  const messageClass = uid === userId ? "sent" : "received";
  const photoURL = uid === userId ? senderPhotoUrl : receiptPhotoUrl;
  const nameTag =
    profile === "INSPECTOR" ? customerFullName : inspectorFullName;

  useEffect(() => {
    setDate(moment(+createdAt).format("MM/DD/YYYY HH:mm:ss"));
  }, [createdAt]);

  return (
    <>
      <div className={`message ${messageClass}`}>
        {(isEmpty(previousUid) || previousUid !== uid) && (
          <img src={photoURL} alt={"user picture"} />
        )}
        <div className="bubble">
          {(isEmpty(previousUid) || previousUid !== uid) && (
            <p className="nameTag">
              {uid === userId ? "Me" : nameTag}{" "}
              <span className="date">{date}</span>
            </p>
          )}
          <div
            className={`${
              !isEmpty(previousUid) && previousUid === uid
                ? "text indentBubble"
                : "text"
            } `}
          >
            {text}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatMessage;
