import React, {useEffect} from 'react'
import Materialize from "materialize-css/dist/js/materialize.min.js";

export default function UserInfo(props) {

  useEffect(() => {
    Materialize.CharacterCounter.init(document.getElementById('bio'));
  }, [])

  return (
    <div className='container pt-5 mt-5'>
      {props.isDisabled ? <button className='btn right housky-primary-bg' onClick={props.handleEdit}>Update Information</button>
        : <button className='btn right housky-primary-bg' onClick={props.handleEdit}>Cancel Update</button>}
      <div className='row pt-5 mt-5'>
        <div className='col s6 m8'>
          <h4>Welcome Back, </h4>
          <h5 style={{ textTransform: 'uppercase' }}>
            {props.first_name} {props.last_name}
          </h5>
        </div>
      </div>
      <div className="row pt-5">
        <form className="col s12">
          <h5>User Information</h5>
          <div className="row">
            <div className="input-field col s6">
              <input disabled={props.isDisabled ? true : false} id="first_name" type="text" value={props.first_name} onChange={props.handleChange} />
              <label className={`${props.first_name ? 'active' : ''} ${props.isDisabled ? '' : 'required-field'}`} htmlFor="first_name">First Name</label>
            </div>
            <div className="input-field col s6">
              <input disabled={props.isDisabled ? true : false} id="last_name" type="text" value={props.last_name} onChange={props.handleChange} />
              <label className={`${props.last_name ? 'active' : ''} ${props.isDisabled ? '' : 'required-field'}`} htmlFor="last_name">Last Name</label>
            </div>
          </div>
          <div className="row ">
            <div className="input-field col s6">
              <input disabled={props.isDisabled ? true : false} id="email" type="email" value={props.email} onChange={props.handleChange} />
              <label className={`${props.email ? 'active' : ''} ${props.isDisabled ? '' : 'required-field'}`} htmlFor="email">Email</label>
            </div>
            <div className="input-field col s6">
              <input disabled={props.isDisabled ? true : false} id="temp_cell_number" type="text" value={props.isDisabled ? `(${props.area_code}) - ${props.cell_number}` : props.temp_cell_number} onChange={props.handleCell} />
              <label className={`${props.area_code ? 'active' : ''} ${props.isDisabled ? '' : 'required-field'}`} htmlFor="temp_cell_number">Cell Number</label>
            </div>
          </div>
          {!props.isDisabled ? <></>
            :
            <div className='row center'>
              <button data-target="rePasswordModal" style={{ height: 'auto' }} type='submit' className={`modal-trigger btn housky-primary-complimentary-bg`}>Change Password</button>
            </div>}
          {props.isDisabled ? <></>
            :
            <div className='row center'>
              <button data-target="reAuthModal" style={{ height: 'auto' }} type='submit' onClick={props.updateUser} className={`${props.email.toUpperCase() === props.oriEmail.toUpperCase() || props.email ==='' ? '' : 'modal-trigger'} py-2 px-5 btn housky-primary-complimentary-bg`}>Confirm</button>
            </div>
          }
        </form>
      </div>
    </div>
  );
}