import React from 'react'

export default (props) => {
  return <>
    <h5 className='center'>Inspector Profile</h5>
    <ul className='p-3'>
      <li data-target="isEditModal" onClick={props.handleNav} id='user_info' style={{ fontSize: '1.5rem', borderRight: `${props.currentPage === 'user_info' ? '4px solid #66cccc' : '0'}` }} className={`${props.isDisabled ? '' : 'modal-trigger'} valign-wrapper py-1 mouse-pointer ${props.currentPage==='user_info' ? 'housky-primary-text':''} ${props.currentPage === 'user_info' ? '' : 'gray-text-color'}`}>
        <i className='material-icons' style={{ fontSize: '2.5rem' }}>person_outline</i> User Information
      </li>
      <li data-target="isEditModal" onClick={props.handleNav} id='user_address' style={{ fontSize: '1.5rem', borderRight: `${props.currentPage === 'user_address' ? '4px solid #66cccc' : '0'}` }} className={`${props.isDisabled ? '' : 'modal-trigger'} valign-wrapper py-1 mouse-pointer ${props.currentPage==='user_address' ? 'housky-primary-text':''} ${props.currentPage === 'user_address' ? '' : 'gray-text-color'}`}>
        <i className='material-icons' style={{ fontSize: '2.5rem' }}>home</i> User Address
      </li>
      <li data-target="isEditModal" onClick={props.handleNav} id='inspector_info' style={{ fontSize: '1.5rem', borderRight: `${props.currentPage === 'inspector_info' ? '4px solid #66cccc' : '0'}` }} className={`${props.isDisabled ? '' : 'modal-trigger'} valign-wrapper py-1 mouse-pointer ${props.currentPage==='inspector_info' ? 'housky-primary-text':''} ${props.currentPage === 'inspector_info' ? '' : 'gray-text-color'}`}>
        <i className= 'material-icons' style={{ fontSize: '2.5rem' }}>supervisor_account</i> Inspector Information
      </li>
      <li data-target="isEditModal" onClick={props.handleNav} id='company_info' style={{ fontSize: '1.5rem', borderRight: `${props.currentPage === 'company_info' ? '4px solid #66cccc' : '0'}` }} className={`${props.isDisabled ? '' : 'modal-trigger'} valign-wrapper py-1 mouse-pointer ${props.currentPage==='company_info' ? 'housky-primary-text':''} ${props.currentPage === 'company_info' ? '' : 'gray-text-color'}`}>
        <i className= 'material-icons' style={{ fontSize: '2.5rem' }}>home_work</i> Company Information
      </li>
      <li data-target="isEditModal" onClick={props.handleNav} id='payment_info' style={{ fontSize: '1.5rem', borderRight: `${props.currentPage === 'payment_info' ? '4px solid #66cccc' : '0'}` }} className={`${props.isDisabled ? '' : 'modal-trigger'} valign-wrapper py-1 mouse-pointer ${props.currentPage==='payment_info' ? 'housky-primary-text':''} ${props.currentPage === 'payment_info' ? '' : 'gray-text-color'}`}>
        <i className= 'material-icons' style={{ fontSize: '2.5rem' }}>payment</i> Payment Information
      </li>
      <li data-target="isEditModal" onClick={props.handleNav} id='account_management' style={{ fontSize: '1.5rem', borderRight: `${props.currentPage === 'account_management' ? '4px solid #66cccc' : '0'}` }} className={`valign-wrapper py-1 mouse-pointer ${props.currentPage==='account_management' ? 'housky-primary-text':''} ${props.currentPage === 'account_management' ? '' : 'gray-text-color'}`}>
        <i className= 'material-icons' style={{ fontSize: '2.5rem' }}>settings</i> Account Management
      </li>
      <li data-target="isEditModal" onClick={props.handleNav} id='reviews' style={{ fontSize: '1.5rem', borderRight: `${props.currentPage === 'reviews' ? '4px solid #66cccc' : '0'}` }} className={`valign-wrapper py-1 mouse-pointer ${props.currentPage==='reviews' ? 'housky-primary-text':''} ${props.currentPage === 'reviews' ? '' : 'gray-text-color'}`}>
        <i className= 'material-icons' style={{ fontSize: '2.5rem' }}>reviews</i> Reviews
      </li>
      <li data-target="isEditModal" onClick={props.handleNav} id='price_calculator' style={{ fontSize: '1.5rem', borderRight: `${props.currentPage === 'price_calculator' ? '4px solid #66cccc' : '0'}` }} className={`valign-wrapper py-1 mouse-pointer ${props.currentPage==='price_calculator' ? 'housky-primary-text':''} ${props.currentPage === 'price_calculator' ? '' : 'gray-text-color'}`}>
        <i className= 'material-icons' style={{ fontSize: '2.5rem' }}>calculate</i> Price Calculator
      </li>
      <li data-target="isEditModal" onClick={props.handleNav} id='tools' style={{ fontSize: '1.5rem', borderRight: `${props.currentPage === 'tools' ? '4px solid #66cccc' : '0'}` }} className={`valign-wrapper py-1 mouse-pointer ${props.currentPage==='tools' ? 'housky-primary-text':''} ${props.currentPage === 'tools' ? '' : 'gray-text-color'}`}>
        <i className= 'material-icons' style={{ fontSize: '2.5rem' }}>construction</i> Tools
      </li>
    </ul>
  </>
}