import RadioButton from "../../GeneralComponents/RadioButton/RadioButton";
import React, {useEffect, useRef} from "react";
import { getTimeOptions } from "../../../utils/utils";
import Materialize from "materialize-css/dist/js/materialize.js";

const TimeOfDay = (props) => {
  const inputEl = useRef(null);
  const { otherTime, isOtherTime, handleOtherTime, radioButtonsDisabled, otherTimeInputDisabled } = props;

  useEffect(() => {
    const elems = document.querySelectorAll('.timepicker');
    const instances = Materialize.Timepicker.init(elems, {twelveHour: true, autoClose: true, onCloseEnd: handleInputOnChange});
  }, [isOtherTime]);

  const renderTimeOptions = () => {
    const { timeOfDay } = props;
    let groupName = "time_of_day";
    let availableOptions = [];
    if (timeOfDay.early_morning) {
      availableOptions.push(
        <div className="col s12 m6 pl-0 ml-0" key="early_morning">
          <RadioButton
            setValue="early_morning"
            setName={groupName}
            handleClick={(e) => {
              props.handleSelectedOptions(e.target.value);
            }}
            setText={getTimeOptions("early_morning", true)}
            disabled={radioButtonsDisabled}
          />
        </div>
      );
    }
    if (timeOfDay.morning) {
      availableOptions.push(
        <div className="col s12 m6 pl-0 ml-0" key="morning">
          <RadioButton
            setValue="morning"
            setName={groupName}
            handleClick={(e) => {
              props.handleSelectedOptions(e.target.value);
            }}
            setText={getTimeOptions("morning", true)}
            disabled={radioButtonsDisabled}
          />
        </div>
      );
    }
    if (timeOfDay.afternoon) {
      availableOptions.push(
        <div className="col s12 m6 pl-0 ml-0" key="afternoon">
          <RadioButton
            setValue="afternoon"
            setName={groupName}
            handleClick={(e) => {
              props.handleSelectedOptions(e.target.value);
            }}
            setText={getTimeOptions("afternoon", true)}
            disabled={radioButtonsDisabled}
          />
        </div>
      );
    }
    if (timeOfDay.late_afternoon) {
      availableOptions.push(
        <div className="col s12 m6 pl-0 ml-0" key="late_afternoon">
          <RadioButton
            setValue="late_afternoon"
            setName={groupName}
            handleClick={(e) => {
              props.handleSelectedOptions(e.target.value);
            }}
            setText={getTimeOptions("late_afternoon", true)}
            disabled={radioButtonsDisabled}
          />
        </div>
      );
    }

    availableOptions.push(
      <div className="col s12 m6 pl-0 ml-0" key="other-time">
        <RadioButton
          setValue="other_time"
          setName={groupName}
          handleClick={(e) => {
            props.handleSelectedOptions('other_time');
          }}
          setText={'Other'}
          setChecked={isOtherTime}
          disabled={radioButtonsDisabled}
        />
      </div>
    );

    return availableOptions;
  };

  const handleInputOnChange = (event) => {
    handleOtherTime(inputEl.current.value);
  };

  return (
    <div className="col s12 pl-0">
      <p><span>Time of day</span></p>
      <div style={{display: "flex", flexWrap: "wrap"}}>
        {
          renderTimeOptions()
        }
        {
          isOtherTime &&
          <div className="col s12 pl-0 my-2" style={{paddingRight: '0'}}>
            <div className="input-field">
              <input
                ref={inputEl}
                id="other-time"
                placeholder=''
                value={otherTime}
                type="text"
                disabled={otherTimeInputDisabled}
                className="timepicker"
                readOnly={true}
              />
              <label className='active' htmlFor="other-date">New time (hh:mm)</label>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default TimeOfDay;
