import React from "react";
import { Inspector as InspectorFirebase } from "../../../utils/firebase/firebase";
import { getOpenJobs } from "../../../services/service";
import JobsInfoRow from "../OpenJobsRow/JobsInfoRow";

class OpenJobs extends React.Component {
  state = {
    idToken: "",
    openJobs: [],
    currentFilter: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    InspectorFirebase.getIdToken()
      .then((idToken) => {
        this.setState({ idToken });
        return getOpenJobs(idToken);
      })
      .then((jobs) => {
        this.setState({ openJobs: jobs.data });
      });
  }

  handleFilter = (filter) => {
    getOpenJobs(this.state.idToken, filter).then((jobs) => {
      this.setState({ openJobs: jobs.data, currentFilter: filter });
    });
  };

  render() {
    return (
      <>
        <div className="adm-jobs-filters">
          <button
            className="btn-small my-2"
            onClick={() => {
              this.handleFilter("pending");
            }}
          >
            Pending
          </button>
          <button
            className="btn-small my-2"
            onClick={() => {
              this.handleFilter("acceptedNotCancelled");
            }}
          >
            Accepted
          </button>
          <button
            className="btn-small my-2"
            onClick={() => {
              this.handleFilter("completed");
            }}
          >
            Completed
          </button>
          <button
            className="btn-small my-2"
            onClick={() => {
              this.handleFilter("acceptedCancelled");
            }}
          >
            Accepted and Cancelled
          </button>

          <button
            className="btn-small my-2"
            onClick={() => {
              this.handleFilter("cancelledNotAccepted");
            }}
          >
            Pending and Cancelled
          </button>
        </div>
        <div className="container">
          <div className="row" style={{ marginBottom: "80px" }}>
            {this.state.openJobs.map((e, i) => {
              return <JobsInfoRow key={i} e={e} index={i + 1} />;
            })}
          </div>
        </div>
      </>
    );
  }
}

export default OpenJobs;
