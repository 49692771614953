import React, {useEffect, useState} from 'react'
import {Inspector as InspectorFirebase} from "../../../utils/firebase/firebase";
import {createTool, deleteTool, getAllTools, updateTool} from "../../../services/service";
import './Tools.css'
import ToolsCard from "./ToolsCard";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { inspectorApp as inspectorFirebase } from "../../../firebase";
import Materialize from "materialize-css/dist/js/materialize.min.js";

export default function() {
  const [ filter, setFilter ] = useInput('');
  const [ tools, setTools ] = useState([]);
  const [ token, setToken ] = useState('');
  const [ name, setName ] = useState('');
  const [ url, setUrl ] = useState('');
  const [ isAddingNewTool, setIsAddingNewTool ] = useState(false);

  /// for uploading tool image
  const [ src, setSrc ] = useState(null);


  useEffect(() => {
    (
      async() => {
        try {
          const idToken = await InspectorFirebase.getIdToken();
          const tools = await getAllTools(idToken);
          setToken(idToken);
          setTools(tools.data)
        } catch (e) {
          console.log(e);
        }
      }
    )()
  }, []);

  const onNameChange = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const getToolsCards = () => {
    return tools
              .filter(tool => tool.tool_name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
              .map((tool, index) => {
                return (
                  <ToolsCard key={tool.tool_id} tool={tool} onEdit={onEdit} onDelete={onDelete} src={src} handlePictureSelected={handlePictureSelected} />
                );
              })
  };

  const onAddToolCardClose = () => {
    setName('');
    setUrl('');
    setIsAddingNewTool(false);
  };

  const addTool = async (imageUrl) => {
    const id = uuid();
    await createTool(token, {
      name,
      url: imageUrl,
      id
    })

    const updatedTools = _.concat([{
      tool_id: id,
      tool_name: name,
      tool_image_url: imageUrl
    }], tools);

    setIsAddingNewTool(false);
    setTools(updatedTools);
  };

  const handlePictureSelected = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]))
  }

  const uploadImageToStorage = async () => {
    const root = inspectorFirebase.storage().ref();
    const profileImage = root.child(
      `toolImage/${name}`
    );
    try {
      const imageResponse = await fetch(src);
      const imageBlob = await imageResponse.blob();
      const snapshot = await profileImage.put(imageBlob);
      const imageUrl = await snapshot.ref.getDownloadURL();
      setUrl(imageUrl);
      return imageUrl;
    } catch (err) {
      console.log(err);
    }
  };

  const onUpload = async () => {
    if (src) {
      try {
        const imageUrl = await uploadImageToStorage();
        await addTool(imageUrl);
        Materialize.toast({
          html: "Profile image has been uploaded.",
          classes: "rounded",
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  const getAddToolCard = () => {
    if(isAddingNewTool) {
      return (
        <div className="card large col s3" style={{margin: '10px'}}>
          <div className="card-content">
            <div className="row">
              <span className="card-title">Add a new tool</span>
            </div>
            <div>
              <div className="row">
                <div>Tool Name</div>
                <input type="text" value={ name || ''} onChange={onNameChange} className="validate" />
              </div>
            </div>
            <div className="row">
              <label htmlFor="upload2"><a className="btn">Select Photo</a></label>
              <input type="file" id="upload2" style={{display: 'none'}} onChange={handlePictureSelected}/>
            </div>
            <div className='row'>
              <a disabled={!name || !src} className="btn" style={{marginRight: '20px'}} onClick={onUpload}>
                Upload
              </a>
              <a className="red btn" onClick={onAddToolCardClose}>
                Cancel
              </a>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const onEdit = async (toolId, toolName, toolImageUrl) => {
    const updatedTool = {
      toolId,
      toolName,
      toolImageUrl
    };
    const updatedTools = tools.map(tool => {
      if(tool.tool_id === toolId) {
        return {
          tool_name: toolName,
          tool_id: toolId,
          tool_image_url: toolImageUrl
        };
      } else {
        return tool;
      }
    });

    await updateTool(token, updatedTool);

    setSrc(null);
    setTools(updatedTools);
  }

  const onDelete = async (toolId) => {
    const updatedTools = tools.filter(tool => {
      return tool.tool_id !== toolId
    });

    await deleteTool(token, toolId);
    setTools(updatedTools);
  };

  return (
    <div style={{ minHeight: "100vh", marginTop: "3rem" }}>
      <div style={{padding: '10px'}} className="row">
        <input value={filter} onChange={setFilter} className="col s2"/>
        <button className="btn-small col s1" style={{marginLeft: '10px'}}>Filter</button>
        <button className="btn-small col s1" onClick={() => setIsAddingNewTool(true)} style={{marginLeft: '10px'}}>Add</button>
      </div>
      <div className='row'>
        { getAddToolCard() }
        { getToolsCards() }
      </div>
    </div>
  );
}

function useInput(initValue) {
  const [ value, setValue ] = useState(initValue);
  const onValueChange = (e) => {
    e.preventDefault();
    setValue(e.target.value)
  };
  return [ value, onValueChange ];
}