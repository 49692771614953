import React from 'react'

const PricingBody = props => {
  return <div className={`col p-0 s2 center ${props.header ? 'pricingTableHeader' : 'pricingTableBody'}`} style={{ backgroundColor: `${props.bgColor}`, border: '1px solid #D5DAE1' }}>
    <p className={`${props.header ? 'pricing-header' : 'pricing-body'}`}>{props.header ? '' : '$'}{props.e[props.item]}</p>
  </div>
}

const handleHover = (id) => {
  document.getElementById(id).classList.add('info-box-active')
}
const handleLeave = (id) => {
  document.getElementById(id).classList.remove('info-box-active')
}

export default (props) => {
  return <>
    <div>
      <div className='container'>
        <div className='row my-0'>
          <div className='col p-0 s2 center pricingTableHeader'><p className='pricing-header'></p></div>
          {props.houseTypes.map((e, i) => {
            return <PricingBody e={e} index={i} key={i} item='title' bgColor='#EDF0F6' header={true} />
          })}
        </div>
      </div>
    </div>
    <div style={{ backgroundColor: 'white', marginBottom: '120px' }}>
      <div className='container'>
        <div className='row mb-0'>
          <div className='col p-0 s2 center pricingTableBody' style={{ backgroundColor: '#EDF0F6', border: '1px solid #D5DAE1' }}>
            <p className='pricing-body'>Inspection
            <span
                onMouseLeave={() => { handleLeave('info-box-1') }}
                onMouseOver={() => { handleHover('info-box-1') }}
                className='more-info-question'>?</span>
            </p>
            <div id='info-box-1' className='more-info-box z-depth-2'>
              <p>
                {props.type === 'inspector' ?
                  'Price includes credit card processing fees.' :
                  'House Inspection and inspection features vary by inspector'}
              </p>
            </div>
          </div>
          {
            props.houseTypes.map((e, i) => {
              return <PricingBody e={e} index={i} key={i} item='total' bgColor='white' />
            })
          }
          <div className='col p-0 s2 center pricingTableBody' style={{ backgroundColor: '#EDF0F6', border: '1px solid #D5DAE1' }}>
            <p className='pricing-body'>WDI
            <span
                onMouseLeave={() => { handleLeave('info-box-2') }}
                onMouseOver={() => { handleHover('info-box-2') }}
                className='more-info-question'>?</span>
            </p>
            <div id='info-box-2' className='more-info-box z-depth-2'>
              <p>
                {props.type === 'inspector' ?
                  'Price includes credit card processing fees.' :
                  'Wood Destroying Insect/Termite Inspection'}
              </p>
            </div>
          </div>
          {
            props.houseTypes.map((e, i) => {
              return <PricingBody e={e} index={i} key={i} item='wdi' bgColor='white' />
            })
          }
          <div className='col p-0 s2 center pricingTableBody' style={{ backgroundColor: '#EDF0F6', border: '1px solid #D5DAE1' }}>
            <p className='pricing-body'>Radon
            <span
                onMouseLeave={() => { handleLeave('info-box-3') }}
                onMouseOver={() => { handleHover('info-box-3') }}
                className='more-info-question'>?</span>
            </p>
            <div id='info-box-3' className='more-info-box z-depth-2'>
              <p>
                {props.type === 'inspector' ?
                  'Price includes credit card processing fees.' :
                  'Radon Inspection/Radio Active Gass Inspection'}
              </p>
            </div>
          </div>
          {
            props.houseTypes.map((e, i) => {
              return <PricingBody e={e} index={i} key={i} item='radon' bgColor='white' />
            })
          }
        </div>
        <div className='row mb-0' style={{ display: 'flex' }}>
          <div className='col p-0 s2 center pricingTableBody' style={{ backgroundColor: '#EDF0F6', border: '1px solid #D5DAE1' }}>
            <p className='pricing-body'>What you get</p>
          </div>
          <div className='col p-0 s10 center pricingTableBody' style={{ backgroundColor: 'white', border: '1px solid #D5DAE1' }}>
            <ul className='pricing-body'>
              <li><i className="material-icons" style={{ color: 'rgb(93, 197, 96)' }}>home</i>Home Inspection</li>
              <li><i className="material-icons" style={{ color: 'rgb(93, 197, 96)' }}>picture_as_pdf</i>Online Report Access</li>
              <li><i className="material-icons" style={{ color: 'rgb(93, 197, 96)' }}>perm_contact_calendar</i>Certified Inspectors</li>
              <li><i className="material-icons" style={{ color: 'rgb(93, 197, 96)' }}>schedule</i>Quick and Easy Scheduling process</li>
              {props.type === 'inspector' ?
                <li><i className="material-icons" style={{ color: 'rgb(93, 197, 96)' }}>attach_money</i>Price includes credit card processing fees.</li> : <></>}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </>
}