import moment from "moment";

const mockDataSeed = {
  "jobid": "4ca98a90-624f-11eb-8c53-19376b6aca90",
  "houseid": "4c9e6700-624f-11eb-8c53-19376b6aca90",
  "userid": "29d55f80-624f-11eb-8c53-19376b6aca90",
  "additional_features": {
    "wdi": true,
    "radon": true
  },
  "reportid": "d24253c0-6250-11eb-8c53-19376b6aca90",
  "eventinfo": [
    {
      "title": "",
      "start": "1612155600000",
      "end": "1612155600000",
      "allDay": true,
      "date": "Monday, February 1st 2021",
      "unix": "1612155600000",
      "early_morning": false,
      "morning": true,
      "afternoon": false,
      "late_afternoon": false
    }
  ],
  "title": "CONDO 190 CLAREMONT AVENUE",
  "current_status": "COMPLETED",
  "accepted": true,
  "inspection_cost": 550,
  "address_one": "190 Claremont Avenue",
  "address_two": "4C",
  "city": "New York",
  "state": "NY",
  "zipcode": "10027",
  "country": null,
  "latitude": 40.8148588,
  "longitude": -73.9598843,
  "house_typeid": 1,
  "house_detailid": "4c92a730-624f-11eb-8c53-19376b6aca90",
  "first_name": "TEST",
  "last_name": "USER",
  "email": "TESTUSER@GMAIL.COM",
  "area_code": 917,
  "cell_number": 5766524,
  "token": "kTpOPww2BCSFLtrvkmZeJmUGciU2",
  "registration_date": "2021-01-29T16:29:30.872Z",
  "house_type": "Condo",
  "bedroom": "3",
  "bathroom": "3",
  "sqft": "3333",
  "year_built": "1988",
  "parking": "Driveway",
  "foundation": "",
  "attic": "",
  "description_text": null,
  "price": 340,
  "margin": "30",
  "beta": "1",
  "inspection_quality": "1",
  "processing_fee": "1.03",
  "radon": "100",
  "wdi": "70",
  "ins_longitude": -73.9598843,
  "ins_latitude": 40.8148588
};

const houseType = ['Condo', 'Single Family', 'Town House', 'Two-Three Family', 'Mansion'];

export const generateMockData = () => {
  let results = [];
  for(let i = 0; i < 20; i++) {
    let result = Object.assign({}, mockDataSeed);
    let inspection_cost = Math.floor(Math.random() * 100 + 500);
    let house_type = houseType[Math.floor(Math.random() * 5)];
    let bedroom = Math.floor(Math.random() * 5 + 2);
    let bathroom = Math.floor(Math.random() * 4 + 1);
    let month = Math.floor(Math.random() * 12 + 1);
    let day = Math.floor(Math.random() * 28 + 1);
    let unix = moment(`${month}/${day}/2020`).format('x');
    let id = Math.floor(Math.random() * 100000000000000);

    result.eventinfo = [Object.assign({}, mockDataSeed.eventinfo, { unix })];
    result.inspection_cost = inspection_cost;
    result.house_type = house_type;
    result.bedroom = bedroom;
    result.bathroom = bathroom;
    result.jobid = id.toString();
    results.houseid = id.toString();

    results.push(result);
  }

  return results;

};