import React from "react";
import PrivateComponent from "../../../components/PrivateComponent";
import HouskyPricingComponent from "../../../components/AdminDomain/HouskyPricing/HouskyPricing";

class Pricing extends React.Component {
  render() {
    return (
      <PrivateComponent>
        <HouskyPricingComponent />
      </PrivateComponent>
    );
  }
}

export default Pricing;
