import React, {useEffect, useState} from "react";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import { app as firebase } from "../../../firebase";
import AuthContext from "../../../contexts/auth";
import AuthWrapper from "../../AuthWrapper";
import {Redirect, Link, useHistory} from "react-router-dom";
import ShowEye from "../../../assets/eye.svg";
import HideEye from "../../../assets/eye-slash.svg";
import Loader from "../../Loader";
import { LoadTutorial } from "../../../services/helper";
import "./Login.css";

// const Login = () => {
//   const showForm = () => {
//     return (
//       <div>
//         <div className="row">
//           <h5>Sign In</h5>
//         </div>
//         <div>
//           <form>
//             <div className="row">
//               <label for="email" className="signInInputBxLabel">
//                 Email Address
//               </label>
//               <div>
//                 <input
//                   id="email"
//                   type="text"
//                   class="browser-default signInInputBx"
//                 />
//               </div>
//             </div>
//             <div className="row">
//               <label for="password" className="signInInputBxLabel">
//                 Password
//               </label>
//               <div>
//                 <input
//                   id="password"
//                   type="text"
//                   class="browser-default signInInputBx"
//                 />
//               </div>
//             </div>
//             <div className="row">
//               <button class="browser-default signInButton">SIGN IN</button>
//             </div>
//             <div className="buttonFooter">
//               <span>Don't have an account? </span>
//               <Link to="/signup">Sign Up</Link>
//             </div>
//           </form>
//         </div>
//       </div>
//     );
//   };
//
//   return (
//     <div className="pageContainer">
//       <div className="signInBoxContainer">
//         <div class="formContainer">{showForm()}</div>
//         <div className="imageContainer">
//           <div>
//             <img
//               id="signInImage"
//               src={require("../../../assets/sign-up-home-2.png")}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
//
// export default Login;

// Previous Version
export default function Login(props) {
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ loading, setLoading ] = useState(true);
  const [ hidden, setHidden ] = useState(true);

  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 700);
  }, [])

  const toggleShow = () => {
    setHidden(!hidden);
  };

  const handleEmailChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  }

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  }

  const handleLogIn = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        if (history.location.state && history.location.state.from === 'schedule') {
          const queryParams = window.location.search;
          history.push(`/schedule${queryParams}`);
        } else {
          history.push('/dashboard');
        }
      })
      .catch((err) => {
        const {message} = err;
        console.error(`User login: ${message}`);
        Materialize.toast({html: "Invalid user credentials", classes: "rounded"});
      });
  };

  const getForm = () => {
    return (
      <div style={{ backgroundColor: "#104b69", minHeight: "75vh" }}>
        <img
          src={require("../../../assets/why-us.png")}
          alt="background"
          style={{ position: "absolute", width: "40%" }}
        />
        <div
          className="row mb-0"
          style={{ position: "relative", zIndex: "2", paddingBottom: "100px" }}
        >
          <h4
            className="white-text center-align mt-0"
            style={{ padding: "40px 0px" }}
          >
            User log in to Housky
          </h4>
          <div
            className="col s12 m4 offset-m4"
            style={{ borderRadius: "10px", backgroundColor: "white" }}
          >
            <div className="container">
              <form className="py-4">
                <h6>Enter your email and password</h6>
                <label>Email</label>
                <input
                  name="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <div style={{ position: "relative" }}>
                  <label>Password</label>
                  <input
                    name="password"
                    type={hidden ? "password" : "text"}
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <img
                    src={hidden ? HideEye : ShowEye}
                    onClick={toggleShow}
                    alt="show/hide"
                    style={{
                      height: "20px",
                      position: "absolute",
                      top: "32px",
                      right: "0px",
                    }}
                  />
                </div>
                <button
                  className="btn py-1 housky-primary-complimentary-bg"
                  style={{ height: "auto", width: "100%", color: "black" }}
                  onClick={handleLogIn}
                >
                  Login
                </button>
                <div className="center my-2">
                  <Link to="/signup">Create an account?</Link>
                </div>
                <div className="center my-2">
                  <Link to="/reset">Forgot your password?</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <AuthContext.Consumer>
        {(user) => {
          if (user.user) {
            return (
              <>
                <Redirect to="/dashboard" />
              </>
            );
          } else {
            return <>{loading ? <Loader /> : getForm()}</>;
          }
        }}
      </AuthContext.Consumer>
    </>
  );
}
