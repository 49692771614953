import React from 'react'
import { Link } from 'react-router-dom'
import './footer.css'

export default () => {
  return (<div style={{ position: 'relative' }}>
    <footer id="page-footer" style={{ backgroundColor: '#333333' }}>
      <div className='container'>
        <div className="row">
          <div className="col s12 m6 l3">
            <h5 className="white-text">Company</h5>
              <ul>
                <li>
                  {
                    window.location.href.split(".")[0].split("/")[2] === "inspector" ?
                      <Link className="footer-links grey-text text-lighten-3" to="/mission">Mission</Link>
                      :
                      <a className="footer-links grey-text text-lighten-3" href="https://housky.io/mission">Mission</a>
                  }
                </li>
                {/*<li>*/}
                {/*  {*/}
                {/*    window.location.href.split(".")[0].split("/")[2] === "inspector" ?*/}
                {/*      <a className="footer-links grey-text text-lighten-3" href="https://housky.io/pricing">Pricing</a>*/}
                {/*      :*/}
                {/*      <Link className="footer-links grey-text text-lighten-3" to="/pricing">Pricing</Link>*/}
                {/*  }*/}
                {/*</li>*/}
                <li>
                  <a className="footer-links grey-text text-lighten-3" href="https://housky.io/learning_center">Learning Center</a>
                </li>
                <li><a className="footer-links grey-text text-lighten-3" href="">About Us</a></li>
              </ul>
          </div>
          <div className="col s12 m6 l3">
            <h5 className="white-text">Support</h5>
            <ul>
              <li><a className="footer-links grey-text text-lighten-3" href="https://housky.io/contact-us">Contact Us</a></li>
              <li><a className="footer-links grey-text text-lighten-3" href="https://housky.io/terms/tos">Terms of Service</a></li>
              <li><a className="footer-links grey-text text-lighten-3" href="https://housky.io/terms/privacy">Privacy Policy</a></li>
              <li><a className="footer-links grey-text text-lighten-3" href="https://housky.io/terms/cookies">Cookies Policy</a></li>
              {/*<li><a className="footer-links grey-text text-lighten-3" href="https://housky.io/terms/booking-protection">Booking Protection</a></li>*/}
            </ul>
          </div>
          <div className="col s12 m6 l3">
            <h5 className="white-text">Inspector</h5>
            <ul>
              <li><a className="footer-links grey-text text-lighten-3" href="https://inspector.housky.io/">Working with Housky</a></li>
              <li><a className="footer-links grey-text text-lighten-3" href="https://inspector.housky.io/inspectorFaq">FAQs</a></li>
              <li><a className="footer-links grey-text text-lighten-3" href="https://inspector.housky.io">Inspector Sign Up</a></li>
            </ul>
          </div>
          <div className="col s12 m6 l3">
            <h5 className="white-text">Social Media</h5>
            <ul>
              <li style={{cursor: 'pointer'}}><img className='mx-2' style={{ width: '30px' }} src={require('../../assets/facebook.png')} alt="Facebook" onClick={() => window.open('https://www.facebook.com/Housky.io', '_blank')} /></li>
              <li><img className='mx-2' style={{ width: '30px' }} src={require('../../assets/twitter.png')} alt="Twitter" /></li>
            </ul>
          </div>
        </div>
      </div>
      <div id="footer-copyright">
        <div className="container center white-text">
          © 2020 Housky All rights reserved.
        </div>
      </div>
    </footer>
  </div>)
}