import React, {useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import './SearchResultPageSearchBar.css';

export default function(props) {

  const { initValue, onChange } = props;

  const [ searchTerm, setSearchTerm ] = useState('');

  const history = useHistory();

  useEffect(() => {
    setSearchTerm(!!initValue ? initValue : "");
  }, []);

  const onSearch = () => {
    if (process.env.REACT_APP_ENV === "PROD") {
      if (window.location.href.split(".")[0].split("/")[2] === "user") {
        window.location.href = `https://housky.io/search?query=${searchTerm}`
      } else {
        history.push(`/search?query=${searchTerm}`);
      }
    } else if (props.domain === "user") { /// in qa/local env and in user domain, we need to set the domain to main first and then search
      props.setDomain('main');
      history.push(`/search?query=${searchTerm}`);
    }
    if(onChange) {
      onChange(searchTerm);
    }
  };

  const handleEnter = (e) => {
    if(searchTerm && e.key === 'Enter') {
      onSearch();
    }
  };

  const getBorderStyle = () => {
    if(props.borderColor) {
      return `1px solid ${props.borderColor}`
    }
  };

  return (
    <div className="searchResultSearchBoxContainer">
      <input
        id="searchBarInputBox"
        type="text"
        className="browser-default searchResultSearchBox"
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyPress={handleEnter}
        autoComplete="off"
        placeholder="Search by zipcode or name"
        value={searchTerm}
        style={{border: getBorderStyle() }}
      />
      <div className="searchResultSearchIconContainer" onClick={onSearch}>
        <i className="material-icons">search</i>
      </div>
    </div>
  );
}
