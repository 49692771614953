import React from 'react';
import moment from "moment";
import Table from "../../GeneralComponents/Table/Table";

const JobRequestsTable = ({tableHeaders, responsiveHeaders, jobs, inspectorLat, inspectorLng, onClickRow}) => {
  const handleOnClick = (event, jobId) => {
    event.preventDefault();
    onClickRow(jobId);
  }

  return (
    <Table
      tableClass="striped centered hightlight"
      tableHeaders={tableHeaders}
      responsiveHeaders={responsiveHeaders}
      tableWrapperClass="rwdTable"
      emptyTableMessage={"No job requests yet!"}
    >
      {
        jobs.map((job) => {
          const firstName = `${job.first_name[0]}${job.first_name.slice(1).toLowerCase()}`;
          const lastName = `${job.last_name[0]}${job.last_name.slice(1).toLowerCase()}`;
          const fullName = `${firstName} ${lastName}`;
          const distance = ((Math.pow(job.latitude - inspectorLat, 2) + Math.pow(job.longitude - inspectorLng, 2)) / 2) * 100;
          const cost = job?.inspector_offer?.cost ?? 0;

          return(
            <tr className="mouse-pointer" key={job.jobid} id={job.jobid} onClick={(event) => {handleOnClick(event, job.jobid)}}>
              <td>{moment(job.eventinfo[0].unix, "x").format("MM/DD/YYYY")}</td>
              <td className="hideHeader">{fullName}</td>
              <td>{job.house_type}</td>
              <td className="hideHeader">{`${job.bedroom} / ${job.bathroom}`}</td>
              <td>{job.address_one}</td>
              <td className="hideHeader">
                {distance >= 0 && distance < 0.49
                  ? "Near"
                  : distance >= 0.5 && distance < 0.99
                    ? "Average"
                    : distance >= 1
                      ? "Far"
                      : distance >= 1.5
                        ? "Far away"
                        : "Not available"}
              </td>
              <td style={{color: "green", position: "relative", fontWeight: "bold"}}>
                {!!cost ? `$${ cost}` : ' - '}
              </td>
            </tr>
          );
        })
      }
    </Table>
  );
}

export default JobRequestsTable;
