import React from "react";

const FilterTableHeader = (props) => {
  const { header, thClass = "", left, right, clickEvent, icon, center } = props;
  const getBoarderCss = () => {
    return (
      left
        ? {
          borderTopLeftRadius: "2px",
          borderBottomLeftRadius: "2px",
        }
        : right
        ? {
          borderTopRightRadius: "2px",
          borderBottomRightRadius: "2px",
        }
        : {}
    );
  };

  const getCenterCss = () => {
    return (
      center ? { display: 'flex', alignItems: 'center' } : null
    );
  };

  return (
    <th
      name={header}
      id={header}
      className={`table-header` + thClass}
      style={{...getBoarderCss(), ...getCenterCss()}}
      onClick={(e) => {
        if (clickEvent) {
          clickEvent(e);
        }
      }}
    >
      {header} {icon && icon}
    </th>
  );
};

export default FilterTableHeader;
