import { Link, Redirect } from "react-router-dom";
import AuthContext from "../../../contexts/auth";
import HideEye from "../../../assets/eye-slash.svg";
import React from "react";
import ShowEye from "../../../assets/eye.svg";
import { inspectorApp as firebase } from "../../../firebase";
import { getAdminUser } from "../../../services/service";

class Login extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      isUserSignedIn: false,
      email: "",
      password: "",
      hidden: true,
      loginErrorMessage: "",
      isLoginError: false,
    };
  }

  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  componentDidUpdate = () => {};

  handleFormInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFormSubmit = (e) => {
    const { email, password } = this.state;
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        return firebase.auth().currentUser.getIdToken(true);
      })
      .then((idToken) => {
        return getAdminUser(idToken);
      })
      .then((response) => {
        console.log(response.user_exists);
        if (response.user_exists) {
          this.setState({ isUserSignedIn: true });
        } else {
          firebase.auth().signOut();
          this.setState({
            loginErrorMessage: "ERROR",
            isLoginError: true,
          });
        }
      })
      .catch((error) => {
        if (error.code === "auth/invalid-email" || error.code === "auth/wrong-password") {
          this.setState({
            isLoginError: true,
            loginErrorMessage: "Invalid email or password",
          });
        } else {
          this.setState({
            isLoginError: true,
            loginErrorMessage: "Error user may not exist or be disabled",
          });
        }
      });
  };

  render() {
    const { isUserSignedIn, loginErrorMessage, isLoginError } = this.state;
    const loginForm = (
      <div style={{ backgroundColor: "#104b69", minHeight: "75vh" }}>
        <img
          src={require("../../../assets/why-us.png")}
          alt="background"
          style={{ position: "absolute", width: "40%" }}
        />
        <div className="row mb-0" style={{ position: "relative", zIndex: "2", paddingBottom: "100px" }}>
          <h4 className="white-text center-align mt-0" style={{ padding: "40px 0px" }}>
            Admin log in to Housky
          </h4>
          <h6 className="white-text center-align mt-0">{this.props.message}</h6>
          <div className="col s12 m4 offset-m4" style={{ borderRadius: "10px", backgroundColor: "white" }}>
            <div className="container">
              <form className="py-4">
                <h6>Enter your email and password</h6>
                <label>Email</label>
                <input name="email" type="email" onChange={this.handleFormInput}></input>
                <div style={{ position: "relative" }}>
                  <label>Password</label>
                  <input
                    name="password"
                    type={this.state.hidden ? "password" : "text"}
                    onChange={this.handleFormInput}
                  ></input>
                  <img
                    src={this.state.hidden ? ShowEye : HideEye}
                    onClick={this.toggleShow}
                    alt="show/hide"
                    style={{
                      height: "20px",
                      position: "absolute",
                      top: "32px",
                      right: "0px",
                    }}
                  />
                </div>
                {isLoginError ? (
                  <label>
                    <h5>{loginErrorMessage}</h5>
                  </label>
                ) : (
                  <></>
                )}
                <button
                  className="btn py-1 housky-primary-complimentary-bg"
                  style={{ height: "auto", width: "100%", color: "black" }}
                  onClick={this.handleFormSubmit}
                >
                  Login
                </button>
                <div className="center my-2">
                  <Link to="/signup">Create an account?</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col m7 hide-on-small-only"></div>
      </div>
    );

    return isUserSignedIn ? <Redirect to="/promotions" /> : loginForm;
  }
}

export default Login;
