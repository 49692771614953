import React, {useEffect, useState} from "react";
import { app as userFirebase } from "../../../firebase";
import { getUserJobs, getUser } from "../../../services/service";
import JobInfo from "./JobInfo";
import InfoCard from "./InfoCard";
import ReportModal from "./ReportModal";
import {Link, useHistory} from "react-router-dom";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import { removeModal } from "../../../services/helper";
import SearchBar from "../../GeneralComponents/SearchBar/SearchResultPageSearchBar";

export default function DashboardInfo(props) {

  const [ jobs, setJobs ] = useState([]);
  const [ recentJob, setRecentJob ] = useState('');
  const [ showWarning, setShowWarning ] = useState(false);

  const history = useHistory();

  const getToken = async () => {
    let currentUser = await userFirebase.auth().currentUser;
    if(currentUser) {
      return currentUser.getIdToken(true)
    } else {
      return Promise.reject();
    }
  };

  useEffect(() => {
    getToken()
      .then((res) => {
        getUser(res).then((user) => {
          getUserJobs(user.userid, res).then((res) => {
            let sorted = res.sort((a, b) => {
              if (a.createdat > b.createdat) return -1;
              if (a.createdat < b.createdat) return 1;
              return 0;
            });
            setJobs(sorted);
            setRecentJob(sorted[0]);
          });
        })
    })
      .catch(async (e) => {
        await userFirebase.clearPersistence();
        await userFirebase.signOut();
        history.push('/login')
    })
    let elems = document.querySelectorAll(".modal");
    Materialize.Modal.init(elems, { inDuration: "450" });

    return (() => {
      if (document.getElementById("report-container"))
        removeModal("#report-container");
    });
  }, []);

  const onClick = () => {
    const searchBarInputBox = document.getElementById('searchBarInputBox');
    searchBarInputBox.focus();
    if(!showWarning) {
      setTimeout(() => {
        setShowWarning(false);
      }, 6500);
      setShowWarning(true);
    }
  };

  const getLink = () => {

    if(props.selectedInspectors && props.selectedInspectors.length) {
      return (
        <Link
          to={{
            pathname: "/schedule",
            state: {
              scrollTop: true,
            },
          }}
        >
          <div id="dashboard-schedule-btn" className="dash-nav">
            <img
              className="dashboard-icon"
              src={require("../../../assets/dashboard-schedule.png")}
              alt="Schedule"
            />
            <h5 className="truncate-overfill">Schedule</h5>
          </div>
        </Link>
      );
    } else {
      return (
        <span onClick={onClick} style={{cursor: 'pointer'}}>
          <div id="dashboard-schedule-btn" className="dash-nav">
            <img
              className="dashboard-icon"
              src={require("../../../assets/dashboard-schedule.png")}
              alt="Schedule"
            />
            <h5 className="truncate-overfill">Schedule</h5>
          </div>
        </span>
      );
    }
  };

  const getAddInspectorWarning = () => {
    if(showWarning) {
      return (
        <div style={{paddingLeft: '20px', color: 'red', marginTop: '2px'}}>
          Please add some inspectors to your list before you schedule
        </div>
      );
    }
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          height: "200px",
          width: "100%",
          zIndex: "-1",
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="rgb(16, 75, 105)"
            fillOpacity="1"
            d="M0,224L48,224C96,224,192,224,288,208C384,192,480,160,576,165.3C672,171,768,213,864,224C960,235,1056,213,1152,197.3C1248,181,1344,171,1392,165.3L1440,160L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          ></path>
        </svg>
      </div>
      <div className="container">
        <div className="row" style={{ marginTop: "60px" }}>
          <div id="dashboard-info" className="col s12 l8">
            <InfoCard
              header={"Welcome to Housky!"}
              type={"info-mascot"}
              body={"Welcome back to Housky. You can continue your scheduling process by clicking the Schedule button. All your jobs can be found by clicking the Recent Jobs button."}
              bgColor={"housky-primary-complimentary-bg"}
              mascot={require("../../../assets/inspector-icon.png")}
            />
          </div>
        </div>
        <div
          id="report-container"
          className="modal modal-fixed-footer"
          style={{ minHeight: "70vh" }}
        >
          {jobs.length > 0 ? (
            <ReportModal jobs={jobs} />
          ) : (
            <>
              <div className="modal-content center valign-wrapper">
                <div
                  style={{
                    margin: "auto",
                    height: "auto",
                    padding: "1rem 2rem",
                    backgroundColor: "#66cccc",
                  }}
                >
                  <h4>No Reports at the moment</h4>
                  <Link to="/schedule">
                    <p className="btn">Schedule House Inspection</p>
                  </Link>
                </div>
              </div>
              <div className="modal-footer valign-wrapper">
                <a
                  href="#!"
                  className="btn modal-close housky-primary-bg"
                  style={{ width: "85%", margin: "auto" }}
                >
                  Close
                </a>
              </div>
            </>
          )}
        </div>
        <div className="row" style={{ margin: "2rem 0rem" }}>
          <div className="col s12 m6 white">
            { getLink() }
          </div>
          {/*<div className="col s12 m4 white">*/}
          {/*  <div*/}
          {/*    data-target="report-container"*/}
          {/*    className="dash-nav mouse-pointer modal-trigger"*/}
          {/*  >*/}
          {/*    <img*/}
          {/*      className="dashboard-icon"*/}
          {/*      src={require("../../../assets/dashboard-report.png")}*/}
          {/*      alt="Reports"*/}
          {/*    />*/}
          {/*    <h5 className="truncate-overfill">View Reports</h5>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="col s12 m6 white">
            <Link to="/dashboard/inspections">
              <div className="dash-nav">
                <img
                  className="dashboard-icon"
                  src={require("../../../assets/inspection_icon.png")}
                  alt="Inspections"
                />
                <h5 className="truncate-overfill">Recent Jobs</h5>
              </div>
            </Link>
          </div>
        </div>
        {/*{this.state.recentJob ? (*/}
        {/*  <JobInfo recentJob={this.state.recentJob} />*/}
        {/*) : (*/}
        {/*  <></>*/}
        {/*)}*/}
        <div style={{paddingLeft: '20px'}}>
          Need to add more inspectors? Search here
        </div>
        <div style={{marginTop: '-10px'}}>
          <SearchBar domain={props.domain} setDomain={props.setDomain} borderColor="#26a69a"/>
        </div>
        { getAddInspectorWarning() }
        <div style={{ marginBottom: "390px" }}></div>
      </div>
    </>
  );
}
