import React, {useEffect, useState} from "react";
import AccountInputs from "../../GeneralComponents/FormInputs/AccountInputs";
import {getReviews, upsertReviews} from "../../../services/service";
import Materialize from "materialize-css/dist/js/materialize.min.js";

export default (props) => {
  const [ reviews, setReviews ] = useState({});
  const [ isEditMode, setIsEditMode ] = useState(false);

  useEffect(() => {
    (
      async () => {
        try {
          const reviews = await getReviews(props.companyId);
          setReviews(reviews.data);
        } catch (e) {
          console.log(e);
        }
      }
    )();
  }, []);

  const editButtonClicked = async () => {
    if(isEditMode) {
      const reviews = await getReviews(props.companyId);
      setReviews(reviews.data);
      setIsEditMode(false);
    } else {
      setIsEditMode(true);
    }
  };

  const getEditButton = () => {
    if(isEditMode) {
      return <button className='btn right housky-primary-bg' onClick={editButtonClicked}>Cancel Update</button>
    } else {
      return <button className='btn right housky-primary-bg' onClick={editButtonClicked}>Update Information</button>
    }
  };

  const update = async () => {
    try {
      setIsEditMode(false);
      await upsertReviews(props.companyId, reviews);
      Materialize.toast({
        html: "Successfully updated reviews setting",
        classes: "rounded",
      });
    } catch (e) {
      Materialize.toast({
        html: "Something went wrong, please try again later",
        classes: "rounded",
      });
      console.log(e);
    }
  };

  const getUpdateButton = () => {
    if(isEditMode) {
      return (
        <div className='row center'>
          <button style={{ height: 'auto' }} onClick={update} className="btn housky-primary-complimentary-bg">Confirm</button>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    let newReviews = { ...reviews };
    newReviews[e.target.id] = e.target.value;
    setReviews(newReviews);
  };

  return (
    <div className='container pt-5 mt-5'>
      { getEditButton() }
      <div className="row pt-5">
        <form className="col s12">
          <h5 style={{marginBottom: "10px"}}>Reviews</h5>
          <h6>Google</h6>
          <div className="row">
            <AccountInputs
              name="Reviews Url"
              size="col s8"
              isDisabled={!isEditMode}
              isRequired={true}
              id="google_reviews_url"
              type="text"
              value={reviews.google_reviews_url || ""}
              handleChange={handleInputChange} />
          </div>
          <h6>Yelp</h6>
          <div className="row">
            <AccountInputs
              name="Reviews Url"
              size="col s8"
              isDisabled={!isEditMode}
              isRequired={true}
              id="yelp_reviews_url"
              type="text"
              value={reviews.yelp_reviews_url || ""}
              handleChange={handleInputChange} />
          </div>
          { getUpdateButton() }
        </form>
      </div>
    </div>
  );
}