import React from "react";
import styles from './ProfilePictureFAQ.module.css';

export default function() {
  return (
    <div className={styles.FAQ_page_container}>
      <div className={styles.FAQ_page_title}>
        <b>How to take a good profile picture with your iPhone</b>
      </div>
      <div className={styles.content}>
        A good profile picture is extremely important in making first impression when customers view your profile on Housky. Here’s the tips that will help you take a good profile picture with a help of friend and a relatively new version of iphone.
      </div>
      <div className={styles.content}>
        1.	Wear your usual work outfit, pick a sunny day but don’t stand directly under the sunlight
      </div>
      <div className={styles.content}>
        2.	Ask a friend to take the photo for you using Portrait mode (available on iphone 7plus or newer), select appropriate lighting option (usually natural light by default will work), make sure you are in focus.
      </div>
      <div className={styles.content}>
        3.	Take a few photos with different expression and angles and select the one you feel best represents yourself
      </div>
      <div className={styles.content}>
        4.	Crop your picture properly when upload the photo on Housky, ensure to show more of your face
      </div>
      <div className={styles.imageContainer}>
        <img className={styles.profilePictureInstruction} src={require('../../../assets/profilePictureFAQ.jpg')} />
      </div>
      <div className={styles.linkContainer}>
        Reference: <a target="_blank" href="https://fixthephoto.com/how-to-take-a-good-profile-picture.html">https://fixthephoto.com/how-to-take-a-good-profile-picture.html</a>
      </div>
    </div>
  )
}