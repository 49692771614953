import React, { useEffect, useState } from "react";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import toUpper from "lodash/toUpper";
import isEmpty from "lodash/isEmpty";
import first from "lodash/first";
import get from "lodash/get";
import { InspectorTab } from "../InspectorTab";
import { getInspectorsByInspectorId } from "../../../../services/service";
import UploadAgreement from "../UploadAgreement";

const Assigned = (props) => {
  const { job } = props;
  const {
    current_status,
    inspectorid,
    signed_pre_inspection_agreement_url,
    pre_inspection_agreement_accepted,
    pre_inspection_agreement_url,
    jobid,
    reportid,
  } = job;
  const [selectedInspector, setSelectedInspector] = useState("");
  const [inspectorOffer, setInspectorOffer] = useState({});
  const [status, setStatus] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    preInspectionAgreementMessage();
  }, [jobid]);

  useEffect(() => {
    !isEmpty(inspectorid) && setInspectorOffer(get(job, `inspectors_offers.${inspectorid}`, {}));
  }, [inspectorid]);

  const getInspectorData = async (inspectorid) => {
    const inspectorInfo = await getInspectorsByInspectorId(inspectorid);
    setSelectedInspector(inspectorInfo);
  };

  const openDoc = (link) => {
    window.open(link, "_blank");
  };

  const preInspectionAgreementMessage = () => {
    let status = "Pre - Inspection";
    let description = "";

    if (isEmpty(pre_inspection_agreement_url)) {
      description = "Waiting for the inspector to provide a pre-inspection agreement";
    }
    if (!isEmpty(pre_inspection_agreement_url) && isEmpty(signed_pre_inspection_agreement_url)) {
      description = "Please Review the pre-inscription Agreement and sign it at least 24 hours prior to the inspection.";
    }
    if (!isEmpty(signed_pre_inspection_agreement_url) && !pre_inspection_agreement_accepted) {
      description = "Waiting for the inspector to confirm your signed agreement.";
    }
    if (!isEmpty(signed_pre_inspection_agreement_url) && !!pre_inspection_agreement_accepted) {
      status = "WIP - Inspection"
      description = "Inspector is making the inspection. As soon as the work is done, the inspector will upload the report.";
    }
    if (!isEmpty(reportid) && !!job['report_status'] && job['report_status'] !== 'COMPLETED') {
      status = "Working on report"
      description = "Inspector is working on the report. This will be available soon.";
    }
    if (!isEmpty(reportid) && !!job['report_status'] && job['report_status'] === 'COMPLETED') {
      status = "Report uploaded"
      description = "Report is available, please click the Release Escrow button above to pay the inspector and then you will be able to download the report.";
    }

    setStatus(status);
    setDescription(description);
  }

  useEffect(() => {
    !isEmpty(inspectorid) && getInspectorData(inspectorid);
  }, [inspectorid]);

  return (
    <div className="panel pt2">
      <>
        <div className="col m12 l9 jobBoard">
          <div className="col s12 m6">
            <div className="row title">
              <div>House Information</div>
            </div>
            <div className="row">
              <div className="col s6">
                <div> {get(job, "house_type")} </div>
                <div>
                  {" "}
                  {get(job, "bedroom")} bedroom , {get(job, "bathroom")}{" "}
                  bathroom
                </div>
              </div>
              <div className="col s12 m6">
                <div> Parking {get(job, "parking")} </div>
                <div>
                  {" "}
                  {get(job, "foundation") !== "Not Applicable" &&
                    get(job, "foundation")}{" "}
                </div>
                <div>
                  {" "}
                  {get(job, "attic") !== "Not Applicable" &&
                    get(job, "attic")}{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col s12 m6 title">
            <div>
              <span>
                {" "}
                <span className="title black-text">Final Price: </span> ${" "}
                {get(inspectorOffer, "cost", "")} (paid in escrow)
              </span>
            </div>
            <div>
              <span>
                {" "}
                <span className="title black-text">Inspection Date: </span>
                {get(inspectorOffer, "date", "")}
              </span>
            </div>
            <div>
              <span>
                {" "}
                <span className="title black-text">Time: </span>
                {get(inspectorOffer, "time", "")}
              </span>
            </div>
          </div>
        </div>
        <div className="col m12 l3 statusDescription">
          <div>
            <span className="boldText">
              Status: <span className="mainText"> {status}</span>
            </span>
          </div>
          <div className="blockText">
            {description}
          </div>
          <div className="row">
            <div className="col s12 mt-3">
              <button
                disabled={isEmpty(pre_inspection_agreement_url)}
                className="btn light-blue capitalize"
                onClick={() =>
                  openDoc(get(job, "pre_inspection_agreement_url"))
                }
              >
                Pre-inspection Agreement
              </button>
            </div>
          </div>
        </div>

        {
          !isEmpty(pre_inspection_agreement_url) &&
          !pre_inspection_agreement_accepted &&
          (
            <div className="col s12">
              <div className="uploadBox">
                <UploadAgreement
                  jobid={jobid}
                  doc={signed_pre_inspection_agreement_url}
                />
              </div>
            </div>
          )
        }

        <div className="col s12 adjustPading">
          <div className="sectionChat">
            <InspectorTab
              inspector={selectedInspector}
              current_status={current_status}
              priceRange={""}
              userId={get(job, "userid", "")}
              inspectorsOffers={get(job, "inspectors_offers", "")}
              messageId={get(inspectorOffer, "message_id", "")}
            />
          </div>
        </div>
      </>
    </div>
  );
};

export default Assigned;
