import React from "react";

const UserRecordsRow = props => {
  const {
    user_recordid,
    first_name,
    last_name,
    email,
    initial_amount,
    initial_paid,
    total_amount,
    total_paid
  } = props.userRecordsData;
  const clickEvent = props.clickEvent;
  const firstName = `${first_name[0]}${first_name.slice(1).toLowerCase()}`;
  const lastName = `${last_name[0]}${last_name.slice(1).toLowerCase()}`;
  const fullName = `${firstName} ${lastName}`;
  return (
    <tr style={{ cursor: "pointer" }} id={user_recordid} onClick={clickEvent}>
      <td>{fullName}</td>
      <td>{email.toLowerCase()}</td>
      <td>${initial_amount ? initial_amount : "0"}</td>
      <td>{initial_paid ? "Yes" : "No"}</td>
      <td>${total_amount ? total_amount : "0"}</td>
      <td>{total_paid ? "Yes" : "No"}</td>
    </tr>
  );
};

export default UserRecordsRow;
