import React from 'react'

export default (props) => {
  return <div className='container pt-5 mt-5'>
    <div className="row pt-5 mt-5">
      <div className="col s12">
        <h5>Account Management</h5>
        {props.account_active ?
          <h6>Your account is currently <b style={{color: "green"}}>ACTIVE</b>, toggling the switch below will remove you from Housky Scheduling platform, and you <b>WILL NOT</b> receive jobs! </h6>
          :
          <h6>Your account is currently <b style={{color: "red"}}>INACTIVE</b>, toggling the switch below will add you to the Housky Scheduling platform, and you <b>WILL BE ABLE</b> to receive jobs! </h6>}
        <div className="col s12">
          {
            props.account_active ?
              <button onClick={props.handleManagement} className='btn-small red'>Deactivate</button>
              :
              <button onClick={props.handleManagement} className='btn-small green'>Activate</button>
          }
          <p>By keeping the account active, you agree to the <a href="https://housky.io/terms/tos" target="_blank" rel="noopener noreferrer">Terms and Service of using Housky.</a></p>
        </div>
      </div>
    </div>
  </div>
}