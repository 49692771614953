import React from "react";
import "./jobsInfoRow.css";

const InspectorList = (props) => {
  const {
    i_area_code,
    i_cell_number,
    i_email,
    inspector_first_name,
    inspector_last_name,
    inspectorid,
  } = props.e;
  return (
    <div
      className={`row mb-0 adm-job-inspector-item ${
        props.current === inspectorid ? "adm-job-current-inspector" : ""
      }`}
    >
      <div className="col s12">
        <p className="adm-job-name">
          {inspector_first_name?.toLowerCase()}{" "}
          {inspector_last_name?.toLowerCase()}
        </p>
        <p className="my-0">
          <span className="adm-job-header">Email:</span> {i_email}
        </p>
        <p className="my-0">
          <span className="adm-job-header">Cell Number:</span> {i_area_code} -{" "}
          {i_cell_number}
        </p>
      </div>
    </div>
  );
};

export default (props) => {
  const {
    title,
    jobid,
    first_name,
    last_name,
    email,
    area_code,
    cell_number,
    inspection_cost,
    paid,
    restriction_inspector,
    inspectorOnWindow,
    inspectorid,
  } = props.e;
  return (
    <div className="col s12 adm-jobs-container">
      <span className="adm-job-index">{props.index}</span>
      <div className="row mb-0">
        <div className="col s12 m6">
          <div className="row mb-0">
            <div className="col s12">
              <p className="adm-job-title mb-0">{title}</p>
              <p className="adm-job-jobid my-0">{jobid}</p>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <p className="adm-job-name">
                {first_name.toLowerCase()} {last_name.toLowerCase()}
              </p>
              <p className="my-0">
                <span className="adm-job-header">Email:</span> {email}
              </p>
              <p className="my-0">
                <span className="adm-job-header">Cell Number:</span> {area_code}{" "}
                - {cell_number}
              </p>
              <p className="my-0">
                <span className="adm-job-header">Inspection Cost:</span> $
                {inspection_cost}
              </p>
              <p className="my-0 adm-job-header">
                Paid Status: {paid ? "PAID" : "NOT PAID"}
              </p>
            </div>
          </div>
        </div>
        <div className="col s12 m6 adm-job-restriction-list">
          {inspectorOnWindow ? (
            restriction_inspector?.map((e, i) => {
              return (
                <InspectorList
                  key={i}
                  e={e}
                  current={inspectorOnWindow.inspectorid}
                />
              );
            })
          ) : (
            <InspectorList e={props.e} current={inspectorid} />
          )}
        </div>
      </div>
    </div>
  );
};
