import React from "react";
import { cancelJob, cancelJobByUser } from "../../../services/service";
import { inspectorApp as InspectorFirebase } from "../../../firebase";
import { app as UserFirebase } from "../../../firebase";

const CancelJob = props => {
  return (
    <div className={`col s12 m7 ` + props.containerClass}>
      <h6 className="center-align">Are you sure you want to cancel this job?</h6>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="btn-small"
          onClick={e => {
            handleCancelJob(props.jobId, props.setNewCurrentStatus, props.removeCancelJob);
          }}
        >
          Yes
        </button>
        <button className="btn-small ml-3" onClick={props.removeCancelJob}>
          No
        </button>
      </div>
    </div>
  );
};

const handleCancelJob = (jobid, setNewCurrentStatus, removeCancelJob) => {
  if (InspectorFirebase.auth().currentUser) {
    InspectorFirebase.auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        return cancelJob(jobid, idToken);
      })
      .then(() => {
        setNewCurrentStatus();
        removeCancelJob();
      });
  }
  if (UserFirebase.auth().currentUser) {
    UserFirebase.auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        return cancelJobByUser(jobid, idToken);
      })
      .then(() => {
        setNewCurrentStatus();
        removeCancelJob();
      });
  }
};

export default CancelJob;
