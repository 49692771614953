import React, {useState} from "react";
import moment from "moment";
import _ from "lodash";

export default function({ inspectorList, setFilteredInspectorList }) {

  const [query, setQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const search = () => {
    let filteredInspectorByQuery = [];
    let filteredInspectorByDate = [];
    if(query) {
      inspectorList.forEach(inspector => {
        if (
          inspector.inspector_first_name.toLowerCase().includes(query.toLowerCase()) ||
          inspector.inspector_last_name.toLowerCase().includes(query.toLowerCase()) ||
          inspector.i_email?.toLowerCase().includes(query.toLowerCase()) ||
          inspector.comp_name?.toLowerCase().includes(query.toLowerCase())
        ) {
          filteredInspectorByQuery.push(inspector);
        }
      });
    } else {
      filteredInspectorByQuery = inspectorList;
    }

    if(startDate && endDate) {
      inspectorList.forEach(inspector => {
        const registrationDate = moment(inspector.registration_date).format('YYYY-MM-DD')
        if (moment(registrationDate).isBetween(startDate, endDate, undefined, '[]')) {
          filteredInspectorByDate.push(inspector);
        }
      });
    } else {
      filteredInspectorByDate = inspectorList;
    }

    // get a intersection among the three filtered results to get the final result
    const filteredJobs = _.intersectionWith(filteredInspectorByQuery, filteredInspectorByDate, (x, y) => x.inspectorid === y.inspectorid );
    setFilteredInspectorList(filteredJobs);
  };

  const clearFilter = () => {
    setStartDate('');
    setEndDate('');
    setQuery('');
    setFilteredInspectorList(inspectorList);
  };

  const closeFilter = () => {
    clearFilter();
    setShowFilter(!showFilter);
  };

  return (
    <div className="row pb-0 mb-0">
      <div className='row col s12 m12 l2 p-0'>
        <button
          onClick={closeFilter}
          className="btn-small m-1"
        >
          {showFilter ? "CLose filter" : "Quick filter"}
        </button>
        { showFilter &&
        <button
          className="btn-small m-1"
          onClick={() => {
            clearFilter();
          }}
        >
          Clear filter
        </button>
        }
      </div>
      { showFilter &&
      <React.Fragment>
        <div className="row col s12 m4 l3 animate fadeInDown half-one">
          <div className="input-field col s12">
            <input
              id="query"
              placeholder='Email, name or company'
              value={query}
              type="text"
              onChange={ e => { setQuery(e.target.value) }}
            />
            <label className='active' htmlFor="query">Search</label>
          </div>
          <div className="col s12">
            <button
              className="btn-small m-1"
              onClick={search}
            >
              Search
            </button>
          </div>
        </div>
        <div className='p-0 col s12 m4 l4 animate fadeInDown half-one'>
          <div className="input-field col s6">
            <input
              type="date"
              name="startDate"
              value={startDate}
              onChange={ e => {setStartDate(e.target.value) }}
            />
            <label htmlFor="startDate">From</label>
          </div>
          <div className="input-field col s6">
            <input
              type="date"
              name="endDate"
              value={endDate}
              onChange={e => { setEndDate(e.target.value) }}
            />
            <label htmlFor="endDate">To</label>
          </div>
        </div>
      </React.Fragment>
      }
    </div>
  );
}