import "./JobHistory.css";
import AuthContext from "../../../contexts/auth";
import InspectorFilter from "../InspectorFilter/InspectorFilter";
import { JobsHistoryContent } from "../PagesContent";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import React, {useContext, useEffect, useRef, useState} from "react";
import SelectedJob from "../SelectedJob/SelectedJob";
import Table from "../../GeneralComponents/Table/Table";
import TableRow from "../../GeneralComponents/TableRow/TableRow";
import { inspectorApp as firebase } from "../../../firebase";
import {getInspectorIdByToken, getJobHistory} from "../../../services/service";
import DashboardChart from '../Chart/Chart';
import {generateMockData} from "../../../utils/mockData";
import {calculateInspectorPayout} from "../../../utils/utils";
import get from "lodash/get"

export default function JobHistory ({ mockMode }) {
  useContext(AuthContext);

  const [jobHistory, setJobHistory] = useState([]);
  const [idToken, setIdToken] = useState();
  const [jobSelected, setJobSelected] = useState({});
  const [selectedRowId, setSelectedRowId] = useState('');
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [calculatorParams, setCalculatorParams] = useState([]);
  const selectedJobRef = useRef();

  let { tableHeaders } = JobsHistoryContent;

  useEffect(() => {
    (
        async() => {
          window.scrollTo(0, 0);
          window.gtag('event', 'page_view', {
            page_location: 'https://inspector.housky.io/history',
              page_title: 'JobHistory',

          });
          try {
            let jobHistory;
            let inspector;
            if(mockMode) {
              jobHistory = generateMockData();
            }else {
              const idToken = await firebase.auth().currentUser.getIdToken(true);
              jobHistory = await getJobHistory(idToken);
              inspector = await getInspectorIdByToken(idToken)
            }
            setJobSelected({});
            setIdToken(idToken);
            setJobHistory(jobHistory);
            setFilteredJobs(jobHistory);
            let calculatorParams = get(inspector, "calculator_params", {})
            setCalculatorParams(calculatorParams)
          } catch (e) {
            console.log(e);
          }
        }
    )();
  }, [mockMode])

  const selectJob = (e) => {
    const newSelectedRowId = e.target.parentNode.id;
    if(newSelectedRowId.toString() === selectedRowId) {
      document.getElementById(selectedRowId).classList.remove("active-row");
      setJobSelected({});
      setSelectedRowId('');
    } else {
      if (jobSelected.jobid) {
        document.getElementById(selectedRowId).classList.remove("active-row");
        document.getElementById(newSelectedRowId).classList.add("active-row");
        setSelectedRowId(newSelectedRowId);
      } else {
        document.getElementById(newSelectedRowId).classList.add("active-row");
        setSelectedRowId(newSelectedRowId);
      }
      for (let job of jobHistory) {
        if (job.jobid === newSelectedRowId) {
          setJobSelected(job);
        }
      }
    }

    selectedJobRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start"
    });
  };

  const setFilteredJobsState = (filteredJobs) => {
    if (filteredJobs.length === 0) {
      Materialize.toast({ html: "No Results", classes: "rounded" });
      return;
    }
    if (jobSelected.jobid) {
      document.getElementById(selectedRowId).classList.remove("active-row");
    }
    setFilteredJobs(filteredJobs);
    setJobSelected({});
    setSelectedRowId('');
  };

  const closeJobDetail = () => {
      document.getElementById(selectedRowId).classList.remove("active-row");
      setJobSelected({});
  }

  const getTotalEarning = () => {
    return filteredJobs.reduce((acc, job) => {
      const inspector_offer = !!job.inspector_offer ? job.inspector_offer : {};
      const cost = !!inspector_offer.cost ? inspector_offer.cost : 0;
      acc += calculateInspectorPayout(cost)
      return acc;
    }, 0);
  };

  const getJobDetailsCard = () => {
    if(jobSelected.jobid) {
      return <SelectedJob {...jobSelected} closeJobDetail={closeJobDetail} />;
    } else {
      return (
        <div style={{color: '#104b69', textAlign: 'center'}}>
          <div>Click on a job to see details</div>
          <div>Scroll down to see full job history</div>
        </div>
      );
    }
  };

  return (
      <div style={{ minHeight: "90vh" }} className="pb-5 mt3 pt-3 pl-5 pr-5">
        <InspectorFilter
            jobsList={jobHistory}
            setFilteredJobs={setFilteredJobsState}
            resetFilteredJobs={() => {
              setFilteredJobs(jobHistory);
            }}
        />
        <div className="row" style={{minHeight: '500px'}}>
            <div className="col s12">
                <DashboardChart filteredJobs={filteredJobs} />
                <div style={{padding: '20px'}}>
                    <div>
                        <b>Total earned:</b> {`$${getTotalEarning()}`}
                    </div>
                    <div>
                        <b>Total jobs:</b> { filteredJobs.length }
                    </div>
                </div>
            </div>
        </div>
        <div className="col s12">
          <Table
              tableClass="striped centered hightlight"
              tableHeaders={tableHeaders}
              emptyTableMessage={"No jobs completed yet!"}
          >
            {
                filteredJobs.map((e) => (
                    <TableRow
                        inspectorLat={filteredJobs[0].ins_latitude}
                        inspectorLng={filteredJobs[0].ins_longitude}
                        tableHeaders={tableHeaders}
                        key={e.jobid}
                        jobData={e}
                        clickEvent={selectJob}
                    />
                ))
            }
          </Table>
        </div>
        <div className="row" ref={selectedJobRef}>
          <div className="col s12">
            {jobSelected.jobid && <SelectedJob {...jobSelected} closeJobDetail={closeJobDetail} calculatorParams={calculatorParams} />}
          </div>
        </div>
        <div style={{ marginTop: "60px", marginBottom: "100px" }} />
      </div>
  );
}
