import React, {Fragment, useEffect, useState} from "react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { getReviews } from "../../../services/service";
import { capitalize } from "lodash";

const InspectorCard = (props) => {
  const { inspector } = props;
  const [starsArray, setStarsArray] = useState([]);
  const [review, setReview] = useState("0.0");
  const defaultPhotoInspector =
    "https://firebasestorage.googleapis.com/v0/b/housky-chat.appspot.com/o/inspectorAvatar.jpeg?alt=media&token=2c792fad-d542-41f1-ab67-51213776d4a9";

  const starsRender = (stars) => {
    const array = [];
    for (let index = 0; index < stars; index++) {
      array.push("");
    }
    setStarsArray(array);
  };

  const openDoc = (link) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    setStarsArray([]);
    !isEmpty(inspector) && getCompanyReview(inspector.companyid);
  }, [inspector]);

  const getCompanyReview = async (companyId) => {
    try {
      const { data } = await getReviews(companyId);
      if (!isEmpty(data)) {
        const stars = get(data, "google_reviews_score", "0.0");
        setReview(stars);
        starsRender(parseInt(stars));
      } else {
        setReview("0.0");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="inspectorProfileCard">
        <div className="mainText">INSPECTOR</div>
        <div className="phContainer">
          <div
            className="profileBox"
            style={{
              backgroundImage: `url(${
                !isEmpty(inspector.photoid)
                  ? inspector.photoid
                  : defaultPhotoInspector
              })`,
            }}
          ></div>
        </div>

        <div className="mainText">
          {capitalize(inspector.inspector_first_name)}{" "}
          {capitalize(inspector.inspector_last_name)}
        </div>
        <div className="mainText">{capitalize(inspector.comp_name)}</div>
      </div>
      <div className="raitingBox">
        <span className="raitingDetail">
          <span>{review}</span>
          <span className="starsRow">
            {!isEmpty(starsArray) ? starsArray.map((star, index) => <Fragment key={index}>★</Fragment>) : <>☆</>}
          </span>
        </span>
        <div className="inspectorActions">
          <button
            className="btn blue-text text-darken-2 blue lighten-5 btn waves-light"
            disabled={!isEmpty(inspector.sample_report) ? false : true}
            onClick={() => openDoc(inspector.sample_report)}
          >
            Sample Report
          </button>

          <button
            className="btn blue-text text-darken-2 blue lighten-5 btn waves-light mt2"
            disabled={!isEmpty(inspector.personal_tos) ? false : true}
            onClick={() => openDoc(inspector.personal_tos)}
          >
            Terms & Agreement
          </button>
        </div>
      </div>
    </div>
  );
};

export default InspectorCard;
