import React, {useEffect, useState} from "react";
import { Inspector as InspectorFirebase } from "../../../utils/firebase/firebase";
import { getPendingJobs } from "../../../services/service";
import PendingJobsRow from "../PendingJobsRow/PendingJobsRow";

export default function() {

	const [ pendingJobs, setPendingJobs ] = useState([]);
	const [ idToken, setIdToken ] = useState('');

	useEffect(() => {
		(
			async () => {
				const idToken = await InspectorFirebase.getIdToken();
				const res = await getPendingJobs(idToken);
				const pendingJobs = res.data;
				pendingJobs.sort((a, b) => {
					return (new Date(b.createdat)).getTime() - (new Date(a.createdat)).getTime()
				})
				setIdToken(idToken);
				setPendingJobs(pendingJobs);
			}
		)();
	}, []);

	return (
		<div className="container">
			<div className="row" style={{ marginBottom: "80px" }}>
				{pendingJobs.map((pendingJob, i) => {
					return <PendingJobsRow key={i} pendingJob={pendingJob} idToken={idToken} />;
				})}
			</div>
		</div>
	);
}
