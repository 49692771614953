import React from "react";
import Materialize from "materialize-css/dist/js/materialize.min.js";

class Parking extends React.Component {
  componentDidMount() {
    let elem = document.querySelectorAll("select");
    Materialize.FormSelect.init(elem, {
      classes: ""
    });
  }
  render() {
    const { parking } = this.props;
    return (
      <div className="input-field col s8">
        <select
          id="parking"
          name="parking"
          value={parking}
          onChange={this.props.handleChange}
        >
          <option value="Select Parking">Select Parking</option>
          <option value="Driveway">Driveway</option>
          <option value="Garage Parking">Garage</option>
          <option value="Parking Lot">Parking Lot</option>
          <option value="Street Parking">Street</option>
        </select>

        <label className={parking ? "active" : ""} htmlFor="parking">
          Parking
        </label>
      </div>
    );
  }
}

export default Parking;
