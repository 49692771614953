import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import first from "lodash/first";
import get from "lodash/get";
import { getInfoByInspectorId } from "../../../../services/service";
import { PendingInspectorTab } from "../PendingInspectorTab";
import axios from "axios";
import { capitalize } from "lodash";

const Pending = (props) => {
  const { job } = props;
  const [inspectorsList, setInspectorsList] = useState([]);
  const [selectedInspector, setSelectedInspector] = useState("");
  const defaultPhotoInspector =
    "https://firebasestorage.googleapis.com/v0/b/housky-chat.appspot.com/o/inspectorAvatar.jpeg?alt=media&token=2c792fad-d542-41f1-ab67-51213776d4a9";

  useEffect(() => {
    setInspectorsList([]);
    !isEmpty(job) && listInspectors();
  }, [job.jobid]);

  const getInspectorsInfo = async () => {
    const { restriction_inspector } = job;
    const requests = [];
    restriction_inspector.forEach(async (inspector) => {
      requests.push(getInfoByInspectorId(inspector));
    });
    let inspectorsInfoList = [];
    await axios.all(requests).then((data) => {
      inspectorsInfoList = data.map((response) => {
        return response.data;
      });
    });
    setInspectorsList(inspectorsInfoList);
  };

  const listInspectors = () => {
    const { restriction_inspector } = job;
    setInspectorsList([]);
    if (!isEmpty(restriction_inspector)) {
      getInspectorsInfo();
    }
  };

  useEffect(() => {
    !isEmpty(inspectorsList) && setSelectedInspector(first(inspectorsList));
  }, [inspectorsList]);

  return (
    <div className="panel">
      <>
        <div className="col s12 m8 l9 jobBoard">
          <div className="col s12 m6">
            <div className="row title">House Information</div>
            <div className="row">
              <div className="col m6 s12">
                <div> {get(job, "house_type")} </div>
                <div>
                  {" "}
                  {get(job, "bedroom")} bedroom{", "} {get(job, "bathroom")}{" "}
                  bathroom
                </div>
              </div>
              <div className="col m6 s12">
                <div>
                  Parking{" "}
                  {get(job, "parking") !== "Not Applicable" &&
                    get(job, "parking")}
                </div>
                <div>
                  {" "}
                  {get(job, "foundation") !== "Not Applicable" &&
                    get(job, "foundation")}{" "}
                </div>
                <div>
                  {" "}
                  {get(job, "attic") !== "Not Applicable" &&
                    get(job, "attic")}{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col s12 m6 title">
            <div className="row title">Selected Dates</div>
            {!isEmpty(get(job, "eventinfo", [])) && (
              <div className="datesBox">
                {get(job, "eventinfo").map((event, index) => (
                  <div key={index} className="dateSelecteds">{event.date}</div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="col s12 m4 l3 statusDescription">
          <div>
            <span className="boldText">
              Status: <span className="mainText"> Inspector Negotiation </span>
            </span>
          </div>
          <div className="blockText">
            You have successfully submitted your request. Your selected
            inspector will review the request, communicate with you and finalize
            the price.
          </div>
        </div>
        {!isEmpty(inspectorsList) ? (
          <>
            <div className="inspectorsTabs">
              {inspectorsList.map((inspector, index) => (
                <button
                  key={index}
                  className="tabButton"
                  onClick={() => setSelectedInspector(inspector)}
                >
                  <div
                    className={`tabItem ${
                      inspector.inspectorid === selectedInspector.inspectorid &&
                      "highlightTab"
                    }`}
                  >
                    <div
                      className="inspectorPhoto"
                      style={{
                        backgroundImage: `url(${
                          !isEmpty(inspector.photoid)
                            ? inspector.photoid
                            : defaultPhotoInspector
                        })`,
                      }}
                    />
                    <span className="inspectorName">
                      {capitalize(inspector.inspector_first_name)}{" "}
                      <span className="lastNameBox">
                        {capitalize(inspector.inspector_last_name)}
                      </span>
                    </span>
                  </div>
                </button>
              ))}
            </div>
            <PendingInspectorTab
              job={job}
              inspector={selectedInspector}
              status="Pending"
              userId={get(job, "userid", "")}
              priceRange={get(job, "price_range", "")}
              inspectorsOffers={get(job, "inspectors_offers", "")}
              inspectionCost={get(job, "inspection_cost", "")}
              inspectionDate={get(job, "inspection_date", "")}
            />
          </>
        ) : (
          <div className="inspectorsTabs">No inspectors listed</div>
        )}
      </>
    </div>
  );
};

export default Pending;
