import React from "react";
import { withRouter, Redirect, Link } from "react-router-dom";
import { inspectorApp as firebase } from "../../../firebase";
import { inspectorHasStripe, authorizeStripe } from "../../../services/service";
import queryString from "query-string";

class StripeInspectorConnect extends React.Component {
  state = {
    idToken: "",
    inspectorHasStripe: false,
    stripeConnectRunning: false,
    stripeToken: "",
  };

  componentDidMount() {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        this.setState({ idToken });
        return inspectorHasStripe(idToken);
      })
      .then((data) => {
        this.setState({ inspectorHasStripe: data.inspectorHasStripe });
        let urlQueryParams = queryString.parse(this.props.location.search);
        this.setState({ stripeToken: urlQueryParams.code && urlQueryParams.code });
        if (!data.inspectorHasStripe) {
          if (urlQueryParams.code) {
            this.setState({ stripeConnectRunning: true });
            authorizeStripe(urlQueryParams.code, this.state.idToken).then((res) => {
              res.inspectorHasStripe && this.setState({ inspectorHasStripe, stripeConnectRunning: false });
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  checkURLForRedirect = () => {
    let urlQueryParams = queryString.parse(this.props.location.search);
    if (!urlQueryParams.code || this.state.inspectorHasStripe) {
      return <Redirect to="/account" />;
    }
  };

  render() {
    const { inspectorHasStripe, stripeConnectRunning } = this.state;
    return (
      <div style={{ minHeight: "500px" }} className="container mt-5 pt-5">
        {inspectorHasStripe && (
          <div>
            <h5>Your account has been connected to Stripe</h5>{" "}
            <Link to="/account" className="btn btn-large">
              Go back to account
            </Link>
          </div>
        )}
        {stripeConnectRunning && <h5>Setting up your stripe account... DO NOT CLOSE THIS PAGE!</h5>}
      </div>
    );
  }
}

export default withRouter(StripeInspectorConnect);
