import React from "react";
import styles from './InspectorSchoolsPage.module.css';

export default function() {
	return (
		<div className={styles.container}>
			<div className={styles.title}>
				New Jersey Inspector Schools
			</div>
			<div className={styles.school}>
				<div>
					National Institute of Home Inspection at <a href="https://inspectorschool.net/" target="_blank">https://inspectorschool.net</a>
				</div>
				<div className={styles.paragraph}>
					{`
						From their website: "The National Institute Of Home Inspection realizes that the home inspection industry is an ever growing and changing industry, and we change and update our course material to stay ahead of the curve. 
						NIHI NJ home inspector certification courses will cover topics such as, 
						Building Your Inspection Business,  Marketing, Report writing and many other areas that promote inspector success, while reducing inspector risk."
					`}
				</div>
			</div>
		</div>
	);
}