import React from "react";
import PrivateComponent from "../../../components/PrivateComponent";
import PromotionsComponent from "../../../components/AdminDomain/Promotions/Promotions";

class Promotions extends React.Component {
  render() {
    return (
      <PrivateComponent>
        <PromotionsComponent />
      </PrivateComponent>
    );
  }
}

export default Promotions;
