import InspectorRecordDetails from "../InspectorRecordDetails/InspectorRecordDetails";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import React from "react";
import { updateInspectorRecordsPayment } from "../../../services/service";

class SelectedInspectorRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amountToUpdate: "",
    };
  }

  componentDidMount() {
    let elem = document.querySelectorAll(".collapsible");
    Materialize.Collapsible.init(elem, {
      classes: "",
    });
  }

  updateRecordWithPaidAmount = (e) => {
    e.preventDefault();
    const recordToUpdate = {
      amount: this.state.amountToUpdate,
      inspector_recordid: this.props.inspectorRecord.inspector_recordid,
    };
    updateInspectorRecordsPayment(this.props.idToken, recordToUpdate)
      .then((res) => {
        this.props.getInspectorRecords(this.props.inspectorRecord.inspector_recordid);
        this.setState({ amountToUpdate: "" });
      })
      .catch((err) => {
        Materialize.toast({
          html: "Could not update record",
          classes: "rounded",
        });
      });
  };

  render() {
    const {
      reportid,
      inspector_first_name,
      inspector_last_name,
      user_first_name,
      user_last_name,
      paid,
      amount,
    } = this.props.inspectorRecord;
    const { handleSeeReport } = this.props;
    const inspectorFirstName = `${inspector_first_name[0]}${inspector_first_name.slice(1).toLowerCase()}`;
    const inspectorLastName = `${inspector_last_name[0]}${inspector_last_name.slice(1).toLowerCase()}`;
    const inspectorFullName = `${inspectorFirstName} ${inspectorLastName}`;
    const userFirstName = `${user_first_name[0]}${user_first_name.slice(1).toLowerCase()}`;
    const userLastName = `${user_last_name[0]}${user_last_name.slice(1).toLowerCase()}`;
    const userFullName = `${userFirstName} ${userLastName}`;
    return (
      <div className="container card-panel grey lighten-5 mb-5 pb-5">
        <div className="section">
          <h5>{inspectorFullName}</h5>
          {reportid ? (
            <h6>
              <button className="btn-small" onClick={handleSeeReport}>
                See Report
              </button>
            </h6>
          ) : (
            <label>Inspector has not completed this job yet</label>
          )}
          {paid ? (
            <p>Amount paid to inspector ${Number.parseFloat(amount).toFixed(2)}</p>
          ) : (
            <form>
              <span>Amount paid to inspector: </span>
              {"$"}
              <div className="input-field inline">
                <input
                  disabled={!reportid ? true : false}
                  name="amountToUpdate"
                  type="number"
                  min="0.01"
                  step="0.01"
                  value={this.state.amountToUpdate}
                  onChange={(e) => {
                    let { name, value } = e.target;
                    this.setState({ [name]: value });
                  }}
                />
                <label>Amount</label>
              </div>
              <div>
                <button onClick={this.updateRecordWithPaidAmount} className={`btn ${reportid ? "" : "disabled"}`}>
                  Pay
                </button>
              </div>
            </form>
          )}
        </div>
        <div className="divider"></div>
        <div>
          <InspectorRecordDetails
            selectedInspectorRecord={this.props.inspectorRecord}
            housePrices={this.state.housePrices}
            houseTypes={this.state.houseTypes}
          />
        </div>
      </div>
    );
  }
}

export default SelectedInspectorRecord;
