import React from "react";
import AuthContext from "../../../contexts/auth";
import { inspectorApp as firebase } from "../../../firebase";
import "firebase/storage";
import { getAllUserRecords } from "../../../services/service";
import UserRecordsRow from "../UserRecordsRow/UserRecordsRow";

class UserRecords extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      idToken: null,
      allUserRecords: [],
      selectedUserRecord: {},
      filteredRecords: []
    };
  }

  handleFilter = e => {
    const { allUserRecords } = this.state;
    let filterType = e.target.name;
    if (filterType === "initial_not_paid") {
      this.setState({
        filteredRecords: allUserRecords.filter(e => !e.initial_paid)
      });
    } else if (filterType === "initial_paid") {
      this.setState({
        filteredRecords: allUserRecords.filter(e => e.initial_paid)
      });
    } else if (filterType === "total_not_paid") {
      this.setState({
        filteredRecords: allUserRecords.filter(e => !e.total_paid)
      });
    } else if (filterType === "total_paid") {
      this.setState({
        filteredRecords: allUserRecords.filter(e => e.total_paid)
      });
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        this.setState({ idToken });
        return getAllUserRecords(idToken);
      })
      .then(data => {
        let userRecords = [
          {
            user_recordid: "2",
            initial_amount: 200.0,
            initial_paid: true,
            total_amount: null,
            total_paid: false,
            first_name: "user 1",
            last_name: "l_user 1",
            email: "email1"
          },
          {
            user_recordid: "3",
            initial_amount: 300,
            initial_paid: true,
            total_amount: null,
            total_paid: false,
            first_name: "user 1",
            last_name: "l_user 1",
            email: "email1"
          },
          {
            user_recordid: "4",
            initial_amount: 200,
            initial_paid: true,
            total_amount: null,
            total_paid: false,
            first_name: "user 1",
            last_name: "l_user 1",
            email: "email1"
          },
          {
            user_recordid: "5",
            initial_amount: null,
            initial_paid: false,
            total_amount: null,
            total_paid: false,
            first_name: "user 1",
            last_name: "l_user 1",
            email: "email1"
          },
          {
            user_recordid: "6",
            initial_amount: null,
            initial_paid: false,
            total_amount: null,
            total_paid: false,
            first_name: "user 1",
            last_name: "l_user 1",
            email: "email1"
          },
          {
            user_recordid: "7",
            initial_amount: 200,
            initial_paid: true,
            total_amount: 500,
            total_paid: true,
            first_name: "user 1",
            last_name: "l_user 1",
            email: "email1"
          },
          {
            user_recordid: "8",
            initial_amount: 200,
            initial_paid: true,
            total_amount: 500,
            total_paid: true,
            first_name: "user 1",
            last_name: "l_user 1",
            email: "email1"
          },
          {
            user_recordid: "9",
            initial_amount: 200,
            initial_paid: true,
            total_amount: 500,
            total_paid: true,
            first_name: "user 1",
            last_name: "l_user 1",
            email: "email1"
          },
          {
            user_recordid: "10",
            initial_amount: 200,
            initial_paid: true,
            total_amount: 500,
            total_paid: true,
            first_name: "user 1",
            last_name: "l_user 1",
            email: "email1"
          },
          {
            user_recordid: "11",
            initial_amount: 200,
            initial_paid: true,
            total_amount: 500,
            total_paid: true,
            first_name: "user 1",
            last_name: "l_user 1",
            email: "email1"
          }
        ];
        this.setState({ allUserRecords: [...data, ...userRecords] });
      })
      .catch(err => {
        console.log(err.request.responseURL);
        console.log(err.message);
      });
  }

  render() {
    console.log(this.state);
    return (
      <div style={{ minHeight: "95vh" }} className="pl-5 pr-5">
        <div className="mt-5 pt-5">
          <div
            style={{ display: "flex", justifyContent: "space-around" }}
            className="container"
          >
            <div className="valign-wrapper">
              <span>Filter By: </span>
            </div>
            <button
              className="btn-flat"
              onClick={this.handleFilter}
              name="initial_not_paid"
            >
              Initial Not Paid
            </button>
            <button
              className="btn-flat"
              onClick={this.handleFilter}
              name="initial_paid"
            >
              Initial Paid
            </button>
            <button
              className="btn-flat"
              onClick={this.handleFilter}
              name="total_not_paid"
            >
              Total Not Paid
            </button>
            <button
              className="btn-flat"
              onClick={this.handleFilter}
              name="total_paid"
            >
              Total Paid
            </button>
          </div>
          <div
            style={{ maxHeight: "375px", overflow: "auto" }}
            className="card-panel grey lighten-5"
          >
            <table className="striped centered highlight mb-5">
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>User Email</th>
                  <th>Initial Amount</th>
                  <th>Initial Amount Paid</th>
                  <th>Total Amount</th>
                  <th>Total Amount Paid</th>
                </tr>
              </thead>
              <tbody>
                {this.state.filteredRecords.length > 0 ? (
                  this.state.filteredRecords.map(filteredRecord => {
                    return (
                      <UserRecordsRow
                        key={filteredRecord.user_recordid}
                        userRecordsData={filteredRecord}
                        clickEvent={this.handleSelectUserRecord}
                      />
                    );
                  })
                ) : this.state.allUserRecords.length > 0 ? (
                  this.state.allUserRecords.map(e => {
                    return (
                      <UserRecordsRow
                        key={e.user_recordid}
                        userRecordsData={e}
                        clickEvent={this.handleSelectUserRecord}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="6">{`No records yet`}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default UserRecords;
