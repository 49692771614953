import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const AppointmentInfo = ({ eventInfo, assignedToSameInspector, handleRemoveJobFromDashboard, ...props }) => {
  const jobStatus = !!props.jobStatus ? props.jobStatus.toUpperCase() : '';
  const removeThisJob = () => {
    return (
      <button className="btn red lighten-1" onClick={handleRemoveJob}>Remove Job</button>
    );
  }

  const handleRemoveJob = (event) => {
    event.preventDefault();
    handleRemoveJobFromDashboard();
  }

  return (
    <div>
      <h5>Appointment</h5>
      <p>
        {moment(eventInfo[0].unix, "x").format("dddd, MMM Do YYYY")}{" "}
        {renderTime(eventInfo[0])}
      </p>
      {
        !assignedToSameInspector && jobStatus === "PENDING" && removeThisJob()
      }
    </div>
  );
};

const renderTime = ({ early_morning, morning, afternoon, late_afternoon }) => {
  if (early_morning) return "07:00AM";
  if (morning) return "09:00AM";
  if (afternoon) return "12:00PM";
  if (late_afternoon) return "03:00PM";
};
export default AppointmentInfo;
