import Tools from "../../../components/AdminDomain/Tools/Tools";
import PrivateComponent from "../../../components/PrivateComponent";
import React from "react";

class ToolsComponent extends React.Component {
  render() {
    return (
      <PrivateComponent>
        <Tools />
      </PrivateComponent>
    );
  }
}

export default ToolsComponent;
