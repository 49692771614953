import React from 'react';
import cssStyles from './PriceRangeDisplay.module.css'


const PriceRangeDisplay = (props) => {
    const RangeHighEndPoint = props.max * 1.2
    const labelWidth = `${(props.max / RangeHighEndPoint).toFixed(2) * 100}%`

    return(
        <div className={cssStyles.progressBarContainer}>
            <progress className={cssStyles.progressBar} value={props.max} max={RangeHighEndPoint}/>
            <div className={cssStyles.labelContainer}>
                <div className={cssStyles.labelInnerBx} style={{width: `${labelWidth}`}}>
                    <span>{`$${props.min}`}</span>
                    <span>{`$${props.max}`}</span>
                </div>
            </div>
        </div>
    )
};


export default PriceRangeDisplay;
