import OpenJobsComponent from "../../../components/AdminDomain/OpenJobs/OpenJobs";
import PrivateComponent from "../../../components/PrivateComponent";
import React from "react";

class OpenJobs extends React.Component {
  render() {
    return (
      <PrivateComponent>
        <OpenJobsComponent />
      </PrivateComponent>
    );
  }
}

export default OpenJobs;
