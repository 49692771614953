import React from 'react'
import SummaryInfo from './SummaryInfo'
export default (props) => {

  return (
    <div className='container' style={{marginTop: "15px"}}>
      <div className='row mb-0'>
        <SummaryInfo
          bgColor="rgb(240, 240, 240)"
          size={12}
          inspectorList={props.inspectorList}
          address_one={props.address_one}
          address_two={props.address_two}
          formState={props.formState}
          city={props.city}
          zipcode={props.zipcode}
          houseType={props.houseType}
          bedAmount={props.bedAmount}
          bathAmount={props.bathAmount}
          sqftAmount={props.sqftAmount}
          yearbuilt={props.yearbuilt}
          parking={props.parking}
          foundation={props.foundation}
          foundationOther={props.foundationOther}
          attic={props.attic}
          atticOther={props.atticOther}
          events={props.events}
          additionalFeatures={props.additional_features}
        />
        <form className="col s12 center" style={{ marginBottom: '80px' }}>
          <div className='row center'>
            <button className='btn col my-1 py-1 s12 m5 housky-secondary-bg left' style={{ height: 'unset' }} onClick={props.handleSelectiveBack}>Back</button>
            <button type='submit' className='btn col my-1 py-1 s12 m5 offset-m2 housky-primary-bg right' style={{ height: 'unset' }} onClick={props.handleConfirm}>Submit Request</button>
          </div>
        </form>
      </div>
    </div>
  );
}