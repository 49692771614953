import React, {useState} from 'react'
import AuthWrapper from '../../../components/AuthWrapper'
import AuthContext from '../../../contexts/auth'
import Schedule from '../../../components/UserDomain/Schedule/Schedule'
import './schedule.css'
import Loader from '../../../components/Loader'
import {useHistory} from "react-router-dom";

export default function ScheduleContainer(props) {

  const [ loading, setLoading ] = useState(true);

  const history = useHistory();

  const onLogInClicked = () => {
    const queryParams = window.location.search;
    history.push(`/login${queryParams}`, {from: 'schedule'});
  };

  const Success = () => {
    return <Schedule setDomain={props.setDomain} selectedInspectors={props.selectedInspectors} setSelectedInspectors={props.setSelectedInspectors} />
  }

  const Fail = () => {
    if (!loading)
      return <div className='row valign-wrapper' style={{ height: '70vh' }}>
        <div className='center-align' style={{ width: '100%' }}>
          <h4>Oops, Looks like you're not logged in.</h4>
          <button onClick={onLogInClicked} className='btn housky-primary-complimentary-bg' style={{ padding: '1rem 4rem', height: 'auto' }}>Log In</button>
        </div>
      </div>
    else {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
    return <Loader />
  }

  return <>
    <AuthContext.Consumer>
      {
        (user) => {
          return <AuthWrapper user={user} success={<Success user={user} />} fail={<Fail />} />
        }
      }
    </AuthContext.Consumer>
  </>
}