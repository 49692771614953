import React from "react";
import InspectorPrivateComponent from "../../../components/InspectorPrivateComponent";
import StripeInspectorConnectComponent from "../../../components/InspectorDomain/StripeInspectorConnect/StripeInspectorConnect";

class StripeInspectorConnect extends React.Component {
  render() {
    return (
      <InspectorPrivateComponent>
        <StripeInspectorConnectComponent />
      </InspectorPrivateComponent>
    );
  }
}

export default StripeInspectorConnect;
