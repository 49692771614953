import "firebase/storage";
import "./InspectorReport.css";

import {
  getInspectionReport,
  setReportToComplete,
  updateFullHouse,
  updateInspectorReport,
} from "../../../services/service";

import AuthContext from "../../../contexts/auth";
import CancelJob from "../CancelJob/CancelJob";
import InspectorReportInfo from "./InspectorReportInfo";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import React from "react";
import ReportPDF from "../../UserDomain/HouseReport/ReportPDF";
import { inspectorApp as firebase } from "../../../firebase";
import { withRouter } from "react-router-dom";

class InspectorReport extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      report: {},
      pdfref: null,
      pdfreview: null,
      idToken: null,
      wantToUpdateReport: false,
      markReportComplete: false,
      viewPDF: false,
      uploadStatus: null,
      isHouseUpdating: false,
      reportIsComplete: false,
      isCancelJobActive: false,
    };
  }
  componentDidMount() {
    let elem = document.querySelectorAll(".dropdown-trigger");
    Materialize.Dropdown.init(elem, {
      classes: "",
    });

    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        this.setState({ idToken });
        getInspectionReport(this.props.match.params.report, idToken).then((data) => {
          this.setState({ report: { ...data }, defaultReport: { ...data } });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  transitionPDF = () => {
    if (this.state.viewPDF) {
      document.getElementById("housepdf").classList.remove("transitionPDF");
      this.setState({ viewPDF: false });
    } else {
      document.getElementById("housepdf").classList.add("transitionPDF");
      this.setState({ viewPDF: true });
    }
  };
  runMaterialize = () => {
    let elem = document.querySelectorAll("select");
    Materialize.FormSelect.init(elem, {
      classes: "",
    });
  };
  handleEdit = (e) => {
    e.preventDefault();
    this.setState({
      isDisabled: !this.state.isDisabled,
      report: { ...this.state.defaultReport },
    });
  };
  handleChange = (e) => {
    let report = this.state.report;
    report[e.target.id] = e.target.value;
    this.setState({
      report,
    });
  };
  updateFullHouseInfo = (houseData, houseDetailData) => {
    this.setState({ isHouseUpdating: true });
    updateFullHouse(houseData, houseDetailData)
      .then((res) => {
        return getInspectionReport(this.props.match.params.report, this.state.idToken);
      })
      .then((data) => {
        this.setState({
          report: { ...data },
          isHouseUpdating: false,
        });
      });
  };
  handleReport = (e, preview, ref) => {
    if (!e.target.files[0]) {
      return <React.Fragment></React.Fragment>;
    } else {
      this.setState({
        [preview]: URL.createObjectURL(e.target.files[0]),
        [ref]: e.target.files[0],
      });
    }
  };
  handleUpdateReport = () => {
    this.setState({ wantToUpdateReport: !this.state.wantToUpdateReport });
  };
  renderPDFUpload = () => {
    return (
      <div className="mt-5 pt-5">
        <h6>Upload Report PDF?</h6>
        <div className="file-field input-field">
          <div className="btn-small housky-primary-bg">
            <span>Upload File</span>
            <input
              type="file"
              onChange={(e) => {
                this.handleReport(e, "pdfreview", "pdfref");
              }}
              accept="application/pdf"
            />
          </div>
          <div className="file-path-wrapper">
            <input className="file-path validate gray-text-color" type="text" />
          </div>
        </div>
        {!this.state.pdfref ? (
          <React.Fragment></React.Fragment>
        ) : (
          <div className="row center">
            {this.state.uploadStatus && (
              <div style={{ width: "100%" }}>
                <label>
                  <h5>{this.state.uploadStatus}% Complete</h5>
                </label>
              </div>
            )}
            <button
              style={{ height: "auto" }}
              type="submit"
              onClick={this.uploadReportToFirebase}
              className="py-2 px-5 btn housky-primary-complimentary-bg"
              disabled={this.state.uploadStatus && true}
            >
              Confirm
            </button>
          </div>
        )}
      </div>
    );
  };
  uploadReportToFirebase = (e) => {
    let storageRef = firebase.storage().ref();
    let inspectorJobsRef = storageRef.child(this.context.user.email).child("reports");
    let reportURLRef = inspectorJobsRef.child(`${this.state.report.reportid}.pdf`);
    let reportUploadTask = reportURLRef.put(this.state.pdfref);
    reportUploadTask.on(
      "state_changed",
      (snapshot) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({
          uploadStatus: progress > 10 && Number.parseInt(progress),
        });
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        console.log(error);
      },
      () => {
        reportUploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            return updateInspectorReport(this.state.idToken, {
              pdf_url: downloadURL,
              report: this.state.report.reportid,
            });
          })
          .then((res) => {
            if (res.status === 201) {
              let localReport = this.state.report;
              localReport.pdf_url = res.data.data.pdf_url;
              this.setState({
                report: { ...localReport },
                defaultReport: { ...localReport },
                wantToUpdateReport: this.state.wantToUpdateReport && false,
                uploadStatus: null,
                pdfref: null,
                pdfreview: null,
              });
            }
          });
      }
    );
  };
  handleCompleteReport = (e) => {
    if (!this.state.report.pdf_url) {
      Materialize.toast({
        html: `Please upload report PDF`,
        classes: "rounded",
      });
      return;
    }

    if (this.state.report.status === "COMPLETED" || this.state.reportIsComplete) return;

    setReportToComplete(this.state.idToken, this.state.report.reportid)
      .then((res) => {
        const data = res.data;
        if (data.data.report_completed) {
          Materialize.toast({
            html: `Report Completed!`,
            classes: "rounded",
          });
          this.setState({
            markReportComplete: false,
            reportIsComplete: true,
            report: {
              ...this.state.report,
              status: "COMPLETED"
            },
          });
        } else {
          Materialize.toast({
            html: `${res.data.data.message}`,
            classes: "rounded",
          });
          this.setState({markReportComplete: false});
        }
      });
  };

  setNewCurrentStatus = () => {
    getInspectionReport(this.props.match.params.report, this.state.idToken).then((data) => {
      this.setState({ report: { ...data }, defaultReport: { ...data } });
    });
  };

  render() {
    return (
      <div className="row" style={{minHeight:'75vh'}}>
        {this.state.report.current_status === "CANCELLED" && (
          <label className="center-align">
            <h5>Job has been cancelled</h5>
          </label>
        )}
        <div className="col s12 m7">
          <div style={{ display: "flex", justifyContent: "space-around" }} className="pt-5 mt-5">
            {this.state.report.pdf_url && !["CANCELLED", "COMPLETED"].includes(this.state.report.status.toUpperCase()) &&
              (
                <button className="btn-small" onClick={this.handleUpdateReport}>
                  Upload new report
                </button>
              )}
            {this.state.report.pdf_url && this.state.report.status !== "CANCELLED" && (
              <button
                className="btn-small"
                onClick={(e) => {
                  this.setState({
                    markReportComplete: !this.state.markReportComplete,
                  });
                }}
                disabled={this.state.report.status === "COMPLETED" || this.state.reportIsComplete}
              >
                {this.state.report.status === "COMPLETED" || this.state.reportIsComplete
                  ? "Report is complete"
                  : "Set report as complete"}
              </button>
            )}
            {this.state.report.current_status === "PENDING" && (
              <button
                className="btn-small"
                onClick={(e) => {
                  this.setState({
                    isCancelJobActive: !this.state.isCancelJobActive,
                  });
                }}
              >
                Cancel Job
              </button>
            )}
            <button id="" className="btn-small show-on-small hide-on-med-and-up" onClick={this.transitionPDF}>
              {this.state.viewPDF ? "See Info" : "See PDF"}
            </button>
          </div>

          <div className="container">
            {this.state.wantToUpdateReport && (
              <React.Fragment>
                {this.renderPDFUpload()}
                <button className="btn-small" onClick={this.handleUpdateReport}>
                  Cancel
                </button>
              </React.Fragment>
            )}
            {this.state.markReportComplete && (
              <div className="py-5">
                <label>
                  <h6>Are you sure you want to mark this report as complete?</h6>
                </label>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button onClick={this.handleCompleteReport} className="btn-small">
                    Yes
                  </button>
                  <button
                    className="btn-small teal lighten-2 ml-3"
                    onClick={(e) => {
                      this.setState({ markReportComplete: false });
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            )}
          </div>
          {this.state.isCancelJobActive && (
            <CancelJob
              containerClass="col s12 m12 py-5"
              jobId={this.state.report.jobid}
              setNewCurrentStatus={this.setNewCurrentStatus}
              removeCancelJob={() => {
                this.setState({
                  isCancelJobActive: !this.state.isCancelJobActive,
                });
              }}
            />
          )}
          {/* */}
          {this.state.report.pdf_url ? (
            <React.Fragment>
              <div
                style={this.state.viewPDF ? { height: "calc(100vh - 150px)" } : {}}
                className="report-container row m-0 mt-3"
              >
                <div id="housepdf" className="col s12 mobilepdf" style={{ height: "100%" }}>
                  {this.state.report.pdf_url && <ReportPDF reportURL={this.state.report.pdf_url} />}
                </div>
              </div>
            </React.Fragment>
          ) : this.state.report.current_status !== "CANCELLED" ? (
            this.renderPDFUpload()
          ) : null}
          {/* */}
        </div>
        <div className="col s12 m5">
          {this.state.report.reportid && (
            <InspectorReportInfo
              isHouseUpdating={this.state.isHouseUpdating}
              report={this.state.report}
              updateFullHouseInfo={this.updateFullHouseInfo}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(InspectorReport);
