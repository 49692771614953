import React from "react";
import "./FilterTable.css";

const FilterTable = ({
  tableHeaders = [],
  tableClass = "",
  tableStyle = {},
  tHeadClass = "",
  tHeadStyle = {cursor:'pointer'},
  thClass = "",
  thStyle = "",
  tableWrapperClass = "",
  tableWrapperStyle = {},
  emptyTableMessage,
  ...restProps
}) => {
  return (
    <div
      style={{ overflow: "auto", ...tableWrapperStyle }}
      className={`pb-5 card-panel table-height ` + tableWrapperClass}
    >
      <table style={{ ...tableStyle }} className={`` + tableClass}>
        <thead style={{ ...tHeadStyle }} className={`` + tHeadClass}>
          <tr>{tableHeaders}</tr>
        </thead>
        <tbody>
          {restProps.children}
          {renderEmptyRows(emptyTableMessage, tableHeaders.length, restProps.children ? restProps.children.length : 0)}
        </tbody>
      </table>
    </div>
  );
};

const renderEmptyRows = (emptyTableMessage = "", tableRowWidth = "", tableRowCount) => {
  if (tableRowCount === 0) {
    return (
      <tr>
        <td colSpan={`${tableRowWidth}`}>{emptyTableMessage}</td>
      </tr>
    );
  }
  const emptyRowArray = [];
  for (let i = 0; i < 6 - tableRowCount; i++) {
    emptyRowArray.push(
      <tr key={i}>
        <td colSpan={`${tableRowWidth}`}></td>
      </tr>
    );
  }
  return emptyRowArray;
};

export default FilterTable;
