import React from "react";
import Materialize from "materialize-css/dist/js/materialize.min.js";

class Active extends React.Component {
  componentDidMount() {
    let elem = document.querySelectorAll("select");
    Materialize.FormSelect.init(elem, {
      classes: ""
    });
  }
  render() {
    const { active } = this.props;
    return (
      <div className="input-field col s4">
        <select
          id="active"
          name="active"
          value={active ? "Yes" : "No"}
          onChange={this.props.handleChange}
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        <label htmlFor="active">Active</label>
      </div>
    );
  }
}

export default Active;
