import Materialize from "materialize-css/dist/js/materialize.min.js";

const stateOption = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
]

const isComplexPassword = (password) => {
  let passw = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,128}$/
  if (password) {
    if (password.match(passw))
      return true;
    else
      return false;
  }
  return false
}

const validatePassword = (password) => {
  let letter = document.getElementById("letter")
  let capital = document.getElementById("capital")
  let number = document.getElementById("number")
  let length = document.getElementById("length")
  let symbol = document.getElementById("symbol")
  if (password) {
    // Validate lowercase letters
    let lowerCaseLetters = /[a-z]/g;
    if (letter) {
      if (password.match(lowerCaseLetters)) {
        letter.classList.remove("invalid");
        letter.classList.add("valid");
      } else {
        letter.classList.remove("valid");
        letter.classList.add("invalid");
      }
    }
    // Validate capital letters
    let upperCaseLetters = /[A-Z]/g;
    if (capital) {
      if (password.match(upperCaseLetters)) {
        capital.classList.remove("invalid");
        capital.classList.add("valid");
      } else {
        capital.classList.remove("valid");
        capital.classList.add("invalid");
      }
    }
    // Validate numbers
    let numbers = /[0-9]/g;
    if (number) {
      if (password.match(numbers)) {
        number.classList.remove("invalid");
        number.classList.add("valid");
      } else {
        number.classList.remove("valid");
        number.classList.add("invalid");
      }
    }
    // Validate length
    if (length) {
      if (password.length >= 8) {
        length.classList.remove("invalid");
        length.classList.add("valid");
      } else {
        length.classList.remove("valid");
        length.classList.add("invalid");
      }
    }
    // Validate symbol
    let symbols = /[(?=.*)(!@#$%^&*]/g
    if (symbol) {
      if (password.match(symbols)) {
        symbol.classList.remove("invalid");
        symbol.classList.add("valid");
      } else {
        symbol.classList.remove("valid");
        symbol.classList.add("invalid");
      }
    }
  }
}

const removeModal = (selector) => {
  let instance = Materialize.Modal.getInstance(document.querySelector(selector));
  if (instance)
    instance.close()
}

const closeNav = () => {
  if (document.querySelector('.sidenav')) {
    let instance = Materialize.Sidenav.getInstance(document.querySelector('.sidenav'));
    if (instance)
      instance.close()
  }
}

const getLocation = () => {
  return new Promise((resolve) => {
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition((position) => {
        resolve(position.coords)
      });
  })
}

const LoadTutorial = () =>{
  let dashboard = localStorage.getItem("dashboard-tutorial")
  let schedule = localStorage.getItem("schedule-tutorial")
  let priority = localStorage.getItem("priority-tutorial")
  if (!dashboard)
    localStorage.setItem("dashboard-tutorial", JSON.stringify({
      completed: false,
      date: Date.now()
    }))
  if (!schedule)
    localStorage.setItem("schedule-tutorial", JSON.stringify({
      completed: false,
      date: Date.now()
    }))
  if (!priority)
    localStorage.setItem("priority-tutorial", JSON.stringify({
      completed: false,
      date: Date.now()
    }))
}


export {
  stateOption,
  isComplexPassword,
  validatePassword,
  removeModal,
  closeNav,
  getLocation,
  LoadTutorial,
}