import React from "react";

const InspectorRecordsRow = props => {
  const {
    inspector_recordid,
    inspector_first_name,
    inspector_last_name,
    user_first_name,
    user_last_name,
    i_email,
    paid,
    amount
  } = props.inspectorRecordsData;
  const inspectorFirstName = `${
    inspector_first_name[0]
  }${inspector_first_name.slice(1).toLowerCase()}`;
  const inspectorLastName = `${
    inspector_last_name[0]
  }${inspector_last_name.slice(1).toLowerCase()}`;
  const inspectorFullName = `${inspectorFirstName} ${inspectorLastName}`;
  const userFirstName = `${user_first_name[0]}${user_first_name
    .slice(1)
    .toLowerCase()}`;
  const userLastName = `${user_last_name[0]}${user_last_name
    .slice(1)
    .toLowerCase()}`;
  const userFullName = `${userFirstName} ${userLastName}`;
  const clickEvent = props.clickEvent;
  return (
    <tr
      style={{ cursor: "pointer" }}
      id={inspector_recordid}
      onClick={clickEvent}
    >
      <td>{inspectorFullName}</td>
      <td>{paid ? "Yes" : "No"}</td>
      <td>
        {amount ? `$${Number.parseFloat(amount).toFixed(2)}` : "Not Paid"}
      </td>
      <td>{userFullName}</td>
      <td>{i_email.toLowerCase()}</td>
    </tr>
  );
};

export default InspectorRecordsRow;
