import React, { useState } from 'react';
import cssStyles from './InspectorSignUp.module.css'
import {
  checkInspector,
  createInspectorStep1
} from "../../../services/service";
import { inspectorApp as firebase } from "../../../firebase";
import Materialize from "materialize-css/dist/js/materialize.min.js";

const InspectorSignUp_Step1 = (props) => {
  const useInput = (initialVal, sideEffectFunction = null) => {
    const [ value, setValue ] = useState(initialVal)
    const onValueChangeHandler = (e) => {
      setValue(e.target.value)
      if (sideEffectFunction){
        sideEffectFunction(e.target.value)
      }
    }
    return [ value, onValueChangeHandler ]
  }

  const checkEmailValidity = (email) => {
  const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return re.test(String(email));
  }

  const [ firstName, onFirstNameChange ] = useInput('')
  const [ lastName, onLastNameChange ] = useInput('')
  const [ email, onEmailChange ] = useInput('')
  const [ password, onPasswordChange ] = useInput('', checkPasswordOnPasswordChange)
  const [ confirmPassword, onConfirmPasswordChange ] = useInput('')
  const [ emailIsInUse, setEmailIsInUse ] = useState(false)
  const [ hasCapitalChar, setHasCapitalChar ] = useState(false)
  const [ hasLowerCaseChar, setHasLowerCaseChar ] = useState(false)
  const [ hasNumericChar, setHasNumericChar ] = useState(false)
  const [ hasSpecialChar, setHasSpecialChar ] = useState(false)
  const [ hasEnoughLength, setHasEnoughLength ] = useState(false)


  const [ isTouched, setIsTouched ] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    confirmPassword: false
  });

  function checkPasswordValidity(password) {
    let hasCapitalChar = false
    let hasLowerCaseChar = false
    let hasNumericChar = false
    let hasSpecialChar = false

    if(password.length < 8){ return false }
    for(let char of password){
      if(/[A-Z]/.test(char)){ hasCapitalChar = true}
      else if(/[a-z]/.test(char)) { hasLowerCaseChar= true }
      else if(!isNaN(char)) { hasNumericChar = true }
      else if(/[*.!@#$%^]/.test(char)) { hasSpecialChar = true }
  }
    return hasCapitalChar && hasLowerCaseChar && hasNumericChar && hasSpecialChar
  }

  const firstNameIsValid = firstName.trim() !== '';
  const lastNameIsValid = lastName.trim() !== '';
  const emailIsValid = email.trim() !== '' && checkEmailValidity(email);
  const passwordIsValid = password.trim() !== '' && checkPasswordValidity(password);
  const confirmPasswordIsValid = confirmPassword.trim() !=='' && confirmPassword === password

  const firstNameClasses = !firstNameIsValid && isTouched.firstName ? `browser-default ${cssStyles.invalid_input}` : `browser-default ${cssStyles.input}`
  const lastNameClasses = !lastNameIsValid && isTouched.lastName ? `browser-default ${cssStyles.invalid_input}` : `browser-default ${cssStyles.input}`
  const emailClasses = !emailIsValid && isTouched.email ? `browser-default ${cssStyles.invalid_input}` : `browser-default ${cssStyles.input}`
  const passwordClasses = !passwordIsValid && isTouched.password ? `browser-default ${cssStyles.invalid_input}` : `browser-default ${cssStyles.input}`
  const confirmPasswordClasses = !confirmPasswordIsValid && isTouched.confirmPassword ? `browser-default ${cssStyles.invalid_input}` : `browser-default ${cssStyles.input}`

  const checkIfEmailInUse = async(email) => {
    const res = await checkInspector(email)
    return !!res.inspector_first_name;
  }

  function checkPasswordOnPasswordChange(password) {
    let capitalCount = 0
    let lowerCount = 0
    let numberCount = 0
    let specialCount = 0
    if (password.length < 8) { setHasEnoughLength(false)} else {setHasEnoughLength(true)}
    for(let char of password){
      if(/[A-Z]/.test(char)){ capitalCount += 1}
      else if(/[a-z]/.test(char)) { lowerCount += 1 }
      else if(!isNaN(char)) { numberCount += 1 }
      else if(/[*.!@#$%^]/.test(char)) { specialCount += 1 }
    }

    if(capitalCount > 0){ setHasCapitalChar(true) } else{ setHasCapitalChar(false) }
    if(lowerCount > 0){ setHasLowerCaseChar(true) } else{ setHasLowerCaseChar(false) }
    if(numberCount > 0){ setHasNumericChar(true) } else{ setHasNumericChar(false) }
    if(specialCount > 0){ setHasSpecialChar(true) } else{ setHasSpecialChar(false) }
  }

  const showCorrespondingMark = (correspondingState) => {
    if(correspondingState){
      return <span style={{color: 'green'}}>✓</span>
    } else{
      return <span style={{color: 'red'}}>✗</span>
    }
  }

  const showPasswordCheckBox = () => {
    return(
      <div className={cssStyles.password_check_container}>
        <div>
          <b>Your password must contain: </b>
        </div>
        <div style={{paddingLeft: '10px'}}>
          <span className={cssStyles.check_item}> {showCorrespondingMark(hasEnoughLength)} At least 8 characters.</span>
          <span className={cssStyles.check_item}> {showCorrespondingMark(hasNumericChar)} At least one number.</span>
          <span className={cssStyles.check_item}> {showCorrespondingMark(hasLowerCaseChar)} At least one lowercase letter.</span>
          <span className={cssStyles.check_item}> {showCorrespondingMark(hasCapitalChar)} At least one capital letter.</span>
          <span className={cssStyles.check_item}> {showCorrespondingMark(hasSpecialChar)} At least one of these special character: *.! @#$%^</span>
        </div>
      </div>
    )
  }

  const onBlur = (fieldName) => {
    return async() => {
      let emailAvailability = false
      if(fieldName === 'email'){
        emailAvailability = await checkIfEmailInUse(email)
      }
      const newIsTouched = Object.assign({...isTouched}, {[fieldName]: true})
      setIsTouched(newIsTouched)
      setEmailIsInUse(emailAvailability)
    }
  };

  const onPasswordFocused = () => {
    const newIsTouched = Object.assign({...isTouched}, {password: true})
      setIsTouched(newIsTouched)
  }

  const signupGetToken = () => {
    return firebase.auth().currentUser.getIdToken(true);
  };

  const createUser = async() => {
    try{
      // create account in firebase, when succeeded, get the token
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      let token = await signupGetToken();

      // create a new row in database for the new user
      let body = {
        firstName,
        lastName,
        email,
        token,
      }
      const inspectorIdResponse = await createInspectorStep1(body);

      // give user feedback about the result
      Materialize.toast({
        html: "Success! Your account has been created. ",
        classes: "rounded",
      });

      //pass token back to parent
      props.setToken(token)

    } catch(e){
      Materialize.toast({
        html: "Error. Please try again later.",
        classes: "rounded",
      });
    }
  }

  const onClickHandler = async (e) => {
    e.preventDefault();

    setIsTouched({
      firstName: true,
      lastName: true,
      email: true,
      password: true,
      confirmPassword: true
    });

    if(!firstNameIsValid){return}

    if(!lastNameIsValid){return}

    if(!emailIsValid){return}

    if(!passwordIsValid){return}

    if(!confirmPasswordIsValid){return}

      // create account in database
    await createUser()
  }

  return(
    <div className={cssStyles.form}>
      <div>
        <div className={cssStyles.row}>
          <div className={cssStyles.full_width_container}>
            <h5>Sign Up</h5>
          </div>
        </div>
        <form>
          <div className={cssStyles.row}>
            <div className={cssStyles.half_width_container}>
              <label htmlFor="f_name" className="">First Name <span style={{color: '#bb3e03'}}>*</span></label>
              <div>
                <input id="f_name" type="text" className={firstNameClasses} onChange={onFirstNameChange} onBlur={onBlur('firstName')}/>
                { !firstNameIsValid && isTouched.firstName && <p className={cssStyles.warning}>Please enter first name.</p>}
              </div>
            </div>
            <div className={cssStyles.half_width_container}>
              <label htmlFor="l_name" className="">Last Name <span style={{color: '#bb3e03'}}>*</span></label>
              <div>
                <input id="l_name" type="text" className={lastNameClasses} onChange={onLastNameChange} onBlur={onBlur('lastName')}/>
                { !lastNameIsValid && isTouched.lastName && <p className={cssStyles.warning}>Please enter last name.</p>}
              </div>
            </div>
          </div>
          <div className={cssStyles.row}>
            <div className={cssStyles.full_width_container}>
              <label htmlFor="email" className="">Email Address <span style={{color: '#bb3e03'}}>*</span></label>
              <div>
                <input id="email" type="text" className={emailClasses} onChange={onEmailChange} onBlur={onBlur('email')}/>
                {!emailIsValid && isTouched.email && <p className={cssStyles.warning}>Please enter a valid email.</p>}
                { emailIsValid && emailIsInUse && <p className={cssStyles.warning}>This email is already in use.</p>}
              </div>
            </div>
          </div>
          <div className={cssStyles.row}>
            <div className={cssStyles.full_width_container}>
              <label htmlFor="password" className="">Password <span style={{color: '#bb3e03'}}>*</span></label>
              <div>
                <input id="password" type="password" className={passwordClasses} onChange={onPasswordChange} onFocus={onPasswordFocused} onBlur={onBlur('password')}/>
                { !passwordIsValid && isTouched.password && showPasswordCheckBox()}
              </div>
            </div>
          </div>
          <div className={cssStyles.row}>
            <div className={cssStyles.full_width_container}>
              <label htmlFor="confirmPassword" className="">Confirm Password <span style={{color: '#bb3e03'}}>*</span></label>
                <div>
                  <input id="confirmPassword" type="password" className={confirmPasswordClasses} onChange={onConfirmPasswordChange} onBlur={onBlur('confirmPassword')}/>
                  {!confirmPasswordIsValid && isTouched.confirmPassword && <p className={cssStyles.warning}>Please enter the same password as above.</p>}
                </div>
            </div>
          </div>
          <div className={cssStyles.row}>
            <div className={cssStyles.full_width_container}>
              <label htmlFor="input_bx" style={{fontSize:'10pt'}}> By clicking CREATE ACCOUNT, you agree with our <a href='https://housky.io/terms/tos'>Terms of Service</a> and <a href='https://housky.io/terms/privacy'>Privacy Policy.</a></label>
            </div>
          </div>
          <div className={cssStyles.row}>
            <div className={cssStyles.full_width_container}>
              <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                <button className={`browser-default ${cssStyles.button}`} onClick={onClickHandler} ><b>CREATE ACCOUNT</b></button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default InspectorSignUp_Step1;
