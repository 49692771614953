import React, {useState} from "react";
import {Link} from "react-router-dom";
import { useHistory } from 'react-router-dom';

export default ({domain, setDomain}) => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const history = useHistory();

  const menuStyle = {
    padding: '7.5px',
    marginLeft: '22.5px',
    marginRight: '22.5px',
    position: 'fixed',
    backgroundColor: 'grey',
    opacity: '90%',
    paddingRight: '40px'
  };

  const optionStyle = {
    cursor: 'pointer'
  };

  const onOptionSelected = (domain) => {
    history.push('/');
    setIsMenuOpen(false);
    setDomain(domain);
  };

  const getMenu = () => {
    if(isMenuOpen) {
      return (
        <div style={menuStyle} onMouseLeave={() => setIsMenuOpen(false)}>
          <div onClick={() => onOptionSelected('main')} style={optionStyle}>Main</div>
          <div onClick={() => onOptionSelected('user')} style={optionStyle}>User</div>
          <div onClick={() => onOptionSelected('inspector')} style={optionStyle}>Inspector</div>
          <div onClick={() => onOptionSelected('admin')} style={optionStyle}>Admin</div>
        </div>
      );
    }
  };

  if(process.env.REACT_APP_ENV !== 'PROD') {
    return (
      <>
        <a className="nav-links left" style={{color: 'red'}} onClick={() => setIsMenuOpen(!isMenuOpen)}>
          SWITCH DOMAIN
        </a>
        { getMenu() }
      </>
    );
  } else {
    return null;
  }
}