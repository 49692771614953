import React from 'react'
import PersonalToS from './PeronalToS'
import SampleReport from './SampleReport'
import Materialize from "materialize-css/dist/js/materialize.min.js";
import AccountInputs from '../../GeneralComponents/FormInputs/AccountInputs';

export default class InspectorInfo extends React.Component {
  componentDidMount = () => {
    let elem = document.querySelectorAll("select");
    Materialize.FormSelect.init(elem, {
      classes: ''
    });
  }
  runMaterialize = () => {
    setTimeout(() => {
      let elem = document.querySelectorAll("select");
      Materialize.FormSelect.init(elem, {
        classes: ''
      });
    }, 100)
  }
  render() {
    return <div className='container pt-5 mt-5'>
      {this.props.isDisabled ? <button className='btn right housky-primary-bg' onClick={this.props.handleEdit}>Update Information</button>
        : <button className='btn right housky-primary-bg' onClick={this.props.handleEdit}>Cancel Update</button>}
      <div className="row pt-5 mt-5">
        <form className="col s12">
          <h5>Inspector Information</h5>
          <div className="row">
            <AccountInputs
              name="Certification Number" size="col s6" isDisabled={this.props.isDisabled} isRequired={false} id="certification_number" type="text" value={this.props.certification_number} handleChange={this.props.handleChange} />
            {this.props.isDisabled ? null : this.runMaterialize()}
            {!this.props.isDisabled ? <div className="input-field col s6">
              <select id="certification" name='certification' defaultValue={this.props.certification} onChange={this.props.handleSelectOptions}>
                <option>N/A</option>
                <option>ASHI Certified Inspector</option>
                <option>ASHI Inspector</option>
                <option>ASHI Associates</option>
                <option>InterNACHI</option>
              </select>
              <label htmlFor="certification">Certification</label>
            </div>
              :
              <>
                <p style={{ fontSize: '0.8rem', color: '#9e9e9e' }}>Certification</p>
                <p>{this.props.certification === 'N/A' ? '' : `${this.props.certification}`}</p>
              </>}
          </div>
          <div className='row'>
            <AccountInputs
              name="Year Started Inspection" size="col s5" isDisabled={this.props.isDisabled} isRequired={true} id="year_started" type="text" value={this.props.year_started} handleChange={this.props.handleYearStarted} />
          </div>
          <div className='row'>
            <h6>Licenses<span className='right blue-text-color mouse-pointer' style={{ fontSize: '14px' }} onClick={this.props.addLicense}>
              {this.props.licenseAmount < 3 && !this.props.isDisabled ? 'Add more' : ''}
            </span></h6>
            <AccountInputs
              name="License Number 1" size="col s8" isDisabled={this.props.isDisabled} isRequired={true} id="license_number_one" type="text" value={this.props.license_number_one || ''} handleChange={this.props.handleChange} />
            <AccountInputs
              name="Issued State" size="col s4" isDisabled={this.props.isDisabled} isRequired={true} id="license_state_one" type="text" value={this.props.license_state_one || ''} handleChange={(e) => { this.props.handleState(e, 'license_state_one', 3) }} />
            {this.props.licenseAmount > 1 ? <>
              <AccountInputs
                name="License Number 2" size="col s8" isDisabled={this.props.isDisabled} id="license_number_two" type="text" value={this.props.license_number_two || ''} handleChange={this.props.handleChange} />
              <AccountInputs
                name="Issued State" size="col s4" isDisabled={this.props.isDisabled} id="license_state_two" type="text" value={this.props.license_state_two || ''} handleChange={(e) => { this.props.handleState(e, 'license_state_two', 3) }} />
            </>
              : null}
            {this.props.licenseAmount > 2 ? <>
              <AccountInputs
                name="License Number 3" size="col s8" isDisabled={this.props.isDisabled} id="license_number_three" type="text" value={this.props.license_number_three || ''} handleChange={this.props.handleChange} />
              <AccountInputs
                name="Issued State" size="col s4" isDisabled={this.props.isDisabled} id="license_state_three" type="text" value={this.props.license_state_three || ''} handleChange={(e) => { this.props.handleState(e, 'license_state_three', 3) }} />
            </>
              : null}
            <AccountInputs
              name="WDI License" size="col s8" isDisabled={this.props.isDisabled} id="wdi_license" type="text" value={this.props.wdi_license || ''} handleChange={this.props.handleChange} />
            {/* <div className="input-field col s4">
                <input disabled={this.props.isDisabled ? true : false} id="wdi_state" type="text" value={this.props.wdi_state || ''} onChange={(e) => { this.props.handleState(e, 'wdi_state', 3) }} />
                <label className={this.props.wdi_state ? 'active' : ''} htmlFor="wdi_state">Issued State</label>
              </div> */}
            <AccountInputs
              name="Radon License" size="col s8" isDisabled={this.props.isDisabled} id="radon_license" type="text" value={this.props.radon_license || ''} handleChange={this.props.handleChange} />
            {/* <div className="input-field col s4">
                <input disabled={this.props.isDisabled ? true : false} id="radon_state" type="text" value={this.props.radon_state || ''} onChange={(e) => { this.props.handleState(e, 'radon_state', 3) }} />
                <label className={this.props.radon_state ? 'active' : ''} htmlFor="radon_state">Issued State</label>
              </div> */}
          </div>
          {this.props.isDisabled ? <></>
            :
            <div className='row center'>
              <button style={{ height: 'auto' }} type='submit' onClick={this.props.updateLicenses} className="py-2 px-5 btn housky-primary-complimentary-bg">Confirm</button>
            </div>
          }
        </form>
        {this.props.isDisabled ?
          <>
            <PersonalToS idToken={this.props.idToken}
              i_email={this.props.i_email}
              inspectorid={this.props.inspectorid}
              getInspectorInfo={this.props.getInspectorInfo}
              personal_tos={this.props.personal_tos} />
            <SampleReport idToken={this.props.idToken}
              i_email={this.props.i_email}
              inspectorid={this.props.inspectorid}
              getInspectorInfo={this.props.getInspectorInfo}
              sample_report={this.props.sample_report} />
          </>
          : <></>}
      </div>
    </div >
  }
}