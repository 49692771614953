import React from 'react'
import {chatApp} from "../../../firebase";
import {useDocument} from "react-firebase-hooks/firestore";
import styles from './ConversationHistory.module.css'

export default function({ conversationId }) {

	if(!conversationId) {
		return (
			<div className="col s12">
				No Conversation yet
			</div>
		);
	}

	const fireStore = chatApp.firestore();
	const conversationsRef = fireStore.collection("messages")
	const query = conversationsRef.doc(conversationId);

	const [ conversationRes, isConversationLoading, error ] = useDocument(query, {
		snapshotListenOptions: { includeMetadataChanges: true }
	})

	const conversation = conversationRes?.data()
	console.log(conversation);

	if(isConversationLoading) {
		return (
			<div>
				Loading...
			</div>
		);
	} else if(error) {
		return <div style={{color: 'red'}}>Error, please try again</div>
	} else if(!conversation?.thread?.length) {
		return (
			<div className="col s12">
				No Conversation yet
			</div>
		);
	}

	const generateConversations = (conversation) => {
		if(conversation?.thread?.length) {
			const getTitle = (id) => id === conversation.customerId ? 'Customer' : 'Inspector';
			const bubbleStyle = (id) => id === conversation.customerId ? `${styles.customerBubble}` : `${styles.inspectorBubble}`;
			return conversation.thread.map((thread, index) => (
					<div key={index} className={styles.threadContainer}>
						<div className={styles.titleContainer}>{`${getTitle(thread.uid)} - ${new Date(parseInt(thread.createdAt)).toLocaleString()}`}</div>
						<div className={`${styles.bubble} ${bubbleStyle(thread.uid)}`}>{thread.text}</div>
					</div>
				))
		}
	}

	return (
		<div className={`col s12 ${styles.conversationHistoryContainer}`}>
			<div>
				<span className={styles.conversationIdContainer}>{ `Conversation ID: `}</span>
				<span>
					{ conversationId }
				</span>
			</div>
			{ generateConversations(conversation) }
		</div>
	);
}