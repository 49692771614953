import React, {useEffect, useState} from "react";
import styles from './PendingJobsRow.module.css'
import { getInspectorBasicProfile } from "../../../services/service";
import {Inspector as InspectorFirebase} from "../../../utils/firebase/firebase";
import ConversationHistory from "../ConversationHistory/ConversationHistory";

export default function(props) {
	const {
		jobid: jobId,
		userid: userId,
		inspectorid: inspectorId,
		restriction_inspector: selectedInspectors,
		createdat: createdAt,
		house_detail: houseDescription,
		pay_date: payDate,
		inspectors_offers: inspectorsOffers,
		userfirstname: userFirstName,
		userlastname: userLastName,
		city,
		state,
		zipcode,
		useremail: userEmail,
		area_code: userAreaCode,
		cell_number: userCellNumber
	} = props.pendingJob

	const [ inspectorInfo, setInspectorInfo ] = useState({});
	const [ currentInspectorId, setCurrentInspectorId ] = useState('');

	useEffect(() => {
		(
			async () => {
				const idToken = await InspectorFirebase.getIdToken();
				const promises = selectedInspectors.map(inspectorId => getInspectorBasicProfile(idToken, inspectorId))
				const inspectorInfoRes = await Promise.all(promises);
				const inspectorInfoMap =
					inspectorInfoRes
						.map(x => x.data)
						.reduce((acc, x) => {
							if(x.inspectorid) {
								acc[x.inspectorid] = x
							}
							return acc
						}, {})
				setInspectorInfo(inspectorInfoMap);
			}
		)();
	}, []);

	const getConversationIdFromInspectorId = (inspectorId) => {
		return inspectorsOffers && inspectorsOffers[inspectorId] && inspectorsOffers[inspectorId].message_id
	};

	const toggleConversationSection = (inspectorId) => {
		if(inspectorId === currentInspectorId) {
			setCurrentInspectorId('');
		} else {
			setCurrentInspectorId(inspectorId);
		}
	};

	const getInspectorList = (inspectorId, index) => {
		if(inspectorId && inspectorInfo[inspectorId]) {
			const {
				inspector_first_name: inspectorFirstName,
				inspector_last_name: inspectorLastName,
				i_area_code: inspectorAreaCode,
				i_cell_number: inspectorCellNumber,
				i_email: inspectorEmail
			} = inspectorInfo[inspectorId]
			const inspectorOffer = inspectorsOffers?.[inspectorId]
			const getOfferSection = () => {
				if(inspectorOffer?.cost && inspectorOffer?.date && inspectorOffer?.time && inspectorOffer?.updated_at) {
					return (
						<p className="my-0">
							<span className="adm-job-header">Offer:</span> { `$${inspectorOffer.cost} at ${inspectorOffer.time} on ${inspectorOffer?.date} - updated on ${new Date(inspectorOffer?.updated_at).toLocaleString()}` }
						</p>
					);
				}
			};

			return (
				<div key={index} className="col s12">
					<p className="adm-job-name">
						{inspectorFirstName?.toLowerCase()}{" "}
						{inspectorLastName?.toLowerCase()}
					</p>
					<p className="my-0">
						<span className="adm-job-header">Inspector ID:</span> { inspectorId }
					</p>
					<p className="my-0">
						<span className="adm-job-header">Email:</span> { inspectorEmail.toLowerCase() }
					</p>
					<p className="my-0">
						<span className="adm-job-header">Phone Number:</span> { `${inspectorAreaCode}${inspectorCellNumber}` }
					</p>
					{ getOfferSection() }
					<button onClick={() => toggleConversationSection(inspectorId)} className="btn-small">
						{ currentInspectorId === inspectorId ? `Hide Conversation` : `Show Conversation` }
					</button>
					<hr/>
				</div>
			)
		}
	};

	return (
		<div className="col s12 adm-jobs-container">
			<span className="adm-job-index">{props.index}</span>
			<div className="row mb-0">
				<div className="col s12 m6">
					<div className="row mb-0">
						<div className="col s12">
							<p className="adm-job-title mb-0">{houseDescription}</p>
							<p className="my-0">
								<span>{`${city}, ${state}, ${zipcode}`}</span>
							</p>
							<p className="my-0">
								<span className="text-bold">{`Job ID: `}</span>
								<span className="adm-job-jobid my-0">{jobId}</span>
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col s12">
							<p className="my-0">
								<span className="adm-job-header">Created at:</span> {new Date(createdAt).toLocaleString()}
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col s12">
							<p className="adm-job-name">
								{userFirstName.toLowerCase()} {userLastName.toLowerCase()}
							</p>
							<p className="my-0">
								<span className="text-bold">{`User ID: `}</span>
								<span className="adm-job-jobid my-0">{userId}</span>
							</p>
							<p className="my-0">
								<span className="adm-job-header">Email:</span> { userEmail.toLowerCase() }
							</p>
							<p className="my-0">
								<span className="adm-job-header">Cell Number:</span> {`${userAreaCode}${userCellNumber}`}
							</p>
						</div>
					</div>
				</div>
				<div className="col s12 m6 adm-job-restriction-list">
					{
						selectedInspectors?.map(getInspectorList)
					}
				</div>
			</div>
			<div className="row mb-0">
				{
					currentInspectorId &&
					<ConversationHistory conversationId={getConversationIdFromInspectorId(currentInspectorId)} />
				}
			</div>
		</div>
	);
}