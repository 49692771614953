import React from "react";
import AuthContext from "../contexts/auth";
import Loader from "../components/Loader";
import SignIn from "../containers/InspectorDomain/SignIn/SignIn";

class InspectorPrivateComponent extends React.Component {
  static contextType = AuthContext;

  render() {
    if (this.context.authStatusCheck) {
      if (this.context.user) {
        return this.props.children;
      } else {
        return <SignIn message="You need to be logged in to see this page" />;
      }
    } else {
      return <Loader />;
    }
  }
}

export default InspectorPrivateComponent;
