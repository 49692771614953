import React from 'react'
import './aboutus.css'

export default () => {
  window.scrollTo(0, 0)
  return <>
    <div style={{ backgroundColor: '#104B69', position: 'fixed', top: '0', zIndex: '1', width: '100%', height: '80px' }}></div>
    <div id='our-mission-bg'>
      <div className='container' style={{ paddingTop: '40px' }}>
        <div className='row'>
          <div className='col s12' style={{ textAlign: 'center' }}>
            <p className='about-us-header'>Our Mission</p>
            <p className='about-us-text'> We are currently servicing New York City and central New Jersey.</p>
            <p className='about-us-text'>Founded in 2019, Housky is a unique real estate portal dedicated to connecting home-owners with the right home inspectors.</p>
            <a className='about-us-text' href='https://user.housky.io/signup'>Join Housky</a>
          </div>
        </div>
      </div>
      <svg style={{ position: 'relative', bottom: '-10px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 140"><path fill="#F7F5FD" fill-opacity="1" d="m1,44l60,16c60,16 180,48 300,37.3c120,-10.3 240,-64.3 360,-80c120,-16.3 240,5.7 360,26.7c120,21 240,43 300,53.3l60,10.7l0,32l-60,0c-60,0 -180,0 -300,0c-120,0 -240,0 -360,0c-120,0 -240,0 -360,0c-120,0 -240,0 -300,0l-60,0l0,-96z"></path></svg>
      <div id='our-story-bg'>
        <div className='container' style={{paddingBottom:'60px'}}>
          <div className='row mb-0'>
            <div className='col s12 m6'>
              <p className='about-us-header mt-0'>Our Story</p>
              <p className='about-us-text'>
                As first time home buyers we struggled to understand the home buying process. We realized that so many home purchases go through without access to a high quality home inspector and decided to do something about it. From this idea was born Housky. It helps you easily locate and schedule a quality home inspector, all online.</p>
            </div>
            <div className='col s12 m6 '>
              <img src={require('../../../assets/about-us-home.png')} style={{ width: '100%' }} alt='house' />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}