import React, { useState } from 'react';
import cssStyles from './InspectorSignUp.module.css'
import { US_STATES } from '../../../utils/constants';
import {
    checkInspectorCompany,
    createInspectionCompany,
    createInspectorStep4,
    getLocationInfo
} from '../../../services/service';
import Materialize from "materialize-css/dist/js/materialize.min.js";
import { Link } from 'react-router-dom';

const useInput = (initialVal) => {
    const [ value, setValue ] = useState(initialVal)
    const onValueChangeHandler = (e) => {
      setValue(e.target.value)
    //   console.log(e.target.value)
    }
    return [ value, onValueChangeHandler, setValue ]
  }

const InspectorSignUp_Step4 = (props) => {

    const [ companyName, onCompanyNameChange ] = useInput('')
    const [ phoneNumber, onPhoneNumberChange ] = useInput('')
    const [ address, onAddressChange, setAddress ] = useInput('')
    const [ aptInfo, onAptInfoChange, setAptInfo ] = useInput('')
    const [ city, onCityChagne, setCity ] = useInput('')
    const [ state, onStateChange, setState ] = useInput('')
    const [ zip, onZipChange, setZip ] = useInput('')
    const [ isSameAddress, setIsSameAddress ] = useState(false)

    const [ isTouched, setIsTouched ] = useState({
        companyName: false,
        phoneNumber: false,
        address: false,
        city: false,
        state: false,
        zip: false
      });

    const companyNameIsValid = companyName.trim() !== ''
    const phoneNumberIsValid = phoneNumber.trim() !== '' && !isNaN(phoneNumber) && phoneNumber.trim().length === 10
    const addressIsValid = address.trim() !== ''
    const cityIsValid = city.trim() !== ''
    const stateIsValid = state.trim() !== ''
    const zipIsValid = zip.trim() !== '' && !isNaN(zip) && zip.trim().length === 5;

    const companyNameClasses = !companyNameIsValid && isTouched.companyName ? `browser-default ${cssStyles.invalid_input}` : `browser-default ${cssStyles.input}`
    const phoneNumberClasses = !phoneNumberIsValid && isTouched.phoneNumber ? `browser-default ${cssStyles.invalid_input}` : `browser-default ${cssStyles.input}`
    const addressClasses = !addressIsValid && isTouched.address ? `browser-default ${cssStyles.invalid_input}` : `browser-default ${cssStyles.input}`
    const cityClasses = !cityIsValid && isTouched.city ? `browser-default ${cssStyles.invalid_input}` : `browser-default ${cssStyles.input}`
    const stateClasses = !stateIsValid && isTouched.state ? `browser-default ${cssStyles.invalid_select}` : `browser-default ${cssStyles.select}`
    const zipClasses = !zipIsValid && isTouched.zip ? `browser-default ${cssStyles.invalid_input}` : `browser-default ${cssStyles.input}`

    const onCheckboxClicked = () => {
        if(!isSameAddress){
            setAddress(props.address.address)
            setAptInfo(props.address.aptInfo)
            setCity(props.address.city)
            setState(props.address.state)
            setZip(props.address.zip)
        } else{
            setAddress('')
            setAptInfo('')
            setCity('')
            setState('')
            setZip('')
        }
        setIsSameAddress(!isSameAddress)
    }

    const onBlur = (fieldName) => {
        return async() => {
          const newIsTouched = Object.assign({...isTouched}, {[fieldName]: true})
          setIsTouched(newIsTouched)
        }
      };

    const saveCompanyInfo = async() => {
        const response = await checkInspectorCompany(phoneNumber)
        let companyId = '';
        if(response){
            companyId = response.companyId;
        } else {
            // write data to inspector company table, get back a company id
            let body = {
                comp_name: companyName,
                comp_address_one: address,
                comp_address_two: aptInfo,
                comp_city: city,
                comp_state: state,
                comp_zipcode: zip,
                comp_email: '',
                comp_area_code: phoneNumber.slice(0,3),
                comp_cell_number: phoneNumber.slice(3,10)
            }
            companyId =  await createInspectionCompany(body)
        }
        return companyId
    }

    const onSubmitHandler = async(e) => {
        try{
            e.preventDefault()
            setIsTouched({
                companyName: true,
                phoneNumber: true,
                address: true,
                city: true,
                state: true,
                zip: true
            })

            if(!companyNameIsValid){return}
            if(!phoneNumberIsValid){return}
            if(!addressIsValid){return}
            if(!cityIsValid){return}
            if(!stateIsValid){return}
            if(!zipIsValid){return}

            // create inspector company in inspector company table
            const inspection_company_id = await saveCompanyInfo();

            // get latitude and longitude from the zipcode
            const locationResponse = await getLocationInfo(zip);
            let latitude = '';
            let longitude = ''
            if(locationResponse) {
                latitude = locationResponse.latitude;
                longitude = locationResponse.longitude;
            }

            // save inspector company id in inspector table
            let body = {
                token: props.token,
                inspectorId: props.inspectorId,
                companyId: inspection_company_id,
                cellAreaCode: phoneNumber.slice(0,3),
                cellNumber: phoneNumber.slice(3),
                latitude: latitude,
                longitude: longitude
            }
            await createInspectorStep4(body);

            setTimeout(() => {
                props.setCurrentStep(5)
            }, 1500);

            Materialize.toast({
                html: "Success! Your information has been updated.",
                classes: "rounded",
            })

        } catch{
            Materialize.toast({
                html: "Error. Please try again later.",
                classes: "rounded",
              });
            }
    }

    return(
        <div className={cssStyles.form}>
            <div style={{width:'100%'}}>
                <div className={cssStyles.row}>
                    <div className={cssStyles.full_width_container}>
                        <h5>Company Address</h5>
                    </div>
                </div>
                    <form>
                        <div className={cssStyles.row}>
                            <div className={cssStyles.half_width_container}>
                                <label htmlFor="companyName" className="">Company Name <span style={{color: '#bb3e03'}}>*</span></label>
                                <div>
                                    <input id="companyName" type="text" className={companyNameClasses} onChange={onCompanyNameChange} onBlur={onBlur('companyName')}/>
                                    { !companyNameIsValid && isTouched.companyName && <p className={cssStyles.warning}>Please enter company name.</p> }
                                </div>
                            </div>
                            <div className={cssStyles.half_width_container}>
                                <label htmlFor="phoneNumber" className="">Phone Number <span style={{color: '#bb3e03'}}>*</span></label>
                                <div>
                                    <input id="phoneNumber" type="text" placeholder="eg. 1234567890" className={phoneNumberClasses} onChange={onPhoneNumberChange} onBlur={onBlur('phoneNumber')}/>
                                    { !phoneNumberIsValid && isTouched.phoneNumber && <p className={cssStyles.warning}>Please enter phone number.</p> }
                                    </div>
                            </div>
                        </div>
                        <div className={cssStyles.row}>
                            <div className={cssStyles.full_width_container}>
                                <input id="input_bx" className="browser-default" type="checkbox"
                                       onClick={onCheckboxClicked}
                                       style={{opacity: '1', pointerEvents: 'auto', position: 'relative'}}/>
                                <label htmlFor="input_bx"> Same as Inspector Address?</label>
                            </div>
                        </div>
                        <div className={cssStyles.row}>
                            <div className={cssStyles.full_width_container}>
                                <label htmlFor="address" className="">Address <span style={{color: '#bb3e03'}}>*</span></label>
                                <div>
                                    <input id="address" type="text" className={addressClasses} value={address} onChange={onAddressChange} onBlur={onBlur('address')}/>
                                    { !addressIsValid && isTouched.address && <p className="validation-error-text">Please enter address.</p> }
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.row}>
                            <div className={cssStyles.full_width_container}>
                                <label htmlFor="aptInfo" className="">Apartment, Suite, etc.</label>
                                <div>
                                    <input id="aptInfo" type="text" className={`browser-default ${cssStyles.input}`} value={aptInfo} onChange={onAptInfoChange}/>
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.row}>
                            <div className={cssStyles.full_width_container}>
                                <label htmlFor="city" className="">City <span style={{color: '#bb3e03'}}>*</span></label>
                                <div>
                                    <input id="city" type="text" className={cityClasses} value={city} onChange={onCityChagne} onBlur={onBlur('city')}/>
                                    { !cityIsValid && isTouched.city && <p className={cssStyles.warning}>Please enter city.</p> }
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.row}>
                            <div className={cssStyles.half_width_container}>
                                <label htmlFor="state" className="">State <span style={{color: '#bb3e03'}}>*</span></label>
                                <div>
                                    <select className={stateClasses} value={state} onChange={onStateChange} onBlur={onBlur('state')}>
                                        <option>Choose State</option>
                                        { US_STATES.map((state, index) => {
                                            return(<option key={index} value={state.abbreviation}>{state.name}</option>)
                                            } ) }
                                    </select>
                                    { !stateIsValid && isTouched.state && <p className={cssStyles.warning}>Please enter state.</p> }
                                </div>
                            </div>
                            <div className={cssStyles.half_width_container}>
                                <label htmlFor="zip" className="">Zip Code <span style={{color: '#bb3e03'}}>*</span></label>
                                <div>
                                    <input id="zip" type="text" className={zipClasses} value={zip} onChange={onZipChange} onBlur={onBlur('zip')}/>
                                    { !zipIsValid && isTouched.zip && <p className={cssStyles.warning}>Please enter zip code.</p> }
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.row}>
                            <div className={cssStyles.full_width_container} style={{marginTop: '15px'}}>
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                    <button className={`browser-default ${cssStyles.button}`} onClick={onSubmitHandler}><b>FINISH</b></button>
                                </div>
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                    <label>You can choose to <Link to='/account'>skip</Link> this for now and fill this later.</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

        </div>
    )
}

export default InspectorSignUp_Step4;
