import InspectorInfoComponent from "../../../components/AdminDomain/InspectorInfo/InspectorInfo";
import PrivateComponent from "../../../components/PrivateComponent";
import React from "react";

class InspectorInfo extends React.Component {
  render() {
    return (
      <PrivateComponent>
        <InspectorInfoComponent />
      </PrivateComponent>
    );
  }
}

export default InspectorInfo;
