import React from 'react'
import Logo from '../assets/HouskyLogo.png'

export default () => {
  return <div style={{ backgroundColor: '#104b69', minHeight:'70vh'}}>
    <div className='container'>
      <div className='row center-align mb-0' style={{ padding:'80px 0px' }}>
        <div className='col s12 m6 offset-m3'>
          <img src={Logo} alt='Housky' style={{ width: '100%' }} />
          <h3>Under Construction!</h3>
          <h4>Please come back later.</h4>
        </div>
      </div>
    </div>
  </div>
}