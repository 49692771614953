import PendingJobs from "../../../components/AdminDomain/PendingJobs/PendingJobs";
import PrivateComponent from "../../../components/PrivateComponent";
import React from "react";

export default function() {
	return (
		<PrivateComponent>
			<PendingJobs />
		</PrivateComponent>
	);
}
