import React from "react";
import StripeConnectButton from "../../../assets/StripeConnectBlue.png";
import { inspectorApp as firebase } from "../../../firebase";
import { inspectorHasStripe, getLoginLink } from "../../../services/service";
// import queryString from "query-string";
import { withRouter } from "react-router-dom";
import Toast from "../../GeneralComponents/Toast/Toast";

class InspectorStripe extends React.Component {
  state = {
    idToken: "",
    inspectorHasStripe: false,
    stripeToken: "",
    loginLink: "",
  };

  componentDidMount() {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        this.setState({ idToken });
        return inspectorHasStripe(idToken);
      })
      .then((data) => {
        this.setState({ inspectorHasStripe: data.inspectorHasStripe });
        //     let urlQueryParams = queryString.parse(this.props.location.search);
        //     this.setState({ stripeToken: urlQueryParams.code && urlQueryParams.code });
        // if (!data.inspectorHasStripe) {
        //       if (urlQueryParams.code) {
        //         authorizeStripe(urlQueryParams.code, this.state.idToken).then(res => {
        //           console.log(res);
        //         });
        //       }
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  generateLogin = () => {
    getLoginLink(this.state.idToken).then(({ data: loginLink }) => {
      this.setState({ loginLink: loginLink.loginLink }, () => {
        Toast("Link is available only for a few mins");
      });
    });
  };

  render() {
    const { inspectorHasStripe, loginLink } = this.state;
    const redirectURI = `https://inspector.housky.io/stripe&client_id=${process.env.REACT_APP_CLIENTID_STRIPE}`;
    return (
      <div className="container pt-5 mt-5">
        <div className="row pt-5 mt-5">
          <div className="col s12">
            {inspectorHasStripe ? (
              <div>
                <h5>Your account has been connected to Stripe.</h5>
                <p>To view your stripe dashboard and see your incoming payments generate a login link below</p>
                <button className="btn btn-small" onClick={this.generateLogin}>
                  Generate Login
                </button>
                {loginLink.length > 0 && (
                  <div className="py-3">
                    <a target="_blank" rel="noopener noreferrer" href={loginLink}>
                      {loginLink}
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <h5>To start accepting jobs and receiving payments, you need to set up a Stripe account.</h5>
                <h5>Housky does not store your banking information. Payments and money transfers will be handled by Stripe.</h5>
                <a href={`https://connect.stripe.com/express/oauth/authorize?redirect_uri=${redirectURI}`}>
                  <img style={{ width: "30%" }} src={StripeConnectButton} alt="stripe-connect" />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(InspectorStripe);
