import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import moment from "moment";
import Materialize from "materialize-css/dist/js/materialize.min.js";

import { CONTACT_US_URL, US_STATES } from "../../../utils/constants.js";
import { postJobConfirmPayment } from "../../../services/service";
import "./PaymentForm.css";
import {useHistory} from 'react-router-dom';

const errorsDefault = {
  firstName: "",
  lastName: "",
  addressOne: "",
  city: "",
  state: "",
  zipCode: "",
};

const stateDefault = {
  firstName: "",
  lastName: "",
  creditCardNumber: "",
  creditCardMonth: "01",
  creditCardYear: `${moment().year()}`,
  creditCardCVV: "",
  addressOne: "",
  addressTwo: "",
  city: "",
  state: "",
  zipCode: "",
  paymentIntent: {},
  paid: false,
  isLoadingPaymentIntent: false,
  loadingMessage: "",
  errorMessage: "",
};

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Helvetica Neue, Helvetica, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const DEFAULT_ERROR_MESSAGE = "There was an error processing the payment, please try it again later.";

const stripePromise = loadStripe(process.env.REACT_APP_PK_TEST_STRIPE);

const PaymentForm = ({ inspectorid, job, paymentIntent, userToken }) => {
  const [errors, setErrors] = useState(errorsDefault);
  const [state, setState] = useState({ ...stateDefault });

  console.log('jobid', job.jobid)

  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  //  MaterializeCSS element initialization
  let select = document.querySelectorAll("select");
  Materialize.FormSelect.init(select, {
    classes: "",
  });

  const checkForm = () => {
    const { firstName, lastName, addressOne, city, zipCode } = state;
    const newErrors = {
      ...errors,
      firstName: !firstName ? "Enter your first name" : "",
      lastName: !lastName ? "Enter your last name" : "",
      addressOne: !addressOne ? "Please enter your address" : "",
      city: !city ? "Please enter your city" : "",
      zipCode: !zipCode ? "Please enter your zip code" : "",
    };
    setErrors(newErrors);

    return (
      !newErrors.firstName &&
      !newErrors.lastName &&
      !newErrors.addressOne &&
      !newErrors.city
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!checkForm()) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    const jobId = job.jobid;
    const cardElement = elements.getElement(CardElement);
    const customerFullName = `${state.firstName} ${state.lastName}`;

    setState({
      ...state,
      isLoadingPaymentIntent: true,
      loadingMessage: "Making your payment...",
    });

    stripe
      .confirmCardPayment(paymentIntent.client_secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            address: {
              city: state.city,
              line1: state.addressOne,
              line2: state.addressTwo,
              country: "US",
              postal_code: state.zipCode,
              state: state.state,
            },
            email: "",
            name: customerFullName,
          },
          metadata: {
            jobid: jobId,
          },
        },
        receipt_email: "",
      })
      .then((result) => {
        if (result.error) {
          this.setState({errorMessage: DEFAULT_ERROR_MESSAGE});
        } else {
          if (
            result.paymentIntent.status === "succeeded" ||
            result.paymentIntent.status === "requires_capture"
          ) {
            setState({
              ...state,
              isLoadingPaymentIntent: true,
              loadingMessage: "Updating data...",
            });

            postJobConfirmPayment(
              jobId,
              inspectorid,
              paymentIntent.id,
              userToken
            )
              .then((data) => {
                setState({
                  ...state,
                  isLoadingPaymentIntent: false,
                  loadingMessage: "",
                  paid: true,
                });

                setTimeout(() => {
                  history.go(0);
                }, 3000);
              })
              .catch((error) => {
                console.error("There was an error!", error);

                setState({
                  ...state,
                  errorMessage: "There was an error",
                  isLoadingPaymentIntent: false,
                  loadingMessage: "",
                  paid: false,
                });
              });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({errorMessage: DEFAULT_ERROR_MESSAGE});
      });
  };

  const handleInputs = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const renderForm = () => {
    return (
      <div
        style={{display: `${!state.isLoadingPaymentIntent && !state.errorMessage && !state.paid ? 'block' : 'none'}`}}
      >
        <div
          className="grey lighten-2"
          style={{
            textAlign: "center",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          }}
        >
          <h5>Complete your payment</h5>
        </div>
        <div className="row mt-5">
          <div className="col s12 m6">
            <form>
              <h5>Payment Information</h5>
              <div className="row" style={{ marginBottom: "40px" }}>
                <div className="input-field col s12">
                  <CardElement options={CARD_ELEMENT_OPTIONS} />
                </div>
              </div>
              <div className="row" style={{ marginBottom: "0" }}>
                <div className="input-field col s12 m6">
                  <input
                    id="first_name"
                    name="firstName"
                    type="text"
                    className={`validate ${
                      !!errors.firstName ? "invalid" : ""
                    }`}
                    onChange={(event) => handleInputs(event)}
                    value={state.firstName}
                  />
                  <label htmlFor="first_name">First Name</label>
                  <span className="helper-text" data-error={errors.firstName} />
                </div>
                <div className="input-field col s12 m6">
                  <input
                    id="last_name"
                    name="lastName"
                    type="text"
                    className={`validate ${!!errors.lastName ? "invalid" : ""}`}
                    onChange={(event) => handleInputs(event)}
                    value={state.lastName}
                  />
                  <label htmlFor="last_name">Last Name</label>
                  <span className="helper-text" data-error={errors.lastName} />
                </div>
              </div>

              <h5>Billing address</h5>
              <div className="row">
                <div className="input-field col s12">
                  <input
                    id="address_one"
                    name="addressOne"
                    type="text"
                    className={`validate ${
                      !!errors.addressOne ? "invalid" : ""
                    }`}
                    onChange={(event) => handleInputs(event)}
                    value={state.addressOne}
                  />
                  <label htmlFor="address_one">Address 1</label>
                  <span
                    className="helper-text"
                    data-error={errors.addressOne}
                  />
                </div>
                <div className="input-field col s12">
                  <input
                    id="address_two"
                    name="addressTwo"
                    type="text"
                    className="validate"
                    onChange={(event) => handleInputs(event)}
                    value={state.addressTwo}
                  />
                  <label htmlFor="address_two">Address 2 (Optional)</label>
                </div>
                <div className="input-field col s12">
                  <input
                    id="city"
                    name="city"
                    type="text"
                    className={`validate ${!!errors.city ? "invalid" : ""}`}
                    onChange={(event) => handleInputs(event)}
                    value={state.city}
                  />
                  <label htmlFor="city">City</label>
                  <span className="helper-text" data-error={errors.city} />
                </div>
                <div className="input-field col s6">
                  <select
                    className="browser-default stateSelect"
                    id="state"
                    name="state"
                    onChange={(event) => handleInputs(event)}
                  >
                    <option value="StateNotSpecified" selected disabled hidden>
                      State
                    </option>
                    {US_STATES.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-field col s6">
                  <input
                    id="zip-code"
                    name="zipCode"
                    type="text"
                    className={`validate ${!!errors.zipCode ? "invalid" : ""}`}
                    onChange={(event) => handleInputs(event)}
                    value={state.zipCode}
                  />
                  <label htmlFor="zip-code">Zip code</label>
                  <span className="helper-text" data-error={errors.zipCode} />
                </div>
              </div>
            </form>
          </div>
          <div className="col s12 m6">
            <span style={{ color: "#00bcd4" }}>
              You payment won't be released to the inspector immediately after
              you input your credit card information. The amount will be stored
              in an escrow account and you will be able to release it to the
              inspector when the inspector finishes the inspection and uploads
              the report.
              <br />
              <br />
              Questions? Please{" "}
              <a style={{ color: "#006064" }} href={CONTACT_US_URL}>
                Contact Us
              </a>
            </span>
          </div>
        </div>
        <div className="row my-5">
          <div className="col s12" style={{ textAlign: "right" }}>
            <button
              type="submit"
              className="waves-effect waves-light btn"
              style={{ backgroundColor: "#00bcd4" }}
              onClick={(event) => handleSubmit(event)}
            >
              Submit payment
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderErrorMessage = () => {
    return (
      <div className="loading-cart">
        <span>{state.errorMessage}</span>
        <a
          data-target="checkout-job-modal"
          className="btn waves-effect modal-trigger"
          onClick={() => {
            history.go(0);
          }}
        >
          Close
        </a>
      </div>
    );
  }

  const renderCircularLoading = () => {
    return (
      <div className="loading-cart">
        <div className="preloader-wrapper big active">
          <div className="spinner-layer spinner-green-only">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>
            <div className="gap-patch">
              <div className="circle"></div>
            </div>
            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
        <span>{state.loadingMessage}</span>
      </div>
    );
  };

  const renderPaymentDone = () => {
    return (
      <div className="loading-cart">
        <div></div>
        <span>Payment done!</span>
        <a
          data-target="checkout-job-modal"
          className="btn waves-effect modal-trigger"
          onClick={() => {
            history.go(0);
          }}
        >
          Close
        </a>
      </div>
    );
  };

  return (
    <div className="card payment-form-card" style={{ margin: 0 }}>
      <div className="card-content">
        {state.isLoadingPaymentIntent && renderCircularLoading()}
        {renderForm()}
        {!state.isLoadingPaymentIntent && state.paid && renderPaymentDone()}
        {!state.isLoadingPaymentIntent && !!state.errorMessage && renderErrorMessage()}
      </div>
    </div>
  );
};

const PaymentFormWrapper = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm {...props} />
    </Elements>
  );
};

export default PaymentFormWrapper;
