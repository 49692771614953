import React from "react";
import InspectorPrivateComponent from "../../../components/InspectorPrivateComponent";
import JobRequestComponent from "../../../components/InspectorDomain/JobRequests/JobRequests";

class JobRequests extends React.Component {
  render() {
    return (
      <InspectorPrivateComponent>
        <JobRequestComponent />
      </InspectorPrivateComponent>
    );
  }
}

export default JobRequests;
