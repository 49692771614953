import React from 'react'
import AccountInputs from '../../GeneralComponents/FormInputs/AccountInputs'

export default (props) => {
  return <div className='container pt-5 mt-5'>
    {props.isDisabled ? <button className='btn right housky-primary-bg' onClick={props.handleEdit}>Update Information</button>
      : <button className='btn right housky-primary-bg' onClick={props.handleEdit}>Cancel Update</button>}
    <div className="row pt-5">
      <form className="col s12">
        <h5>Company Information</h5>
        <div className="row">
          {props.address_error ? <p data-aos="zoom-in" data-aos-duration="300" style={{ fontSize: '16px', color: 'red', textAlign: 'center' }}>Address not found. Please enter valid address.</p> : <></>}
          <AccountInputs
            name="Company Name" size="col s12" isDisabled={props.isDisabled} isRequired={true} id="comp_name" type="text" value={props.comp_name || ''} handleChange={props.handleChange} />
          <AccountInputs
            name="Email" size="col s6" isDisabled={props.isDisabled} isRequired={true} id="comp_email" type="text" value={props.comp_email || ''} handleChange={props.handleChange} />
          <AccountInputs
            name="Cell Number" size="col s6" isDisabled={props.isDisabled} isRequired={true} id="temp_comp_cell_number" type="text" value={props.isDisabled ? `${props.temp_comp_cell_number ? `(${props.comp_area_code}) - ${props.comp_cell_number}` : ''}` : props.temp_comp_cell_number} handleChange={props.handleCell} />
          <div className='input-field col s12'>
            <p className='m-0'>
              <label>
                <input type="checkbox" disabled={props.isDisabled ? true : false} onClick={props.handleSameCheckbox} />
                <span>Same as Inspector Address?</span>
              </label>
            </p>
          </div>
          <AccountInputs
            name="Address" size="col s6" isDisabled={props.isDisabled} isRequired={true} id="comp_address_one" type="text" value={props.comp_address_one || ''} handleChange={props.handleChange} />
          <AccountInputs
            name="Apt/Suite #" size="col s6" isDisabled={props.isDisabled} id="comp_address_two" type="text" value={props.comp_address_two || ''} handleChange={props.handleChange} />
          <AccountInputs
            name="City" size="col s4" isDisabled={props.isDisabled} isRequired={true} id="comp_city" type="text" value={props.comp_city || ''} handleChange={props.handleChange} />
          <AccountInputs
            name="State" size="col s4" isDisabled={props.isDisabled} isRequired={true} id="comp_state" type="text" value={props.comp_state || ''} handleChange={props.handleState} />
          <AccountInputs
            name="Zipcode" size="col s4" isDisabled={props.isDisabled} isRequired={true} id="comp_zipcode" type="text" value={props.comp_zipcode || ''} handleChange={props.handleZipcode} />
        </div>
        {props.isDisabled ? <></>
          :
          <div className='row center'>
            <button style={{ height: 'auto' }} type='submit' onClick={props.updateCompany} className="py-2 px-5 btn housky-primary-complimentary-bg">Confirm</button>
          </div>
        }
      </form>
    </div>
  </div>
}