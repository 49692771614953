import "./account.css";
import "firebase/storage";

import {
  accountManagement,
  createInspectionCompany,
  getInspectorsByInspector,
  getLocationFromAddress,
  updateInspectionCompany,
  updateInspector,
  updateInspectorAddress,
  updateInspectorCompanyInfo,
  updateInspectorLicenses,
  updateInspectorUser,
} from "../../../services/service";
import {
  inspectorApp,
  inspectorApp as inspectorFirebase,
} from "../../../firebase";
import { isComplexPassword, validatePassword } from "../../../services/helper";

import AOS from "aos";
import AccountNav from "./AccountNav.jsx";
import CompanyInfo from "./CompanyInfo.jsx";
import InspectorInfo from "./InspectorInfo.jsx";
import InspectorStripe from "../InspectorStripe/InspectorStripe";
import Manage from "./Manage";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import ReAuthModal from "./ReAuthModal";
import RePasswordModal from "./RePasswordModal";
import React from "react";
import UserAddress from "./UserAddress.jsx";
import UserInfo from "./UserInfo.jsx";
import firebase from "firebase/app";
import { stateOption, removeModal } from "../../../services/helper";
import UploadPhotoModal from './UpdatePhotoModal';
import Reviews from './Reviews';
import PriceCalculator from '../FeeCalculator/InspectorFeeCalculator'
import Tools from "./Tools";

export default class Account extends React.Component {
  state = {
    currentPage: "user_info",
    languageOptions: [
      { name: "Arabic", value: false },
      { name: "Chinese", value: false },
      { name: "English", value: true },
      { name: "French", value: false },
      { name: "Italian", value: false },
      { name: "Korean", value: false },
      { name: "Polish", value: false },
      { name: "Russian", value: false },
      { name: "Spanish", value: false },
      { name: "Yiddish", value: false },
    ],
    photopreview: "",
    oriEmail: "",
    reAuthEmail: "",
    reAuthPassword: "",
    photoref: "",
    certificationpreview: "",
    certiifcation_ref: "",
    inspector_first_name: "",
    inspector_last_name: "",
    i_email: "",
    password: "",
    confirmPassword: "",
    temp_cell_number: "",
    photoid: "",
    i_cell_number: "",
    i_area_code: "",
    i_address_one: "",
    i_address_two: "",
    i_state: "",
    i_city: "",
    i_zipcode: "",
    bio: "",
    languages: ["English"],
    year_started: "",
    certification: "N/A",
    certification_number: "",
    license_number_one: "",
    license_state_one: "",
    license_number_two: "",
    license_state_two: "",
    license_number_three: "",
    license_state_three: "",
    wdi_license: "",
    wdi_state: "",
    radon_license: "",
    radon_state: "",
    inspection_features: [
      {
        name: "Structural and foundation",
        value: false,
      },
      {
        name: "Electrical systems",
        value: false,
      },
      {
        name: "Plumbing systems",
        value: false,
      },
      {
        name: "Roof & attics",
        value: false,
      },
      {
        name: "Basement & crawl spaces",
        value: false,
      },
      {
        name: "Gutters & Drainage",
        value: false,
      },
      {
        name: "Walls, floors, & decks",
        value: false,
      },
      {
        name: "Property & site",
        value: false,
      },
      {
        name: "Furnace & Heating",
        value: false,
      },
      {
        name: "Air conditioning",
        value: false,
      },
      {
        name: "Plumbing fixtures",
        value: false,
      },
      {
        name: "Water heaters",
        value: false,
      },
      {
        name: "Lights and receptacles",
        value: false,
      },
      {
        name: "Window & doors",
        value: false,
      },
      {
        name: "Built-in appliances",
        value: false,
      },
    ],
    comp_name: "",
    comp_address_one: "",
    comp_address_two: "",
    comp_state: "",
    comp_city: "",
    comp_zipcode: "",
    comp_email: "",
    temp_comp_cell_number: "",
    companyid: "",
    isDisabled: true,
    clickedNav: "",
    idToken: "",
    inspectorid: "",
    inspection_company_id: "",
    licenseAmount: 0,
    defaultInspector: {},
    photo_collection: [],
    personal_tos: null,
    sample_report: null,
    address_error: false,
    hidden: true,
    isUpdatePhotoModalVisible: false
  };

  componentDidMount = () => {
    this.getInspectorInfo();
    AOS.init({
      duration: 400,
    });
  };

  componentWillUnmount = () => {
    if (document.getElementById("isEditModal")) removeModal("#isEditModal");
    if (document.getElementById("reAuthModal")) removeModal("#reAuthModal");
    if (document.getElementById("rePasswordModal"))
      removeModal("#rePasswordModal");
  };

  toggleUpdatePhotoModalVisibility = () => {
    /// this is to prevent user from scrolling when modal is on
    /// reference: https://stackoverflow.com/questions/9280258/prevent-body-scrolling-but-allow-overlay-scrolling
    if(this.state.isUpdatePhotoModalVisible){
      window.document.body.style.overflow = 'scroll';
    } else {
      window.document.body.style.overflow = 'hidden';
    }
    this.setState({ isUpdatePhotoModalVisible: !this.state.isUpdatePhotoModalVisible })
  }

  getInspectorInfo = () => {
    this.getToken().then((res) => {
      getInspectorsByInspector(res).then((inspector) => {
        let temp_cell_number = "";
        let temp_comp_cell_number = "";
        if (inspector.i_cell_number)
          temp_cell_number =
            inspector.i_area_code.toString() +
            inspector.i_cell_number.toString();
        if (inspector.comp_cell_number)
          temp_comp_cell_number =
            inspector.comp_area_code.toString() +
            inspector.comp_cell_number.toString();
        let licenseAmount = 0;
        for (let i = 1; i < 4; i++) {
          let j = "one";
          if (i === 2) j = "two";
          if (i === 3) j = "three";
          if (inspector[`license_number_${j}`]) licenseAmount++;
        }
        let tempLanguageOptions = JSON.parse(
          JSON.stringify(this.state.languageOptions)
        );
        if(inspector.languages) {
          inspector.languages.forEach((e) => {
            tempLanguageOptions.forEach((language) => {
              if (language.name === e) {
                language.value = true;
              }
            });
          });
        }
        this.setState({
          idToken: res,
          ...inspector,
          temp_cell_number,
          languageOptions: tempLanguageOptions,
          oriEmail: inspector.i_email,
          temp_comp_cell_number,
          licenseAmount,
          defaultInspector: inspector,
        });
        let elems = document.querySelectorAll(".modal");
        Materialize.Modal.init(elems, { opacity: "0.5" });
        // delete inspector.account_active
      });
    });
  };

  getToken = () => {
    return inspectorFirebase.auth().currentUser.getIdToken(true);
  };

  handleNav = (e) => {
    if (this.state.isDisabled) {
      this.setState({
        currentPage: e.currentTarget.id,
        isDisabled: true,
        clickedNav: e.currentTarget.id,
        ...this.state.defaultInspector,
        photopreview: "",
      });
    } else {
      this.setState({ clickedNav: e.currentTarget.id });
    }
  };

  handleSameCheckbox = (e) => {
    if (e.target.checked)
      this.setState({
        comp_address_one: this.state.i_address_one,
        comp_address_two: this.state.i_address_two,
        comp_email: this.state.i_email,
        comp_area_code: this.state.i_area_code,
        comp_cell_number: this.state.i_cell_number,
        temp_comp_cell_number: `${this.state.i_area_code}${this.state.i_cell_number}`,
        comp_state: this.state.i_state,
        comp_city: this.state.i_city,
        comp_zipcode: this.state.i_zipcode,
      });
    else
      this.setState({
        comp_address_one: this.state.defaultInspector.comp_address_one,
        comp_address_two: this.state.defaultInspector.comp_address_two,
        comp_email: this.state.defaultInspector.comp_email,
        comp_area_code: this.state.defaultInspector.comp_area_code,
        comp_cell_number: this.state.defaultInspector.comp_cell_number,
        temp_comp_cell_number: `${this.state.defaultInspector.comp_area_code}${this.state.defaultInspector.comp_cell_number}`,
        comp_state: this.state.defaultInspector.comp_state,
        comp_city: this.state.defaultInspector.comp_city,
        comp_zipcode: this.state.defaultInspector.comp_zipcode,
      });
  };

  handleEditContinue = (e) => {
    e.preventDefault();
    this.setState({ isDisabled: true, currentPage: this.state.clickedNav });
  };

  handleEdit = (e) => {
    e.preventDefault();
    this.setState({
      isDisabled: !this.state.isDisabled,
      ...this.state.defaultInspector,
      ...this.state.defaultInspector,
    });
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      () => {
        validatePassword(this.state.password);
      }
    );
  };

  handleRestrictedLength = (e, stateName, length) => {
    if (e.target.value.length < length) {
      this.setState({ [stateName]: e.target.value });
    }
  };

  handleStateRestrictedLength = (e, stateName, length) => {
    let currentStateError = true;
    if (e.target.value.length < length) {
      this.setState({ [stateName]: e.target.value.toUpperCase() });
      if (e.target.value.length === 2) {
        currentStateError = this.checkState(e.target.value.toUpperCase());
        this.setState({ stateError: currentStateError });
      }
    }
  };

  checkState = (value) => {
    if (!stateOption.includes(value)) {
      Materialize.toast({
        html: `${value} is not a valid state.`,
        classes: "rounded",
      });
      return true;
    }
    return false;
  };

  handleNumRestrictedLength = (e, stateName, length) => {
    if (
      e.target.value < Infinity &&
      e.target.value > -Infinity &&
      e.target.value.length < length
    ) {
      this.setState({ [stateName]: e.target.value });
    }
  };

  handleSelectOptions = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  addLicense = () => {
    let { licenseAmount } = this.state;
    if (licenseAmount + 1 < 4)
      this.setState({ licenseAmount: licenseAmount + 1 });
  };

  handleCheckbox = (e, name, state_item) => {
    let temp = JSON.parse(JSON.stringify(this.state[state_item]));
    let tempLanguage = [...this.state.languages];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].name === name) {
        temp[i].value = e.target.checked;
      }
    }
    if (state_item === "languageOptions") {
      if (e.target.checked) tempLanguage.push(name);
      else tempLanguage.splice(tempLanguage.indexOf(name), 1);
      this.setState({ [state_item]: temp, languages: tempLanguage });
    } else this.setState({ [state_item]: temp });
  };

  updateCompany = (e) => {
    const {
      inspectorid,
      idToken,
      comp_name,
      comp_address_one,
      comp_address_two,
      comp_state,
      comp_city,
      comp_zipcode,
      comp_email,
      temp_comp_cell_number,
      companyid,
      defaultInspector,
    } = this.state;
    const comp_area_code = temp_comp_cell_number.slice(0, 3);
    const comp_cell_number = temp_comp_cell_number.slice(3);
    e.preventDefault();
    const body = {
      companyid,
      comp_name,
      comp_address_one,
      comp_address_two,
      comp_city,
      comp_state,
      comp_zipcode,
      comp_email,
      comp_area_code,
      comp_cell_number,
    };
    if (
      !comp_name ||
      !comp_address_one ||
      !comp_state ||
      !comp_city ||
      !comp_zipcode ||
      !comp_email ||
      !temp_comp_cell_number
    )
      Materialize.toast({
        html: "Required fields cannot be left blank.",
        classes: "rounded",
      });
    else {
      if (defaultInspector?.comp_name?.toUpperCase() === comp_name.toUpperCase()) {
        updateInspectionCompany(body);
        Materialize.toast({
          html: "Company Information has been updated.",
          classes: "rounded",
        });
        const newBody = JSON.parse(JSON.stringify(body));
        this.setState({
          comp_area_code,
          comp_cell_number,
          isDisabled: true,
          photopreview: "",
          ...newBody,
          defaultInspector: newBody,
        });
      } else {
        createInspectionCompany(body)
          .then((res) => {
            const newBody = JSON.parse(JSON.stringify(body));
            this.setState({
              comp_area_code,
              comp_cell_number,
              isDisabled: true,
              photopreview: "",
              ...newBody,
              defaultInspector: newBody,
              inspection_company_id: res.data.companyid,
            });
            return res.data.companyid;
          })
          .then((companyid) => {
            const body = {
              inspectorid,
              inspection_company_id: companyid,
              token: idToken,
            };
            updateInspectorCompanyInfo(body);
          });
      }
    }
  };

  handlePhoto = (e, preview, ref) => {
    if (!e.target.files[0]) {
      return <></>;
    } else {
      this.setState({
        [preview]: URL.createObjectURL(e.target.files[0]),
        [ref]: e.target.files[0],
      });
    }
  };

  updateUser = async (e) => {
    const {
      oriEmail,
      inspectorid,
      photopreview,
      inspector_first_name,
      inspector_last_name,
      i_email,
      temp_cell_number,
      photoid,
      bio,
      languages,
      idToken,
    } = this.state;
    const i_area_code = temp_cell_number.slice(0, 3);
    const i_cell_number = temp_cell_number.slice(3);
    e.preventDefault();
    const root = inspectorApp.storage().ref();
    let newPhoto = photoid;
    if (photopreview) {
      const profileImage = root.child(
        `${i_email.toLowerCase()}/profile/${this.state.photoref.name}`
      );
      try {
        const snapshot = await profileImage.put(this.state.photoref);
        const url = await snapshot.ref.getDownloadURL();
        newPhoto = url;
      } catch (err) {
        console.log(err);
      }
    }
    if (
      !inspector_first_name ||
      !inspector_last_name ||
      !i_email ||
      !temp_cell_number
    )
      Materialize.toast({
        html: "Required fields cannot be left blank.",
        classes: "rounded",
      });
    else if (temp_cell_number.length !== 10) {
      Materialize.toast({
        html: "Please enter a valid phone number.",
        classes: "rounded",
      });
    } else if (oriEmail === i_email) {
      const body = {
        inspectorid,
        inspector_first_name,
        inspector_last_name,
        i_email,
        i_area_code,
        i_cell_number,
        bio,
        languages,
        token: idToken,
        photoid: newPhoto,
      };
      updateInspectorUser(body).then(() => {
        let user = inspectorFirebase.auth().currentUser;
        user
          .updateProfile({
            displayName: `${inspector_first_name} ${inspector_last_name}`,
            photoURL: newPhoto,
          })
          .then(() => {
            Materialize.toast({
              html: "User information has been updated.",
              classes: "rounded",
            });
            const newBody = JSON.parse(JSON.stringify(body));
            this.setState({
              i_area_code,
              i_cell_number,
              isDisabled: true,
              photopreview: "",
              ...newBody,
              defaultInspector: newBody,
              address_error: false,
            });
          })
          .catch((error) => {
            console.log(`Error updating user profile: ${error}`);
          });
      });
    }
  };

  updateAddress = async (e) => {
    e.preventDefault();
    const {
      inspectorid,
      i_address_one,
      i_address_two,
      i_state,
      i_city,
      i_zipcode,
      idToken,
    } = this.state;

    if (!i_address_one || !i_state || !i_city || !i_zipcode) {
      Materialize.toast({
        html: "Required fields cannot be left blank.",
        classes: "rounded",
      });
      return;
    }

    const body = {
      inspectorid,
      i_address_one,
      i_address_two,
      i_state,
      i_city,
      i_zipcode,
      token: idToken,
    };
    getLocationFromAddress(i_address_one, i_city, i_state, i_zipcode)
      .then((res) => {
        body.latitude = res.geometry.location.lat;
        body.longitude = res.geometry.location.lng;
        updateInspectorAddress(body);
      })
      .then(() => {
        Materialize.toast({
          html: "Your address has been updated.",
          classes: "rounded",
        });
        const newBody = JSON.parse(JSON.stringify(body));
        this.setState({
          isDisabled: true,
          ...newBody,
          defaultInspector: newBody,
          address_error: false,
        });
      })
      .catch((error) => {
        console.log(`Error updating user profile: ${error}`);
      });
  };

  updateLicenses = async (e) => {
    const {
      year_started,
      inspectorid,
      certification,
      certification_number,
      license_number_one,
      license_state_one,
      license_number_two,
      license_state_two,
      license_number_three,
      license_state_three,
      wdi_license,
      wdi_state,
      radon_license,
      radon_state,
      idToken,
    } = this.state;
    let inspection_features = JSON.stringify(this.state.inspection_features);
    e.preventDefault();
    if (!license_number_one || !license_state_one || !year_started)
      Materialize.toast({
        html: "Required fields cannot be left blank.",
        classes: "rounded",
      });
    const body = {
      inspectorid,
      certification,
      certification_number,
      license_number_one,
      license_state_one,
      license_number_two,
      license_state_two,
      license_number_three,
      license_state_three,
      wdi_license,
      wdi_state,
      radon_license,
      radon_state,
      inspection_features,
      year_started,
      token: idToken,
    };
    updateInspectorLicenses(body)
      .then(() => {
        Materialize.toast({
          html: "Inspection information has been updated.",
          classes: "rounded",
        });
        const newBody = JSON.parse(JSON.stringify(body));
        newBody.inspection_features = JSON.parse(newBody.inspection_features);
        this.setState({
          isDisabled: true,
          ...newBody,
          defaultInspector: newBody,
          address_error: false,
        });
      })
      .catch((error) => {
        console.log(`Error updating user profile: ${error}`);
      });
  };

  updateInspector = async (e) => {
    const {
      currentPage,
      year_started,
      oriEmail,
      inspectorid,
      inspection_company_id,
      photopreview,
      inspector_first_name,
      inspector_last_name,
      i_email,
      temp_cell_number,
      photoid,
      i_address_one,
      i_address_two,
      i_state,
      i_city,
      i_zipcode,
      bio,
      languages,
      certification,
      certification_number,
      license_number_one,
      license_state_one,
      license_number_two,
      license_state_two,
      license_number_three,
      license_state_three,
      wdi_license,
      wdi_state,
      radon_license,
      radon_state,
      idToken,
    } = this.state;
    let inspection_features = JSON.stringify(this.state.inspection_features);
    const i_area_code = temp_cell_number.slice(0, 3);
    const i_cell_number = temp_cell_number.slice(3);
    e.preventDefault();
    const root = inspectorApp.storage().ref();
    let newPhoto = "";
    if (photopreview) {
      const profileImage = root.child(
        `${i_email.toLowerCase()}/profile/${this.state.photoref.name}`
      );
      try {
        const snapshot = await profileImage.put(this.state.photoref);
        const url = await snapshot.ref.getDownloadURL();
        newPhoto = url;
      } catch (err) {
        console.log(err);
      }
    }
    if (
      ((!inspector_first_name ||
        !inspector_last_name ||
        !i_email ||
        !temp_cell_number) &&
        currentPage === "user_info") ||
      ((!i_address_one || !i_state || !i_city || !i_zipcode) &&
        currentPage === "user_address") ||
      ((!certification ||
        !certification_number ||
        !license_number_one ||
        !license_state_one ||
        !year_started) &&
        currentPage === "inspector_info")
    )
      Materialize.toast({
        html: "Required fields cannot be left blank.",
        classes: "rounded",
      });
    else if (temp_cell_number.length !== 10) {
      Materialize.toast({
        html: "Please enter a valid phone number.",
        classes: "rounded",
      });
    } else if (oriEmail === i_email) {
      const body = {
        inspectorid,
        inspector_first_name,
        inspector_last_name,
        i_email,
        i_area_code,
        i_cell_number,
        i_address_one,
        i_address_two,
        i_state,
        i_city,
        i_zipcode,
        bio,
        languages,
        certification,
        certification_number,
        license_number_one,
        license_state_one,
        license_number_two,
        license_state_two,
        license_number_three,
        license_state_three,
        wdi_license,
        wdi_state,
        radon_license,
        radon_state,
        inspection_features,
        year_started,
        inspection_company_id,
        token: idToken,
        photoid,
      };
      getLocationFromAddress(i_address_one, i_city, i_state, i_zipcode)
        .then((res) => {
          body.latitude = res.geometry.location.lat;
          body.longitude = res.geometry.location.lng;
          if (newPhoto) body.photoid = newPhoto;
          console.log(body);
          updateInspector(body);
          return newPhoto;
        })
        .then((photo) => {
          let user = inspectorFirebase.auth().currentUser;
          user
            .updateProfile({
              displayName: `${inspector_first_name} ${inspector_last_name}`,
              photoURL: photo ? photo : this.state.photoid,
            })
            .then(() => {
              Materialize.toast({
                html: "User Information has been updated.",
                classes: "rounded",
              });
              const newBody = JSON.parse(JSON.stringify(body));
              newBody.inspection_features = JSON.parse(
                newBody.inspection_features
              );
              this.setState({
                i_area_code,
                i_cell_number,
                isDisabled: true,
                photopreview: "",
                ...newBody,
                defaultInspector: newBody,
                address_error: false,
              });
            })
            .catch((error) => {
              console.log(`Error updating user profile: ${error}`);
            });
        });
    }
  };

  handleReAuth = async (e) => {
    e.preventDefault();
    const {
      year_started,
      oriEmail,
      reAuthEmail,
      reAuthPassword,
      inspectorid,
      inspection_company_id,
      photopreview,
      inspector_first_name,
      inspector_last_name,
      i_email,
      password,
      temp_cell_number,
      confirmPassword,
      photoid,
      i_address_one,
      i_address_two,
      i_state,
      i_city,
      i_zipcode,
      bio,
      languages,
      certification,
      certification_number,
      license_number_one,
      license_state_one,
      license_number_two,
      license_state_two,
      license_number_three,
      license_state_three,
      wdi_license,
      wdi_state,
      radon_license,
      radon_state,
      idToken,
    } = this.state;
    const i_area_code = temp_cell_number.slice(0, 3);
    const i_cell_number = temp_cell_number.slice(3);
    let inspection_features = JSON.stringify(this.state.inspection_features);
    const root = inspectorApp.storage().ref();
    let newPhoto = "";
    if (photopreview) {
      const profileImage = root.child(
        `${i_email.toLowerCase()}/profile/${this.state.photoref.name}`
      );
      try {
        const snapshot = await profileImage.put(this.state.photoref);
        const url = await snapshot.ref.getDownloadURL();
        newPhoto = url;
      } catch (err) {
        console.log(err);
      }
    }
    const body = {
      inspectorid,
      inspector_first_name,
      inspector_last_name,
      i_email,
      i_area_code,
      i_cell_number,
      i_address_one,
      i_address_two,
      i_state,
      i_city,
      i_zipcode,
      bio,
      languages,
      certification,
      certification_number,
      license_number_one,
      license_state_one,
      license_number_two,
      license_state_two,
      license_number_three,
      license_state_three,
      wdi_license,
      wdi_state,
      radon_license,
      radon_state,
      inspection_features,
      year_started,
      photoid,
      inspection_company_id,
      token: idToken,
    };
    if (newPhoto) body.photoid = newPhoto;
    let user = inspectorFirebase.auth().currentUser;
    if (
      !inspector_first_name ||
      !inspector_last_name ||
      !i_email ||
      !temp_cell_number
    )
      Materialize.toast({
        html: "Required fields cannot be left blank.",
        classes: "rounded",
      });
    else if (temp_cell_number.length !== 10) {
      Materialize.toast({
        html: "Please enter a valid phone number.",
        classes: "rounded",
      });
    } else if (password.length < 8) {
      Materialize.toast({
        html: "Password must be longer than 8 characters",
        classes: "rounded",
      });
    } else if (!isComplexPassword(password)) {
      Materialize.toast({
        html: "Password is too simple",
        classes: "rounded",
      });
    } else {
      getLocationFromAddress(i_address_one, i_city, i_state, i_zipcode).then(
        (res) => {
          body.latitude = res.lat;
          body.longitude = res.lng;
          let cred = firebase.auth.EmailAuthProvider.credential(
            reAuthEmail,
            reAuthPassword
          );
          user
            .reauthenticateWithCredential(cred)
            .then(function () {
              if (oriEmail.toUpperCase() !== i_email.toUpperCase()) {
                user
                  .updateEmail(i_email.toUpperCase())
                  .then(function () {
                    updateInspector(body);
                    Materialize.toast({
                      html: "User Information has been updated.",
                      classes: "rounded",
                    });
                  })
                  .catch(function (error) {
                    Materialize.toast({ html: error, classes: "rounded" });
                  });
              } else if (password === confirmPassword) {
                user
                  .updatePassword(password)
                  .then(function () {
                    Materialize.toast({
                      html: "Password has been updated.",
                      classes: "rounded",
                    });
                  })
                  .catch(function (error) {
                    Materialize.toast({ html: error, classes: "rounded" });
                  });
              }
            })
            .catch(function (error) {
              Materialize.toast({ html: error, classes: "rounded" });
            });
          const newBody = JSON.parse(JSON.stringify(body));
          newBody.inspection_features = JSON.parse(newBody.inspection_features);
          this.setState({
            i_area_code,
            i_cell_number,
            isDisabled: true,
            photopreview: "",
            ...newBody,
            defaultInspector: newBody,
          });
        }
      );
    }
  };

  handleChangeAuthEmail = (e) => {
    this.setState({
      reAuthEmail: e.target.value,
    });
  };

  handleChangeAuthPass = (e) => {
    this.setState({
      reAuthPassword: e.target.value,
    });
  };

  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  handleManagement = (e) => {
    let body = {
      token: this.state.idToken,
      inspectorid: this.state.inspectorid,
      account_active: !this.state.account_active,
    };
    accountManagement(body)
      .then(() => {
        if (body.account_active)
          Materialize.toast({
            html:
              "Your account has been reactivated. You can now get new job requests.",
            classes: "rounded",
          });
        else
          Materialize.toast({
            html:
              "Your account has been frozen. You will no longer get new job requests.",
            classes: "rounded",
          });
        this.setState({ account_active: !this.state.account_active }, () => {
          this.getInspectorInfo();
        });
      })
      .catch((err) => {
        console.log(err);
        if (err)
          Materialize.toast({
            html: "Something went wrong. Please Try again later.",
            classes: "rounded",
          });
      });
  };

  CurrentView = () => {
    const {
      currentPage,
      isDisabled,
      photopreview,
      year_started,
      oriEmail,
      languageOptions,
      licenseAmount,
      inspectorid,
      inspector_first_name,
      inspector_last_name,
      i_email,
      password,
      i_cell_number,
      i_area_code,
      temp_cell_number,
      confirmPassword,
      photoid,
      i_address_one,
      i_address_two,
      i_state,
      i_city,
      i_zipcode,
      bio,
      languages,
      certification,
      certification_number,
      license_number_one,
      license_state_one,
      license_number_two,
      license_state_two,
      license_number_three,
      license_state_three,
      wdi_license,
      wdi_state,
      radon_license,
      radon_state,
      inspection_features,
      comp_name,
      comp_address_one,
      comp_address_two,
      comp_state,
      comp_city,
      comp_zipcode,
      comp_email,
      comp_cell_number,
      comp_area_code,
      temp_comp_cell_number,
      photo_collection,
      personal_tos,
      sample_report,
      idToken,
      address_error,
      account_active,
      isUpdatePhotoModalVisible,
    } = this.state;
    if (currentPage === "user_info")
      return (
        <UserInfo
          idToken={idToken}
          getInspectorInfo={this.getInspectorInfo}
          inspectorid={inspectorid}
          oriEmail={oriEmail}
          inspector_first_name={inspector_first_name}
          inspector_last_name={inspector_last_name}
          i_email={i_email}
          password={password}
          confirmPassword={confirmPassword}
          i_area_code={i_area_code}
          i_cell_number={i_cell_number}
          temp_cell_number={temp_cell_number}
          bio={bio}
          languages={languages}
          languageOptions={languageOptions}
          photo_collection={photo_collection}
          handleBio={(e) => {
            this.handleRestrictedLength(e, "bio", 501);
          }}
          handleChange={this.handleChange}
          handleCell={(e) => {
            this.handleNumRestrictedLength(e, "temp_cell_number", 11);
          }}
          handleEdit={this.handleEdit}
          isDisabled={isDisabled}
          handleCheckbox={this.handleCheckbox}
          updateUser={this.updateUser}
          toggleUpdatePhotoModalVisibility={this.toggleUpdatePhotoModalVisibility}
          isUpdatePhotoModalVisible={isUpdatePhotoModalVisible}
        />
      );
    else if (currentPage === "user_address")
      return (
        <UserAddress
          i_address_one={i_address_one}
          i_address_two={i_address_two}
          i_state={i_state}
          i_city={i_city}
          i_zipcode={i_zipcode}
          address_error={address_error}
          handleChange={this.handleChange}
          handleState={(e) => {
            this.handleStateRestrictedLength(e, "i_state", 3);
          }}
          handleZipcode={(e) => {
            this.handleNumRestrictedLength(e, "i_zipcode", 6);
          }}
          handleEdit={this.handleEdit}
          isDisabled={isDisabled}
          updateAddress={this.updateAddress}
        />
      );
    else if (currentPage === "inspector_info")
      return (
        <InspectorInfo
          idToken={idToken}
          inspectorid={inspectorid}
          i_email={i_email}
          personal_tos={personal_tos}
          sample_report={sample_report}
          getInspectorInfo={this.getInspectorInfo}
          licenseAmount={licenseAmount}
          inspection_features={inspection_features}
          year_started={year_started}
          certification={certification}
          certification_number={certification_number}
          license_number_one={license_number_one}
          license_state_one={license_state_one}
          license_number_two={license_number_two}
          license_state_two={license_state_two}
          license_number_three={license_number_three}
          license_state_three={license_state_three}
          wdi_license={wdi_license}
          wdi_state={wdi_state}
          radon_license={radon_license}
          radon_state={radon_state}
          addLicense={this.addLicense}
          handleState={this.handleStateRestrictedLength}
          handleEdit={this.handleEdit}
          handleYearStarted={(e) => {
            this.handleNumRestrictedLength(e, "year_started", 5);
          }}
          handleChange={this.handleChange}
          handleSelectOptions={this.handleSelectOptions}
          handleCheckbox={this.handleCheckbox}
          isDisabled={isDisabled}
          updateLicenses={this.updateLicenses}
        />
      );
    else if (currentPage === "company_info")
      return (
        <CompanyInfo
          comp_name={comp_name}
          comp_address_one={comp_address_one}
          comp_address_two={comp_address_two}
          comp_state={comp_state}
          comp_city={comp_city}
          comp_zipcode={comp_zipcode}
          comp_email={comp_email}
          comp_cell_number={comp_cell_number}
          comp_area_code={comp_area_code}
          temp_comp_cell_number={temp_comp_cell_number}
          address_error={address_error}
          handleEdit={this.handleEdit}
          handleChange={this.handleChange}
          handleSameCheckbox={this.handleSameCheckbox}
          handleCell={(e) => {
            this.handleNumRestrictedLength(e, "temp_comp_cell_number", 11);
          }}
          handleState={(e) => {
            this.handleStateRestrictedLength(e, "comp_state", 3);
          }}
          handleZipcode={(e) => {
            this.handleNumRestrictedLength(e, "comp_zipcode", 6);
          }}
          isDisabled={isDisabled}
          updateCompany={this.updateCompany}
        />
      );
    else if (currentPage === "account_management")
      return (
        <Manage
          account_active={account_active}
          handleManagement={this.handleManagement}
        />
      );
    else if (currentPage === "payment_info") return <InspectorStripe />;
    else if (currentPage === "reviews") {
      return <Reviews companyId={this.state.companyid} inspectorId={this.state.inspectorid} />
    }
    else if (currentPage === "price_calculator") {
      if(window.screen.width < 993) {
        return (
          <div style={{display: 'flex', justifyContent: 'center', fontSize: 'medium'}}>
            <b>For price calculator please visit our desktop site</b>
          </div>
        )
      }
      return <PriceCalculator />
    }
    else if (currentPage === "tools") {
      return <Tools inspectorId={inspectorid} />
    }
    else return <h4>Under Construction. Please come back later.</h4>;
  };

  render() {
    const {
      currentPage,
      isDisabled,
      reAuthEmail,
      reAuthPassword,
      password,
      confirmPassword,
    } = this.state;
    return (
      <div className="row mt-5 pt-5" style={{ minHeight: "75vh" }}>
        <div id="isEditModal" className="modal" style={{ minHeight: "40vh" }}>
          <div className="modal-content">
            <h4>Uh oh, Looks like you were editing.</h4>
            <p>
              Are you sure you want to leave the page? You have unsaved changes.
              Do you wish to leave?
            </p>
          </div>
          <div className="modal-footer">
            <div className="row mb-0">
              <div className="col s12 m6">
                <button
                  onClick={this.handleEditContinue}
                  style={{ width: "100%" }}
                  className="my-1 modal-close housky-primary-complimentary-bg btn"
                >
                  Leave the page
                </button>
              </div>
              <div className="col s12 m6">
                <button
                  style={{ width: "100%" }}
                  className="my-1 modal-close housky-secondary-complimentary-bg btn"
                >
                  Continue editing
                </button>
              </div>
            </div>
          </div>
        </div>
        <ReAuthModal
          reAuthEmail={reAuthEmail}
          reAuthPassword={reAuthPassword}
          handleChange={this.handleChange}
          handleReAuth={this.handleReAuth}
        />
        <RePasswordModal
          hidden={this.state.hidden}
          toggleShow={this.toggleShow}
          reAuthEmail={reAuthEmail}
          reAuthPassword={reAuthPassword}
          password={password}
          confirmPassword={confirmPassword}
          handleChange={this.handleChange}
          handleReAuth={this.handleReAuth}
          handleChangeAuthEmail={this.handleChangeAuthEmail}
          handleChangeAuthPass={this.handleChangeAuthPass}
        />
        <div className="col s12 m3">
          <AccountNav
            currentPage={currentPage}
            handleNav={this.handleNav}
            isDisabled={isDisabled}
          />
        </div>
        <UploadPhotoModal
          inspectorId={this.state.inspectorid}
          token={this.state.idToken}
          updateAccountPhotoPreview={this.updateAccountPhotoPreview}
          isUpdatePhotoModalVisible={this.state.isUpdatePhotoModalVisible}
          toggleUpdatePhotoModalVisibility={this.toggleUpdatePhotoModalVisibility}
          inspectorEmail={this.state.i_email}
          />
        <div className="col s12 m9" style={{ marginBottom: "80px" }}>
          <this.CurrentView />
        </div>
      </div>
    );
  }
}
