import React from "react";
import moment from "moment";
import {calculateInspectorPayout} from "../../../utils/utils";

// Hover effect for hovering over infobox
// Currently commented out as all jobs should be paid before inspector accepts
// const handleHover = (id) => {
//   document.getElementById(id).classList.add("info-box-active");
// };
// const handleLeave = (id) => {
//   document.getElementById(id).classList.remove("info-box-active");
// };

const JobRow = (props) => {
  const {
    bathroom,
    bedroom,
    address_one,
    first_name,
    house_type,
    jobid,
    last_name,
    eventinfo,
    latitude,
    longitude,
    inspection_cost,
    expiration,
    sign_date,
    inspector_offer,
  } = props.jobData;
  const tableHeaders = typeof props.tableHeaders !== "undefined" ? props.tableHeaders : [];
  const clickEvent = props.clickEvent;
  const firstName = `${first_name[0]}${first_name.slice(1).toLowerCase()}`;
  const lastName = `${last_name[0]}${last_name.slice(1).toLowerCase()}`;
  const fullName = `${firstName} ${lastName}`;
  const distance = ((Math.pow(latitude - props.inspectorLat, 2) + Math.pow(longitude - props.inspectorLng, 2)) / 2) * 100;
  return (
    <tr className="mouse-pointer" id={jobid} onClick={clickEvent}>
      {!!tableHeaders[0] && <td>{moment(eventinfo[0].unix, "x").format("MM/DD/YYYY")}</td>}
      {!!tableHeaders[1] && <td className="hideHeader">{fullName}</td>}
      {!!tableHeaders[2] && <td>{house_type}</td>}
      {!!tableHeaders[3] && <td className="hideHeader">{`${bedroom} / ${bathroom}`}</td>}
      {!!tableHeaders[4] && <td>{address_one}</td>}
      {!!tableHeaders[5] &&
        <td className="hideHeader">
          {distance >= 0 && distance < 0.49
            ? "Near"
            : distance >= 0.5 && distance < 0.99
              ? "Average"
              : distance >= 1
                ? "Far"
                : distance >= 1.5
                  ? "Far away"
                  : "Not available"}
        </td>
      }
      {!!tableHeaders[6] &&
        <td
          style={{color: "green", position: "relative", fontWeight: "bold"}}
          // onMouseLeave={() => {
          //   handleLeave(`info-box-${jobid}`);
          // }}
          // onMouseOver={() => {
          //   handleHover(`info-box-${jobid}`);
          // }}
        >
          {!!inspector_offer && !!inspector_offer.cost ? `$${calculateInspectorPayout(inspector_offer.cost)}` : '-'}
          {/* <span className="more-info-question">?</span>
        <div id={`info-box-${jobid}`} style={{ right: "0" }} className="more-info-box z-depth-2">
          <p>{paid ? "This user has paid the fees already." : "This user has yet to pay the fees."}</p>
        </div> */}
        </td>
      }
      {!!tableHeaders[7] && <td>{!!sign_date ? moment(sign_date).format("MMM DD YYYY") : ""}</td>}
    </tr>
  );
};

// const renderTime = ({ early_morning, morning, afternoon, late_afternoon }) => {
//   if (early_morning) return "07:00AM";
//   if (morning) return "09:00AM";
//   if (afternoon) return "12:00PM";
//   if (late_afternoon) return "03:00PM";
// };

export default JobRow;
