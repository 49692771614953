import React from 'react'

const SignUpTutorial = () => {

	return (
		<div>
			<div style={{display: "flex", justifyContent: "center", fontSize: "larger", marginTop: "20px"}}>
				Tutorial of how to set up your inspector account
			</div>
			<div className="hide-on-small-only" style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
				<iframe style={{width: "672px", height: "378px"}} src="https://www.youtube.com/embed/p7bL-n2a8GE" title="YouTube video player"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen></iframe>
			</div>
			<div className="hide-on-med-and-up" style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
				<iframe src="https://www.youtube.com/embed/p7bL-n2a8GE" title="YouTube video player"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen></iframe>
			</div>
		</div>
	)
}

export default SignUpTutorial;