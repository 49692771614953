import React from 'react';
import {Chart, Line, Point, Tooltip} from 'bizcharts';
import {generateDataForTotalCostGraph} from "./mapReduce";

export default function DashboardChart({ filteredJobs }) {

    const data = generateDataForTotalCostGraph(filteredJobs);

    const toolTip = (title, items) => {
        const { totalEarn, time, totalNumberOfInspection } = items[0].data;
        return (
            <div style={{padding:'3px'}}>
                <div style={{}}>
                    <b>{ time }</b>
                </div>
                <div>
                    <b>
                        {`Total Earn: `}
                    </b>
                    { `$${totalEarn}` }
                </div>
                <div>
                    <b>
                        {`Total Jobs: `}
                    </b>
                    { totalNumberOfInspection }
                </div>
            </div>
        );
    };

    const scale = {
      totalEarn: {
        min: 0
      }
    };

    return(
        <div style= {{ padding: '20px'}}>
            <Chart scale={scale} height={375} padding={[40, 20, 50, 40]} autoFit data={data} animate={false} >
              <Point position="time*totalEarn" color="city" shape='circle' />
              <Line shape="smooth" position="time*totalEarn" label="totalEarn" />
              <Tooltip showCrosshairs>
                  { toolTip }
              </Tooltip>
            </Chart>
       </div>
    )
}
