import React from 'react'
import { inspectorApp } from "../../../firebase"
import Materialize from "materialize-css/dist/js/materialize.min.js";
import { updateGallery } from '../../../services/service'

export default class UploadGallery extends React.Component {
  state = {
    photopreview: '',
    photoref: '',
  }

  handlePhoto = (e, preview, ref) => {
    if(e.target.files[0].size > 10000){
      Materialize.toast({html:"File is too large. 10MB limit", classes:'rounded'})
    }
    else if (!e.target.files[0]) {
      return null;
    } else {
      this.setState({
        [preview]: URL.createObjectURL(e.target.files[0]),
        [ref]: e.target.files[0]
      });
    }
  };

  handleUpload = async (e) => {
    e.preventDefault()
    let collection = []
    if (this.props.photo_collection)
      collection = [...this.props.photo_collection]
    let photoid = {};
    if (collection.length < 4) {
      const root = inspectorApp.storage().ref();
      if (this.state.photoref.name) {
        const profileImage = root.child(
          `${this.props.i_email.toLowerCase()}/gallery/${this.state.photoref.name}`
        );
        try {
          const snapshot = await profileImage.put(this.state.photoref);
          const url = await snapshot.ref.getDownloadURL();
          photoid = {
            url,
            name: this.state.photoref.name
          };
        } catch (err) {
          console.log(err);
        }
        collection.push(photoid)
        let body = {
          token:this.props.idToken,
          photo_collection: JSON.stringify(collection),
          inspectorid: this.props.inspectorid
        }
        updateGallery(body)
          .then(() => {
            this.setState({
              photopreview: '',
              photoref: ''
            })
            this.props.getInspectorInfo()
          })
          .catch((err) => {
            Materialize.toast({ html: err, classes: 'rounded' })
          })
      }
    }
    else {
      Materialize.toast({ html: 'You can only have 4 photos in your gallery', classes: 'rounded' })
    }
  }

  render() {
    return <form>
      <div className='col s12 m6'>
        <h6>Add to your Gallery</h6>
        <div className="file-field input-field">
          <div className="btn housky-secondary-complimentary-bg">
            <span>Choose</span>
            <input type="file" onChange={e => { this.handlePhoto(e, "photopreview", "photoref"); }} />
          </div>
          <div className="file-path-wrapper">
            <input className="file-path validate gray-text-color" type="text" />
          </div>
        </div>
        {this.state.photoref ?
          <button className='btn housky-primary-bg mb-2' onClick={this.handleUpload} style={{ width: '100%', height: 'auto', padding: '0.8rem' }}> Upload Photo</button> : <></>}
      </div>
      <div className='col s12 m6 valign-wrapper'>
        {this.state.photoref ?
          <img style={{ width: '100%', height: '250px', maxWidth: '250px', margin: 'auto' }} src={this.state.photopreview} alt='Gallery Preview' />
          : <></>}
      </div>
    </form>
  }
}