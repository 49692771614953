import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import AuthContext from "../../contexts/auth";
import { inspectorApp as firebase } from "../../firebase";
import BrandWhite from "../../assets/housky_logo-06-smaller.png";
import { closeNav } from '../../services/helper'
import Materialize from "materialize-css/dist/js/materialize.min.js";
import "./navbar.css";
import DomainSelector from "../GeneralComponents/DomainSelector";
import {getInspectorsByInspector} from "../../services/service";
import BrandIcon from "../../assets/housky_logo-07.png";

const handleHover = (id) => {
  document.getElementById(id).classList.add('info-box-active')
}
const handleLeave = (id) => {
  document.getElementById(id).classList.remove('info-box-active')
}

function Navbar(props) {

  const context = useContext(AuthContext)
  const [ isSticky, setIsSticky ] = useState(false);
  const [ tooltipVisible, setTooltipVisible ] = useState(false);
  const [ inspector, setInspector ] = useState({});

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const elems = document.querySelectorAll(".sidenav");
    Materialize.Sidenav.init(elems, { edge: "left" });

    const currentUser = firebase.auth().currentUser;
    if (!!currentUser) {
      currentUser.getIdToken(true).then((res) => {
        getInspectorsByInspector(res).then((inspector) => {
          setInspector(inspector);
        });
      });
    }
  }, [])

  const handleScroll = () => {
    if (window.location.pathname === '/' || window.location.pathname === '/pricing') {
      if (window.scrollY > 100) {
        setIsSticky(true);
        setTooltipVisible(false);
      }
      else {
        setIsSticky(false);
      }
    }
  }

  const toLogout = async () => {
    Materialize.toast({ html: "Logged out.", classes: "rounded" });
    await firebase.auth().signOut();
    window.location = 'https://inspector.housky.io';
  };

  const CheckLog = () => {
    if (context.authStatusCheck) {
      if (context.user) {
        return (
          <li onMouseEnter={() => handleHover('inspector-profile')}
              onMouseLeave={() => { handleLeave('inspector-profile') }}>
            <p className='navbar-link-items mouse-pointer'>
              <span className='center-align' style={{ position: 'relative', paddingRight: '30px' }}>
                {!!inspector.inspector_first_name ? inspector.inspector_first_name : 'PROFILE'}
                {context.user.photoURL ?
                  <img className="circle" style={{ position: 'absolute', width: '35px', height: '35px', top: '-8px', right: '-12px' }} src={context.user.photoURL} alt="Inspector"/>
                  : <></>}
                <i className="material-icons" style={{ position: 'absolute', top: '-8px', right: '0px', left: '0px' }}>arrow_drop_down</i>
              </span>
            </p>
            <div id='inspector-profile' className='more-info-box z-depth-2' style={{ right: '10px' }}>
              <Link to="/account" style={{borderBottom: window.location.pathname ==='/account' ? '2px solid #546A76': 'none'}} className='navbar-link-items black-text'>
                SETTINGS
              </Link>
              <Link onClick={toLogout}
                    to="/login"
                    className="navbar-link-items black-text">
                LOGOUT
              </Link>
            </div>
          </li>
        );
      } else {
        return (
          <React.Fragment>
            <DomainSelector domain={props.domain} setDomain={props.setDomain} />
            {/*<li><Link className={`nav-links`} style={{borderBottom: window.location.pathname ==='/pricing' ? '2px solid #546A76': 'none'}} to='/pricing'>PRICING</Link></li>*/}
            <li><Link className={`nav-links`} style={{borderBottom: window.location.pathname ==='/mission' ? '2px solid #546A76': 'none'}} to='/mission'>MISSION</Link></li>
            <li><Link className={`nav-links`} style={{borderBottom: window.location.pathname ==='/inspectorFaq' ? '2px solid #546A76': 'none'}} to='/inspectorFaq'>FAQ</Link></li>
            <li><Link className={`nav-links`} style={{borderBottom: window.location.pathname ==='/login' ? '2px solid #546A76': 'none'}} to='/login'>LOG IN</Link></li>
            <li><Link className={`nav-links nav-links-button`} style={{borderBottom: window.location.pathname ==='/signup' ? '2px solid #546A76': 'none'}} to='/signup'>SIGN UP</Link></li>
          </React.Fragment>
        );
      }
    }
  };

  const onClick = (e) => {
    props.setMockMode(e.target.checked);
  };

  const setToggleTooltipVisibility = (bool) => {
    return () => {
      setTooltipVisible(bool);
    }
  }

  const getTooltipText = () => {
    if(props.mockMode) {
      return `By turning this off, you will be able to see your own real data again`;
    } else {
      return `Turn mock mode on will generate some random data to give you a better idea of what this page is all about`
    }
  }

  const getMockModeToggle = () => {
    if(props.isMockModeActive) {
      return (
        <li>
          <div className="switch navbar-link-items" style={{position: 'relative'}} onMouseEnter={setToggleTooltipVisibility(true)} onMouseLeave={setToggleTooltipVisibility(false)}>
            {`Mock Mode: `}
            <label>
              Off
              <input type="checkbox" onClick={onClick} />
              <span className="lever"/>
              On
            </label>
            <div>
              { tooltipVisible &&
              <div className="toggleToolTip">
                <div><b>Mock Mode</b></div>
                { getTooltipText() }
              </div>
              }
            </div>
          </div>
        </li>
      );
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      <nav id='inspector-nav' className="" style={{ position: `${window.location.pathname === '/' || window.location.pathname === '/pricing' || window.location.pathname === '/mission' ? 'sticky' : 'relative'}`, boxShadow: "none", zIndex: "10", backgroundColor: `${window.location.pathname === '/login' ? '#3C4456' : ''}` }}>
        <div className="nav-wrapper">
          <div className="hide-on-med-and-down">
            <a href="https://housky.io">
              <img alt='Housky' className="brand-logo" src={BrandWhite} style={{ height: '100%', paddingLeft: '28px'}} />
            </a>
          </div>
          <div className="hide-on-large-only">
            <a href="https://housky.io">
              <img alt='Housky' className="brand-logo left" src={BrandIcon}/>
            </a>
          </div>

          <a href="!#" data-target="mobile-nav" className="sidenav-trigger m-0"><i className="material-icons" style={{ color: `black` }}>menu</i></a>
          <ul className="right hide-on-med-and-down nav-links-container">
            {context.authStatusCheck && context.user ? (
              <React.Fragment>
                { getMockModeToggle() }
                <DomainSelector domain={props.domain} setDomain={props.setDomain} />
                <li>
                  <Link to="/job-requests" style={{borderBottom: window.location.pathname ==='/job-requests' ? '2px solid #546A76': 'none'}} className='navbar-link-items'>
                    JOB REQUESTS
                  </Link>
                </li>
                <li>
                  <Link to="/active-jobs" style={{borderBottom: window.location.pathname ==='/active-jobs' ? '2px solid #546A76': 'none'}} className='navbar-link-items'>
                    ACTIVE JOBS
                  </Link>
                </li>
                <li>
                  <Link to="/history" style={{borderBottom: window.location.pathname ==='/history' ? '2px solid #546A76': 'none'}} className='navbar-link-items'>
                    JOB HISTORY
                  </Link>
                </li>
              </React.Fragment>
            ) : (
                <React.Fragment></React.Fragment>
              )}
            { CheckLog() }
          </ul>
          </div>
        </nav>

      <ul className="sidenav" id="mobile-nav">
        <li>
          <Link onClick={closeNav} to="/">HOME</Link>
        </li>
        {context.authStatusCheck && context.user ? (
          <React.Fragment>
            <li>
              <Link onClick={closeNav} to="/job-requests">JOB REQUESTS</Link>
            </li>
            <li>
              <Link onClick={closeNav} to="/active-jobs">ACTIVE JOBS</Link>
            </li>
            <li>
              <Link onClick={closeNav} to="/history">JOB HISTORY</Link>
            </li>
            <li>
              <Link onClick={closeNav} to="/account">SETTINGS</Link>
            </li>
            <li>
              <Link onClick={()=>{
                closeNav()
                toLogout()
              }} to="/">LOGOUT</Link>
            </li>
          </React.Fragment>
        ) : (
          <>
            <li>
              <Link to='/login' onClick={closeNav}>LOGIN</Link>
            </li>
          </>
        )}
      </ul>
    </React.Fragment>
  );
}

export default Navbar;
