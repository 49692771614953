import React from 'react'
import './terms.css'
import { HashLink as Link } from 'react-router-hash-link';
import { TableCell, TableList } from './TermTables'

export default () => {
  return <>
    <div className='row nav-offset'>
    </div>
    <div className='term-background'>
      <div className='container pt-5'>
        <div className='inner-container'>
          <div className='row mt-1 mb-0 p-1 pb-5 content-background'>
            <div className='col s12'>
              <div>
                <h3><b>Housky Privacy Statement</b></h3>
                <h6 className='gray-text'><i>Effective: April 11, 2020</i></h6>
              </div>
            </div>
            <article>
              <section className='py-1'>
                This Privacy Statement explains how Housky Inc (“Housky,” “we,” or “us”) collects and uses your
                personal information to provide our services to you. This Privacy Statement applies to personal
                information we collect when you use our websites and other online products and services
                (collectively, the “Services”) or when you otherwise interact with us. This Privacy Statement does not
                apply to websites, applications, or services that display or link to other privacy statements.
            </section>
              <section className='py-1'>
                We may change this Privacy Statement from time to time. If we make changes, we will notify you by
                revising the date at the top of this page. We encourage you to review the Privacy Statement
                whenever you access the Services or otherwise interact with us to stay informed about our privacy
                practices and the ways in which you can exercise choice over these practices. The practices
                described in this Privacy Statement are subject to applicable laws in the locations in which we
                operate; this means that we only engage in the practices described below in a particular location if
                permitted under the laws of that location. Capitalized terms used but not defined in this Privacy
                Statement have the meanings given to them in the Terms of Service.
            </section>
            </article>
            <div className='col s12'>
              <h4 style={{ color: '#689AEC' }}>Table of Contents</h4>
              <ul>
                <li><Link smooth to='#collect'>How do we collect and use your information?</Link></li>
                <li><Link smooth to='#share'>How do we share your information?</Link></li>
                <li><Link smooth to='#security'>Information Security</Link></li>
                <li><Link smooth to='#retention'>Information Retention</Link></li>
                <li><Link smooth to='#choice'>Your Choices</Link></li>
                <li><Link smooth to='#contact'>Contact Us</Link></li>
              </ul>
            </div>
            <article>
              <h4 id='collect' style={{ color: '#66cccc', fontWeight: '900' }}>How do we collect and use your information?</h4>
              <section className='py-1'>
                The information we collect varies based on how you interact with us, which Services you use, and
                the choices you make. We collect and process information about you with your consent and/or as
                necessary to provide the products and services you use, operate our business, meet our contractual and legal obligations, protect the security of our systems and our customers, or fulfill other
                legitimate interests.
</section>
              <section className='py-1'>
                We collect information about you in various ways when you use our Services, including information
                you provide directly, information collected automatically, and information collected via third-party
                data sources.
</section>
              <section className='py-1'>
                When you are asked to provide information, you may decline. But if you choose not to provide
                information that is necessary for certain products or features, those products or features may not be
                available or function correctly.
</section>
              <section className='py-1'>
                The table below sets forth how we may collect and use your personal information.
</section>
              <section className='col s12 p-0'>
                <div className='row m-0 term-table-body'>
                  <div className='col s4 term-table-cell'>
                    <b style={{ textAlign: 'center' }}>Category of Personal Data</b>
                  </div>
                  <div className='col s4 term-table-cell'>
                    <b style={{ textAlign: 'center' }}>Processing Purpose</b>
                  </div>
                  <div className='col s4 term-table-cell'>
                    <b style={{ textAlign: 'center' }}>Legal Basis for Processing*</b>
                  </div>
                </div>
                <div className='row m-0 term-table-body'>
                  <div className='col s12 term-table-cell'>
                    <b style={{ textAlign: 'center' }}>Information You Provide to Us</b>
                    <p>
                      We collect information you provide directly to us, as follows:
                </p>
                  </div>
                </div>
                <div className='row m-0 term-table-body'>
                  <TableCell
                    main={true}
                    title={"Account Information"}
                    body={[`Includes personal information
                  we collect when you create
                  an account, create or edit
                  your profile, or list your Home
                  Inspection Services through
                  Housky. Account information
                  includes your name, license
                  information, email address,
                  postal address, phone
                  number, photos, and any other
                  account-related information
                  you choose to provide.`]} />
                  <TableList
                    title={"We use this information to"}
                    body={["Create and register your account on Housky.",
                      "Provide, maintain and improve our Services.",
                      "Enable communications between you and other users of the Services.",
                      "Send you technical notices, updates, security alerts, and support and administrative messages.",
                      "Present you with content that is relevant to you."]} />
                  <TableCell
                    title={"Contract Performance"}
                    body={["Legitimate Interests"]} />
                </div>
                <div className='row m-0 term-table-body'>
                  <TableCell
                    main={true}
                    title={"Communication Information"}
                    body={[`Includes the content of any
                  communications you send
                  and receive through the
                  Services, such as messages
                  or reviews (including private
                  reviews), communications you send and receive when
                  you participate in any other
                  interactive features of the
                  Services, or your
                  communications to or from
                  Housky (including the content
                  of any recorded telephone
                  calls).`]} />
                  <TableList
                    title={"We use this information to"}
                    body={["Provide, maintain and improve our Services.",
                      "Detect, investigate and prevent fraudulent transactions and other illegal or unauthorized activities and protect the rights and property of Housky and others.",
                      "Respond to your comments, questions, and requests and provide customer service."]} />
                  <TableCell
                    title={"Consent"}
                    body={["Contract Performance",
                      "Legitimate Interests",
                      "Legal Obligation"]} />
                </div>
                <div className='row m-0 term-table-body'>
                  <TableCell
                    main={true}
                    title={"Legal Obligation"}
                    body={[`Includes payment information
                  you provide to us, such as the
                  amount and type of services
                  purchased, the date and time
                  of a transaction, and payment
                  card information.
                  (Transactions are processed
                  by our third-party payment
                  processors.)`,
                      `If you are a Service Provider,
                  we and our third-party
                  payment processors may
                  also collect certain payment
                  disbursement information to
                  distribute your revenue from
                  the Home Inspection services
                  you provide via the Housky
                  Service.`,
                      `If you are a Service Provider,
                  we may also collect your tax
                  identification number.`]} />
                  <TableList
                    title={"We use this information to"}
                    body={["Conduct Identity Verification or a Background Check prior to you listing your Home Inspection Services on the Housky Service;",
                      "Detect, investigate and prevent fraudulent transactions and other illegal or unauthorized activities and protect the rights and property of Housky and others;",
                      "Use this information to create a payment disbursement account with our third-party payment processor on your behalf."]} />
                  <TableCell
                    title={"Consent"}
                    body={["Contract Performance",
                      "Legitimate Interests",
                      "Legal Obligation"]} />
                </div>
                <div className='row m-0 term-table-body'>
                  <TableCell
                    main={true}
                    title={"Other Information"}
                    body={[`Includes all other personal
                  information you provide to us,
                  such as when you submit a job
                  application, participate in a
                  promotion, complete a
                  survey, communicate with us
                  via third-party social media
                  sites, request customer
                  support, provide us with
                  contact information for a
                  referral, or otherwise
                  communicate with us.`]} />
                  <TableList
                    title={"We use this information to"}
                    body={["Respond to your comments, questions, and requests and provide customer service;",
                      "Communicate with you about products, services, offers, promotions, rewards, and events offered by Housky and others, and provide news and information we think will be of interest to you;",
                      "Facilitate contests, sweepstakes, and promotions, and process and deliver entries and rewards;",
                      "Fulfill your referral requests;",
                      "Carry out any other purpose described to you at the time the information is collected."]} />
                  <TableCell
                    title={"Consent"}
                    body={["Contract Performance",
                      "Legitimate Interests",
                      "Legal Obligation"]} />
                </div>
                <div className='row m-0 term-table-body'>
                  <div className='col s12 term-table-cell'>
                    <b style={{ textAlign: 'center' }}>Information Collected Automatically When You Use the Services</b>
                    <p>
                      When you access or use our Services, we automatically collect information about you, as follows:
                </p>
                  </div>
                </div>

                <div className='row m-0 term-table-body'>
                  <div className='col s4 p-0'>
                    <div className='term-table-cell'>
                      <p style={{ textAlign: 'center', fontWeight: 600 }}>Log Information</p>
                      <p>We collect log information
                      about your use of the
                      Services, including the type of
                      browser you use, access
                      times, pages viewed, your IP
                      address, and the page you
                      visited before navigating to
our Services.</p>
                    </div>
                    <div className='term-table-cell'>
                      <p style={{ textAlign: 'center', fontWeight: 600 }}>Device Information</p>
                      <p>We collect information about
                      the computer or mobile
                      device you use to access our
                      Services, including the
                      hardware model, operating
                      system and version, unique
                      device identifiers, and mobile
network information.</p>
                    </div>
                    <div className='term-table-cell'>
                      <p style={{ textAlign: 'center', fontWeight: 600 }}>Location Information</p>
                      <p>We may collect information
                      about the location of your
                      device each time you access
                      or use certain features of our
                      applications or otherwise
                      consent to the collection of
                      this information. For more
                      details, please see “Your
Choices” below.</p>
                    </div>
                    <div className='term-table-cell'>
                      <p style={{ textAlign: 'center', fontWeight: 600 }}>Cookies Information</p>
                      <p>We use various technologies
                      to operate our Services and
                      collect information, including
                      cookies and web beacons
                      (collectively, “Cookies”). Most
                      browsers are set to accept
                      cookies. To learn more about
                      how we use Cookies and how
                      to manage your preferences,
                      please read our <a href="https://housky.io/terms/cookies" target="_blank" rel="noopener noreferrer">Cookie
                      Policy.</a></p>
                    </div>
                  </div>
                  <TableList
                    title={"We use this information to"}
                    body={["Present the Services to you on your device;",
                      "Provide, maintain and improve our Services;",
                      "Send you technical notices, updates, security alerts, and support and administrative messages;",
                      "Detect, investigate and prevent fraudulent transactions and other illegal or unauthorized activities and protect the rights and property of Housky and others;",
                      "Communicate with you about products, services, offers, promotions, rewards, and events offered by Housky and others, and provide news and information we think will be of interest to you;",
                      "Link or combine with information we get from other sources to help understand your needs and provide you with better service."]} />
                  <TableCell
                    title={"Consent"}
                    body={["Contract Performance",
                      "Legitimate Interests",
                      "Legal Obligation"]} />
                </div>
                <div className='row m-0 term-table-body'>
                  <div className='col s12 term-table-cell'>
                    <b style={{ textAlign: 'center' }}>Information We Collect from Third Parties</b>
                    <p>
                      We may collect information about you from third parties, as follows:
                </p>
                  </div>
                </div>
                <div className='row m-0 term-table-body'>
                  <TableCell
                    main={true}
                    title={"Social Media Information"}
                    body={[`If you create or log into your
                  account through a social
                  media site, we will have
                  access to certain information
                  from that site, such as your
                  name, account information,
                  and friends lists, in
                  accordance with the
                  authorization procedures
                  determined by such social
                  media sites.`]} />
                  <TableList
                    title={"We use this information to"}
                    body={["Allow you to log in to the Housky Service and to help create a public profile for you;",
                      "Allow you to share certain content related to your use of the Services with your friends, followers, or contacts on that social media sites."]} />
                  <TableCell
                    title={"Consent"}
                    body={["Contract Performance",
                      "Legitimate Interests",
                      "Legal Obligation"]} />
                </div>
                <div className='row m-0 term-table-body'>
                  <TableCell
                    main={true}
                    title={"Other Third-Party Information"}
                    body={[`Includes information from
                  other sources, such as
                  partners with whom we offer
                  co-branded services or
                  engage in joint marketing
                  activities, agencies that
                  conduct background checks or
                  identity verification services,
                  and publicly available
                  sources.`]} />
                  <TableList
                    title={"We may use this information on its own or in combination with information you provide to us directly to contact you, send you promotional materials, personalize our Service, prepopulate online forms, and better understand the demographics of our users."}
                    body={[]} />
                  <TableCell
                    title={"Consent"}
                    body={["Contract Performance",
                      "Legitimate Interests",
                      "Legal Obligation"]} />
                </div>
                <div className='row m-0 term-table-body'>
                  <div className='col s12 term-table-cell'>
                    <b style={{ textAlign: 'center' }}>All Information Set Forth Above</b>
                  </div>
                </div>
                <div className='row m-0 term-table-body'>
                  <TableCell
                    title={"All Information Set Forth Above"}
                    body={[]} />
                  <TableList
                    title={"We use this information to"}
                    body={["Provide, maintain and improve our Services;",
                      "Detect, investigate and prevent fraudulent transactions and other illegal or unauthorized activities and protect the rights and property of Housky and others;",
                      "Defend ourselves from potential litigation or legal claims;",
                      "Comply with any applicable legal obligations;",
                      "Carry out any other purpose described to you at the time the information is collected."]} />
                  <TableCell
                    title={"Consent"}
                    body={["Contract Performance",
                      "Legitimate Interests",
                      "Legal Obligation"]} />
                </div>
              </section>
              <section>
                <p><b>* Legal Basis Definitions and Explanations</b></p>
              </section>
              <section>
                <div className='row m-0 term-table-body'>
                  <div className='col s4 term-table-cell'>
                    <b style={{ textAlign: 'center' }}>Category of Personal Data</b>
                  </div>
                  <div className='col s4 term-table-cell'>
                    <b style={{ textAlign: 'center' }}>Processing Purpose</b>
                  </div>
                  <div className='col s4 term-table-cell'>
                    <b style={{ textAlign: 'center' }}>Legal Basis for Processing*</b>
                  </div>
                </div>
                <div className='row m-0 term-table-body'>
                  <TableCell title={"Consent"} body={[]} />
                  <TableCell title={"The data subject has given consent to the processing of his or her personal data for one or more specific purposes."} body={[]} />
                  <TableCell title={"We process this data because you have given us your consent to do so, which you may freely give or withdraw at any time."} body={[]} />
                </div>
                <div className='row m-0 term-table-body'>
                  <TableCell title={"Contract Performance"} body={[]} />
                  <TableCell title={"Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract."} body={[]} />
                  <TableCell title={"We process this data to take steps to enter into and perform a contract with you, namely our Terms of Service. This includes transmitting communications on your behalf, facilitating transactions on the Housky Service, notifying you of important product or service issues and updates, publishing content, and resolving customer support inquiries."} body={[]} />
                </div>
                <div className='row m-0 term-table-body'>
                  <TableCell title={"Legitimate Interests"} body={[]} />
                  <TableCell title={"Processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data."} body={[]} />
                  <TableCell title={"We process this data to pursue our legitimate interests, namely in running our business effectively and properly. This includes developing and improving our products and services, measuring and understanding how people use our products and services, sending you marketing or other service-related communications, conducting market research, and preventing and detecting fraud, or otherwise protecting our users, systems, networks, and communications. Your data will not be processed in reliance on this legal basis if our interests are overridden by your interests or fundamental rights."} body={[]} />
                </div>
                <div className='row m-0 term-table-body'>
                  <TableCell title={"Legal Obligation"} body={[]} />
                  <TableCell title={"Processing is necessary for compliance with a legal obligation to which the controller is subject."} body={[]} />
                  <TableCell title={"We process this data to ensure our compliance with our legal obligations. This includes compliance with financial and tax reporting obligations and responding to governmental requests and legal proceedings."} body={[]} />
                </div>
              </section>
            </article>
            <article>
              <h4 id='share' style={{ color: '#66cccc', fontWeight: '900' }}>How Do We Share Your Information?</h4>
              <section>
                <p>
                  We may share the personal information we collect with third parties as follows or as otherwise
                  described in this Privacy Statement:
                  </p>
              </section>
              <section>
                <ul>
                  <li className='term-list'>when you make or accept a booking;</li>
                  <li className='term-list'>when you sign up for and participate in the interactive areas of our Services, such as creating a user profile or writing a review;</li>
                  <li className='term-list'>with vendors, consultants, business partners, and other service providers who need access to such information to carry out work on our behalf;</li>
                  <li className='term-list'>in response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, or legal process;</li>
                  <li className='term-list'>if we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, or safety of Housky, its employees, its users (including their property), or the public;</li>
                  <li className='term-list'>in connection with, or during negotiations of, any merger, sale of company assets, financing, acquisition, divestiture, bankruptcy, dissolution, or other transaction or proceeding involving sale, transfer, or divestiture of all or a portion of our business or assets to another company, to the extent permitted under applicable law;</li>
                  <li className='term-list'>between and among Housky and our current and future parents, affiliates, subsidiaries, and other companies under common control and ownership;</li>
                  <li className='term-list'>on an aggregated and anonymized basis; and</li>
                  <li className='term-list'>with your consent or at your direction.</li>
                </ul>
              </section>
              <section>
                <h5><b>Social Sharing Features</b></h5>
                <p>
                  The Services may offer social sharing features and other integrated tools (such as the Facebook
                  “Like” button), which let you share actions you take on our Services with other media and vice versa.
                  Your use of such features enables the sharing of information with your friends or the public,
                  depending on the settings you establish with the entity that provides the social sharing feature. For
                  more information about the purpose and scope of information collection and processing in
                  connection with social sharing features, please visit the privacy policies of the entities that provide
                  these features.
                  </p>
                <h5><b>Advertising and Analytics Services Provided by Third Parties</b></h5>
                <p>
                  We may allow others to provide analytics services and/or serve advertisements on our behalf across
                  the Internet. These entities may use cookies, pixel tags, web beacons, or other similar technologies
                  to collect information about your use of our websites and third-party websites. To learn more about
                  how these third parties use these technologies and how to manage your preferences, please read
                  our Cookie Policy. Note that if you opt out of third-party advertising cookies, you will still see online
                  ads, but they will not be personalized.
                  </p>
              </section>
            </article>
            <article>
              <h4 id='security' style={{ color: '#66cccc', fontWeight: '900' }}>Information Security</h4>
              <section>
                <p>
                  Housky takes reasonable measures designed to help protect information about you from loss, theft,
                  misuse, and unauthorized access, use, disclosure, alteration, and destruction. Please be aware that
                  no method of transmission over the Internet or through mobile applications is 100% safe. Therefore,
                  although we use commercially reasonable means to protect your information, we cannot guarantee
                  its absolute security.
               </p>
              </section>
              <section>
                <h5><b>Transfer and Location of Information</b></h5>
                <p>
                  The information we collect may be stored and processed in your region, in the United States, or in
                  any other country where we or our affiliates, subsidiaries, or service providers maintain facilities. The
                  processing locations are chosen in order to operate efficiently, to improve performance, and to
                  create redundancies in order to protect information in the event of an outage or other problem. We
                  take steps designed to ensure that the information we collect under this statement is processed
                  according to the provisions of this statement and applicable law wherever the information is located.
               </p>
              </section>
            </article>
            <article>
              <h4 id='retention' style={{ color: '#66cccc', fontWeight: '900' }}>Information Retention</h4>
              <section>
                <p>
                  We retain information for as long as necessary to provide the products and fulfill the transactions
                  you have requested, or for other essential purposes such as complying with our legal obligations,
                  resolving disputes, and enforcing our agreements. Because these needs can vary for different
                  information types in the context of different products, actual retention periods can vary based on
                  criteria such as user expectations or consent, the sensitivity of the information, the availability of
                  automated controls that enable users to delete information, and our legal or contractual obligations.
               </p>
              </section>
            </article>
            <article>
              <h4 id='choice' style={{ color: '#66cccc', fontWeight: '900' }}>Your Choice</h4>
              <section>
                <h5><b>Managing Your Account</b></h5>
                <p>
                  You may update, correct, or delete certain information about you at any time by logging into your
                  account through our website and navigating to the "Profile" link. If you need assistance, you can
                  contact us.
               </p>
                <p>
                  If you wish to deactivate your account, please contact us. We may decline requests that are
                  prohibited by law, could adversely affect the privacy or other rights of another person, where we are
                  unable to authenticate you as the person to whom the information relates, or are not required to be
                  honored by applicable law.
               </p>
              </section>
            </article>
            <article>
              <h4 id='location' style={{ color: '#66cccc', fontWeight: '900' }}>Location Information</h4>
              <section>
                <p>
                  When you launch any of our applications that collect location information, you will be asked to
                  consent to the application’s collection of this information. We require location information to use
                  certain features of our Services, so if you do not consent to this collection, you cannot use those
                  features.
               </p>
              </section>
            </article>
            <article>
              <h4 id='contact' style={{ color: '#66cccc', fontWeight: '900' }}>Contact Us</h4>
              <section>
                <h5><b>Account Deactivation</b></h5>
                <p>
                  Please email us at info@housky.io.
               </p>
              </section>
              <section>
                <h5><b>Privacy Concerns</b></h5>
                <p>
                  If you have any questions, concerns, or complaints about this Privacy Statement, please email us at <a href="mailto:info@housky.io">info@housky.io</a>
                </p>
              </section>
            </article>
          </div>
        </div>
      </div>
    </div>
  </>
}