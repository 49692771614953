import React, {useContext, useState} from 'react';
import {inspectorApp as firebase} from "../../../firebase";
import AuthContext from "../../../contexts/auth";

const UploadFiles = ({folderName, fileName, callbackSuccess, callbackFail}) => {
  const context = useContext(AuthContext);
  const [preAgreementPDF, setPreAgreementPDF] = useState({});
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadError, setUploadError] = useState("");

  const handleReport = (e, preview, ref) => {
    if (!e.target.files[0]) {
      return <React.Fragment/>;
    } else {
      setPreAgreementPDF({
        [preview]: URL.createObjectURL(e.target.files[0]),
        [ref]: e.target.files[0],
      });
    }
  };

  const uploadReportToFirebase = () => {
    let storageRef = firebase.storage().ref();
    let inspectorJobsRef = storageRef.child(context.user.email).child(folderName);
    let reportURLRef = inspectorJobsRef.child(`${fileName}.pdf`);
    let reportUploadTask = reportURLRef.put(preAgreementPDF.pdfref);

    reportUploadTask.on(
      "state_changed",
      (snapshot) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadStatus(progress > 10 && Number.parseInt(progress));
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        setUploadError("There was an error uploading the file, please try it again");
        console.log(error);

        if (typeof callbackFail !== "undefined") {
          callbackFail(error);
        }
      },
      () => {
        reportUploadTask.snapshot.ref
          .getDownloadURL()
          .then((fileUrl) => {
            if (typeof callbackSuccess !== 'undefined') {
              callbackSuccess(fileUrl);
            }
          })
          .catch((error) => {
            setUploadError("There was an error uploading the file, please try it again");
            console.log(error);

            if (typeof callbackFail !== "undefined") {
              callbackFail(error);
            }
          });
      }
    );
  };

  return (
    <>
      <form className="col s12">
          <label>Upload agreement</label>
          <div className="file-field input-field">
            <div className="btn">
              <span>Browse</span>
              <input
                className="btn mt-5"
                type="file"
                onChange={(e) => {
                  handleReport(e, "pdfreview", "pdfref");
                }}
                accept="application/pdf"
                placeholder="Upload agreement"
              />
            </div>
            <div className="file-path-wrapper">
              <input className="file-path validate" type="text" placeholder="Upload pdf"/>
            </div>
          </div>
      </form>
      {
        !!preAgreementPDF.pdfref ?
          <div className="row center">
            {uploadStatus && (
              <div style={{width: "100%"}}>
                <span>{uploadStatus}% Complete</span>
              </div>
            )}
            <button
              style={{height: "auto"}}
              type="submit"
              onClick={uploadReportToFirebase}
              className="btn mt-5"
              disabled={!!uploadStatus}
            >
              Confirm
            </button>
          </div> : <React.Fragment/>
      }
      {
        !!uploadError &&
        <div style={{width: "100%", color: "darkred"}}>
          <span>{uploadError}</span>
        </div>
      }
    </>
  );
};

export default UploadFiles;
