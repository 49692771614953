import React from 'react';
import {useHistory} from "react-router-dom";
import cssStyles from "./InspectorSignUp.module.css";

const InspectorSignUp_Step5 = () => {

    const history = useHistory();

    const onClick = () => {
      history.push(`/job-requests`);
    };

    return(
        <div className={cssStyles.message_container}>
            <div>
                <span className={cssStyles.step5_title}>You have completed your account</span>
            </div>
            <div style={{textAlign:'center'}}>
                Thank you for setting up your profile. Please go to your account settings to finish the price calculator setting and tool setting.
            </div>
            <div style={{marginTop:'8%'}}>
                <button className={`browser-default ${cssStyles.button}`} onClick={onClick}><b>GO TO ACCOUNT</b></button>
            </div>
        </div>
    )
}

export default InspectorSignUp_Step5;