import React from "react";
import Materialize from "materialize-css/dist/js/materialize.min.js";

class Attic extends React.Component {
  componentDidMount() {
    let elem = document.querySelectorAll("select");
    Materialize.FormSelect.init(elem, {
      classes: ""
    });
  }
  render() {
    const { attic } = this.props;
    return (
      <div className="input-field col s6">
        <select
          id="attic"
          name="attic"
          value={attic}
          onChange={this.props.handleChange}
        >
          <option value="Select Attic">Select Attic</option>
          <option value="Not Applicable">Not Applicable</option>
          <option value="Attic(Finished)">Attic(Finished)</option>
          <option value="Attic(Unfinished)">Attic(Unfinished)</option>
          <option value="Crawlspace">Crawlspace</option>
          <option value="Other">Other</option>
        </select>
        <label htmlFor="attic">Attic</label>
      </div>
    );
  }
}

export default Attic;
