import React from "react";
import { withRouter } from "react-router-dom";
import "./houseReport.css";
import ReportPDF from "../../../components/UserDomain/HouseReport/ReportPDF";
import { app as firebase } from "../../../firebase";
import { getReportByUser } from "../../../services/service";
import AuthContext from "../../../contexts/auth";
import UserReportInfo from "./UserReportInfo";
import CancelJob from "../../../components/InspectorDomain/CancelJob/CancelJob";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import ContractAgreement from "./ContractAgreement";

class UserReportComponent extends React.Component {
  static contextType = AuthContext;
  state = {
    viewPDF: false,
    idToken: null,
    report: {},
    pageError: null,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        this.setState({ idToken });
        getReportByUser(idToken, this.props.match.params.report).then(
          (data) => {
            data.reportid && this.setState({ report: { ...data } });
            !data.reportid &&
              this.setState({
                pageError: `ERROR, report does not exist`,
              });
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          pageError: `ERROR ${err.status}, report does not exist`,
        });
      });
  };

  transitionPDF = () => {
    if (this.state.viewPDF) {
      document.getElementById("housepdf").classList.remove("transitionPDF");
      this.setState({ viewPDF: false });
    } else {
      document.getElementById("housepdf").classList.add("transitionPDF");
      this.setState({ viewPDF: true });
    }
  };

  checkIfReportComplete = () => {
    if (this.state.report.current_status === "COMPLETED") {
      return "Report has been completed";
    } else if (this.state.report.current_status === "CANCELLED") {
      return "This job was cancelled";
    } else {
      if (!this.state.report.pdf_url) {
        return "Report has not been uploaded yet";
      }
      return "Report has not been completed yet";
    }
  };

  setNewCurrentStatus = () => {
    getReportByUser(this.state.idToken, this.props.match.params.report)
      .then((data) => {
        data.reportid && this.setState({ report: { ...data } });
        data.current_status === "CANCELLED" &&
          Materialize.toast({
            html: "This job has been cancelled",
            classes: "rounded",
          });
        !data.reportid &&
          this.setState({
            pageError: `ERROR, report does not exist`,
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          pageError: `ERROR ${err.status}, report does not exist`,
        });
      });
  };

  downloadReport = (e) => {
    let { report } = this.state;
    e.preventDefault();
    fetch(`https://cors-anywhere.herokuapp.com/${report.pdf_url}`)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${report.address_one} ${report.city} ${report.zipcode} House Report.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        // FAILED
        Materialize.toast({
          html: "Oops, something went wrong. Please try again later.",
          classes: "rounded",
        });
      });
  };

  render() {
    return this.state.pageError ? (
      <div style={{ minHeight: "100vh" }}>
        <h4>{this.state.pageError}</h4>
      </div>
    ) : (
      <div style={{ minHeight: "95vh" }} className="row">
        {this.state.report.current_status && (
          <div className="col s12 py-5">
            <label>
              <h5 className="center-align">{this.checkIfReportComplete()}</h5>
            </label>
          </div>
        )}
        <ContractAgreement
          idToken={this.state.idToken}
          report={this.state.report}
        />
        <div>
          {this.state.report.current_status === "PENDING" && (
            <div
              className={
                !this.state.report.pdf_url ? "container px-2 py-2" : "px-3 py-2"
              }
            >
              <button
                className="btn-small"
                onClick={() => {
                  this.setState({
                    isCancelJobActive: !this.state.isCancelJobActive,
                  });
                }}
              >
                Cancel Job
              </button>
            </div>
          )}
          {this.state.report.reportid && this.state.report.pdf_url && (
            <div className="show-on-small hide-on-med-and-up px-3 py-2">
              <button className="btn-small" onClick={this.transitionPDF}>
                {this.state.viewPDF ? "See Info" : "See PDF"}
              </button>
            </div>
          )}
        </div>
        {this.state.isCancelJobActive && (
          <CancelJob
            containerClass="col s12 m12 py-5"
            jobId={this.state.report.jobid}
            removeCancelJob={() => {
              this.setState({
                isCancelJobActive: !this.state.isCancelJobActive,
              });
            }}
            setNewCurrentStatus={this.setNewCurrentStatus}
          />
        )}
        {this.state.report.reportid
          ? this.state.report.pdf_url && (
              <div
                style={
                  this.state.viewPDF ? { height: "calc(100vh - 150px)" } : {}
                }
                className="report-container row m-0 mt-3 col s12 m6"
              >
                <button
                  className="btn"
                  onClick={this.downloadReport}
                  style={{ margin: "auto" }}
                >
                  Download Report
                </button>
                <div
                  id="housepdf"
                  className="col s12 mobilepdf"
                  style={{ height: "100%" }}
                >
                  {this.state.report.pdf_url && (
                    <ReportPDF reportURL={this.state.report.pdf_url} />
                  )}
                </div>
              </div>
            )
          : null}
        <div className={`row col s12 ${this.state.report.pdf_url ? "m6" : ""}`}>
          <div id="houseReportOverview" className="col s12 houseReportTabs">
            {this.state.report.reportid && (
              <UserReportInfo report={this.state.report} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UserReportComponent);
