import React, {useState} from "react";
import { useHistory } from 'react-router-dom';
import './SearchBar.css';

export default function(props) {

  const [ isSearchBoxFocused, setIsSearchBoxFocused ] = useState(false);
  const [ searchTerm, setSearchTerm ] = useState('');

  const history = useHistory();

  const setSearchBoxFocus = (state) => {
    return () => {
      setIsSearchBoxFocused(state);
    }
  };

  const onSearch = () => {
    history.push(`/search?query=${searchTerm}`);
  };

  const handleEnter = (e) => {
    if(searchTerm && e.key === 'Enter') {
      onSearch();
    }
  };

  const getSearchBoxContainerStyle = () => {
    return isSearchBoxFocused ? "searchBoxContainer searchBoxContainerShadow" : "searchBoxContainer";
  };

  return (
    <div className={getSearchBoxContainerStyle()}>
      <input
        type="text"
        className="browser-default searchBox"
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyPress={handleEnter}
        onFocus={setSearchBoxFocus(true)}
        onBlur={setSearchBoxFocus(false)}
        autoComplete="off"
        placeholder="Search by zipcode or name"
        value={searchTerm}
      />
      <div className="searchIconContainer" onClick={onSearch}>
        <i className="material-icons">search</i>
      </div>
    </div>
  );
}