import React from "react";
import PrivateComponent from "../../../components/PrivateComponent";
import { inspectorApp as firebase } from "../../../firebase";
import {
  createInspector,
  getLocationFromAddress,
} from "../../../services/service";

export default class PreReg extends React.Component {
  state = {
    inspectors: [],
  }

  handleFile = (e) => {
    if (!e.target.files[0])
      return null;
    else {
      let reader = new FileReader();
      reader.onload = (file) => {
        let allTextLines = file.srcElement.result.split(/\r\n|\n/);
        let headers = allTextLines[0].split(',');
        let lines = [];
        for (let i = 1; i < allTextLines.length; i++) {
          let data = allTextLines[i].split(',');
          if (data.length === headers.length) {
            let inspector = {};
            for (let j = 0; j < headers.length; j++) {
              if (headers[j] === 'i_area_code' || headers[j] === 'i_cell_number' || headers[j] === 'i_zipcode')
                inspector[headers[j]] = Number.parseInt(data[j])
              else
                inspector[headers[j]] = data[j];
            }
            lines.push(inspector);
          }
        }
        this.setState({ inspectors: lines })
      }
      reader.readAsText(e.target.files[0]);
    }
  }

  signupGetToken = () => {
    return firebase.auth().currentUser.getIdToken(true);
  };

  createUser = () => {
    let inspectors = JSON.parse(localStorage.getItem("housky_certified"))
    if (inspectors.length > 0) {
      let e = inspectors[0]
      firebase.auth()
        .createUserWithEmailAndPassword(e.i_email, e.i_password)
        .then(() => {
          return this.signupGetToken();
        }).then((idToken) => {
          let body = {
            inspector_first_name: e.inspector_first_name,
            inspector_last_name: e.inspector_last_name,
            i_email: e.i_email,
            password: e.i_password,
            i_area_code: e.i_area_code,
            i_cell_number: e.i_cell_number,
            i_address_one: e.i_address_one,
            i_address_two: e.i_address_two,
            i_state: e.i_state,
            i_city: e.i_city,
            i_zipcode: e.i_zipcode,
            languages: ["English"],
            inspection_features: JSON.stringify([
              {
                name: "Structural and foundation",
                value: false
              },
              {
                name: "Electrical systems",
                value: false
              },
              {
                name: "Plumbing systems",
                value: false
              },
              {
                name: "Roof & attics",
                value: false
              },
              {
                name: "Basement & crawl spaces",
                value: false
              },
              {
                name: "Gutters & Drainage",
                value: false
              },
              {
                name: "Walls, floors, & decks",
                value: false
              },
              {
                name: "Property & site",
                value: false
              },
              {
                name: "Furnace & Heating",
                value: false
              },
              {
                name: "Air conditioning",
                value: false
              },
              {
                name: "Plumbing fixtures",
                value: false
              },
              {
                name: "Water heaters",
                value: false
              },
              {
                name: "Lights and receptacles",
                value: false
              },
              {
                name: "Window & doors",
                value: false
              },
              {
                name: "Built-in appliances",
                value: false
              }
            ]),
            housky_certified: true,
            token: idToken
          };
          getLocationFromAddress(e.i_address_one, e.i_city, e.i_state, e.i_zipcode)
            .then(res => {
              console.log(res)
              body.lat = res.geometry.location.lat
              body.lng = res.geometry.location.lng
              return body
            })
            .then(body => {
              console.log(body)
              createInspector(body)
                .then(() => {
                  let user = firebase.auth().currentUser;
                  user.updateProfile({
                    displayName: `${e.inspector_first_name.toUpperCase()} ${e.inspector_last_name.toUpperCase()}`,
                  })
                })
                .then(() => {
                  inspectors.splice(0, 1)
                  localStorage.setItem("housky_certified", JSON.stringify(inspectors))
                  setTimeout(() => {
                    firebase.auth().signOut().then(() => {
                      this.createUser();
                    });
                  }, 300);
                })
            })
        })
    }
  }

  handleRead = (e) => {
    e.preventDefault()
    if (this.state.inspectors.length > 0) {
      localStorage.setItem("housky_certified", JSON.stringify(this.state.inspectors))
      firebase.auth().signOut().then(() => {
        this.createUser()
      })
    }
  }

  render() {
    return (
      <PrivateComponent>
        <div className='container' style={{ minHeight: '60vh', backgroundColor: '#D3D3D3', borderRadius: '5px' }}>
          <div className='row p-2'>
            <div className='col s8 m6'>
              <form>
                <div className="file-field input-field">
                  <div className="btn">
                    <span>CSV File</span>
                    <input type="file" onChange={this.handleFile} />
                  </div>
                  <div className="file-path-wrapper">
                    <input className="file-path validate" type="text" />
                  </div>
                </div>
                <button className='btn' onClick={this.handleRead}>SUBMIT</button>
              </form>
            </div>
            <div className='col s4 m6'>
              <p>Inspectors: {this.state.inspectors.length}</p>
              <ul>
                {this.state.inspectors.length > 0 ?
                  this.state.inspectors.map((e, i) => {
                    return <li key={i}>{e.inspector_first_name} {e.inspector_last_name}</li>
                  }) :
                  <></>}
              </ul>
            </div>
          </div>
        </div>
      </PrivateComponent>
    );
  }
}