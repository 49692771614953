import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const ReportBody = (props) => {
  return (
    <div
      className={`report-card m-1 p-2 ${
        props.e.current_status === "COMPLETED"
          ? "report-card-active"
          : props.e.current_status === "CANCELLED"
          ? "report-card-cancelled"
          : ""
      }`}
    >
      <div className="row mb-0">
        <div className="right">
          {props.e.reportid ? (
            <Link
              to={`housereport/${props.e.reportid}`}
              className="modal-close"
            >
              <i className="material-icons">picture_as_pdf</i>
            </Link>
          ) : (
            <i className="material-icons" title="Report Pending...">
              picture_as_pdf
            </i>
          )}
          <Link to={`checkout/${props.e.jobid}`} className="modal-close">
            <i className="material-icons">payment</i>
          </Link>
        </div>
      </div>
      <div className="row mb-0">
        <h6 className="mt-0 right">
          Posted {moment(props.e.createdat).fromNow()}
        </h6>
        <h6 className="mt-0" style={{ color: "gray" }}>
          {moment(props.e.createdat).format("LLL")}
        </h6>
      </div>
      {props.e.current_status === "COMPLETED" ? (
        <Link to={`housereport/${props.e.reportid}`} className="modal-close">
          <h5 className="m-0 mt-2">{props.e.address_one}</h5>
          <h6 className="mt-0">
            {props.e.city}, {props.e.state} {props.e.zipcode}
          </h6>
        </Link>
      ) : (
        <>
          <h5 className="m-0 mt-2">{props.e.address_one}</h5>
          <h6 className="mt-0">
            {props.e.city}, {props.e.state} {props.e.zipcode}
          </h6>
        </>
      )}
      <p className="m-0" style={{ textTransform: "capitalize" }}>
        House Type: {props.e.house_type}
      </p>
    </div>
  );
};

export default (props) => {
  return (
    <>
      <div className="modal-content">
        <div className="row">
          {props.jobs.map((e, i) => {
            return (
              <div key={i} className="col s12 m12 l6">
                <ReportBody e={e} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="modal-footer valign-wrapper">
        <a
          href="#!"
          className="btn modal-close housky-primary-bg"
          style={{ width: "85%", margin: "auto" }}
        >
          Close
        </a>
      </div>
    </>
  );
};
