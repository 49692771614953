import React, {useState} from 'react'
import { app as firebase } from '../../../firebase'
import { createUser } from '../../../services/service'
import Materialize from "materialize-css/dist/js/materialize.min.js";
import {Link, useHistory} from 'react-router-dom'
import './SignUp.css'

const SignUp = () => {

  const useInput = (initialVal) => {
    const [ value, setValue ] = useState(initialVal)
    const onValueChangeHandler = (e) => {
      setValue(e.target.value)
    }
    return [ value, onValueChangeHandler ]
  }

  const history = useHistory();

  const [firstName, setFirstName] = useInput('');
  const [lastName, setLastName] = useInput('');
  const [email, setEmail] = useInput('');
  const [phoneNumber, setPhoneNumber] = useInput('');
  const [password, setPassword] = useInput('');
  const [confirmPassword, setConfirmPassword] = useInput('');
  const [agreedTerm, setAgreedTerm] = useState(false);

  const showForm = () => {

    const handleRegister = () => {
      if (!firstName || !lastName || !password || !confirmPassword || !email || !phoneNumber) {
        Materialize.toast({ html: 'Please fill out all the required forms.', class: 'rounded' })
      } else if (!agreedTerm) {
        Materialize.toast({ html: 'You have to agree to the terms to proceed', class: 'rounded' })
      } else if (password !== confirmPassword) {
        Materialize.toast({ html: 'Password does not match', class: 'rounded' })
      } else if (password.length < 8) {
        Materialize.toast({
          html: "Password must be longer than 8 characters",
          classes: "rounded"
        });
      } else {
        firebase.auth().createUserWithEmailAndPassword(email, password)
          .then(() => {
            return firebase.auth().currentUser.getIdToken(true);
          }).then((idToken) => {
            let area_code = phoneNumber.slice(0, 3)
            let cell_number = phoneNumber.slice(3)
            const body = {
              first_name: firstName,
              last_name: lastName,
              area_code,
              cell_number,
              email: email,
              token: idToken,
            }
            return createUser(body)
          }).then( () => {
            let user = firebase.auth().currentUser;
            user.updateProfile({
              displayName: `${firstName.toUpperCase()} ${lastName.toUpperCase()}`
            })
            .then(()=>{
              history.push('/dashboard')
            })
          })
          .catch(err => {
            Materialize.toast({ html: err, class: 'rounded' })
          })
      }
    }

    return (
      <div>
        <div className="row">
          <div className="col s12">
            <h5>Sign Up</h5>
          </div>
        </div>
        <div>
          <form>
            <div className="row">
              <div className="col s6">
                <label htmlFor="f_name" className="signUpInputBxLabel">First Name</label>
                <div>
                  <input id="f_name" type="text" className="browser-default signUpInputBx" value={firstName} onChange={setFirstName}/>
                </div>
              </div>
              <div className="col s6">
                <label htmlFor="l_name" className="signUpInputBxLabel">Last Name</label>
                <div>
                  <input id="l_name" type="text" className="browser-default signUpInputBx" value={lastName} onChange={setLastName}/>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="email" className="signUpInputBxLabel">Email Address</label>
                <div>
                  <input id="email" type="text" className="browser-default signUpInputBx" value={email} onChange={setEmail}/>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="cell" className="signUpInputBxLabel">Cell Phone</label>
                <div>
                  <input id="cell" type="number" className="browser-default signUpInputBx" value={phoneNumber} onChange={setPhoneNumber}/>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col s6">
                <label htmlFor="password" className="signUpInputBxLabel">Password</label>
                <div>
                  <input id="password" type="password" className="browser-default signUpInputBx" value={password} onChange={setPassword}/>
                </div>
              </div>
              <div className="col s6">
                <label htmlFor="confirmPassword" className="signUpInputBxLabel">Confirm Password</label>
                <div>
                  <input id="confirmPassword" type="password" className="browser-default signUpInputBx" value={confirmPassword} onChange={setConfirmPassword}/>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <input id="input_bx" className="browser-default" type="checkbox" style={{opacity: '1', pointerEvents:'auto', position: 'relative'}} checked={agreedTerm} onChange={() => setAgreedTerm(!agreedTerm)}/>
                <label htmlFor="input_bx"> Creating an account means you agree with our <a href='https://housky.io/terms/tos'>Terms of Service</a> and <a href='https://housky.io/terms/privacy'>Privacy Policy</a></label>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <div className="signUpButton btn" onClick={handleRegister}>CREATE ACCOUNT</div>
              </div>
              <div className="col s12">
                <div className="buttonFooter">
                  <span>Already have an account? </span>
                  <Link to='/login'>Sign In</Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }


  return(
    <div>
      <div className="pageContainer">
        <div className="signUpBoxContainer">
            <div className="formContainer">
              {showForm()}
            </div>
            <div className="imageContainer hide-on-med-and-down">
              <div>
                <img id="signUpImage" src={require('../../../assets/sign-up-home-2.png')}/>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp;