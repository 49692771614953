import React from 'react'

export default (props) => {
  return props.events.map((e, i) => {
    return <div className='col s4' data-aos="fade-left" key={i} style={{alignSelf:'flex-end', marginLeft: "inherit"}}>
      <h5 className='schedule-date'>{e.date}</h5>
      <form action="#">
        <p>
          <label>
            <input type="checkbox" checked={e.early_morning ? "checked" : false} name='early_morning' onChange={(ele)=>{props.handleTimeslots(ele,e.date)}} />
            <span>Early Morning (7AM - 10AM)</span>
          </label>
        </p>
        <p>
          <label>
            <input type="checkbox" checked={e.morning ? "checked" : false} name='morning' onChange={(ele)=>{props.handleTimeslots(ele,e.date)}} />
            <span>Morning (9AM - 12PM)</span>
          </label>
        </p>
        <p>
          <label>
            <input type="checkbox" checked={e.afternoon ? "checked" : false} name='afternoon' onChange={(ele)=>{props.handleTimeslots(ele,e.date)}} />
            <span>Afternoon (12PM - 3PM)</span>
          </label>
        </p>
        <p>
          <label>
            <input type="checkbox" checked={e.late_afternoon ? "checked" : false} name='late_afternoon' onChange={(ele)=>{props.handleTimeslots(ele,e.date)}} />
            <span>Late Afternoon (3PM - 6PM)</span>
          </label>
        </p>
      </form>
    </div>
  })
}