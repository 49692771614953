import { inspectorApp } from "../../firebase";

//Inspector
const Inspector = {};

//Get idToken
Inspector.getIdToken = () => inspectorApp.auth().currentUser.getIdToken(true);

//User

export { Inspector };
