import React from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../contexts/auth";
import { app as firebase } from "../../firebase";
import BrandWhite from "../../assets/housky_logo-06-smaller.png";
import Materialize from "materialize-css/dist/js/materialize.js";
import "./navbar.css";
import { closeNav } from '../../services/helper'
import RatingStars from "../GeneralComponents/RatingStars/RatingStars";
import {parseQueryParam} from "../../utils/utils";
import {getInspectorsByInspectorId} from "../../services/service";
import BrandIcon from "../../assets/housky_logo-07.png";

const handleHover = (id) => {
  document.getElementById(id).classList.add('info-box-active')
}
const handleLeave = (id) => {
  document.getElementById(id).classList.remove('info-box-active')
}


class Navbar extends React.Component {
  static contextType = AuthContext;
  state = {
    isSticky: false,
    showList: false
  }

  handler =  (e) => {
    let shoppingList = document.getElementById('shoppingList');
    let shoppingListBadge = document.getElementById('shoppingListBadge');
    if(shoppingList && !shoppingList.contains(e.target) && !shoppingListBadge.contains(e.target)) {
      this.setState({ showList: false })
    }
  }

  componentDidMount = async () => {
    window.addEventListener('scroll', this.handleScroll);
    const elems = document.querySelectorAll(".sidenav");
    Materialize.Sidenav.init(elems, { edge: "left" });

    let selectedInspectorsFromQueryParams = parseQueryParam('selectedInspectors')
    if(selectedInspectorsFromQueryParams && selectedInspectorsFromQueryParams !== 'null') {
      let selectedInspectors = [];
      for(let inspector of JSON.parse(selectedInspectorsFromQueryParams)) {
        const response = await getInspectorsByInspectorId(inspector.id);
        const photoid = response.photoid
        selectedInspectors.push(Object.assign(inspector, { imageUrl: photoid }))
      }

      this.props.setSelectedInspectors(selectedInspectors);
    }
    document.addEventListener('click', this.handler);
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handler);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 120) {
      this.setState({
        isSticky: true
      });
    } else {
      this.setState({ isSticky: false })
    }
  }

  toLogout = e => {
    Materialize.toast({ html: "Logged out.", classes: "rounded" });
    firebase.auth().signOut();
    this.setState({ userEmail: "" });
  };

  CheckLog = () => {
    if (this.context.authStatusCheck) {
      if (this.context.user) {
        return (
          <li onMouseEnter={() => handleHover('user-profile')}
            onMouseLeave={() => { handleLeave('user-profile') }}>
            <p className='navbar-link-items mouse-pointer'>
              <span className='center-align' style={{ position: 'relative' }}>
                {this.context.user.displayName ? this.context.user.displayName : 'PROFILE'}
                <i className="material-icons" style={{ position: 'absolute', top: '-8px', right: '0px', left: '0px' }}>arrow_drop_down</i>
              </span>
            </p>
            <div id='user-profile' className='more-info-box z-depth-2' style={{ right: '10px' }}>
              <Link to="/account" style={{ borderBottom: window.location.pathname === '/account' ? '2px solid #546A76' : 'none' }} className='navbar-link-items black-text'>
                SETTINGS
            </Link>
              <a onClick={this.toLogout}
                href="https://housky.io/"
                className="navbar-link-items black-text">
                LOGOUT
            </a>
            </div>
          </li>
        );
      } else {
        return (
          <React.Fragment>
            <li><a className='nav-links white-text' href='https://housky.io/mission'>MISSION</a></li>
            <li><Link className='nav-links white-text' style={{borderBottom: window.location.pathname ==='/login' ? '2px solid #546A76': 'none'}} to='/login' onClick={closeNav} >LOG IN</Link></li>
            <li><Link className='nav-links white-text nav-links-button' style={{borderBottom: window.location.pathname ==='/signup' ? '2px solid #546A76': 'none'}} to='/signup' onClick={closeNav} >SIGN UP</Link></li>
          </React.Fragment>
        );
      }
    }
  };

  deleteInspector = (inspector) => {
    let newSelectorInspectors = this.props.selectedInspectors.filter(i => i.id !== inspector.id);
    window.localStorage.setItem('selectedInspectors', JSON.stringify(newSelectorInspectors))
    this.props.setSelectedInspectors(newSelectorInspectors)
  };

  shoppingListRow = (inspector, key) => {

    const normalizeName = (name) => {
      const lowerCaseName = name.toLowerCase();
      return lowerCaseName[0].toUpperCase() + lowerCaseName.slice(1)
    };

    const getInspectorPhotoSrc = () => {
      if(inspector.imageUrl){
        return inspector.imageUrl
      } else {
        return require('../../assets/blank-profile-picture.png')
      }
    }

    const getInspectorRating = () => {
      return inspector.googleReviewsScore ? parseFloat(inspector.googleReviewsScore).toFixed(1) : '0.0'
    }

    return (
      <div className="shoppingListRow" key={key}>
        <div className="inspectorImageContainer">
          <img src={getInspectorPhotoSrc()} />
        </div>
        <div className="name">
          {`${normalizeName(inspector.firstName)} ${inspector.lastName[0].toUpperCase()}.`}
          <span style={{marginTop: '5px', display: 'flex'}}>
            <RatingStars score={getInspectorRating()} />
            <span style={{alignSelf: 'center', marginLeft: '8px'}}>{ getInspectorRating() }</span>
          </span>
        </div>
        <div className="delete" onClick={() => this.deleteInspector(inspector)}>
          <i className="material-icons">close</i>
        </div>
      </div>
    );
  }

  getShoppingCart = () => {
    if(this.props.selectedInspectors && this.props.selectedInspectors.length > 0) {
      return (
        <div>
          <div className="nav-links right shoppingCartContainer" onClick={() => this.setState({ showList: !this.state.showList })}  id="shoppingListBadge">
            My Inspectors
            <span className="new badge" data-badge-caption="">{ this.props.selectedInspectors.length }</span>
          </div>
          { this.state.showList &&
            <div className="shoppingList" id="shoppingList" style={{right: '140px', top: '60px'}}>
              { this.props.selectedInspectors.map(this.shoppingListRow) }
              <div className="buttons">
                <Link className="btn-small" to="/schedule" onClick={() => this.setState({ showList: false })}>Proceed to Schedule</Link>
              </div>
            </div>
          }
        </div>
      )
    } else {
      return (
        <div>
          <div className="nav-links right shoppingCartContainer" onClick={() => this.setState({ showList: !this.state.showList })}>
            My Inspectors
            <span className="new badge" data-badge-caption="">{ this.props.selectedInspectors.length }</span>
          </div>
          { this.state.showList &&
            <div className="shoppingList">
              <div style={{padding: '10px'}}>
                Add some inspectors to schedule
              </div>
              <div className="buttons">
                <button className="btn-small red" onClick={() => this.setState({ showList: false })}>Close</button>
              </div>
            </div>
          }
        </div>
      )
    }
  }

  render() {
    return (
      <React.Fragment>
        <nav id='user-nav'>
          <div className="nav-wrapper">
            <div className="hide-on-med-and-down">
              <a href='https://housky.io'>
                <img
                  alt="Housky"
                  className="brand-logo"
                  src={BrandWhite} style={{ height: '100%' }}
                />
              </a>
            </div>
            <div className="hide-on-large-only">
              <a to='/'>
                <img alt='Housky' className="brand-logo left" src={BrandIcon}/>
              </a>
            </div>
            <a
              href="!#"
              data-target="mobile-nav"
              className="sidenav-trigger m-0"
            >
              <i className="material-icons">menu</i>
            </a>
            <ul className="right hide-on-med-and-down nav-links-container">
              {this.context.authStatusCheck && this.context.user ? (
                <React.Fragment>
                  <li>
                    <Link to="/dashboard" style={{ borderBottom: window.location.pathname === '/dashboard' ? '2px solid #546A76' : 'none' }} className='white-text navbar-link-items'>
                      DASHBOARD
                    </Link>
                  </li>
                </React.Fragment>
              ) : (
                  <React.Fragment></React.Fragment>
                )}
              <li>
                { this.getShoppingCart() }
              </li>
              {this.CheckLog()}
            </ul>
          </div>
        </nav>

        <ul className="sidenav" id="mobile-nav">
          {this.context.authStatusCheck && this.context.user ? (
            <React.Fragment>
              <li>
                <Link to="/dashboard" onClick={closeNav}>DASHBOARD</Link>
              </li>
              <li>
                <Link to="/account" onClick={closeNav}>SETTINGS</Link>
              </li>
              <li>
                <a onClick={this.toLogout}
                  href="https://housky.io/">
                  LOGOUT
            </a>
              </li>
            </React.Fragment>
          ) : (
              <>
                <li>
                  <a href='https://housky.io' onClick={closeNav}>HOME</a>
                </li>
                <li>
                  <Link to='/login' onClick={closeNav}>LOGIN</Link>
                </li>
              </>
            )}
        </ul>
      </React.Fragment>
    );
  }
}

export default Navbar;
