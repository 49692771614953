import React, {useEffect, useState} from 'react'
import { app as firebase } from '../../../firebase'
import {useHistory} from "react-router-dom";
import InspectionForm from "./InspectionForm";
import {parseQueryParam} from "../../../utils/utils";

export default function(props) {

  const [ token, setToken ] = useState();

  const history = useHistory();

  useEffect(() => {
    (
      async () => {
        let token = await getToken()
        setToken(token)
      }
    )();
  }, []);

  useEffect(() => {
    window.scrollTo(0,0);
    const searchQuery = parseQueryParam('query');
    let queryParams = '';
    if(searchQuery && searchQuery !== 'null') {
      queryParams = `?query=${searchQuery}`
    }
    window.history.replaceState(null, '', `/schedule${queryParams}`)
  }, []);

  const getToken = () => {
    let currentUser = firebase.auth().currentUser;
    if(currentUser) {
      return currentUser.getIdToken(true)
    }
    return ""
  }

  const backToSearchResult = () => {
    const params = new URLSearchParams(window.location.search);
    const searchTerm = params.get('query');
    const env = process.env.REACT_APP_ENV;
    switch (env) {
      case "LOCAL":
      case "QA":
        if(searchTerm) {
          props.setDomain('main');
          history.push(`/search?query=${searchTerm}`)
        } else {
          history.push(`/dashboard`)
        }
        break;
      case "PROD":
        if(searchTerm) {
          history.push(`housky.io/search?query=${searchTerm}`);
        } else {
          history.push(`housky.io/`);
        }
        break;
    }
  }

  return (
    <InspectionForm token={token} inspectorList={props.selectedInspectors} setInspectorList={props.setSelectedInspectors} handleBack={backToSearchResult} history={history} setDomain={props.setDomain} />
  )
}
