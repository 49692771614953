import React from "react";
import "./reportPDF.css";
import { pdfjs, Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class ReportPDF extends React.Component {
  state = {
    numPages: null,
    pageNumber: 1,
    arr: [1]
  };

  onDocumentLoadSuccess = ({numPages}) => {
    this.setState({numPages});
  };
  componentDidMount = () => {
    this.Loader();
  };
  Loader = () => {
    let loadPages = this.loadPages;
    let listElm = document.getElementById("housepdf");
    if (listElm) {
      listElm.addEventListener("scroll", function () {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          setTimeout(() => {
            loadPages();
          }, 1000);
        }
      });
    }
  };

  loadPages = () => {
    let arr = this.state.arr;
    let currentMax = 3;
    if (this.state.numPages < arr.length + currentMax) {
      currentMax = this.state.numPages - arr.length;
    }
    for (let i = 1; i <= currentMax; i++) {
      if (this.state.numPages >= i) arr.push(i);
    }
    this.setState({arr});
  };

  render() {
    const {numPages} = this.state;
    return (
      <>
        {this.state.arr.map((e, i) => {
          return (
            <Document
              key={i}
              file={this.props.reportURL}
              onLoadSuccess={this.onDocumentLoadSuccess}
              onLoadError={() => {
                console.log("err with load");
              }}
              onSourceError={() => {
                console.log("err with source");
              }}
            >
              <p>
                Page {i + 1} of {numPages}
              </p>
              <Page pageNumber={i + 1} />
            </Document>
          );
        })}
      </>
    );
  }
}
