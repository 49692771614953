import React, { useEffect, useState } from "react";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import toUpper from "lodash/toUpper";
import isEmpty from "lodash/isEmpty";
import first from "lodash/first";
import get from "lodash/get";
import { InspectorTab } from "../InspectorTab";
import { getInspectorsByInspectorId } from "../../../../services/service";

const Completed = (props) => {
  const { job } = props;
  const { inspectorid, paid, current_status } = job;
  const [selectedInspector, setSelectedInspector] = useState("");
  const [inspectorOffer, setInspectorOffer] = useState({});
  const reportUrl = !!job['report_pdf_url'] ? job['report_pdf_url'] : '';
  const hasReview = !!job['review_created_at'];

  useEffect(() => {
    !isEmpty(inspectorid) &&
      setInspectorOffer(get(job, `inspectors_offers.${inspectorid}`, {}));
  }, [inspectorid]);

  const getInspectorData = async (inspectorid) => {
    const inspectorInfo = await getInspectorsByInspectorId(inspectorid);
    setSelectedInspector(inspectorInfo);
  };

  const openDoc = (link) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    !isEmpty(inspectorid) && getInspectorData(inspectorid);
  }, [inspectorid]);

  return (
    <div className="panel">
      <>
        <div className="col s12 m9 jobBoard">
          <div className="col s6">
            <div className="row title">House Information</div>
            <div className="row">
              <div className="col s12 m6">
                <div> {get(job, "house_type")} </div>
                <div>
                  {" "}
                  {get(job, "bedroom")} bedroom , {get(job, "bathroom")}{" "}
                  bathroom
                </div>
              </div>
              <div className="col s12 m6">
                <div> Parking {get(job, "parking")} </div>
                <div>
                  {" "}
                  {get(job, "foundation") !== "Not Applicable" &&
                    get(job, "foundation")}{" "}
                </div>
                <div>
                  {" "}
                  {get(job, "attic") !== "Not Applicable" &&
                    get(job, "attic")}{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col s12 m6 title">
            <div>
              <span>
                {" "}
                <span className="title black-text">Final Price: </span> ${" "}
                {get(inspectorOffer, "cost", "")} (paid in escrow)
              </span>
            </div>
            <div>
              <span>
                {" "}
                <span className="title black-text">Inspection Date: </span>
                {get(inspectorOffer, "date", "")}
              </span>
            </div>
          </div>
        </div>
        <div className="col s12  m3 statusDescription">
          <div>
            <span className="boldText">
              Status:{" "}
              <span className="mainText">
                {!hasReview ? 'Review pending' : 'Job Completed'}
              </span>
            </span>
          </div>
          {
            !hasReview &&
            <div className="blockText">
              Please give your inspector a quick review
            </div>
          }
          <div className="blockText">
            <div className="boldText">
              <div className="row">
                <button
                  className="btn light-blue capitalize actionBtn"
                  onClick={() =>
                    openDoc(get(job, "pre_inspection_agreement_url"))
                  }
                >
                  Pre-inspection Agreement
                </button>
              </div>
              <div className="row">
                <button
                  className={`btn light-blue capitalize ${
                    !paid && "disabled"
                  } actionBtn`}
                  onClick={() => openDoc(reportUrl)}
                >
                  Inspection Report
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col s12">
          <InspectorTab
            inspector={selectedInspector}
            current_status={current_status}
            userId={get(job, "userid", "")}
            priceRange={""}
            messageId={get(inspectorOffer, "message_id", "")}
          />
        </div>
      </>
    </div>
  );
};

export default Completed;
