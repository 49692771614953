import React from 'react';
import {useState, useEffect} from "react";

import './EvaluationDots.css'

const defaultProps = {
  skillEvaluated: "",
  score: 1,
  disabled: true,
  handlePassUp: () => {},
}

const EvaluationPoints =
  ({
     score = defaultProps.score,
     skillEvaluated = defaultProps.skillEvaluated,
     disabled = defaultProps.disabled,
     handlePassUp = defaultProps.handlePassUp,
   }) => {
    const pointTotal = 10;
    const [state, setState] = useState({skillEvaluated: skillEvaluated, score: score});

    useEffect(() => {
    }, [state.skillEvaluated, state.score]);

    const handleOnClick = (event, number) => {
      event.preventDefault();
      if (!disabled) {
        setState({
          ...state,
          score: number,
        });

        handlePassUp(state.skillEvaluated, number);
      }
    }

    const handleOnMouseEnter = (event, number) => {
      event.preventDefault();
      if (!disabled) {
        setState({
          ...state,
          score: number,
        });
      }
    }

    const handleOnMouseLeave = (event) => {
      event.preventDefault();
      if (!disabled) {
        setState({
          ...state,
          score: score,
        });
      }
    }

    const renderDots = () => {
      const dots = [];
      if (disabled) {
        for (let i = 1; i <= pointTotal; i++) {
          dots.push(
            <div
              key={`dots-key-${i}`}
              className="evaluation-dots__outer"
            >
              <div className="evaluation-dots__inner" style={{width: `${i <= score ? '100%' : '0%'}`}}/>
            </div>
          );
        }
      } else {
        for (let i = 1; i <= pointTotal; i++) {
          dots.push(
            <div
              key={`dots-key-${i}`}
              className="evaluation-dots__outer"
              onClick={event => handleOnClick(event, i)}
              onMouseEnter={event => handleOnMouseEnter(event, i)}
              onMouseLeave={handleOnMouseLeave}
              style={{cursor: `${disabled ? '' : 'pointer'}`}}
            >
              <div className="evaluation-dots__inner" style={{width: `${i <= state.score ? '100%' : '0%'}`}}/>
            </div>
          );
        }
      }
      return dots;
    }

    return (
      <div>
        {renderDots()}
      </div>
    )
  };

export default EvaluationPoints;
