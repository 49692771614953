import "./ActiveJobs.css";
import {
  acceptPreInspectionAgreementByInspector,
  createJobReport,
  getActiveJobs,
  getInspectorManagement,
  getInspectorIdByToken, updateInspectorAgreement,
} from "../../../services/service";
import { inspectorApp as userFirebase } from "../../../firebase";
import { ActiveJobsContent } from "../PagesContent";
import AuthContext from "../../../contexts/auth";
import InspectorFilter from "../InspectorFilter/InspectorFilter";
import { Inspector as InspectorFirebase } from "../../../utils/firebase/firebase";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import React from "react";
import { Redirect } from "react-router-dom";
import SelectedJob from "../SelectedJob/SelectedJob";
import Table from "../../GeneralComponents/Table/Table";
import TableRow from "../../GeneralComponents/TableRow/TableRow";
import { selectJobInTable } from "../../../utils/utils";
import Map from "../../GeneralComponents/GoogleMap/Map";
import get from "lodash/get";
import { withRouter } from "react-router-dom";

class ActiveJobs extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      activeJobs: [],
      jobSelected: {},
      idToken: null,
      selectedRowId: "",
      filteredJobs: [],
      showFilter: false,
      isLoading: false,
    };
  }

  getToken = () => {
    return userFirebase.auth().currentUser.getIdToken(true);
  };

  getInspectorData = () => {
    this.getToken().then((res) => {
      getInspectorIdByToken(res).then((inspector) => {
        const { latitude, longitude } = inspector;
        this.setState({
          inspectorId: get(inspector, "inspectorid", ""),
          inspectorLocation: { lat: latitude, long: longitude },
        });
      });
    });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    InspectorFirebase.getIdToken()
      .then((idToken) => {
        this.setState({ idToken });
        return getInspectorManagement(idToken);
      })
      .then((res) => {
        this.setState({
          lat: res.latitude,
          lng: res.longitude,
        });
        return getActiveJobs(this.state.idToken);
      })
      .then((data) => {
        this.setState({
          activeJobs: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.getInspectorData();
  };

  selectJob = (e) => {
    const { jobSelected, selectedRowId } = this.state;
    let setSelectedRowId = () => {
      this.setState({ selectedRowId: e.target.parentNode.id });
    };
    let updateSelectedJob = (job) => {
      this.setState({ jobSelected: job });
    };
    selectJobInTable(
      jobSelected.jobid,
      selectedRowId,
      e.target.parentNode.id,
      setSelectedRowId,
      updateSelectedJob,
      this.state.activeJobs
    );
  };

  handleSeeReport = (e) => {
    return <Redirect push to={`/report/${this.state.jobSelected.jobid}`} />;
  };

  handleCreateJobReport = () => {
    const { match, location, history } = this.props;
    if (this.state.jobSelected.reportid) {
      history.push(`/report/${this.state.jobSelected.reportid}`);
      return;
    }
    const { jobid, houseid } = this.state.jobSelected;
    let reportData = {
      houseid,
      jobid,
    };
    createJobReport(reportData, this.state.idToken).then((job) => {
      history.push(`/report/${job.reportid}`);
    });
  };

  renderTime = ({ early_morning, morning, afternoon, late_afternoon }) => {
    if (early_morning) return "07:00AM";
    if (morning) return "09:00AM";
    if (afternoon) return "12:00PM";
    if (late_afternoon) return "03:00PM";
  };

  setNewCurrentStatus = () => {
    const { jobSelected } = this.state;
    getActiveJobs(this.state.idToken)
      .then((data) => {
        Materialize.toast({
          html: "Cancelled job moved to history",
          classes: "rounded",
        });
        for (let job of data) {
          if (job.jobid === jobSelected.jobid) {
            this.setState({
              jobSelected: job,
              activeJobs: data,
            });
            return;
          }
        }
        this.setState({ activeJobs: data, jobSelected: {} });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setFilteredJobs = (filteredJobs) => {
    if (filteredJobs.length === 0) {
      Materialize.toast({ html: "No Results", classes: "rounded" });
      return;
    }
    if (this.state.jobSelected.jobid) {
      document
        .getElementById(this.state.selectedRowId)
        .classList.remove("active-row");
    }
    this.setState({ filteredJobs, jobSelected: {}, selectedRowId: "" });
  };

  handleAcceptPreInspectionAgreement = () => {
    const jobid = this.state.jobSelected.jobid;
    this.setState({
      isLoading: true,
    });
    if (!!jobid) {
      acceptPreInspectionAgreementByInspector(jobid, this.state.idToken)
        .then((data) => {
          const jobSelected = this.state.jobSelected;
          this.setState({
            jobSelected: {
              ...jobSelected,
              pre_inspection_agreement_accepted: true,
            },
            isLoading: false,
          });
        })
        .catch((error) => {
          console.error(
            "There was an error accepting pre inspection agreement by inspector",
            error
          );
          this.setState({
            isLoading: false,
          });
        });
    } else {
      console.error("No job selected");
    }
  };

  mapSelectJob = (id) => {
    const { jobSelected, selectedRowId } = this.state;
    if (jobSelected.jobid) {
      document.getElementById(selectedRowId).classList.remove("active-row");
      document.getElementById(id).classList.add("active-row");
      this.setState({ selectedRowId: id });
    } else {
      document.getElementById(id).classList.add("active-row");
      this.setState({ selectedRowId: id });
    }
    for (let job of this.state.activeJobs) {
      if (job.jobid === id) {
        this.setState({
          jobSelected: job,
        });
      }
    }
  };

  handlePreAgreementUpload = (preInspectionAgreementUrl) => {
    const {idToken} = this.state;
    const {jobid} = this.state.jobSelected;

    updateInspectorAgreement(idToken, jobid, preInspectionAgreementUrl)
      .then((data) => {
        this.setState({
          ...this.state,
          jobSelected: {
            ...this.state.jobSelected,
            pre_inspection_agreement_url: preInspectionAgreementUrl,
          }
        })
      })
      .catch((error) => {
        console.error('There was an error updating inspection agreement url', error);
      });
  };

  render() {
    let { jobSelected, filteredJobs, activeJobs } = this.state;
    let { tableHeaders } = ActiveJobsContent;
    return (
      <div style={{ minHeight: "90vh" }} className="pb-5 mt3 pt-3 pl-5 pr-5">
        <InspectorFilter
          jobsList={activeJobs}
          setFilteredJobs={this.setFilteredJobs}
          clearFilter={() => {
            this.setState({ filteredJobs: [] });
          }}
        />
        <div className="row">
          <div className={`col s12 ${!!jobSelected.jobid ? "l8" : ""}`}>
            <Table
              tableClass="striped centered hightlight"
              tableHeaders={tableHeaders}
              emptyTableMessage={"No active jobs yet!"}
            >
              {activeJobs.length > 0 && filteredJobs.length > 0
                ? filteredJobs.map((e) => (
                    <TableRow
                      tableHeaders={tableHeaders}
                      inspectorLat={this.state.activeJobs[0].ins_latitude}
                      inspectorLng={this.state.activeJobs[0].ins_longitute}
                      key={e.jobid}
                      jobData={e}
                      clickEvent={this.selectJob}
                    />
                  ))
                : activeJobs.map((e) => (
                    <TableRow
                      tableHeaders={tableHeaders}
                      inspectorLat={this.state.activeJobs[0].ins_latitude}
                      inspectorLng={this.state.activeJobs[0].ins_longitude}
                      key={e.jobid}
                      jobData={e}
                      clickEvent={this.selectJob}
                    />
                  ))}
            </Table>
          </div>
          {activeJobs.length > 0 && !!jobSelected.jobid ? (
            <div className="col s12 l4">
              <div className="card table-height">
                <Map
                  markers={activeJobs}
                  clickEvent={this.mapSelectJob}
                  lat={this.state.lat}
                  lng={this.state.lng}
                  job={jobSelected}
                  inspectorLocation={this.state.inspectorLocation}
                />
              </div>
            </div>
          ) : (
            <React.Fragment />
          )}
        </div>

        {jobSelected.jobid ? (
          <div className="divider"></div>
        ) : (
          <div style={{ maginTop: "60px", marginBottom: "60px" }}></div>
        )}
        {jobSelected.jobid && (
          <SelectedJob
            handleCreateJobReport={this.handleCreateJobReport}
            setNewCurrentStatus={this.setNewCurrentStatus}
            handleAcceptPreInspectionAgreement={
              this.handleAcceptPreInspectionAgreement
            }
            handlePreAgreementUpload={this.handlePreAgreementUpload}
            isLoading={this.state.isLoading}
            inspectorId={this.state.inspectorId}
            {...jobSelected}
          />
        )}
      </div>
    );
  }
}

export default withRouter(ActiveJobs);
