import React from 'react'

const TableCell = (props) => {
  return <div className='col s4 term-table-cell'>
    <p style={{ textAlign: 'center', fontWeight:`${props.main ? 600 : 400}`, overflowX:'scroll' }}>{props.title}</p>
    {props.body.map((e,i)=>{
      return <p key={i}>{e}</p>
    })}
  </div>
}

const TableList = (props) => {
  return <div className='col s4 term-table-cell'>
    <p style={{ textAlign: 'center' }}>{props.title}</p>
    <ul>
      {props.body.map((e, i) => {
        return <li className='term-list' key={i}>{e}</li>
      })}
    </ul>
  </div>
}

export {TableCell, TableList}