import React from "react";
import HomeDetails from "../../InspectorDomain/HomeDetails/HomeDetails";
import UserInfo from "../../InspectorDomain/UserInfo/UserInfo";
import moment from "moment";

const InspectorRecordDetails = props => {
  const { selectedInspectorRecord } = props;
  const userInfo = {
    first_name: selectedInspectorRecord.user_first_name,
    last_name: selectedInspectorRecord.user_last_name,
    email: selectedInspectorRecord.u_email,
    area_code: selectedInspectorRecord.u_area_code,
    cell_number: selectedInspectorRecord.u_cell_number,
    accepted: selectedInspectorRecord.accepted
  };
  const houseInfo = {
    address_one: selectedInspectorRecord.address_one,
    address_two: selectedInspectorRecord.address_two,
    city: selectedInspectorRecord.city,
    state: selectedInspectorRecord.state,
    zipcode: selectedInspectorRecord.zipcode,
    bedroom: selectedInspectorRecord.bedroom,
    bathroom: selectedInspectorRecord.bathroom,
    house_type: selectedInspectorRecord.house_type
  };

  const jobDetails = {
    current_status: selectedInspectorRecord.current_status,
    radon: selectedInspectorRecord.radon,
    wdi: selectedInspectorRecord.wdi,
    pdf_url: selectedInspectorRecord.pdf_url,
    eventinfo: selectedInspectorRecord.eventinfo
  };

  const inspectorData = {
    inspector_first_name: selectedInspectorRecord.inspector_first_name,
    inspector_last_name: selectedInspectorRecord.inspector_last_name,
    i_area_code: selectedInspectorRecord.i_area_code,
    i_cell_number: selectedInspectorRecord.i_cell_number,
    i_email: selectedInspectorRecord.i_email
  };

  console.log(selectedInspectorRecord);
  return (
    <div className="row">
      <div className="col s12 m6">
        <UserInfo userInfo={userInfo} houseInfo={houseInfo} />
        <HomeDetails {...houseInfo} />
      </div>
      <div className="col s12 m6">
        <JobDetails job={jobDetails} userInfo={userInfo}/>
        <InspectorDetails inspectorData={inspectorData} />
      </div>
    </div>
  );
};

const JobDetails = ({ job, ...restProps }) => {
  return (
    <div>
      <h5>Job Details</h5>
      <p>{job.pdf_url ? <a href={job.pdf_url}>See Report</a> : "Report has not been uploaded"}</p>
      <p>
        <span className="text-bold">Current Status: </span> {job.current_status}
      </p>
      <p>
        <span className="text-bold">Appt Date: </span>
        {moment(job.eventinfo[0].unix, "x").format("dddd, MMM Do YYYY")} at {renderTime(job.eventinfo[0])}
      </p>
    </div>
  );
};

const InspectorDetails = ({ inspectorData, ...restProps }) => {
  const { inspector_first_name, inspector_last_name, i_email, i_area_code, i_cell_number } = inspectorData;
  return (
    <div>
      <h5>Inspector</h5>
      <p>
        <span className="text-bold">Name:</span> {`${inspector_first_name} ${inspector_last_name}`}
      </p>

      <p>
        <span className="text-bold">Email:</span> {i_email.toLowerCase()}
      </p>
      <p>
        <span className="text-bold">Phone:</span>{" "}
        {`${i_area_code}-${String(i_cell_number).slice(0, 3)}-${String(i_cell_number).slice(3)}`}
      </p>
    </div>
  );
};

const renderTime = ({ early_morning, morning, afternoon, late_afternoon }) => {
  if (early_morning) return "07:00AM";
  if (morning) return "09:00AM";
  if (afternoon) return "12:00PM";
  if (late_afternoon) return "03:00PM";
};

export default InspectorRecordDetails;
