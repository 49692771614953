import React from 'react';
import cssStyles from '../../../containers/InspectorDomain/InspectorFaq/InspectorFaqPage.module.css'

const NewLearningCenter = () => {
	return(
		<div className={cssStyles.eightThingsToKnowContentContainer}>
			<div className={cssStyles.titleContainer}>
				8 Things Your Need to Know Before Your Home Inspection
			</div>
			<div>
				<div>
					{`
						What to expect from a home inspection? Whether you are getting a pre-listing 
						home inspection as a seller or a standard buyer home inspection, these are the 
						few things that your home inspector would like you to know to facilitate the 
						home inspection.
					`}
				</div>
				<ol>
					<li>
						<h5>
							<b>A home inspection is not a pass or a fail.</b>
						</h5>
						{`
							Unless the home is about to fall down, the inspector will never tell someone 
							not to buy a house. Every house has issues big and small, and most of the 
							issues can be fixed with reasonable knowledge, money, and experience. 
							As a seller, you don’t need to be defensive if the inspector discovers 
							something you don’t consider an issue. For example, when an inspector 
							points out that the countertop outlet isn’t GFCI, it can be fixed for less than 
							$20. Hence you don’t need to get combative about it. And by doing a pre-
							listing inspection with Housky, you will have more time to deal with these 
							problems than discovering them at a buyer’s home inspection later. 
							As a buyer, not only focus on issues that the inspector discovered, but also 
							pay attention to things that “passed” the inspection. Ask the home inspector 
							questions to know more about the house, but do it when the inspector is not 
							in the process of inspecting a potential issue.
						`}
					</li>
					<li>
						<h5>
							<b>Home inspectors are not experts in everything.</b>
						</h5>
						{`
							Most home inspectors are generalists. Do not expect your inspector can 
							explain every problem and the fix for it, even for those who are in the 
							industry for many years. 
							Every house is unique. There are innovations in building construction every 
							year. Your inspector is learning every day trying to keep up with innovation 
							and study the traditional ways, whether it is electrical, plumbing, HVAC, roof, 
							or structure. S/he will try to discover every issue possible, but most often you 
							will need to consult a specialist to know how to fix the issue and the cost to 
							do so.  
							If you need the inspector to inspect something specific, make sure you 
							inquire about it and assess which inspector is best suited for the job. You can 
							do so by reviewing the inspector’s profile page to learn about each 
							inspector’s specialty.
						`}
					</li>
					<li>
						<h5>
							<b>Give your home inspector a little patience during the inspection.</b>
						</h5>
						{`
							You don’t need to shout “how does it look?” to the home inspector the 
							moment s/he climbs onto the roof or crawls into the attic.  Nobody likes to be 
							rushed when performing a task. Some items might take a little longer to 
							inspect than you expect.
							
							Therefore, for your benefit, let the inspector take her/his time, and s/he will 
							inform you when there is an opinion in mind.
						`}
					</li>
					<li>
						<h5>
							<b>Provide clear access to facilitate the inspection.</b>
						</h5>
						{`
							Organize the house so that the inspector can easily access everything on the 
							inspection list. Roof, Plumbing, Electrical components, Appliances, Heating 
							and air conditioning systems, Ventilation, Fireplace and chimney, Foundation,
							Attic and any of its visible insulation, Basement. 
							The inspector is not required to inspect places that they don’t have easy 
							access to, such as squeezing through tiny spaces in certain parts of the attic 
							or basement.
							Removing the cover on the electric panel to inspect the wires and breakers is 
							the standard practice. Don’t worry, the inspector will put the cover back 
							afterward.
						`}
					</li>
					<li>
						<h5>
							<b>A real inspection takes a lot more than 45 minutes.</b>
						</h5>
						{`
							How long does a home inspection take? Depends on the size of the house, 
							but most inspections require the inspector an hour or longer to examine the 
							house properly. Different inspectors have different processes and 
							methodologies. The final time required for a home inspection varies not only 
							based on the experience, expertise, and tools used by the inspector, but also 
							depends on the weather, temperature, and the size and condition of the 
							house.
						`}
					</li>
					<li>
						<h5>
							<b>Home inspectors cannot predict how long a given system will last.</b>
						</h5>
						{`
							Some systems might be old and worn but are still working. In general, 
							depending on how frequently the system is used and serviced, gas ranges, 
							refrigerators, washers, and dryers have 12-15 years of life expectancy. Air 
							conditioners normally last 10-15 years and furnaces on average last 15-20 
							years. 
							The cost to replace a system varies, especially in an inflationary environment 
							when the cost of materials and labor are frequently adjusted. The home 
							inspector can give you a rough estimate, but s/he will suggest you get a 
							quote from two to three different contractors after the home inspection.
						`}
					</li>
					<li>
						<h5>
							<b>A typical home inspection does not include everything.</b>
						</h5>
						{`
							On Housky, you can find the sample Pre-Inspection agreement of the home 
							inspector, which will disclose the details of what is included in the inspection. 
							As pointed out in the second point of the list, some inspections such as 
							sewer, well, wood destroying insect, and radon detection, required special 
							equipment from a specialist. If you are concerned about these extra 
							inspections, let your home inspector know from the chat on Housky. Your
							home inspector can introduce the specialist to work with her/him for an 
							additional cost.
						`}
					</li>
					<li>
						<h5>
							<b>Home inspection cost goes up from time to time.</b>
						</h5>
						{`
							Wondering how much a home inspection costs? Housky provides an 
							estimated price range based on the parameters you provided on the house. 
							Each inspector calculates the price differently. But in general, the factors that
							influence the price are: how old is the house, how large is the house, the 
							location of the house, and the experience/expertise of the inspector. 
							But don’t let the cost be the most important factor to decide which inspector 
							to hire. The best practice is to hire an inspector with the specialty you need 
							for the job, and with a sample report that you find easy to understand. 
							In most cases, a good home inspection not only saves you many times more 
							than the cost but most importantly, you also learn about the house better 
							and you will have peace of mind.
						`}
					</li>
				</ol>
				<div>
					{`
						Housky is a simple platform to schedule a home inspection online. Visit 
						housky.io, enter the zip code of the house, and you can see all the home 
						inspectors covering the area, with the relevant information for you to decide 
						who to inquire about the cost for the job. 
					`}
				</div>
			</div>
		</div>
	)
}

export default NewLearningCenter;