import React, { useState } from 'react';
import cssStyles from './InspectorSignUp.module.css'
import { US_STATES, isLicenseNotRequired } from '../../../utils/constants';
import Materialize from "materialize-css/dist/js/materialize.min.js";
import { createInspectorStep3 } from '../../../services/service';
import { Link } from 'react-router-dom';

const useInput = (initialVal) => {
    const [ value, setValue ] = useState(initialVal)
    const onValueChangeHandler = (e) => {
      setValue(e.target.value)
    }
    return [ value, onValueChangeHandler ]
  }

const InspectorSignUp_Step3 = (props) => {
    const [ certificationNumber_1, onCertificationNumberChange_1 ] = useInput('')
    const [ certificationNumber_2, onCertificationNumberChange_2 ] = useInput('')
    const [ certification_1, onCertificationChange_1 ] = useInput('')
    const [ certification_2, onCertificationChange_2 ] = useInput('')
    const [ yearStarted, onYearStartedChange ] = useInput('')

    const [ licenseNumber_1, setLicenseNumber_1 ] = useState('')
    const [ licenseNumber_2, setLicenseNumber_2 ] = useState('')
    const [ licenseState_1, setLicenseState_1 ] = useState('')
    const [ licenseState_2, setLicenseState_2 ] = useState('')
    const [ tooltipVisibility, setTooltipVisibility ] = useState(false)
    const [ isAddMoreCertificationClicked, setIsAddMoreCertificationClicked ] = useState(false)
    const [ isAddMoreLicenseClicked, setIsAddMoreLicenseClicked ] = useState(false)

    const onLicenseStateChange_1 = (e) => {
        if(isLicenseNotRequired(e.target.value)){
            setLicenseNumber_1('Not required in this state')
        } else {
            setLicenseNumber_1('');
        }
        setLicenseState_1(e.target.value)
    }

    const onLicenseStateChange_2 = (e) => {
        if(isLicenseNotRequired(e.target.value)){
            setLicenseNumber_2('Not required in this state')
        } else {
            setLicenseNumber_2('');
        }
        setLicenseState_2(e.target.value)
    }

    const onLicenseNumberChange_1 = (e) => {
        setLicenseNumber_1(e.target.value)
    }

    const onLicenseNumberChange_2 = (e) => {
        setLicenseNumber_2(e.target.value)
    }

    const toggleTooltipVisibility = () => {
        setTooltipVisibility(!tooltipVisibility)
    }

    const showTooltip = () => {
        if(tooltipVisibility){
            return(
                <div className={cssStyles.tooltip_container}>
                    If your state doesn't require a license, enter "not required in my state".
                </div>
            )
        } else {
            return null
        }
    }

    const onClickAddMoreCertification = () => {
        setIsAddMoreCertificationClicked(!isAddMoreCertificationClicked)
    }

    const onClickAddMoreLicense = () => {
        setIsAddMoreLicenseClicked(!isAddMoreLicenseClicked)
    }

    const addAdditionalCertification = (onCertificationNumberChange, onCertificationChange) => {
        if(isAddMoreCertificationClicked){
            return(
                <div className={cssStyles.step3_inner_row}>
                    <div className={cssStyles.half_width_container}>
                        <label htmlFor="certification2" className="">Add Certification</label>
                        <div>
                            <select id="certification2" className={`browser-default ${cssStyles.select}`} onChange={onCertificationChange}>
                                <option>Certification</option>
                                <option value="ASHI Certified Inspector">ASHI Certified Inspector</option>
                                <option value="ASHI Inspector">ASHI Inspector</option>
                                <option value="ASHI Associates">ASHI Associates</option>
                                <option value="InterNACHI">InterNACHI</option>
                            </select>
                        </div>
                    </div>
                    <div className={cssStyles.half_width_container}>
                        <div>
                            <input type="text" placeholder="Full Certification Number" className={`browser-default ${cssStyles.input}`} onChange={onCertificationNumberChange}/>
                        </div>
                    </div>

                </div>
            )
        } else{
            return null
        }
    }

    const addAdditionalLicense = (onLicenseNumberChange, onLicenseStateChange) => {
        if(isAddMoreLicenseClicked){
            return (
                <div className={cssStyles.step3_inner_row}>
                    <div className={cssStyles.half_width_container}>
                        <label htmlFor="licenses2">Add extra state</label>
                        <div>
                            <select id="licenses2"  className={`browser-default ${cssStyles.select}`} onChange={onLicenseStateChange}>
                                <option value="">License State</option>
                                { US_STATES.map((state, index) => {
                                    return(<option key={index} value={state.abbreviation}>{state.name}</option>)
                                }  ) }
                            </select>
                        </div>
                    </div>
                    <div className={cssStyles.half_width_container} style={{position:'relative'}}>
                        <div>
                            <input disabled={isLicenseNotRequired(licenseState_2) || !licenseState_2} type="text" value={licenseNumber_2} placeholder="Full License Number" className={`browser-default ${cssStyles.input}`} onChange={onLicenseNumberChange}/>
                        </div>
                    </div>

                </div>
            )
        } else{
            return null
        }
    }

    const saveFormToDatabase = async () => {
        try{
            let body = {
                token: props.token,
                inspectorId: props.inspectorId,
                certificationNumber_1,
                certification_1,
                licenseNumber_1,
                licenseNumber_2,
                licenseState_1,
                licenseState_2,
                yearStarted
            }
            await createInspectorStep3(body);

            setTimeout(() => {
                props.setCurrentStep(4)
            }, 1500);

            // give user feedback about the result
            Materialize.toast({
                html: "Success! Your information has been updated.",
                classes: "rounded",
            });

            } catch{
                Materialize.toast({
                html: "Error. Please try again later.",
                classes: "rounded",
            });
        }
    }

    const isSubmitButtonDisabled = () => {
        return (licenseState_1 && !licenseNumber_1) || (licenseState_2 && ! licenseNumber_2)
    };

    const onSubmitHandler = (e) => {
        e.preventDefault()
        // save information to database
        saveFormToDatabase()
    }

    return(
        <div className={cssStyles.form}>
            <div style={{width:'100%'}}>
                <div className={cssStyles.row}>
                    <div className={cssStyles.full_width_container}>
                        <h5>Inspector Info</h5>
                    </div>
                </div>
                <form>
                    <div className={cssStyles.step3_outer_row}>
                        <div className={cssStyles.step3_inner_row}>
                            <div className={cssStyles.half_width_container}>
                                <label htmlFor="certification1" className="">Add Certification</label>
                                <div>
                                    <select id="certification1" className={`browser-default ${cssStyles.select}`} onChange={onCertificationChange_1}>
                                        <option>Certification</option>
                                        <option value="ASHI Certified Inspector">ASHI Certified Inspector</option>
                                        <option value="ASHI Inspector">ASHI Inspector</option>
                                        <option value="ASHI Associates">ASHI Associates</option>
                                        <option value="InterNACHI">InterNACHI</option>
                                    </select>
                                </div>
                            </div>
                            <div className={cssStyles.half_width_container}>
                                <div>
                                    <input type="text" placeholder="Full Certification Number" className={`browser-default ${cssStyles.input}`} onChange={onCertificationNumberChange_1}/>
                                </div>
                            </div>
                        </div>
                        { addAdditionalCertification(onCertificationNumberChange_2, onCertificationChange_2) }
                        <div className={cssStyles.step3_inner_row}>
                            { !isAddMoreCertificationClicked && <div className={`browser-default ${cssStyles.addMore_button}`} onClick={onClickAddMoreCertification} disabled={isAddMoreCertificationClicked}><b>+</b></div>}
                            { isAddMoreCertificationClicked && <span className={cssStyles.cannot_add}>* You can go to your profile to add more.</span>}
                        </div>
                    </div>
                    <div className={cssStyles.step3_outer_row}>
                        <div className={cssStyles.step3_inner_row}>
                            <div className={cssStyles.half_width_container} style={{position:'relative'}}>
                                <label htmlFor="licenses1">States where you do inspection <span style={{color: '#bb3e03'}}>*</span></label>
                                <div>
                                    <select
                                      id="licenses1"
                                      className={`browser-default ${cssStyles.select}`}
                                      onChange={onLicenseStateChange_1}
                                    >
                                        <option value="">License State</option>
                                        {US_STATES.map((state, index) => {
                                            return (<option key={index} value={state.abbreviation}>{state.name}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className={cssStyles.half_width_container}>
                                <div>
                                    <input disabled={isLicenseNotRequired(licenseState_1) || !licenseState_1} type="text" placeholder="Full License Number" className={`browser-default ${cssStyles.input}`} value={licenseNumber_1} onChange={onLicenseNumberChange_1}/>
                                </div>
                            </div>
                        </div>
                        { addAdditionalLicense(onLicenseNumberChange_2, onLicenseStateChange_2) }
                        <div className={cssStyles.step3_inner_row}>
                            { !isAddMoreLicenseClicked && <div className={`browser-default ${cssStyles.addMore_button}`} onClick={onClickAddMoreLicense} disabled={isAddMoreLicenseClicked}><b>+</b></div> }
                            { isAddMoreLicenseClicked && <span className={cssStyles.cannot_add}>* You can go to your profile to add more.</span>}
                        </div>
                    </div>
                    <div className={cssStyles.step3_outer_row}>
                        <div className={cssStyles.half_width_container}>
                            <label htmlFor="startYr" className="">Year Started Inspection</label>
                            <div>
                                <input id="startYr" type="text" className={`browser-default ${cssStyles.input}`} onChange={onYearStartedChange}/>
                            </div>
                        </div>
                    </div>
                    <div className={cssStyles.step3_outer_row} style={{marginTop: '45px'}}>
                        <div className={cssStyles.full_width_container}>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                <button disabled={isSubmitButtonDisabled()} className={cssStyles.button} onClick={onSubmitHandler}><b>SAVE & CONTINUE</b></button>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                <label>You can choose to <Link to='/account'>skip</Link> this for now and fill this later.</label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default InspectorSignUp_Step3;
