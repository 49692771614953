import React, {useRef, useState} from "react";
import './Tools.css'
import {inspectorApp as inspectorFirebase} from "../../../firebase";
import Materialize from "materialize-css/dist/js/materialize.min.js";

export default function(props) {

  const { tool: {tool_name, tool_image_url, tool_id}, src, handlePictureSelected, onEdit, onDelete } = props;

  const [ name, setName ] = useState(tool_name);

  const closeButton = useRef(null);

  const onNameChange = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const getEditor = () => {
    return (
      <div>
        <div className="row">
          <div>Tool Name</div>
          <input type="text" value={ name || ''} onChange={onNameChange} className="validate" />
        </div>
      </div>
    );
  };

  const uploadImageToStorage = async () => {
    const root = inspectorFirebase.storage().ref();
    const profileImage = root.child(
      `toolImage/${name}`
    );
    try {
      if(!src) {
        /// this means no new images has been selected, use the old image url
        return tool_image_url;
      }

      const imageResponse = await fetch(src);
      const imageBlob = await imageResponse.blob();
      const snapshot = await profileImage.put(imageBlob);
      const imageUrl = await snapshot.ref.getDownloadURL();
      return imageUrl;
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdate = async () => {
    try {
      const imageUrl = await uploadImageToStorage();
      await onEdit(tool_id, name, imageUrl);
      Materialize.toast({
        html: "Profile image has been updated.",
        classes: "rounded",
      });

      // close the edit page
      setTimeout(() => {
        closeButton.current.click()
      }, 1000)
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="card large col s3" style={{margin: '10px'}}>
      <div className="card-image">
        <img src={tool_image_url} />
      </div>
      <div className="card-content">
        <span className="card-title grey-text text-darken-4">
          {name}
          <i className="material-icons right activator editIcon">edit</i>
        </span>
      </div>
      <div className="card-reveal">
        <span className="grey-text text-darken-4">
          <i className="material-icons card-title right" id="UpdateToolImageCloseButton" ref={closeButton}>close</i>
        </span>
        {getEditor()}
        <div className="row">
          <div className='row'>
            <label htmlFor="upload55123"><a className="btn" style={{marginRight: '20px'}}>Select Photo</a></label>
            <input type="file" id="upload55123" style={{display: 'none'}} onChange={handlePictureSelected}/>
            <a className="btn" onClick={onUpdate}>
              Upload
            </a>
          </div>
          <a className="btn red card-title" onClick={() => onDelete(tool_id)}>Delete</a>
        </div>
      </div>
    </div>
  );
}