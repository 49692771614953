import React from 'react'
import './terms.css'
export default () => {
  return <>
    <div className='row nav-offset'>
    </div>
    <div className='term-background'>
      <div className='container pt-5'>
        <div className='inner-container'>
          <div className='row mt-1 mb-0 p-2 pb-5 content-background'>
            <div className='col s12'>
              <div>
                <h3><b>Housky&#39;s Cookie Policy</b></h3>
              </div>
            </div>
            <article>
              <section>
                <p>Housky&#39;s Cookie Policy</p>
                <p className='gray-text'><i>Effective: April 11, 2020</i></p>
                <p>
                  This Cookie Policy ("<strong>Cookie Policy</strong>") describes how Housky Inc (collectively, “<strong>Housky</strong>,” “<strong>we</strong>,” “<strong>us</strong>,” or "<strong>our</strong>") collect and process information about you or your device on Housky’s websites (the “<strong>Housky Sites</strong>”) through the use of cookies. For information about our other data privacy practices, please visit our <a href="https://housky.io/terms/privacy" target="_blank" rel="noopener noreferrer">Privacy Statement</a>.
              </p>
              </section>
              <section>
                <p>
                  By using the Housky Sites, you accept the use of cookies in accordance with this Cookie Policy, subject to any choices you make as described below.
                </p>
              </section>
            </article>
            <article>
              <h5><b>What are cookies?</b></h5>
              <section>
                <p>
                  Cookies can be delicious. Cookies can also be small text files that can be stored on and accessed from your device when you visit a website. They may include a unique identifier that distinguishes your computer or mobile device from other devices. In addition to cookies, we may use other technologies to track your use of the Housky Sites, such as pixel tags and web beacons. We use the term "cookies" in this Cookie Policy to refer generally to these technologies that may collect information automatically when you visit the Housky Sites. Cookies are common and serve many purposes, such as letting you navigate between web pages efficiently, remembering your preferences, and generally improving your user experience.
                </p>
              </section>
              <section>
                <p>
                  Cookies can be “persistent cookies” that remain on your hard drive until they expire or are deleted or “session cookies” that are deleted from your hard drive when you close your browser. Cookies can also be “first-party cookies” which are set by us, or “third-party cookies” which are set by others.
                </p>
              </section>
              <section>
                <p>
                  For further information on cookies, including how to see what cookies have been set on your device and how to manage or delete them, visit www.allaboutcookies.org.
                </p>
              </section>
            </article>
            <article>
              <h5><b>What kind of cookies are served through the Housky Sites?</b></h5>
              <section>
                <p>
                  We use three categories of cookies on the Housky Sites: Strictly Necessary Cookies, Analytics Cookies, and Advertising Cookies:
                </p>
              </section>
              <section>
                <h6><b>Strictly Necessary Cookies</b></h6>
                <p>
                  Strictly Necessary cookies enable you to navigate the Housky Sites and use its features, such as accessing secure areas of the Housky Sites. Strictly Necessary cookies don’t gather information about you that could be used for marketing or remembering where you have visited on the Internet. This category of cookies cannot be disabled.
              </p>
              </section>
              <section>
                <h6><b>Analytics Cookies</b></h6>
                <p>Analytics cookies help us analyze how you use the Housky Sites. Analytics cookies collect and store information such as how you interact with the Housky Sites, errors you encounter when using the Housky Sites, and which pages within the Housky Sites visitors go to most often. We use the information collected by these cookies to improve the Housky Sites and your user experience.
Some of our third-party partners who set Analytics cookies on the Housky Sites include <a href="https://google.com" target="_blank" rel="noopener noreferrer">Google</a>.</p>
              </section>
              <section>
                <h6><b>Advertising Cookies</b></h6>
                <p>
                  We and our third-party partners use Advertising cookies on the Housky Sites to deliver ads to you on third-party websites and applications, execute referral programs and brand partnerships, and populate job boards. We also may use Advertising cookies to know when you return to the Housky Sites after visiting these partners’ websites and applications.
                </p>
              </section>
            </article>
            <article>
              <h5><b>How can I manage or delete cookies?</b></h5>
              <section>
                <p>The following are examples of how you can manage or delete many of the cookies served through the Housky Sites:</p>
              </section>
              <section>
                <ul>
                  <li className="term-list"><strong>Cookie opt-out links.</strong> You may manage or opt out of certain uses of many cookies served through the Housky Sites by using the links provided above.</li>
                  <li className="term-list"><strong>Industry opt-out sites.</strong> You may also opt out of some uses of Advertising cookies by visiting: <a href="https://www.aboutads.info/choices" target="_blank" rel="noopener noreferrer">www.aboutads.info/choices</a>, <a href="https://optout.networkadvertising.org" target="_blank" rel="noopener noreferrer">optout.networkadvertising.org.</a></li>
                  <li className="term-list"><strong>Browser settings.</strong> Many web browsers accept cookies by default. If you prefer, you can usually change your browser's settings to reject and/or to remove many cookies. On some browsers, you can choose to let the Housky Sites place cookies, but choose to reject cookies from certain third parties (such as analytics companies or advertising companies). As the precise means by which you may do this will vary from browser to browser, please visit your browser's help or settings menu for more information.</li>
                </ul>
              </section>
              <section>
                <p>
                  Please note also that if you choose to reject or remove cookies, this may prevent certain features or services of Housky Sites from working properly. Since your cookie opt-out preferences are also stored in a cookie in your website browser, please also note that if you delete all cookies, use a different browser, or buy a new computer, you will need to renew your opt-out choices.
                </p>
              </section>
            </article>
            <article>
              <h5><b>E-mail tracking</b></h5>
              <section>
                <p>
                  Housky may also use e-mail tracking technologies to monitor the success of e-mail campaigns it operates (for example, recording how many e-mails in a campaign were opened). If you do not want us to track emails we send you, some email services let you change your display to turn off HTML or disable the download of images, and exercising these rights should effectively disable our email tracking. In addition, you may also unsubscribe from our marketing emails as described in those emails.
                </p>
              </section>
            </article>
            <article>
              <h5><b>Do Not Track</b></h5>
              <section>
                <p>Some Internet browsers include the ability to transmit “Do Not Track” signals. The Housky Sites do not currently process or respond to “Do Not Track” signals. To learn more about “Do Not Track,” please visit "<a href="https://allaboutdnt.com/" target="_blank" rel="noopener noreferrer">All About Do Not Track</a>".
In addition to “Do Not Track,” there are many ways that web browser signals and similar mechanisms can indicate your tracking choices, and we may not be aware of nor honor every mechanism.</p>
              </section>
            </article>
            <article>
              <h5><b>Modifications</b></h5>
              <section>
                <p>
                  We may amend this Cookie Policy at any time by posting the amended version on the Housky Sites or by providing such notice about or obtaining consent to changes as may be required by applicable law.
                </p>
              </section>
            </article>
            <article>
              <h5><b>Contact Information</b></h5>
              <section>
                <p>
                  If you have any questions regarding this Cookie Policy, please email Housky at <a href="mailto:info@Housky.io">info@Housky.io</a>.
                </p>
              </section>
            </article>
          </div>
        </div>
      </div>
    </div>
  </>
}