import React from 'react'
import cssStyles from './InspectorFaqPage.module.css'

const InspectorFaqPage = () => {
    return (
        <div className={cssStyles.contentContainer}>
            <div className={cssStyles.titleContainer}>
                <span style={{color: '#48cae4'}}>HOUSKY</span>.io Inspector FAQ
            </div>
            <div className={cssStyles.itemContainer}>
                <div className={cssStyles.question}>What is <span style={{color: '#48cae4'}}>HOUSKY</span>?</div>
                <div className={cssStyles.answer}>We are a simple and transparent platform dedicated to helping customers find the right home inspectors and schedule home inspections.</div>
            </div>
            <div className={cssStyles.itemContainer} style={{marginTop:'0'}}>
              <div className={cssStyles.answer}>
                Customers can view the details and sample reports of all the home inspectors on our platform, and select a list of home inspectors for further consideration. The selected inspectors will have the option to accept or pass on the job. All the communication, payment, scheduling, and report sharing can be done on our platform.
              </div>
            </div>
            <div className={cssStyles.itemContainer} style={{marginTop:'0'}}>
                <div className={cssStyles.quetionWithIcon}>
                    <div className={cssStyles.question}>How is <span style={{color: '#48cae4'}}>HOUSKY</span> different from Home Advisor?</div>
                    <img style={{maxWidth: '12%'}} src={require('../../../assets/inspector-icon-flipped.png')}/>
                </div>
                <div className={cssStyles.answer}>
                    <p>We are a platform dedicated purely to home inspections.</p>
                    <p>We take a small referral fee of $35 + 3% bank processing fee on the job <b>only</b> after it is accepted.</p>
                    <p>Home inspectors can use our platform for their online presence and CRM. Registered home inspectors will have their own link to their business profile, so that they don't need to create a website themselves. The Pre-Inspection Agreement can be e-signed and stored. Home Inspection Reports can be uploaded on the platform to share with the customer.</p>
                    <p>Inspectors can set up the base price for any given job using the price calculator on their settings page. Inspectors know exactly how much the job will pay them and have the freedom to accept or decline the job assigned.</p>
                 </div>
            </div>
            <div className={cssStyles.itemContainer}>
                <div className={cssStyles.question}>How do Home Inspectors on <span style={{color: '#48cae4'}}>HOUSKY</span> get paid?</div>
                <div className={cssStyles.answer}>We use <b>Stripe</b> <span style={{fontStyle:'italic'}}>(a payment processing service)</span> to process out payments. A home inspector needs to register with <b>Stripe</b> on the Housky platform and link his bank account on <b>Stripe</b> (Housky will NOT have access to your bank detail information). When the client decides to hire an inspector, s/he will pay with a credit card and place the funds in escrow. Once the inspector finishes the inspection and uploads the report on Housky, the client needs to release escrow to view the report, and then the inspector's bank account will be funded.</div>
            </div>
            <div className={cssStyles.itemContainer}>
                <div className={cssStyles.question}>How does <span style={{color: '#48cae4'}}>HOUSKY</span> make good inspectors stand out?</div>
                <div className={cssStyles.answer}>Customers will first get a glance of all the inspectors covering the zip code of the house, sorted by distance. A brief summary of the inspector's credentials will be listed on the first page, and by clicking the profile picture, detailed bios and sample reports can be viewed. We will help inspectors select a good profile photo and refine their bios.</div>
            </div>
            <div className={cssStyles.itemContainer}>
              <div className={cssStyles.question}>Home inspector schools</div>
              <div className={cssStyles.homeInspectorSchools}>
                <a href="https://inspector.housky.io/new-york-inspector-schools">New York home inspector schools</a>
                <a href="https://inspector.housky.io/new-jersey-inspector-schools">New Jersey home inspector schools</a>
              </div>
            </div>
            <div className={cssStyles.homeIconContainer}>
                <img src={require('../../../assets/sign-up-home-2.png')}/>
            </div>
            <div className={cssStyles.footNoteContainer}>
                Housky reserves all the rights to change these terms and descriptions at any time without prior notice.
            </div>
        </div>
    )

}

export default InspectorFaqPage;