import Attic from "../FormSelectOptions/Attic";
import Foundation from "../FormSelectOptions/Foundation";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import Parking from "../FormSelectOptions/Parking";
import React from "react";

class InspectorReportInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      report: { ...this.props.report },
      defaultReport: { ...this.props.report },
      foundationOther: "",
      atticOther: "",
    };
  }

  componentDidMount() {}
  componentDidUpdate() {
    if (!this.state.isDisabled) this.runMaterialize();
  }

  runMaterialize = () => {
    let elem = document.querySelectorAll("select");
    Materialize.FormSelect.init(elem, {
      classes: "",
    });
  };

  handleEdit = (e) => {
    e.preventDefault();
    this.setState({
      isDisabled: !this.state.isDisabled,
      report: { ...this.state.defaultReport },
    });
  };

  handleChange = (e) => {
    let report = this.state.report;
    report[e.target.id] = e.target.value;
    this.setState({
      report,
    });
  };

  handleUpdateHouseInfo = (e) => {
    e.preventDefault();
    this.setState({ isHouseUpdating: true });
    const {
      houseid,
      address_one,
      address_two,
      city,
      state,
      zipcode,
      house_detailid,
      bedroom,
      bathroom,
      sqft,
      year_built,
      parking,
      foundation,
      attic,
      description_text,
    } = this.state.report;

    const houseData = {
      houseid,
      address_one,
      address_two,
      city,
      state,
      zipcode,
    };
    const houseDetailData = {
      house_detailid,
      bedroom,
      bathroom,
      sqft,
      year_built,
      parking,
      foundation,
      attic,
      description_text,
    };

    this.props.updateFullHouseInfo(houseData, houseDetailData);
    this.setState({ isDisabled: !this.state.isDisabled });
  };

  render() {
    const { isDisabled, foundationOther, atticOther } = this.state;
    const {
      address_one,
      city,
      state,
      zipcode,
      year_built,
      sqft,
      bedroom,
      bathroom,
      foundation,
      attic,
      parking,
    } = this.state.report;

    return (
      <div className="container pt-5 mt-5">
        {this.props.report.current_status !== "CANCELLED" && (
          <React.Fragment>
            {isDisabled ? (
              <button className="btn-small right housky-primary-bg" onClick={this.handleEdit}>
                Update Information
              </button>
            ) : (
              <button className="btn-small right housky-primary-bg" onClick={this.handleEdit}>
                Cancel Update
              </button>
            )}
          </React.Fragment>
        )}

        <div className="pt-5">
          <form className="col s12">
            <div className="row">
              <div className="input-field col s12">
                <input
                  disabled={isDisabled ? true : false}
                  id="address_one"
                  type="text"
                  value={address_one}
                  onChange={this.handleChange}
                />
                <label className={address_one ? "active" : ""} htmlFor="address_one">
                  Address
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s4">
                <input
                  disabled={isDisabled ? true : false}
                  id="city"
                  type="text"
                  value={city}
                  onChange={this.handleChange}
                />
                <label className={city ? "active" : ""} htmlFor="city">
                  City
                </label>
              </div>
              <div className="input-field col s4">
                <input
                  disabled={isDisabled ? true : false}
                  id="state"
                  type="text"
                  maxLength="2"
                  value={state}
                  onChange={this.handleChange}
                />
                <label className={state ? "active" : ""} htmlFor="state">
                  State
                </label>
              </div>
              <div className="input-field col s4">
                <input
                  disabled={isDisabled ? true : false}
                  id="zipcode"
                  type="number"
                  value={zipcode}
                  maxLength="5"
                  onChange={this.handleChange}
                />
                <label className={zipcode ? "active" : ""} htmlFor="zipcode">
                  Zipcode
                </label>
              </div>
            </div>
            <div className="divider"></div>
            <div className="section">
              <div className="row mb-0">
                <div className="input-field col s4">
                  <input
                    disabled={isDisabled ? true : false}
                    id="bedroom"
                    type="number"
                    value={bedroom}
                    onChange={this.handleChange}
                  />
                  <label className={bedroom ? "active" : ""} htmlFor="bedroom">
                    Bedroom
                  </label>
                </div>
                <div className="input-field col s4">
                  <input
                    disabled={isDisabled ? true : false}
                    id="bathroom"
                    type="number"
                    value={bathroom}
                    onChange={this.handleChange}
                  />
                  <label className={bathroom ? "active" : ""} htmlFor="bathroom">
                    Bathroom
                  </label>
                </div>
                <div className="input-field col s4">
                  <input
                    disabled={isDisabled ? true : false}
                    id="sqft"
                    type="number"
                    value={sqft}
                    onChange={this.handleChange}
                  />
                  <label className={sqft ? "active" : ""} htmlFor="sqft">
                    Sqft
                  </label>
                </div>
              </div>
              <div className="row mb-0">
                <div className="input-field col s4">
                  <input
                    disabled={isDisabled ? true : false}
                    id="year_built"
                    type="number"
                    maxLength="4"
                    value={year_built}
                    onChange={this.handleChange}
                  />
                  <label className={year_built ? "active" : ""} htmlFor="year_built">
                    Year Built
                  </label>
                </div>

                {isDisabled ? (
                  <div className="input-field col s8">
                    <input disabled id="parking" type="text" value={parking} />
                    <label className={parking ? "active" : ""} htmlFor="parking">
                      Parking
                    </label>
                  </div>
                ) : (
                  <Parking handleChange={this.handleChange} parking={parking} />
                )}
              </div>

              <div className="row mb-0">
                {isDisabled ? (
                  <div className="input-field col s6">
                    <input disabled id="foundation" type="text" value={foundation} />
                    <label className={foundation ? "active" : ""} htmlFor="foundation">
                      Foundation
                    </label>
                  </div>
                ) : (
                  <Foundation handleChange={this.handleChange} foundation={foundation} />
                )}
                {isDisabled ? (
                  <div className="input-field col s6">
                    <input disabled id="attic" type="text" value={attic} />
                    <label className={attic ? "active" : ""} htmlFor="attic">
                      Attic
                    </label>
                  </div>
                ) : (
                  <Attic handleChange={this.handleChange} attic={attic} />
                )}
                {foundation === "Other" ? (
                  <div className="input-field col s6">
                    <input id="foundationOther" type="text" value={foundationOther} onChange={this.handleChange} />
                    <label className={foundationOther ? "active" : ""} htmlFor="foundationOther">
                      Foundation Other
                    </label>
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                {attic === "Other" ? (
                  <div className="input-field col s6">
                    <input id="atticOther" type="text" value={atticOther} onChange={this.handleChange} />
                    <label className={atticOther ? "active" : ""} htmlFor="atticOther">
                      Attic Other
                    </label>
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </div>
            </div>
            {this.props.report.current_status !== "CANCELLED" && (
              <React.Fragment>
                {isDisabled ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <div className="row center">
                    <button
                      style={{ height: "auto" }}
                      type="submit"
                      onClick={this.handleUpdateHouseInfo}
                      className="py-2 px-5 btn housky-primary-complimentary-bg"
                      disabled={this.props.isHouseUpdating}
                    >
                      Confirm
                    </button>
                  </div>
                )}
              </React.Fragment>
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default InspectorReportInfo;
