import React from 'react';
import cssStyles from '../../../containers/InspectorDomain/InspectorFaq/InspectorFaqPage.module.css'

const NewLearningCenter = () => {
    const items = [
        {
            question: 'What is Housky?',
            answer: 'We are a simple and transparent platform dedicated to helping customers find the right home inspectors and schedule home inspections.'
        },
        {
            question: 'How does Housky work?',
            answer: 'Customers can view the details and sample reports of all the Home Inspectors on our platform, select a few Home Inspectors to inquire about the cost and other details of the inspection. When an inspector is hired, all the payment, scheduling, and home inspector report-sharing can be done on our platform.'
        },
        {
            question: 'Why do a home inspection when you are buying a house?',
            answer: 'A home inspection can help you identify problems with your home before the purchase process is completed. ' +
                'Some problems can be very expensive to fix and cost thousands of dollars. By using a home inspection service, you can ' +
                'potentially identify the issues with your future home and get them fixed prior to completing your home purchase. ' +
                'While it is tempting to use a home inspector that charges less, an unqualified inspector could miss serious issues with your home ' +
                'that will cost you much more down the road. We ensure that home inspectors on our platform are state licensed and certified.'
        },
        {
            question: 'How can I trust the work quality of my inspector?',
            answer: 'We ensure that all our inspectors have a certification and are state licensed. ' +
                'You can view and compare the profile of the inspectors on the Housky platform. ' +
                'Each inspector has provided a sample report and pre-inspection agreement for you to understand his inspection work. ' +
                'Pick the one that you are most comfortable with.'
        },
        {
            question: 'Is it a good idea to accompany the home inspector during the inspection?',
            answer: 'It will be much easier for you to get familiarized with the house if you are present during the inspection. ' +
                'You can find out issues along with the inspector and have the chance to ask questions on the spot so that ' +
                'you can comprehend the inspection report that you will receive afterward. A good inspector will teach you so much more about ' +
                'the house even if there are no major issues found.'
        },
        {
            question: 'What do home inspectors check for?',
            answer: 'The detail of what is covered in the inspection is written in the Pre-Inspection Agreement provided by the inspector. ' +
                'During a typical inspection, the home inspector will look at the foundation, structural components, roof, ' +
                'HVAC, plumbing, and electrical systems of the house, then provide a written home inspection report with the results. '
        },
        {
            question: 'Should I get a home inspection before listing my house?',
            answer: 'A pre-inspection helps the seller to understand if there are problems that a potential buyer would like to be fixed. ' +
                'As the seller, you can fix these issues before listing the house on the market. It will be much easier to sell the house, ' +
                'shorten the negotiation process and avoid any surprises.'
        },
    ]
    return(
        <div className={cssStyles.contentContainer}>
            <div className={cssStyles.titleContainer}>
                <span style={{color: '#48cae4'}}>HOUSKY</span>.io Learning Center
            </div>
            <div className={cssStyles.itemContainer}>
                <div className={cssStyles.question}>
                    What is <span style={{color: '#48cae4'}}>HOUSKY</span>?
                </div>
                <div className={cssStyles.answer}>{items[0].answer}</div>
            </div>
            <div className={cssStyles.itemContainer} style={{marginTop:'0'}}>
                <div className={cssStyles.quetionWithIcon}>
                    <div className={cssStyles.question}>How does <span style={{color: '#48cae4'}}>HOUSKY</span> work?</div>
                    <img style={{maxWidth: '12%'}} src={require('../../../assets/inspector-icon-flipped.png')}/>
                </div>
                <div className={cssStyles.answer}>{items[1].answer}</div>
            </div>
            <div className={cssStyles.itemContainer}>
                <div className={cssStyles.question}>{items[2].question}</div>
                <div className={cssStyles.answer}>{items[2].answer}</div>
            </div>
            <div className={cssStyles.itemContainer}>
                <div className={cssStyles.question}>{items[3].question}</div>
                <div className={cssStyles.answer}>{items[3].answer}</div>
            </div>
            <div className={cssStyles.itemContainer}>
                <div className={cssStyles.question}>{items[4].question}</div>
                <div className={cssStyles.answer}>{items[4].answer}</div>
            </div>
            <div className={cssStyles.itemContainer}>
                <div className={cssStyles.question}>{items[5].question}</div>
                <div className={cssStyles.answer}>{items[5].answer}</div>
            </div>
            <div className={cssStyles.itemContainer}>
                <div className={cssStyles.question}>{items[6].question}</div>
                <div className={cssStyles.answer}>{items[6].answer}</div>
            </div>
            <div className={cssStyles.itemContainer}>
              <div className={cssStyles.question}>8 Things Your Need to Know Before Your Home Inspection</div>
              <div className={cssStyles.answer}>
                {`
                  What to expect from a home inspection? Whether you are getting a pre-listing 
                  home inspection as a seller or a standard buyer home inspection, these are the 
                  few things that your home inspector would like you to know to facilitate the 
                  home inspection.
                `}
                <a href="https://housky.io/what-to-expect-from-a-home-inspection">Read More</a>
              </div>
            </div>
            <div className={cssStyles.homeIconContainer}>
                <img src={require('../../../assets/sign-up-home-2.png')}/>
            </div>
            <div className={cssStyles.footNoteContainer}>
                Housky reserves all the rights to change these terms and descriptions at any time without prior notice.
            </div>
        </div>
    )
}

export default NewLearningCenter;