import React from "react";
import toUpper from "lodash/toUpper";
import get from "lodash/get";
import InspectorCard from "./InspectorCard";
import ChatRoom from "../../GeneralComponents/ChatRoom";
import { isEmpty } from "lodash";
import AcceptPaymentButton from "../AcceptPaymentButton";

export const PendingInspectorTab = (props) => {
  const { job, inspector, status, inspectorsOffers, userId } = props;
  const { inspectorid } = inspector;
  // console.log("inspe", inspectorid);
  const inspectorPriceRange = {
    min: get(job, `price_range.${inspectorid}.min`),
    max: get(job, `price_range.${inspectorid}.max`),
  };
  // console.log(inspectorPriceRange);
  const inspectorOffer = get(inspectorsOffers, inspectorid, {});
  return (
    <>
      {get(job, "price_range") !== null ? (
        <div className="row infoTabWaiting headerDetails">
          <div className="col m12 l8">
            {get(inspectorOffer, "cost") !== undefined ? (
              <div>
                <div className="detail">
                  <span className="boldText">Final Price:</span>
                  <span className="boldText blueText">
                    {` $ ${get(inspectorOffer, "cost", "")}`}
                  </span>
                </div>
                <div className="detail">
                  <span className="boldText">Inspection Date:</span>
                  <span className="boldText blueText">
                    {` ${get(inspectorOffer, "date", "")} `}
                    {`  ${get(inspectorOffer, "time", "")}`}
                  </span>
                </div>
              </div>
            ) : (
              get(inspectorPriceRange, "min") !== null &&
              get(inspectorPriceRange, "min") !== undefined && (
                <>
                  <span className="boldText">Price Range:</span> $
                  {get(inspectorPriceRange, "min")} -{" $"}
                  {get(inspectorPriceRange, "max")}
                </>
              )
            )}
          </div>
          <div className="col m12 l4">
            <div>
              <div className="boldText">
                Action:{" "}
                <span className="mainText">
                  {get(inspectorOffer, "cost") !== undefined && (
                    <AcceptPaymentButton job={job} inspectorid={inspectorid} />
                  )}
                </span>
              </div>
              <div className="boldText status">
                Status:{" "}
                <span className="mainText">
                  {get(inspectorOffer, "cost") !== undefined
                    ? "Final quote Provided"
                    : toUpper(status) === "PENDING" && "Inspector Review"}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row infoTabWaiting">
          <div className="infoWait">
            <i className="material-icons infoIcon">info</i>
            <div>Waiting for inspector offer</div>
          </div>
        </div>
      )}

      <div className="sectionChat">
        <div className="row infoTab">
          <div className="col s12 m8 l9">
            <div className="row chatRow">
              {!isEmpty(get(inspectorOffer, "message_id", "")) && (
                <div className="chatFrame">
                  <ChatRoom
                    userId={userId}
                    messageId={get(inspectorOffer, "message_id", "")}
                    profile="CLIENT"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col s12 m4 l3 inspectorCard">
            <InspectorCard inspector={inspector} />
          </div>
        </div>
      </div>
    </>
  );
};
