import InspectorListComponent from "../../../components/AdminDomain/InspectorList/InspectorListComponent";
import PrivateComponent from "../../../components/PrivateComponent";
import React from "react";

class InspectorList extends React.Component {
  render() {
    return (
      <PrivateComponent>
        <InspectorListComponent />
      </PrivateComponent>
    );
  }
}

export default InspectorList;
