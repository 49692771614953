import React, {useEffect, useState} from "react";
import CancelJob from "../CancelJob/CancelJob";
import {Inspector as InspectorFirebase} from "../../../utils/firebase/firebase";
import {root, getInspectorbyToken} from "../../../services/service";
import axios from "axios";
import Materialize from "materialize-css/dist/js/materialize.min.js";

const JobDetails =
  ({
     jobid,
     wdi,
     radon,
     current_status,
     house_typeid,
     house_type,
     prices,
     userInfo,
     inspector_offer,
     ...props
   }) => {
    const [isCancelJobActive, setIsCancelJobActive] = useState(false);
    const [inspector, setInspector] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      (async () => {
        const id = await InspectorFirebase.getIdToken();
        const resp = await getInspectorbyToken(id);
        const data = resp.data;
        setInspector({
          i_email: data.i_email,
          i_name: `${data.inspector_first_name} ${data.inspector_last_name}`,
        });
      })();
    }, []);

    const docusignAuth = async () => {
      setLoading(true);
      try {
        await axios({
          url: "docusign/auth",
          method: "post",
          baseURL: root,
          data: {jobid},
        });
        Materialize.toast({
          html: "Documents has been sent.",
          classes: "rounded",
        });
      } catch (err) {
        alert("Something went wrong... Please try again later or contact admin.");
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        {loading && (
          <div>
            <h4>Please do not leave page... Sending Documents to be signed...</h4>
            <div className="progress">
              <div className="indeterminate"/>
            </div>
          </div>
        )}
        <div className="pt-3 mt-1">
          {isCancelJobActive ? (
            <CancelJob
              jobId={jobid}
              removeCancelJob={(e) => {
                setIsCancelJobActive(!isCancelJobActive);
              }}
              setNewCurrentStatus={props.setNewCurrentStatus}
            />
          ) : (
            <React.Fragment>
              <h5>Job Details</h5>
              <p>
                <span className="text-bold">Current Status</span> {current_status}
              </p>
              {
                !!wdi &&
                <p>
                  <span className="text-bold">WDI:</span> Requested
                </p>
              }
              {
                !!radon &&
                <p>
                  <span className="text-bold">Radon:</span> Requested
                </p>
              }
              <p>
                <span className="text-bold">Total Price:</span> $
                {
                  !!inspector_offer && !!inspector_offer.cost ? inspector_offer.cost : 0
                }
              </p>
              {
                current_status === "PENDING" && (
                  <div className="row">
                    <button
                      className="btn mt-2"
                      onClick={(e) => {
                        setIsCancelJobActive(!isCancelJobActive);
                      }}
                    >
                      Cancel Job
                    </button>
                  </div>
                )
              }
            </React.Fragment>
          )}
        </div>
      </>
    );
  };

export default JobDetails;
