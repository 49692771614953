import React, {useEffect, useRef, useState} from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import ChatMessage from "../ChatMessage";
import { chatApp } from "../../../firebase";
import "firebase/firestore";
import "./styles.css";
import moment from "moment";
import { get, isEmpty } from "lodash";

const firestore = chatApp.firestore();

const ChatRoom = ({ messageId, userId, profile, height, disabled }) => {
  const chatBoxRef = useRef();
  const messagesRef = firestore.collection("messages");
  let query = null;
  if (!!messageId) {
    query = messagesRef.doc(messageId);
  }
  const [formValue, setFormValue] = useState("");
  const [message, loading, error] = useDocument(query, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const messageData = !!message && !!message.data() ? message.data() : null;
  const jobId = messageData?.jobId ?? "";
  const chatHeight = height && height - 132;
  const isDisabled = typeof disabled !== "undefined" ? disabled : false;

  useEffect(() => {
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
  }, [jobId]);

  useEffect(() => {
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
  }, [message])

  const sendMessage = async (e) => {
    e.preventDefault();

    const thread = message.data().thread;
    thread.push({
      uid: userId,
      text: formValue,
      createdAt: moment().format("x"),
    });

    await messagesRef.doc(messageId).update({
      ...message.data(),
      thread: thread,
    });

    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    setFormValue("");
  };

  const renderThread = () => {
    if (!!messageData && !isEmpty(get(messageData, "thread"))) {
      return messageData.thread.map((msg, index) => (
        <ChatMessage
          key={index}
          message={msg}
          profile={profile}
          inspectorFullName={messageData.inspectorFullName}
          customerFullName={messageData.customerFullName}
          previousUid={
            index > 0 ? get(messageData.thread[index - 1], "uid") : ""
          }
          userId={userId}
          senderPhotoUrl={
            profile === "INSPECTOR"
              ? messageData.inspectorPhotoUrl
              : "https://firebasestorage.googleapis.com/v0/b/housky-chat.appspot.com/o/IMG_6473.PNG?alt=media&token=d60e713c-843e-4f65-8494-820293a01178"
          }
          receiptPhotoUrl={
            profile === "INSPECTOR"
              ? "https://firebasestorage.googleapis.com/v0/b/housky-chat.appspot.com/o/IMG_6473.PNG?alt=media&token=d60e713c-843e-4f65-8494-820293a01178"
              : messageData.inspectorPhotoUrl
          }
        />
      ));
    } else if (!messageId) {
      return <>Conversation has not been found</>;
    } else {
      return <>Start a conversation</>;
    }
  };

  return (
    <div className="chat-room">
      <section>
        <div className="chatBoard">
          <div
            ref={chatBoxRef}
            style={chatHeight && { minHeight: chatHeight }}
            className={` ${
              profile === "INSPECTOR" ? "inspectorChat" : "userChat"
            } chat-box`}
          >
            {error && <strong>Error: {JSON.stringify(error)}</strong>}
            {loading && <span>Document: Loading...</span>}
            {renderThread()}
          </div>
          <form className="chat-form" onSubmit={sendMessage}>
            <input
              disabled={isDisabled}
              value={formValue}
              className="msgInput"
              onChange={(e) => setFormValue(e.target.value)}
              placeholder={isDisabled ? 'Chat is not longer available' : 'Write your message here...'}
            />
            <button type="submit" disabled={!formValue.trim() || isDisabled}>
              <span
                className={profile === "INSPECTOR" ? "sendBtnHide" : "sendBtn"}
              >
                Send
              </span>{" "}
              <i className="material-icons left">send</i>
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ChatRoom;
