import React, { useEffect, useState, useContext }  from "react";
import { Redirect } from "react-router-dom";
import AuthContext from "../../../contexts/auth";
import { inspectorApp as firebase } from "../../../firebase";
// import ShowEye from "../../../assets/eye.svg";
// import HideEye from "../../../assets/eye-slash.svg";
import { Link } from "react-router-dom";
import cssStyles from "./InspectorSignIn.module.css"
// import { isUser } from "babel-types";
// import { signContract } from "../../../services/service";

const useInput = (initialVal) => {
  const [ value, setValue ] = useState(initialVal)
  const onValueChangeHandler = (e) => {
    setValue(e.target.value)
  }
  return [ value, onValueChangeHandler ]
}

const Login = () => {

  const [ email, onEmailChange ] = useInput('')
  const [ password, onPasswordChange ] = useInput('')
  const [ loginErrorMessage, setLoginErrorMessage ] = useState('')
  const [ isLoginError, setIsLoginError ] = useState(false)
  const [ isUserSignedIn, setIsUserSignedIn ] = useState(false)

  const context = useContext(AuthContext)

  useEffect(() => {
    if(isUserSignedIn !== !!context.user){
      setIsUserSignedIn(!!context.user)
    }
  }, [isUserSignedIn, context.user]
  )

  const onSubmit = (e) => {
    e.preventDefault()
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(error => {
        if (
          error.code === "auth/invalid-email" ||
          error.code === "auth/wrong-password"
        ) {
          setIsLoginError(true)
          setLoginErrorMessage("* Invalid email or password.")
        } else {
          setIsLoginError(true)
          setLoginErrorMessage("* Error user may not exist or be disabled.")
        }
      })
  }

  const showForm = () => {
    return(
        <form className={cssStyles.form}>
          <div className={cssStyles.formRow}>
            <label htmlFor="email">Email Address</label>
            <input id="email" type="text" className={`browser-default ${cssStyles.inputField}`} onChange={onEmailChange}/>
          </div>
          <div className={cssStyles.formRow}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <label htmlFor="password">Password</label>
              <Link to='/reset'>Forgot password?</Link>
            </div>
            <input id="password" type="password" className={`browser-default ${cssStyles.inputField}`} onChange={onPasswordChange}/>
          </div>
          <div className={cssStyles.errorMessage}>
            {isLoginError ? <span>{loginErrorMessage}</span> : <></>}
          </div>
          <div className={cssStyles.buttonContainer}>
              <button onClick={onSubmit}><b>SIGN IN</b></button>
              <div className={cssStyles.underButtonText}>
                  <span>Don't have an account? </span>
                  <Link to='/signup'>Sign Up</Link>
              </div>
          </div>
        </form>
    )
  }

  if(!isUserSignedIn){
    return(
      <div className={cssStyles.pageContainer}>
        <div className={cssStyles.contentContainer}>
          <div className={cssStyles.contentLeftBx}>
                {/*<span >We connect House Inspectors to those who need it.</span>*/}
                <img alt="" src={require('../../../assets/sign-up-home-2.png')}/>
          </div>
          <div className={cssStyles.contentRightBx}>
            <div className={cssStyles.formTitleBx}>
              <span>Sign In</span>
            </div>
            {showForm()}
          </div>
        </div>
      </div>
    )
  } else{
    return(<Redirect to="/job-requests" />)
  }
}

export default Login;
