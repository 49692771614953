import React from 'react';

export default function () {
  return (
    <div className="valign-wrapper" style={{ backgroundColor: '#104B69' }}>
      <div className="row valign white-text">
        <div className="col s12 m10 offset-m1">
          <div className="row" style={{ margin: '80px 0px' }}>
            <div className="col s12">
              <h2>Have questions?</h2>
            </div>
            <div className="col s12 m9">
              <h4>Email us</h4>
              <div style={{ fontSize: '18px', paddingLeft: '20px', marginBottom: '10px' }}>
                <a style={{}} href='mailto:info@housky.io?subject=Inquiry' target="_blank" rel="noopener noreferrer" >Yifu@housky.io</a>
              </div>
            </div>
            <div className="col s12 m9">
              <h4>Text us</h4>
              <div style={{ fontSize: '18px', paddingLeft: '20px', marginBottom: '10px' }}>
                929-426-9205
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}