import React, { useState } from 'react';
import cssStyles from './InspectorSignUp.module.css'
import { US_STATES } from '../../../utils/constants';
import { Link } from 'react-router-dom';
import Materialize from "materialize-css/dist/js/materialize.min.js";
import { createInspectorStep2 } from '../../../services/service';

const useInput = (initialVal) => {
    const [ value, setValue ] = useState(initialVal)
    const onValueChangeHandler = (e) => {
      setValue(e.target.value)
    }
    return [ value, onValueChangeHandler ]
  }

const InspectorSignUp_Step2 = (props) => {
    const [ address, onAddressChange ] = useInput('')
    const [ aptInfo, onAptInfoChange ] = useInput('')
    const [ city, onCityChange ] = useInput('')
    const [ state, onStateChange ] = useInput('')
    const [ zip, onZipChange ] = useInput('')
    const [ isTouched, setIsTouched ] = useState({
        address: false,
        city: false,
        state: false,
        zip: false
    })

    const addressIsValid = address.trim() !== ''
    const cityIsValid = city.trim() !== ''
    const stateIsValid = state.trim() !== ''
    const zipIsValid = zip.trim() !== '' && !isNaN(zip) && zip.length === 5;

    const addressClasses = !addressIsValid && isTouched.address ? `browser-default ${cssStyles.invalid_input}` : `browser-default ${cssStyles.input}`
    const cityClasses = !cityIsValid && isTouched.city ? `browser-default ${cssStyles.invalid_input}` : `browser-default ${cssStyles.input}`
    const stateClasses = !stateIsValid && isTouched.state ? `browser-default ${cssStyles.invalid_select}` : `browser-default ${cssStyles.select}`
    const zipClasses = !zipIsValid && isTouched.zip ? `browser-default ${cssStyles.invalid_input}` : `browser-default ${cssStyles.input}`

    const onBlur = (fieldName) => {
        return async() => {
          const newIsTouched = Object.assign({...isTouched}, {[fieldName]: true})
          setIsTouched(newIsTouched)
        }
      };

    const saveFormToDatabase = async () => {
        try{
            let body = {
                token: props.token,
                inspectorId: props.inspectorId,
                address,
                aptInfo,
                zip,
                state,
                city
            }
            await createInspectorStep2(body);

            // pass address information back to parent
            props.setAddress({address, aptInfo, city, state, zip})

            setTimeout(() => {
                props.setCurrentStep(3)
            }, 1500);

            // give user feedback about the result
            Materialize.toast({
                html: "Success! Your information has been updated.",
                classes: "rounded",
            });

        } catch{
            Materialize.toast({
            html: "Error. Please try again later.",
            classes: "rounded",
        });
        }
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();
        setIsTouched({
            address: true,
            city: true,
            state: true,
            zip: true
        });

        if(!addressIsValid){ return }
        if(!cityIsValid){ return }
        if(!stateIsValid){ return }
        if(!zipIsValid){ return }

        // update database
        saveFormToDatabase()
    }

    return(
        <div className={cssStyles.form}>
            <div style={{width: '100%'}}>
                <div className={cssStyles.row}>
                    <div className={cssStyles.full_width_container}>
                        <h5>Home Address</h5>
                    </div>
                </div>
                <form>
                    <div className={cssStyles.row}>
                        <div className={cssStyles.full_width_container}>
                            <label htmlFor="address" className="">Address <span style={{color: '#bb3e03'}}>*</span></label>
                            <div>
                                <input id="address" type="text" className={ addressClasses } onChange={ onAddressChange } onBlur={onBlur('address')}/>
                                { !addressIsValid && isTouched.address && <p className={cssStyles.warning}>Please enter address.</p>}
                            </div>
                        </div>
                    </div>
                    <div className={cssStyles.row}>
                        <div className={cssStyles.full_width_container}>
                            <label htmlFor="aptInfo" className="">Apartment, suite, etc.</label>
                            <div>
                                <input id="aptInfo" type="text" className={`browser-default ${cssStyles.input}`} onChange={ onAptInfoChange }/>
                            </div>
                        </div>
                    </div>
                    <div className={cssStyles.row}>
                        <div className={cssStyles.full_width_container}>
                            <label htmlFor="city" className="">City <span style={{color: '#bb3e03'}}>*</span></label>
                            <div>
                                <input id="city" type="text" className={ cityClasses } onChange={ onCityChange } onBlur={onBlur('city')}/>
                                { !cityIsValid && isTouched.city && <p className={cssStyles.warning}>Please enter city.</p>}
                            </div>
                        </div>
                    </div>
                    <div className={cssStyles.row}>
                        <div className={cssStyles.half_width_container}>
                            <label htmlFor="state" className="">State <span style={{color: '#bb3e03'}}>*</span></label>
                            <div>
                                <select className={ stateClasses } onChange={ onStateChange } onBlur={onBlur('state')}>
                                    <option>Choose State</option>
                                    {US_STATES.map((state, index) => {
                                        return (<option key={index} value={state.abbreviation}>{state.name}</option>)
                                    })}
                                </select>
                                { !stateIsValid && isTouched.state && <p className={cssStyles.warning}>Please choose state.</p>}
                            </div>
                        </div>
                        <div className={cssStyles.half_width_container}>
                            <label htmlFor="zip" className="">Zip Code <span style={{color: '#bb3e03'}}>*</span></label>
                            <div>
                                <input id="zip" type="text" className={zipClasses} onChange={ onZipChange } onBlur={onBlur('zip')}/>
                                { !zipIsValid && isTouched.zip && <p className={cssStyles.warning}>Please enter zip code.</p>}
                            </div>
                        </div>
                    </div>
                    <div className={cssStyles.row} style={{marginTop: '60px'}}>
                        <div className={cssStyles.full_width_container}>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                <button className={`browser-default ${cssStyles.button}`} onClick={ onSubmitHandler }><b>SAVE & CONTINUE</b></button>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                <label>You can choose to <Link to='/account'>skip</Link> this for now and fill this in later.</label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
      </div>
    )

};

export default InspectorSignUp_Step2;
