import React from 'react'

const defaultSrc = (ev) => {
  ev.target.src = require('../../../assets/blank-profile-picture.png')
}

export default (props) => {

  const getAdditionalFeatures = () => {
    if(props.additionalFeatures && props.additionalFeatures.radon || props.additionalFeatures && props.additionalFeatures.wdi) {
      return (
        <div className='row'>
          <h5>Additional Inspections</h5>
          <div className='subText'>
            {props.additionalFeatures && props.additionalFeatures.radon ? <h6>Radon Test</h6> : <></>}
            {props.additionalFeatures && props.additionalFeatures.wdi ? <h6>Wood Destroying Insect(Termite) Inspection</h6> : <></>}
          </div>
        </div>
      );
    }
  };

  return <div className={`col s12 m${props.size} center`} style={{ backgroundColor: props.bgColor, position: 'relative', zIndex: '5' }}>
    <h5>Your Schedule Summary</h5>
    <div className='row' style={{ border: '1px solid #546A76', borderLeft: '0', borderRight: '0' }}>
      <h6>Selected Inspectors</h6>
      {props.inspectorList && props.inspectorList.map((e, i) => {
        return <div className='inspector-circle-preview px-2' key={i} style={{ position: 'relative' }}>
          <img alt='Preview'
            onError={defaultSrc}
            title={`${e.firstName} ${e.lastName}`}
            style={{ width: '65px', height: '65px', borderRadius: '50%' }}
            src={e.imageUrl ? e.imageUrl : require('../../../assets/blank-profile-picture.png')}
            id={e.id} />
        </div>
      })}
    </div>
    <div className='row' style={{ borderBottom: '1px solid #546A76' }}>
      <h5>Address Information</h5>
      <div className='subText'>
        <h6 style={{textTransform:'capitalize'}}>{props.address_one} {props.address_two}</h6>
        <h6 style={{textTransform:'capitalize'}}><span style={{textTransform:'uppercase'}}>{props.formState}{props.formState ? ',' : ''}</span> {props.city} {props.zipcode}</h6>
      </div>
    </div>
    <div className='row' style={{ borderBottom: '1px solid #546A76' }}>
      <h5>House Information</h5>
      <div className='subText'>
        {props.houseType !== 'Select House Type' ? <h6>{props.houseType}</h6> : <></>}
        {props.bedAmount ?
          <h6>{props.bedAmount} Beds, {props.bathAmount ? `${props.bathAmount} Bathrooms` : ''}</h6>
          : <></>}
        <h6>{props.sqftAmount ? `${props.sqftAmount} Sqft` : ''} </h6>
        <h6>{props.yearbuilt ? `${props.yearbuilt}  Year Built` : ''}</h6>
        <h6>{props.parking !== 'Select Parking' ? `${props.parking}` : ''}</h6>
        <h6>{props.foundation !== 'Select Foundation' ? props.foundation === 'Other' ? `${props.foundationOther} Foundation` : `${props.foundation} Foundation` : ''}</h6>
        <h6>{props.attic !== 'Select Attic' ? props.attic === 'Other' ? `${props.atticOther} Attic` : `${props.attic} Attic` : ''}</h6>
      </div>
    </div>
    {props.events.length > 0 ?
      <>
        <div className='row'>
          <h5>Selected Dates</h5>
          {props.events.map((e, i) => {
            return <div className='col s4 py-2' style={{ minHeight: '130px' }} key={i}>
              {e.date}
              <div style={{ color: 'gray' }}>
                {e.early_morning ? <p className='m-0'>Early Morning</p> : null}
                {e.morning ? <p className='m-0'>Morning</p> : null}
                {e.afternoon ? <p className='m-0'>Afternoon</p> : null}
                {e.late_afternoon ? <p className='m-0'>Late Afternoon</p> : null}
              </div>
            </div>
          })}
        </div>
      </> : <></>}
    { getAdditionalFeatures() }
  </div>
}