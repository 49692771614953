import React from "react";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import Calendar from "./Calendar";
import FormContainer from "./FormContainer";
import Timeslots from "./Timeslots";
import SummaryInfo from "./SummaryInfo";
import moment from "moment";
import AOS from "aos";
import "aos/dist/aos.css";
import { Redirect } from "react-router-dom";
import SubmitRequest from "./SubmitRequest";
import LoadingModal from "./LoadingModal";
import {
  getUser,
  createDetail,
  createHouse,
  createUserJob,
  deleteDetail,
  deleteHouse,
  getPrices,
  getForecast,
  getLocationFromAddress, getLocationInfo,
} from "../../../services/service";
import ConfirmInspectors from "./ConfirmInspectors";
import haversineDistance from "haversine-distance";
import {app as firebase} from "../../../firebase";

const handleHover = (id) => {
  document.getElementById(id).classList.add("info-box-active");
};
const handleLeave = (id) => {
  document.getElementById(id).classList.remove("info-box-active");
};

const getToken = async () => {
  let currentUser = firebase.auth().currentUser;
  if(currentUser) {
    return currentUser.getIdToken(true)
  }
  return ""
}

const CONDO_BASE_SIZE = 1000
const SINGLE_FAMILY_BASE_SIZE = 1500
const MULTI_FAMILY_BASE_SIZE = 2000
const DEFAULT_SPECIAL = 100

export default class InspectionForm extends React.Component {
  state = {
    current: "forms",
    address_one: "",
    address_two: "",
    city: "",
    formState: "",
    zipcode: "",
    houseType: "Select House Type",
    bedAmount: "",
    bathAmount: "",
    sqftAmount: "",
    yearbuilt: "",
    parking: "Select Parking",
    attic: "Select Attic",
    foundation: "Select Foundation",
    foundationOther: "",
    atticOther: "",
    events: [],
    allChecked: 0,
    additional_features: {
      wdi: false,
      radon: false,
    },
    userid: "",
    token: "",
    inspectorIdList: [],
    redirect: false,
    wdi_price: "",
    radon_price: "",
    house_price: "",
    beta: "",
    inspection_quality: "",
    margin: "",
    processing_fee: "",
    inspection_cost: 0,
    address_error: false,
    forecast: {},
    jobid: "",
    found_address: null,
    estimatedPrice: {}
  };

  componentDidMount = async () => {
    let inspectorIdList = [];
    window.scrollTo(0, 0);
    this.runMaterialize();
    AOS.init({
      duration: 1200,
    });
    const token = await getToken();
    const user = await getUser(token);
    if(this.props.inspectorList) {
      this.props.inspectorList.map((e) => {
        return inspectorIdList.push(e.id);
      });
    }
    this.setState({
      userid: user.userid,
      token: this.props.token,
      inspectorIdList,
    });
  };

  runMaterialize = () => {
    let elem = document.querySelectorAll("select");
    Materialize.FormSelect.init(elem, {
      classes: "",
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleRestrictedLength = (e, stateName, length) => {
    if (e.target.value.length < length) {
      this.setState({ [stateName]: e.target.value });
    }
  };

  handleNumRestrictedLength = (e, stateName, length) => {
    if (
      e.target.value < Infinity &&
      e.target.value > -1 &&
      e.target.value.length < length
    ) {
      this.setState({ [stateName]: e.target.value });
    }
  };

  handleSelectOptions = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // locationFromGeo = () =>{
  //   getLocation()
  //   .then((res)=>{
  //     console.log(res)
  //   })
  // }

  handleNextPage = (e) => {
    e.preventDefault();
    const {
      address_one,
      formState,
      city,
      zipcode,
      current,
      houseType,
      yearbuilt,
      sqftAmount
    } = this.state;
    if (current === "forms") {
      if (!address_one || !formState || !city || !zipcode || !sqftAmount || !yearbuilt) {
        Materialize.toast({
          html: "Please fill out all required forms",
          classes: "rounded",
        })
      } else if (houseType === "Select House Type") {
        Materialize.toast({
          html: "Please select a house type.",
          classes: "rounded",
        })
      } else {
        let prices = {};
        getLocationFromAddress(address_one, city, formState, zipcode)
          .then((address) => {
            getPrices()
              .then((res) => {
                res.map((e) => {
                  if (e.house_type === houseType) {
                    prices.house_price = e.price;
                    prices.wdi_price = e.wdi;
                    prices.radon_price = e.radon;
                    prices.beta = e.beta;
                    prices.inspection_quality = e.inspection_quality;
                    prices.margin = e.margin;
                    prices.processing_fee = e.processing_fee;
                  }
                  return prices;
                });
                return prices;
              })
              .then((prices) => {
                let {
                  wdi_price,
                  radon_price,
                  house_price,
                  beta,
                  inspection_quality,
                  margin,
                  processing_fee,
                } = prices;

                beta = Number.parseInt(beta);
                inspection_quality = Number.parseInt(inspection_quality);
                margin = Number.parseInt(margin);
                processing_fee = Number.parseFloat(processing_fee);
                let inspection_cost = Number.parseInt(house_price);

                inspection_cost =
                  (inspection_cost * processing_fee + margin) *
                  beta *
                  inspection_quality;

                window.scrollTo(0, 0);
                let address_one = "";
                let address_route = "";
                let address_city = "";
                let address_state = "";
                let address_zipcode = "";
                for (let i = 0; i < address.address_components.length; i++) {
                  if (
                    address.address_components[i].types.includes(
                      "street_number"
                    )
                  )
                    address_one = address.address_components[i].long_name;
                  if (address.address_components[i].types.includes("route"))
                    address_route = address.address_components[i].long_name;
                  if (
                    address.address_components[i].types.includes(
                      "neighborhood"
                    ) ||
                    address.address_components[i].types.includes("locality") ||
                    address.address_components[i].types.includes(
                      "administrative_area_level_3"
                    )
                  )
                    address_city = address.address_components[i].long_name;
                  if (
                    address.address_components[i].types.includes(
                      "administrative_area_level_1"
                    )
                  )
                    address_state = address.address_components[i].short_name;
                  if (
                    address.address_components[i].types.includes("postal_code")
                  )
                    address_zipcode = address.address_components[i].long_name;
                }
                let found_address = {
                  address_one,
                  address_route,
                  address_city,
                  address_state,
                  address_zipcode,
                };
                this.setState({
                  found_address,
                  current: "inspectorConfirmation",
                  wdi_price,
                  radon_price,
                  house_price,
                  beta,
                  inspection_quality,
                  margin,
                  processing_fee,
                  inspection_cost,
                  address_error: false,
                  address_one: `${address_one} ${address_route}`,
                  city: address_city,
                  formState: address_state,
                  zipcode: address_zipcode,
                  lat: address.geometry.location.lat,
                  lng: address.geometry.location.lng,
                });
              });
            return address.geometry.location;
          })
          .then((location) => {
            getForecast(location.lat, location.lng).then((res) => {
              this.setState({ forecast: res });
            });
          })
          .catch(() => {
            window.scrollTo(0, 0);
            this.setState({ address_error: true });
          });
      }
    } else if (current === "inspectorConfirmation") {
      window.scrollTo(0, 0);
      this.setState({ current: "calendar" });
    } else if (current === "calendar") {
      window.scrollTo(0, 0);
      this.setState({ current: "timeslots" });
    } else if (current === "timeslots") {
      if (this.state.allChecked === this.state.events.length) {
        window.scrollTo(0, 0);
        this.setState({ current: "additional" });
      } else
        Materialize.toast({
          html: "Please select a time availability for all dates.",
          classes: "rounded",
        });
    }
  };

  handleCalendarAdd = (e) => {
    let { events } = this.state;
    if (e > new Date()) {
      for (let i = 0; i < events.length; i++) {
        if (events[i].date === moment(e).format("dddd, MMMM Do YYYY")) {
          events.splice(i, 1);
          this.setState({ events });
          return events;
        }
      }
      if (events.length < 9) {
        events.push({
          title: "",
          start: moment(e).format("x"),
          end: moment(e).format("x"),
          allDay: true,
          date: moment(e).format("dddd, MMMM Do YYYY"),
          unix: moment(e).format("x"),
          early_morning: false,
          morning: false,
          afternoon: false,
          late_afternoon: false,
        });
        let sorted = events.sort((a, b) => {
          if (a.unix < b.unix) return -1;
          if (a.unix > b.unix) return 1;
          return 0;
        });
        this.setState({ events: sorted });
      }
    } else {
      // console.log("TOO OLD")
    }
  };

  handleCheck = () => {
    let { events } = this.state;
    let allChecked = 0;
    for (let i = 0; i < events.length; i++) {
      if (
        events[i].early_morning ||
        events[i].morning ||
        events[i].afternoon ||
        events[i].late_afternoon
      )
        allChecked++;
    }
    return allChecked;
  };

  handleTimeslots = (e, date) => {
    let { events } = this.state;
    for (let i = 0; i < events.length; i++) {
      if (events[i].date === date) {
        events[i][e.currentTarget.name] = !events[i][e.currentTarget.name];
      }
    }
    let allChecked = this.handleCheck();
    this.setState({ events, allChecked });
  };

  handleAdditional = (e) => {
    let { additional_features } = this.state;
    additional_features[e.target.id] = !additional_features[e.target.id];
    this.setState({ additional_features });
  };

  handleSelectiveBack = (e) => {
    e.preventDefault();
    if (this.state.current === "additional")
      this.setState({ current: "timeslots" });
    else if (this.state.current === "timeslots")
      this.setState({ current: "calendar" });
    else if (this.state.current === "calendar")
      this.setState({ current: "inspectorConfirmation" })
    else if (this.state.current === "inspectorConfirmation") {
      this.setState({ current: "forms" });
      setTimeout(() => {
        this.runMaterialize();
      }, 100);
    } else {
      this.props.handleBack(e);
    }
  };

  handleConfirm = (e) => {
    e.preventDefault();
    this.setState({ loadingModal: true });
    let {
      address_one,
      address_two,
      city,
      formState,
      zipcode,
      houseType,
      bedAmount,
      bathAmount,
      sqftAmount,
      yearbuilt,
      parking,
      attic,
      foundation,
      foundationOther,
      atticOther,
      events,
      additional_features,
      userid,
      lat,
      lng,
      token,
      wdi_price,
      radon_price,
      house_price,
      beta,
      inspection_quality,
      margin,
      processing_fee,
    } = this.state;
    beta = Number.parseInt(beta);
    inspection_quality = Number.parseInt(inspection_quality);
    margin = Number.parseInt(margin);
    processing_fee = Number.parseFloat(processing_fee);
    let inspection_cost = Number.parseInt(house_price);
    inspection_cost =
      (inspection_cost * processing_fee + margin) * beta * inspection_quality;

    if (bedAmount === "") bedAmount = 0;
    if (bathAmount === "") bathAmount = 0;
    if (sqftAmount === "") sqftAmount = 0;
    if (yearbuilt === "") yearbuilt = 0;
    if (parking === "Select Parking") parking = "Street";
    if (foundation === "Other" || foundation === "Select Foundation")
      foundation = foundationOther;
    if (attic === "Other" || attic === "Select Attic") attic = atticOther;
    const detailBody = {
      bedroom: bedAmount,
      bathroom: bathAmount,
      sqft: sqftAmount,
      year_built: yearbuilt,
      parking,
      foundation,
      attic,
    };
    // CONDO by Default
    let house_typeid = 1;
    if (houseType === "Town House") house_typeid = 2;
    else if (houseType === "Single Family") house_typeid = 3;
    else if (houseType === "Two-Three Family") house_typeid = 4;
    else if (houseType === "Mansion") house_typeid = 5;
    const houseBody = {
      address_one,
      address_two,
      city,
      state: formState,
      zipcode,
      house_typeid,
      latitude: lat,
      longitude: lng,
    };
    if (additional_features.wdi) inspection_cost += Number.parseInt(wdi_price);
    if (additional_features.radon)
      inspection_cost += Number.parseInt(radon_price);
    console.log(this.props.token)
    const jobBody = {
      inspection_cost,
      eventinfo: JSON.stringify(events),
      additional_features: JSON.stringify(additional_features),
      current_status: "PENDING",
      job_type: "USER",
      restriction_inspector: this.state.inspectorIdList,
      accepted: false,
      token,
      userid,
      reportid: null,
      inspectorid: null,
      title: `${houseType} ${address_one}`,
      price_range: JSON.stringify(this.state.estimatedPrice),
    };
    createDetail(detailBody).then((detail) => {
      houseBody.house_detailid = detail.data.house_detailid;
      createHouse(houseBody)
        .then((house) => {
          jobBody.houseid = house.data.houseid;
          createUserJob(jobBody)
            .then((job) => {
              Materialize.toast({
                html: "Email Confirmation has been sent!",
                classes: "rounded",
                displayLength: 3000,
              });
              Materialize.toast({
                html:
                  "Your Inspection request has been submitted!",
                classes: "rounded",
                displayLength: 6500,
              });
              // this.setState({ jobid: job.data.jobid, redirect: true });
              window.localStorage.removeItem('selectedInspectors');
              this.props.history.push('/dashboard/inspections')
            })
            .catch(() => {
              deleteHouse(house.data.houseid);
              deleteDetail(detail.data.house_detailid);
            });
        })
        .catch(() => {
          deleteDetail(detail.data.house_detailid);
        });
    });
  };

  handleStateRestrictedLength = (e, stateName, length) => {
    if (e.target.value.length < length) {
      this.setState({ [stateName]: e.target.value.toUpperCase() });
    }
  };

  handleCorrectAddress = () => {
    const { found_address } = this.state;
    this.setState({
      address_error: false,
      address_one: `${found_address.address_one} ${found_address.address_route}`,
      city: found_address.address_city,
      formState: found_address.address_state,
      zipcode: found_address.address_zipcode,
    });
  };

  getEstimatedPrice = (inspector, houseLocation) => {
    const { calculatorParams, latitude, longitude } = inspector;
    const basePriceMap = {
      'Condo': calculatorParams.basePrice.condoBasePrice,
      'Single Family': calculatorParams.basePrice.singleFamilyBasePrice,
      'Two-Three Family': calculatorParams.basePrice.multiFamilyBasePrice
    }

    const baseSizeMap = {
      'Condo': CONDO_BASE_SIZE,
      'Single Family': SINGLE_FAMILY_BASE_SIZE,
      'Two-Three Family': MULTI_FAMILY_BASE_SIZE
    };

    const basePriceResult = basePriceMap[this.state.houseType];

    const age = new Date().getFullYear() - parseInt(this.state.yearbuilt);
    const ageResult = age <= 5 ? 0 : (Math.ceil((age - 5)/5)) * calculatorParams.ageBase
    const sizeResult = parseInt(this.state.sqftAmount) <= baseSizeMap[this.state.houseType] ? 0 : Math.ceil((parseInt(this.state.sqftAmount) - baseSizeMap[this.state.houseType])/500) * calculatorParams.sizeBase
    const distance = haversineDistance({lat: houseLocation.latitude, lng: houseLocation.longitude}, { latitude: latitude, longitude: longitude }) / 1600;
    const mileageResult = distance <= 5 ? 0: Math.ceil((distance - 5)/5) * calculatorParams.mileageBase
    let finalResult = basePriceResult + ageResult + sizeResult + mileageResult
    if(this.state.additional_features.wdi){ finalResult += calculatorParams.WDIBase}
    if(this.state.additional_features.radon){ finalResult += calculatorParams.radonBase}

    return finalResult
  }

  setEstimatedPrice = (price) => {
    this.setState({
      estimatedPrice: price
    })
  };

  render() {
    let {
      address_one,
      address_two,
      city,
      formState,
      zipcode,
      houseType,
      bedAmount,
      bathAmount,
      sqftAmount,
      yearbuilt,
      parking,
      attic,
      foundation,
      foundationOther,
      atticOther,
      events,
      current,
      allChecked,
      additional_features,
      house_price,
      wdi_price,
      radon_price,
      inspection_cost,
      address_error,
      found_address,
    } = this.state;

    if(this.state.redirect) {
      return <Redirect to={`/checkout/${this.state.jobid}`} />
    }

    if(this.state.loadingModal) {
      return <LoadingModal />
    }

    const getStep = () => {
      switch (current) {
        case "additional":
          return (
            <SubmitRequest
              inspectorList={this.props.inspectorList}
              address_one={address_one}
              address_two={address_two}
              formState={formState}
              city={city}
              zipcode={zipcode}
              houseType={houseType}
              bedAmount={bedAmount}
              bathAmount={bathAmount}
              sqftAmount={sqftAmount}
              yearbuilt={yearbuilt}
              parking={parking}
              foundation={foundation}
              foundationOther={foundationOther}
              attic={attic}
              atticOther={atticOther}
              events={events}
              house_price={house_price}
              wdi_price={wdi_price}
              radon_price={radon_price}
              inspection_cost={inspection_cost}
              handleConfirm={this.handleConfirm}
              additional_features={additional_features}
              handleAdditional={this.handleAdditional}
              handleSelectiveBack={this.handleSelectiveBack}
            />
          );
        case "forms":
          return (
            <FormContainer
              found_address={found_address}
              handleCorrectAddress={this.handleCorrectAddress}
              // locationFromGeo={this.locationFromGeo}
              address_one={address_one}
              address_two={address_two}
              city={city}
              formState={formState}
              zipcode={zipcode}
              houseType={houseType}
              bedAmount={bedAmount}
              bathAmount={bathAmount}
              sqftAmount={sqftAmount}
              yearbuilt={yearbuilt}
              parking={parking}
              attic={attic}
              foundation={foundation}
              foundationOther={foundationOther}
              atticOther={atticOther}
              address_error={address_error}
              additional_features={additional_features}
              handleChange={this.handleChange}
              handleState={(e) => {
                this.handleStateRestrictedLength(e, "formState", 3);
              }}
              handleSelectOptions={this.handleSelectOptions}
              handleNumRestrictedLength={this.handleNumRestrictedLength}
              handleNextPage={this.handleNextPage}
              handleBack={this.props.handleBack}
              handleAdditional={this.handleAdditional}
            />
          );
        case "calendar":
          return (
            <div data-aos="fade-left" style={{display: "contents"}}>
              <div className="col s12 center" style={{ position: "relative" }}>
                <h5 style={{ maxWidth: "80%", margin: "auto" }}>
                  Please select up to 9 best available dates for
                  scheduling your home inspection.
                  <span
                    onMouseLeave={() => {
                      handleLeave("info-box");
                    }}
                    onMouseOver={() => {
                      handleHover("info-box");
                    }}
                    className="more-info-question"
                    style={{
                      lineHeight: "16px",
                      width: "16px",
                      fontSize: "16px",
                      marginTop: "4px",
                      marginLeft: "8px",
                      bottom: "unset",
                      right: "unset",
                    }}>?</span>
                </h5>
                <div id="info-box" className="more-info-box z-depth-2">
                  <p>
                    By providing three dates you are maximizing
                    your chance of getting matched with a home
                    inspector.
                  </p>
                </div>
              </div>
              <Calendar
                events={events}
                forecast={this.state.forecast}
                handleCalendarAdd={this.handleCalendarAdd}
              />
              <div className="col s12 pt-3 center" style={{ marginBottom: "100px" }}>
                <button
                  className={` py-1 btn ${
                    events.length > 2 && events.length <= 9
                      ? "housky-primary-bg"
                      : "housky-secondary-bg"
                  }`}
                  onClick={
                    events.length > 2 && events.length <= 9
                      ? this.handleNextPage
                      : () => {
                        Materialize.toast({
                          html:
                            "Please a minimum of 3 dates and up to 9 dates.",
                          classes: "rounded",
                        });
                      }
                  }
                  style={{ width: "85%", height: "auto" }}
                >
                  Next - Select Time
                </button>
              </div>
            </div>
          );
        case "timeslots":
          return (
            <div data-aos="fade-left">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <Timeslots events={events} handleTimeslots={this.handleTimeslots} />
              </div>
              <div className="col s12 pt-3 center" style={{ marginBottom: "100px" }}>
                <button
                  className={`btn py-1 ${
                    allChecked === this.state.events.length
                      ? "housky-primary-bg"
                      : "housky-secondary-bg"
                  }`}
                  onClick={this.handleNextPage}
                  style={{ width: "85%", height: "auto" }}
                >
                  Next - View Summary
                </button>
              </div>
              <div style={{marginTop: "1rem"}}>
                <span>Select time range that you are available, your inspector will confirm the exact time later.</span>
              </div>
            </div>
          );
        case "inspectorConfirmation":
          return (
            <ConfirmInspectors
              backToSchedule={this.handleSelectiveBack}
              token={this.props.token}
              handleNext={this.handleNextPage}
              setInspectorList={this.props.setInspectorList}
              inspectorList={this.props.inspectorList}
              getEstimatedPrice={this.getEstimatedPrice}
              houseZipcode={this.state.zipcode}
              estimatedPrice={this.state.estimatedPrice}
              setEstimatedPrice={this.setEstimatedPrice}
            />
          );
      }
    };

    const getSummaryInfoSection = () => {
      if(this.state.current !== 'additional') {
        return (
          <SummaryInfo
            bgColor="#b2bac8"
            size={4}
            inspectorList={this.props.inspectorList}
            address_one={address_one}
            address_two={address_two}
            formState={formState}
            city={city}
            zipcode={zipcode}
            houseType={houseType}
            bedAmount={bedAmount}
            bathAmount={bathAmount}
            sqftAmount={sqftAmount}
            yearbuilt={yearbuilt}
            parking={parking}
            foundation={foundation}
            foundationOther={foundationOther}
            attic={attic}
            atticOther={atticOther}
            events={events}
            additionalFeatures={this.state.additional_features}
          />
        );
      }
    }

    const getStyle = () => {
      if (this.state.current !== 'additional') {
        return 'col s12 m8 mt-3 p-0';
      } else {
        return 'col s12'
      }
    }


    return (
      <div className="row mb-0 pt-0 form-row">
        <div className={getStyle()}>
          <div className="container">
              <span style={{ fontSize: "24px" }} className="mouse-pointer mt-2 pt-2 pl-2" onClick={this.handleSelectiveBack}>
                <i className="material-icons">arrow_back</i> Back
              </span>
            { getStep() }
          </div>
        </div>
        { getSummaryInfoSection() }
      </div>
    );
  }
}
