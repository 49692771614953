import React, { useEffect, useState } from "react";
import { app as userFirebase } from "../../../firebase";
import { getUserJobs, getUser } from "../../../services/service";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import { InspectionStage } from "./InspectionStage";

const DashboardForInspections = (props) => {
  const [jobs, setJobs] = useState([]);
  const [recentJob, setRecentJob] = useState({});
  const [tabs] = useState(["Pending", "Accepted", "Completed"]);
  const [currentTab, setCurrentTab] = useState("Pending");
  const [loading, setLoading] = useState(false);

  const getToken = () => {
    return userFirebase.auth().currentUser.getIdToken(true);
  };

  useEffect(() => {
    setLoading(true);
    getToken().then((res) => {
      getUser(res).then((user) => {
        getUserJobs(user.userid, res).then((res) => {
          let sorted = res.sort((a, b) => {
            if (a.createdat > b.createdat) return -1;
            if (a.createdat < b.createdat) return 1;
            return 0;
          });
          setJobs(sorted);
          setRecentJob(sorted[0]);
          setLoading(false);
        });
      });
    });
    let elems = document.querySelectorAll(".modal");
    Materialize.Modal.init(elems, { inDuration: "450" });
  }, []);

  const openTab = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <div className="mainPanel">
      <div className="row section">
        <div className="col mainTabs">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`${currentTab === tab ? "activeTab" : ""} tabBtn`}
              onClick={() => openTab(tab)}
            >
              {tab === "Accepted" ? "Assigned" : tab}
            </button>
          ))}
        </div>
      </div>
      <div className="row">
        <InspectionStage loading={loading} tabName={currentTab} jobs={jobs} />
      </div>
    </div>
  );
};

export default DashboardForInspections;
