import React from 'react';
import './HomePageCard.css'

export default (props) => {

    const redirect = () => {
      window.location = props.path
    };

    return(
        <div className="home-page-card__card-container" onClick={redirect}>
            <div className="home-page-card__card-icon">
                <img src={ props.src }/>
            </div>
            <div className="home-page-card__card-title">
                <span>{props.title}</span>
            </div>

            <div className="home-page-card__card-content">
                <p style={{marginLeft: '10px', marginRight: '10px'}}>{props.intro}</p>
            </div>

            <div className="home-page-card__card-footer">
                <div id="button" onClick={redirect}>{props.buttonTitle}</div>
            </div>
        </div>
    )
};


