import "./app.css";
import "./helper.css";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { app as firebase, inspectorApp } from "./firebase";

//=====Multiple Domain Pages
import AboutUs from "./containers/MainDomain/AboutUs/AboutUs";
import MissionCenter from "./containers/MainDomain/MissionCenter/MissionCenter";
import ActiveJobs from "./containers/InspectorDomain/ActiveJobs/ActiveJobs";
import AdminHouskyPricing from "./containers/AdminDomain/HouskyPricing/HouskyPricing";
import AdminInspectorRecords from "./containers/AdminDomain/InspectorRecords/InspectorRecords";
//ADMIN Domian Pages
import AdminNavbar from "./components/AdminDomain/Navbar";
import AdminPreReg from "./containers/AdminDomain/PreReg/PreReg";
import AdminPromotions from "./containers/AdminDomain/Promotions/Promotions";
import AdminSignIn from "./containers/AdminDomain/SignIn/SignIn";
import AdminUserRecords from "./containers/AdminDomain/UserRecords/UserRecords";
import Tools from "./containers/AdminDomain/Tools/Tools";
//====Context
import AuthContext from "./contexts/auth";
import BookingProtection from "./containers/MainDomain/Terms/BookingProtection";
import Checkout from "./containers/UserDomain/Checkout/Checkout";
//=====Main Domain Pages
import Construction from "./containers/Construction";
import Cookies from "./containers/MainDomain/Terms/Cookies";
import Dashboard from "./containers/UserDomain/Dashboard/Dashboard";
import InspectionsDashboard from "./containers/UserDomain/Dashboard/Inspections";
import { Elements } from "@stripe/react-stripe-js";
import Home from "./containers/MainDomain/Home/Home";
import HouseReport from "./containers/UserDomain/Report/HouseReport.js";
import InspectorAccount from "./containers/InspectorDomain/Account/InspectorAccount";
import InspectorHome from "./containers/InspectorDomain/Home/InspectorHomePage";
import InspectorInfo from "./containers/AdminDomain/InspectorInfo/InspectorInfo";
import InspectorList from "./containers/AdminDomain/InspectorList/InspectorList";
import ContactUs from "./containers/MainDomain/ContactUs/ContactUs";
import Search from "./containers/MainDomain/Search/Search";
import IndividualPage from "./containers/MainDomain/IndividualPage/InspectorIndividualPage";
//=====Inspector Domain Pages
import InspectorNavbar from "./components/InspectorDomain/Navbar";
import InspectorReport from "./containers/InspectorDomain/InspectorReport/InspectorReport";
import InspectorSignUp from "./components/InspectorDomain/SignUp/NewInspectorSignUp";
import JobHistory from "./containers/InspectorDomain/JobHistory/JobHistory";
import JobRequests from "./containers/InspectorDomain/JobRequests/JobRequests";
import LearningCenter from "./containers/MainDomain/LearningCenter/LearningCenter";
import InspectorFaq from "./containers/InspectorDomain/InspectorFaq/InspectorFaqPage";
import FeeCalculator from "./components/InspectorDomain/FeeCalculator/InspectorFeeCalculator";
//=====User Domain Pages
import Login from "./components/UserDomain/Login/LogIn";
import MainFooter from "./components/MainDomain/Footer";
import MainNavbar from "./components/MainDomain/Navbar";
import OpenJobs from "./containers/AdminDomain/OpenJobs/OpenJobs";
import Pricing from "./containers/Pricing/Pricing";
import Privacy from "./containers/MainDomain/Terms/Privacy";
import ResetPassword from "./containers/ResetPassword/ResetPassword";
import Schedule from "./containers/UserDomain/Schedule/Schedule";
import SignIn from "./containers/InspectorDomain/SignIn/SignIn";
import SignUp from "./components/UserDomain/SignUp/SignUp";
import StripeInspectorConnect from "./containers/InspectorDomain/StripeInspectorConnect/StripeInspectorConnect";
import Tos from "./containers/MainDomain/Terms/Tos";
import UserAccount from "./containers/UserDomain/Account/UserAccount";
import UserNavbar from "./components/UserDomain/Navbar";
import { loadStripe } from "@stripe/stripe-js";
import ProfilePictureFAQ from "./components/InspectorDomain/ProfilePictureFAQ/ProfilePictureFAQ";
import Review from "./components/UserDomain/InspectorJobReview";
import PendingJobs from "./containers/AdminDomain/PendingJobs/PendingJobs";
import NewYorkInspectorSchoolPage from "./components/InspectorDomain/InspectorSchoolsPage/NewYorkInspectorSchoolPage";
import NewJerseyInspectorSchoolPage
  from "./components/InspectorDomain/InspectorSchoolsPage/NewJerseyInspectorSchoolPage";
import EightThinsToKnowPage from "./components/MainDomain/EightThingsToKnowPage/EightThinsToKnowPage";

const stripePromise = loadStripe(process.env.REACT_APP_PK_TEST_STRIPE);

const WindowsBlock = () => {
  return (
    <>
      <MainNavbar />
      <div id="navOffset" style={{ backgroundColor: "#104B69" }}></div>
      <div
        style={{
          backgroundColor: "#104b69",
          minHeight: "60vh",
          color: "white",
        }}
      >
        <div className="container">
          <div
            className="row center-align mb-0"
            style={{ padding: "80px 0px" }}
          >
            <div className="col s12 m6 offset-m3">
              <img
                src={require("./assets/HouskyLogo.png")}
                alt="Housky"
                style={{ width: "100%" }}
              />
            </div>
            <div className="col s12 m8 offset-m2 mb-4">
              <h3>Sorry, Internet Explorer is not supported at the moment.</h3>
              <h4>
                Please use Microsoft Edge, Google Chrome, FireFox, Safari or any
                up to date browser.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <MainFooter />
    </>
  );
};

const MobileUserWarning = () => {
  return (
    <>
      <div id="navOffset" style={{ backgroundColor: "#104B69" }}></div>
      <div
        style={{
          backgroundColor: "#104b69",
          minHeight: "60vh",
          color: "white",
        }}
      >
        <div className="container">
          <div
            className="row center-align mb-0"
            style={{ padding: "80px 0px" }}
          >
            <div className="col s12 m6 offset-m3">
              <img
                src={require("./assets/HouskyLogo.png")}
                alt="Housky"
                style={{ width: "100%" }}
              />
            </div>
            <div className="col s12 m8 offset-m2 mb-4">
              <h4>Sorry, mobile browser is not supported at the moment.</h4>
              <h5>
                Please visit us on your laptop or desktop computer for the full
                experience.
              </h5>
            </div>
          </div>
        </div>
      </div>
      <MainFooter />
    </>
  );
};

const Err = () => {
  return (
    <>
      {/* Should redirect to local subdomain home or 404 page. */}
      <Construction />
    </>
  );
};

function App(props) {
  let [user, setUser] = useState(null);
  let [authStatusCheck, setAuthStatusCheck] = useState(false);
  let [domain, setDomain] = useState("main");

  useEffect(() => {
    if(process.env.REACT_APP_ENV === "PROD" && window.location.protocol === 'http:') {
      console.log('Redirecting to https')
      window.location = `https://${window.location.host}`
    }
  }, [])

  useEffect(() => {
    let unsubscribeUser = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setAuthStatusCheck(true);
    });

    return unsubscribeUser;
  }, []);

  useEffect(() => {
    let unsubscribeInspector = inspectorApp
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          setUser(user);
        } else {
          setUser(null);
        }
        setAuthStatusCheck(true);
      });

    return unsubscribeInspector;
  }, []);

  const MainDomain = () => {
    const getNavBar = () => {
      if (window.location.href.indexOf("/search") < 0 && window.location.href.indexOf("/inspector") < 0) {
        return (
          <Route
            path="/"
            render={() => <MainNavbar domain={domain} setDomain={setDomain} />}
          />
        );
      }
    };
    return (
      <>
        {getNavBar()}
        <Switch>
          <Route path="/" exact component={Home} />
          {/*<Route path="/mission" exact component={AboutUs} />*/}
          <Route path="/mission" exact component={MissionCenter} />
          <Route path="/learning_center" exact component={LearningCenter} />
          <Route path="/terms/tos" exact component={Tos} />
          <Route path="/terms/privacy" exact component={Privacy} />
          <Route path="/terms/cookies" exact component={Cookies} />
          <Route
            path="/terms/booking-protection"
            exact
            component={BookingProtection}
          />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route
            path="/search"
            exact
            render={() => <Search setDomain={setDomain} />}
          />
          <Route
            path="/inspector/:inspectorId"
            exact
            component={(props) => <IndividualPage otherProps={props} domain={domain} setDomain={setDomain} />}
          />
          <Route path="/what-to-expect-from-a-home-inspection" component={EightThinsToKnowPage} />
          <Route component={Err} />
        </Switch>
        <Route path="/" component={MainFooter} />
      </>
    );
  };

  const UserDomain = ({ match }) => {
    const [ selectedInspectors, setSelectedInspectors ] = useState([]);
    if (!!document.documentMode) return <WindowsBlock />;
    return (
      <>
        <Route path="/" render={() => <UserNavbar selectedInspectors={selectedInspectors} setSelectedInspectors={setSelectedInspectors} />} />
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/reset" exact component={ResetPassword} />
          <Route
            path="/dashboard"
            exact
            render={() => <Dashboard setDomain={setDomain} domain={domain} selectedInspectors={selectedInspectors} />}
          />
          <Route
            path="/dashboard/inspections"
            exact
            component={InspectionsDashboard}
          />
          <Route
            path="/dashboard/review-job/:jobId/:inspectorId"
            exact
            component={Review}
          />
          <Route path="/account" exact component={UserAccount} />
          <Route path="/signup" exact component={SignUp} />
          <Route path="/housereport/:report" exact component={HouseReport} />
          <Route path="/checkout/:jobid" exact component={Checkout} />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route
            path="/schedule"
            exact
            render={() => <Schedule setDomain={setDomain} selectedInspectors={selectedInspectors} setSelectedInspectors={setSelectedInspectors} />}
          />
          <Route component={Err} />
        </Switch>
        <Route path="/" component={MainFooter} />
      </>
    );
  };

  const InspectorDomain = ({ match }) => {
    if (!!document.documentMode) return <WindowsBlock />;

    return (
      <>
        <Route
          path="/"
          render={() => (
            <InspectorNavbar domain={domain} setDomain={setDomain} />
          )}
        />
        <Switch>
          <Route path="/" exact component={InspectorHome} />
          <Route path="/mission" exact component={AboutUs} />
          <Route path="/reset" exact component={ResetPassword} />
          <Route
            path="/job-requests"
            exact
            component={JobRequests}
          />
          <Route
            path="/active-jobs"
            exact
            component={ActiveJobs}
          />
          <Route path="/signup" exact component={InspectorSignUp} />
          <Route path="/login" exact component={SignIn} />
          <Route
            path="/account"
            exact
            component={InspectorAccount}
          />
          <Route
            path="/history"
            exact
            render={(props) => <JobHistory {...props} />}
          />
          <Route path="/report/:report" exact component={InspectorReport} />
          <Route path="/stripe" exact component={StripeInspectorConnect} />
          <Route path="/inspectorFaq" exact component={InspectorFaq} />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route path="/profilePicture" exact component={ProfilePictureFAQ} />
          <Route path="/calculator" exact component={FeeCalculator} />
          <Route path="/new-york-inspector-schools" exact component={NewYorkInspectorSchoolPage} />
          <Route path="/new-jersey-inspector-schools" exact component={NewJerseyInspectorSchoolPage} />
          <Route component={Err} />
        </Switch>
        <Route path="/" component={MainFooter} />
      </>
    );
  };

  const AdminDomain = ({ match }) => {
    if (!!document.documentMode) return <WindowsBlock />;
    return (
      <>
        <Route
          path="/"
          render={() => <AdminNavbar domain={domain} setDomain={setDomain} />}
        />
        <Switch>
          <Route path="/" exact component={AdminPromotions} />
          <Route path="/prereg" exact component={AdminPreReg} />
          <Route path="/promotions" exact component={AdminPromotions} />
          <Route
            path="/inspector_records"
            exact
            component={AdminInspectorRecords}
          />
          <Route path="/pricing" exact component={AdminHouskyPricing} />
          <Route path="/user_records" exact component={AdminUserRecords} />
          <Route path="/login" exact component={AdminSignIn} />
          <Route path="/inspector_list" exact component={InspectorList} />
          <Route
            path="/inspector/:inspectorId"
            exact
            component={InspectorInfo}
          />
          <Route path="/open_jobs" exact component={OpenJobs} />
          <Route path="/pendingJobs" exact component={PendingJobs} />
          <Route path="/tools" exact component={Tools} />
          <Route component={Err} />
        </Switch>
        <Route path="/" component={MainFooter} />
      </>
    );
  };

  const SubDomains = () => {
    if (process.env.REACT_APP_ENV === "PROD") {
      if (window.location.href.split(".")[0].split("/")[2] === "user")
        return <Route path="/" component={UserDomain} />;
      if (window.location.href.split(".")[0].split("/")[2] === "inspector")
        return <Route path="/" component={InspectorDomain} />;
      if (window.location.href.split(".")[0].split("/")[2] === "admin")
        return <Route path="/" component={AdminDomain} />;
      else return <Route path="/" component={MainDomain} />;
    } else {
      if (domain === "user") return <Route path="/" component={UserDomain} />;
      if (domain === "inspector")
        return <Route path="/" component={InspectorDomain} />;
      if (domain === "admin") return <Route path="/" component={AdminDomain} />;
      return <Route path="/" component={MainDomain} />;
    }
  };

  return (
    <BrowserRouter>
      <AuthContext.Provider value={{ user, authStatusCheck }}>
        <Elements stripe={stripePromise}>
          <SubDomains />
        </Elements>
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
