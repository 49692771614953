import React, {useEffect} from "react";
import "./priority.css";
import {getLocationInfo} from "../../../services/service";

export default function(props) {

  useEffect(() => {
    (
      async () => {
        const houseLocation = await getLocationInfo(props.houseZipcode);
        const estimatedPrice = props.inspectorList.reduce((acc, inspector) => {
          if(inspector.calculatorParams && houseLocation) {
            const price = props.getEstimatedPrice(inspector, houseLocation);
            acc[inspector.id] = {
              min: price,
              max: price + inspector.calculatorParams.specialBuffer
            }
          }
          return acc;
        }, {})

        props.setEstimatedPrice(estimatedPrice);
      }
    )();
  }, []);

  const getInspectorPhotoSrc = (inspector) => {
    if(inspector.imageUrl){
      return inspector.imageUrl
    } else {
      return require('../../../assets/blank-profile-picture.png');
    }
  }

  const capitalizeFirst = (name) => {
    return name.charAt(0) + name.slice(1).toLowerCase()
  }

  const getEstimatedPriceSection = (inspector) => {
    if(inspector.calculatorParams) {
      const estimatedPrice = props.estimatedPrice[inspector.id];
      if(estimatedPrice) {
        return (
          <span className="new badge" style={{backgroundColor: 'white', color: '#66cccc'}} data-badge-caption="">{`$${estimatedPrice.min} - $${estimatedPrice.max}`}</span>
        )
      }
    }
  }

  return (
    <div className="row mb-0">
      <div className='col s12 p-0'>
        <div className="row m-0">
          <div className='m-3'>
            <div className='container'>
              <div className="row" style={{backgroundColor: "#66cccc", padding: "10px", borderRadius: "5px"}}>
                This is the list of inspector(s) you selected to inquire about this job, you will be able to communicate with them further to decide who you want to hire.
              </div>
              <div className="row pt-5">
                {props.inspectorList.map((inspector, index) => (
                  <div style={{display: 'flex', marginBottom: '20px'}} key={index}>
                    <img className="priority-image" src={getInspectorPhotoSrc(inspector)} alt={`${inspector.firstName} ${inspector.lastName}`}/>
                    <div className="priority-body row">
                      <div className="col m6 s12">
                        <div>
                          <span>{capitalizeFirst(inspector.firstName)} </span>
                          <span>{capitalizeFirst(inspector.lastName)}</span>
                        </div>
                        <div>
                          <div style={{ fontSize: '14px' }}>{inspector.companyName}</div>
                        </div>
                      </div>
                      <div className="col m6 s12">
                        { getEstimatedPriceSection(inspector) }
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='row hide-on-small-only'>
                <button
                  type="submit"
                  className="btn my-1 offset-m2 housky-primary-bg py-1"
                  style={{ height: "unset", width: "100%" }}
                  onClick={props.handleNext}>
                  Next - Select Dates
                </button>
              </div>
            </div>
            <div className='mt-2 hide-on-med-and-up' style={{ marginBottom: '90px' }}>
              <button
                type="submit"
                className="btn my-1 offset-m2 housky-primary-md py-1"
                style={{ height: "unset", width: "100%" }}
                onClick={props.handleNext}>
                Next - Select Dates
              </button>
            </div>
            <div className="hide-on-small-only" style={{minHeight:'43vh'}}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
