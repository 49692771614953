import React from "react"
import {
  app as userFirebase
} from "../../../firebase";
import firebase from "firebase/app";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import UserInfo from "./UserInfo"
import ReAuthModal from "../../InspectorDomain/Account/ReAuthModal"
import RePasswordModal from "../../InspectorDomain/Account/RePasswordModal";
import { getUser, updateUser } from "../../../services/service"
import { validatePassword, isComplexPassword } from '../../../services/helper'
import { removeModal } from '../../../services/helper'
import {Redirect} from 'react-router-dom'

export default class UserAccount extends React.Component {
  state = {
    userid: '',
    email: "",
    first_name: "",
    last_name: "",
    area_code: "",
    cell_number: "",
    temp_cell_number: "",
    oriEmail: "",
    reAuthEmail: "",
    reAuthPassword: "",
    password: "",
    confirmPassword: "",
    defaultUser: "",
    isDisabled: true,
    hidden: true,
    redirect: false
  }
  componentDidMount = () => {
    let temp_cell_number = "";
    this.getToken()
      .then(res => {
        getUser(res)
          .then((user) => {
            if (user.cell_number)
              temp_cell_number = user.area_code.toString() + user.cell_number.toString();
            this.setState({ ...user, oriEmail: user.email, temp_cell_number, defaultUser: user, userToken: res })
          })
      })
      .then(() => {
        let elems = document.querySelectorAll(".modal");
        Materialize.Modal.init(elems, { opacity: "0.5" });
      })
  }

  componentWillUnmount = () => {
    // removeModal('#isEditModal')
    if (document.getElementById('reAuthModal'))
      removeModal('#reAuthModal')
    if (document.getElementById('rePasswordModal'))
      removeModal('#rePasswordModal')
  }

  getToken = async () => {
    let currentUser = await userFirebase.auth().currentUser;
    if(currentUser) {
      return currentUser.getIdToken(true)
    } else {
      return Promise.reject();
    }
  };

  handleEdit = e => {
    e.preventDefault();
    this.setState({
      isDisabled: !this.state.isDisabled,
      ...this.state.defaultUser,
    });
  };

  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    }, () => {
      validatePassword(this.state.password)
    });
  };

  handleNumRestrictedLength = (e, stateName, length) => {
    if (
      e.target.value < Infinity &&
      e.target.value > -Infinity &&
      e.target.value.length < length
    ) {
      this.setState({ [stateName]: e.target.value });
    }
  };

  handleReAuth = (e) => {
    let { userid, first_name, last_name, temp_cell_number, email, oriEmail, userToken,
      reAuthEmail, reAuthPassword, confirmPassword, password } = this.state
    e.preventDefault();
    const area_code = temp_cell_number.slice(0, 3);
    const cell_number = temp_cell_number.slice(3);
    let body = { userid, first_name, last_name, email, area_code, cell_number, token: userToken }
    if (
      !first_name ||
      !last_name ||
      !email ||
      !temp_cell_number
    )
      Materialize.toast({
        html: "Required fields cannot be left blank.",
        classes: "rounded"
      });
    else if (temp_cell_number.length !== 10) {
      Materialize.toast({
        html: "Please enter a valid phone number.",
        classes: "rounded"
      });
    } else if (password.length < 8) {
      Materialize.toast({
        html: "Password must be longer than 8 characters",
        classes: "rounded"
      });
    }
    else if (!isComplexPassword(password)) {
      Materialize.toast({
        html: "Password is too simple",
        classes: "rounded"
      });
    }
    else {
      let user = userFirebase.auth().currentUser;
      let cred = firebase.auth.EmailAuthProvider.credential(
        reAuthEmail,
        reAuthPassword
      );
      user
        .reauthenticateWithCredential(cred)
        .then(function () {
          if (oriEmail.toUpperCase() !== email.toUpperCase()) {
            user
              .updateEmail(email.toUpperCase())
              .then(function () {
                updateUser(body);
                Materialize.toast({
                  html: "User Information has been updated.",
                  classes: "rounded"
                });
              })
              .catch(function (error) {
                Materialize.toast({ html: error, classes: "rounded" });
              });
          } else if (password === confirmPassword) {
            user
              .updatePassword(password)
              .then(function () {
                Materialize.toast({
                  html: "Password has been updated.",
                  classes: "rounded"
                });
              })
              .catch(function (error) {
                Materialize.toast({ html: error, classes: "rounded" });
              });
          }
        })
        .catch(function (error) {
          Materialize.toast({ html: error, classes: "rounded" });
        });
      const newBody = JSON.parse(JSON.stringify(body));
      this.setState({
        area_code,
        cell_number,
        isDisabled: true,
        ...newBody,
        defaultUser: newBody
      });
    }
  };

  updateUser = (e) => {
    let { userid, first_name, last_name, temp_cell_number, email, oriEmail, userToken } = this.state
    e.preventDefault();
    const area_code = temp_cell_number.slice(0, 3);
    const cell_number = temp_cell_number.slice(3);
    if (
      !first_name ||
      !last_name ||
      !email ||
      !temp_cell_number
    )
      Materialize.toast({
        html: "Required fields cannot be left blank.",
        classes: "rounded"
      });
    else if (temp_cell_number.length !== 10) {
      Materialize.toast({
        html: "Please enter a valid phone number.",
        classes: "rounded"
      });
    }
    else if (oriEmail === email) {
      let body = { userid, first_name, last_name, email, area_code, cell_number, token: userToken }
      updateUser(body)
        .then(() => {
          let user = firebase.auth().currentUser;
          user.updateProfile({
            displayName: `${first_name} ${last_name}`
          }).then(() => {
            Materialize.toast({
              html: "User Information has been updated.",
              classes: "rounded"
            });
            const newBody = JSON.parse(JSON.stringify(body));
            this.setState({
              area_code,
              cell_number,
              isDisabled: true,
              ...newBody,
              defaultUser: newBody
            });
          })
        })
    }
  }

  handleChangeAuthEmail = e => {
    this.setState({
      reAuthEmail: e.target.value
    });
  };

  handleChangeAuthPass = e => {
    this.setState({
      reAuthPassword: e.target.value
    });
  };

  handleTutorial = e => {
    e.preventDefault()
    localStorage.setItem("dashboard-tutorial", JSON.stringify({ completed: false, date: null }))
    localStorage.setItem("schedule-tutorial", JSON.stringify({ completed: false, date: null }))
    localStorage.setItem("priority-tutorial", JSON.stringify({ completed: false, date: null }))
    window.scrollTo(0,0)
    this.setState({ redirect: true })
  }


  render() {
    return (
      <div className="container">
        {this.state.redirect ? <Redirect push to={`dashboard`} /> : <></>}
        <div className="row mt-5 pt-5" style={{ minHeight: '75vh' }}>
          <ReAuthModal
            reAuthEmail={this.state.reAuthEmail}
            reAuthPassword={this.state.reAuthPassword}
            handleChange={this.handleChange}
            handleReAuth={this.handleReAuth}
          />
          <RePasswordModal
            hidden={this.state.hidden}
            toggleShow={this.toggleShow}
            reAuthEmail={this.state.reAuthEmail}
            reAuthPassword={this.state.reAuthPassword}
            password={this.state.password}
            confirmPassword={this.state.confirmPassword}
            handleChange={this.handleChange}
            handleReAuth={this.handleReAuth}
            handleChangeAuthEmail={this.handleChangeAuthEmail}
            handleChangeAuthPass={this.handleChangeAuthPass}
          />
          <div className="col s12">
            <UserInfo
              oriEmail={this.state.oriEmail}
              first_name={this.state.first_name}
              last_name={this.state.last_name}
              email={this.state.email}
              temp_cell_number={this.state.temp_cell_number}
              area_code={this.state.area_code}
              cell_number={this.state.cell_number}
              handleChange={this.handleChange}
              handleCell={e => {
                this.handleNumRestrictedLength(e, "temp_cell_number", 11);
              }}
              handleEdit={this.handleEdit}
              updateUser={this.updateUser}
              isDisabled={this.state.isDisabled} />
          </div>
          <div className="col s6 offset-s3" style={{ marginBottom: "80px", borderTop: '1px solid black', paddingTop: '1.5rem' }}>
            <button
              onClick={this.handleTutorial}
              style={{ height: 'auto', backgroundColor: '#104b69', color: 'white', width: '100%' }} type='submit' className="btn">
              Restart Tutorial
                </button>
          </div>
        </div>
      </div>
    );
  }
}
