import React from "react";
import PrivateComponent from "../../../components/PrivateComponent";
import UserRecordsComponent from "../../../components/AdminDomain/UserRecords/UserRecords";

class UserRecords extends React.Component {
  render() {
    return (
      <PrivateComponent>
        <UserRecordsComponent />
      </PrivateComponent>
    );
  }
}

export default UserRecords;
