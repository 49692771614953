import React, { useEffect, useState } from "react";
import toUpper from "lodash/toUpper";
import isEmpty from "lodash/isEmpty";
import first from "lodash/first";
import get from "lodash/get";
import Pending from "./Stages/Pending";
import Assigned from "./Stages/Assigned";
import Completed from "./Stages/Completed";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import {
  getReportPdf,
  releaseEscrow,
  getReviewByJobId,
} from "../../../services/service";
import { app as userFirebase } from "../../../firebase";
import {Link, useHistory} from "react-router-dom";

export const InspectionStage = (props) => {
  const { tabName, jobs, loading } = props;
  const [jobsFiltered, setJobsFiltered] = useState([]);
  const [currentJob, setCurrentJob] = useState({});
  const [defaultOpen, setDefaultOpen] = useState(true);
  const [showReview, setShowReview] = useState(false);
  const [releasingEscrow, setReleasingEscrow] = useState(false);
  const [jobId, setJobId] = useState('');

  const history = useHistory();

  useEffect(() => {
    setJobsFiltered([]);
    setCurrentJob({});
  }, [tabName]);

  useEffect(() => {
    if (!isEmpty(jobs)) {
      let filtered = [...jobs];
      filtered = filtered.filter(
        (job) => toUpper(job.current_status) === toUpper(tabName)
      );
      setJobsFiltered(filtered);
      !isEmpty(filtered) && setCurrentJob(first(filtered));
    }
  }, [tabName, jobs]);

  const getToken = () => {
    return userFirebase.auth().currentUser.getIdToken(true);
  };

  const stagesView = {
    Pending: <Pending job={currentJob} />,
    Accepted: <Assigned job={currentJob} />,
    Completed: <Completed job={currentJob}/>
  };

  // const getActionForReview = () => {
  //   getReviewByJobId(currentJob.jobid)
  //     .then((data) => {
  //       isEmpty(get(data, "data")) ? setShowReview(true) : setShowReview(false);
  //     })
  //     .catch((error) => {
  //       console.error("error", error);
  //     });
  // };

  const handleTab = (job) => {
    setDefaultOpen(false);
    currentJob === job ? setCurrentJob({}) : setCurrentJob(job);
  };

  const handleReleaseEscrowOnClick = (event, job) => {
    event.preventDefault();
    setJobId(job.jobid);
    setReleasingEscrow(true);

    getToken()
      .then((idToken) => {
        return releaseEscrow(job.jobid, idToken);
      })
      .then((result) => {
        const data = result.data;
        console.log(data);
        if (!!data.released_escrow) {
          Materialize.toast({
            html: `Job Completed!`,
            classes: "rounded",
          });

          setTimeout(() => {
            history.go(0);
          }, 3000);
        } else {
          Materialize.toast({
            html: `${!!data.err_message ? data.err_message : 'There was an error releasing the escrow. Try it again later.'}`,
            classes: "rounded",
          });
        }
      })
      .catch((error) => {
        console.error("Release Escrow error", error);
      })
      .finally(() => {
        setReleasingEscrow(false);
      });
  };

  const renderReleaseEscrowButton = (job) => {
    if (job.current_status === 'ACCEPTED' && !!job.reportid) {
      if (jobId === job.jobid) {
        return (
          <div className="absoluteBox">
            <span className="boldText mr15">Action:</span>
            {
              releasingEscrow ?
                <span className="boldText mr15">Releasing escrow...</span> :
                <button
                  className={`btn waves-effect green capitalize ${get(job, "report_status") !== "COMPLETED" && "disabled"}`}
                  onClick={(event) => handleReleaseEscrowOnClick(event, job)}
                >
                  Release Escrow
                </button>
            }
          </div>
        );
      } else {
        return (
          <div className="absoluteBox">
            <span className="boldText mr15">Action:</span>
            <button
              className={`btn waves-effect green capitalize ${get(job, "report_status") !== "COMPLETED" && "disabled"}`}
              onClick={(event) => handleReleaseEscrowOnClick(event, job)}
            >
              Release Escrow
            </button>
          </div>
        );
      }
    }
    return <></>;
  }

  const renderReviewButton = (job) => {
    const hasReview = !job['review_created_at'];
    if (job.current_status === 'COMPLETED' && hasReview) {
      return (
        <div className="absoluteBox">
          <span className="boldText mr15">Action:</span>
          <Link
            to={`/dashboard/review-job/${job.jobid}/${job.inspectorid}`}
            className="btn waves-effect green capitalize"
          >
            Review inspector
          </Link>
        </div>
      );
    }
    return <></>;
  }

  return (
    <div className="row">
      {loading ? (
        <div className="row section feedback">Loading...</div>
      ) : isEmpty(jobsFiltered) ? (
        <div className="row section">
          <div className="col s12 feedback">No jobs to show</div>
        </div>
      ) : (
        jobsFiltered.map((job, key) => (
          <div key={key} className="row section">
            <div className="col s12">
              <div className="relativeBox">
                <button
                  className={`jobItem ${
                    tabName === "Completed" ? "jobWithAction" : ""
                  }`}
                  onClick={() => handleTab(job)}
                >
                  <span>
                    {currentJob === job || (key === 0 && defaultOpen) ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-1 w-1 arrow"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-1 w-1 arrow"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 15l7-7 7 7"
                        />
                      </svg>
                    )}
                    {get(job, "title")}, {get(job, "city")}, {get(job, "state")}
                    , {get(job, "zipcode")}
                  </span>
                </button>
                {renderReleaseEscrowButton(job)}
                {renderReviewButton(job)}
              </div>
            </div>

            {currentJob === job && stagesView[tabName]}
          </div>
        ))
      )}
    </div>
  );
};
