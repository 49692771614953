import React, {useEffect, useState} from 'react';
import cssStyles from './InspectorFeeCalculator.module.css';
import PriceRangeDisplay from "../../GeneralComponents/PriceRangeDisplay/PriceRangeDisplay";
import {Inspector as InspectorFirebase} from "../../../utils/firebase/firebase";
import {
    updateInspectorCalculatorParams,
    getInspectorCalculatorParams,
    updateShowPriceRange
} from '../../../services/service'

const CONDO_BASE_PRICE = 250
const CONDO_BASE_SIZE = 1000
const SINGLE_FAMILY_BASE_PRICE = 300
const SINGLE_FAMILY_BASE_SIZE = 1500
const MULTI_FAMILY_BASE_PRICE = 400
const MULTI_FAMILY_BASE_SIZE = 2000

const AGE_BASE = 0
const SIZE_BASE = 50
const MILEAGE_BASE = 0
const WDI_BASE = 50
const RADON_BASE = 80
const DEFAULT_SPECIAL = 100

const useInput = (initValue) => { /// Customized Hooks
    const [ value, setValue ] = useState(initValue);
    const onValueChangeHandler = (e) => {
        setValue(e.target.value);
    };
    return [ value, onValueChangeHandler ];
};

const useNumberInput = (initValue) => {
    const [ value, setValue ] = useState(initValue);
    const onValueChangeHandler = (e) => {
        if(e.target.value.trim() === ''){setValue('0')}
        else if(!isNaN(e.target.value)){
            let rawValue = e.target.value
            let i = 0
            while (i < rawValue.length-1){
                if(rawValue[i] === '0' && rawValue[i+1] !== '.'){ i++ }
                else break
            }
            rawValue = rawValue.slice(i)
            setValue(parseFloat(rawValue));
        }
    };
    return [ value, onValueChangeHandler, setValue ];
};

const InspectorFeeCalculator = () => {
    // price range states
    const [ priceRangeMin, setPriceRangeMin ] = useState(CONDO_BASE_PRICE)
    const [ priceRangeMax, setPriceRangeMax ] = useState(
        MULTI_FAMILY_BASE_PRICE
        + Math.ceil((100 - 5)/5) * MILEAGE_BASE
        + Math.ceil((4000 - 2000)/500) * SIZE_BASE
        + Math.ceil((100-5)/5) * AGE_BASE
        + DEFAULT_SPECIAL
    )

    // states & variables for estimate panel
    const [ propertyType, onPropertyTypeChangeHandler ] = useInput('');
    const [ distance, setDistance ] = useState('')
    const [ propertySize, setPropertySize ] = useState('');
    const [ propertyAge, setPropertyAge ] = useState('');
    const [ WDI, onWDIChangeHandler ] = useInput('');
    const [ radon, onRadonChangeHandler ] = useInput('');
    const [ priceEstimate, setPriceEstimate ] = useState(0)
    const [ isFieldTouched, setIsFieldTouched ] = useState({
        distance: false,
        propertyType: false,
        propertySize: false,
        propertyAge: false,
    })
    const isPropertyTypeInvalid = isFieldTouched.propertyType && !propertyType
    const patternOne = /^[0]$|^[1-9]$|^[1-9][0-9]$|^1[0-9]{2}$|^200$/
    const patternTwo = /^[3-9][0-9]{2}$|^[1-9][0-9]{3}$|^[1-2][0-9]{4}$|^30000$/
    const isDistanceInvalid = isFieldTouched.distance && !patternOne.test(distance)
    const isPropertySizeInvalid = isFieldTouched.propertySize && !patternTwo.test(propertySize)
    const isPropertyAgeInvalid = isFieldTouched.propertyAge && !patternOne.test(propertyAge)

    // states & variables for settings panel
    const [ condoBasePrice, onCondoBasePriceChangeHandler, setCondoBasePrice ] = useNumberInput(CONDO_BASE_PRICE)
    const [ singleFamilyBasePrice, onSingleFamilyBasePriceChangeHandler, setSingleFamilyBasePrice ] = useNumberInput(SINGLE_FAMILY_BASE_PRICE)
    const [ multiFamilyBasePrice, onMultiFamilyBasePriceChangeHandler, setMultiFamilyBasePrice ] = useNumberInput(MULTI_FAMILY_BASE_PRICE)
    const [ mileageBase, onMileageBaseChangeHandler, setMileageBase ] = useNumberInput(MILEAGE_BASE)
    const [ ageBase, onAgeBaseChangeHandler, setAgeBase ] = useNumberInput(AGE_BASE)
    const [ sizeBase, onSizeBaseChangeHandler, setSizeBase ] = useNumberInput(SIZE_BASE)
    const [ specialBuffer, onSpecialBufferChangeHandler, setSpecialBuffer ] = useNumberInput(DEFAULT_SPECIAL)
    const [ WDIBase, onWDIBaseChangeHandler, setWDIBase ] = useNumberInput(WDI_BASE)
    const [ radonBase, onRadonBaseChangeHandler, setRadonBase ] = useNumberInput(RADON_BASE)
    const [ isEditMode, setIsEditMode ] = useState(false)
    const [ shouldShowPriceRange, setShouldShowPriceRange ] = useState(false)

    // other states
    const [ isToolTipVisible, setIsToolTipVisible ] = useState(
        {
            priceRange: false,
            estimate: false,
            settings: false,
            basePrice: false,
            mileage: false,
            age: false,
            special: false
        }
    )

    useEffect(() => {
        (
          async () => {
              try {
                  const token = await InspectorFirebase.getIdToken();
                  const res = await getInspectorCalculatorParams(token);
                  const params = res.calculator_params;
                  if(params) {
                      setCondoBasePrice(params.basePrice?.condoBasePrice);
                      setSingleFamilyBasePrice(params.basePrice?.singleFamilyBasePrice);
                      setMultiFamilyBasePrice(params.basePrice?.multiFamilyBasePrice);
                      setSpecialBuffer(params.specialBuffer);
                      setSizeBase(params.sizeBase);
                      setMileageBase(params.mileageBase);
                      setRadonBase(params.radonBase);
                      setAgeBase(params.ageBase);
                      setWDIBase(params.WDIBase)
                  }
                  setShouldShowPriceRange(res.show_price_range);
              } catch (e) {
                  console.log("error fetching the calculator params", e);
              }
          }
        )();
    }, []);

    const onDistanceChangeHandler = (e) => {
        const newIsFieldTouched =  Object.assign({...isFieldTouched}, {distance: true})
        setIsFieldTouched(newIsFieldTouched)
        setDistance(e.target.value)
        }

    const onPropertySizeChangeHandler = (e) => {
        const newIsFieldTouched =  Object.assign({...isFieldTouched}, {propertySize: true})
        setIsFieldTouched(newIsFieldTouched)
        setPropertySize(e.target.value)
    }

    const onPropertyAgeChangeHandler = (e) => {
        const newIsFieldTouched =  Object.assign({...isFieldTouched}, {propertyAge: true})
        setIsFieldTouched(newIsFieldTouched)
        setPropertyAge(e.target.value)
    }

    const toggleToolTipVisibility = (name) => {
        return () => {
            const newIsToolTipVisible = Object.assign({...isToolTipVisible}, {[name]: !isToolTipVisible[name]})
            setIsToolTipVisible(newIsToolTipVisible)
            // console.log(newIsToolTipVisible.basePrice)
        }
    }

    const showToolTip = (name, toolTipClassName, splitable=false) => {
        const toolTipMessages = {
            priceRange: 'Base Price + MileagePrice x Miles + AgePrice x Age + SizePrice x Size + Special.' +
                '|Min Price is a brand new house within 5 miles less than 1000 sqft.' +
                '|Max Price is based on a 100 year old house and 100 miles away with 4000 sqft + buffer.' +
                '|If for all house type, min is based on a condo, max is based on a multi family.',
            estimate: 'This will be the base price for a specific house.|You can set the final price by adding an amount less than the special price in Settings.',
            settings: 'Adjust the settings to reflect your pricing.|Use the estimator and price range on the left to test your settings.',
            basePrice: 'Base size for Condo is 1000 sqft.|Base size for single family is 1500 sqft.|Base size for multi family is 2000 sqft.',
            mileage: '0-5 mile is free, this number is additional 5 mile increment.',
            age: '0-5 year new is free, this number is additional 5 year increment.',
            special: 'This is a buffer to allow you to add additional price given special situation different houses. This will be the max amount you can add.'
        }

        if(splitable) {
            return (
                <div className={ cssStyles[toolTipClassName]}>
                    { toolTipMessages[name].split('|').map(line => {
                        return <span key={line} style={{display: 'block'}}>{line}</span>
                    }) }
                </div>
            )
        } else {
            return (
                <div className={ cssStyles[toolTipClassName]}>
                    { toolTipMessages[name] }
                </div>
            )
        }
    }

    const showPriceRange = ()=> {
        return(
            <div className={cssStyles.priceRangeContainer}>
                <span className={cssStyles.title1}>
                    Price Range
                    <i className="tiny material-icons"
                       onMouseOver={toggleToolTipVisibility('priceRange')}
                       onMouseLeave={toggleToolTipVisibility('priceRange')}>help
                    </i>
                </span>
                <span>
                    { isToolTipVisible.priceRange && showToolTip('priceRange', 'priceRangeToolTipStyle', true) }
                </span>
                <div>
                    <PriceRangeDisplay min={priceRangeMin} max={priceRangeMax} />
                </div>
            </div>
        )
    }

    const calculateEstimate = () => {

        const prices = { condo: condoBasePrice, singleFamily: singleFamilyBasePrice, multiFamily: multiFamilyBasePrice }
        const baseSizes = { condo: CONDO_BASE_SIZE, singleFamily: SINGLE_FAMILY_BASE_SIZE, multiFamily: MULTI_FAMILY_BASE_SIZE}
        const basePriceResult = prices[propertyType]
        const ageResult = propertyAge <= 5 ? 0 : (Math.ceil(parseInt(propertyAge - 5)/5)) * ageBase
        const sizeResult = propertySize <= baseSizes[propertyType] ? 0 : Math.ceil((parseInt(propertySize) - baseSizes[propertyType])/500) * sizeBase
        const mileageResult = distance <= 5 ? 0: Math.ceil((distance - 5)/5) * mileageBase
        let finalResult = basePriceResult + ageResult + sizeResult + mileageResult + specialBuffer
        if(WDI){ finalResult += WDIBase}
        if(radon){ finalResult += radonBase}
        setPriceEstimate(finalResult)
        // console.log(mileageResult, sizeResult, ageResult)
    }

    const onViewEstimateClicked = () => {
        setIsFieldTouched({
            distance: true,
            propertyType: true,
            propertySize: true,
            propertyAge: true,
        })

        // check required fields
        if(!propertyType){return}
        if(!distance){return}
        if(!propertySize){return}
        if(!propertyAge){return}

        // Calculate
        calculateEstimate()
    };

    const showEstimatePanel = () => {
        return(
            <div>
                <div className={cssStyles.title2Container}>
                    <span>Estimate Rate
                        <i className="tiny material-icons"
                           onMouseOver={toggleToolTipVisibility('estimate')}
                           onMouseLeave={toggleToolTipVisibility('estimate')}>help
                        </i>
                    </span>
                    <span>
                        { isToolTipVisible.estimate && showToolTip('estimate', 'estimateToolTipStyle', true) }
                    </span>
                    <span>$ {priceEstimate.toFixed(2)}</span>
                </div>
                <div className={cssStyles.estimateBodyContainer}>
                    <div className={cssStyles.estimateRowContainer}>
                        <div className={cssStyles.estimateRow}>
                            <div className={cssStyles.estimateCol_lt}>
                                <span>What is the type of this property? (Condo, Single Family, Multi Family)</span>
                            </div>
                            <div className={cssStyles.estimateCol_rt}>
                                <select className={isPropertyTypeInvalid ? `browser-default ${cssStyles.estimateSelectInvalid}` : `browser-default ${cssStyles.estimateSelect}` }
                                        onChange={onPropertyTypeChangeHandler}
                                        value={propertyType}>
                                    <option value="">Choose Value</option>
                                    <option value="condo">Condo</option>
                                    <option value="singleFamily">Single Family</option>
                                    <option value="multiFamily">Multi Family</option>
                                </select>
                            </div>
                        </div>
                        <div className={cssStyles.estimateErrMsgContainer}>
                            {isPropertyTypeInvalid && <span className={cssStyles.estimateErrMsg}>Property type can't be empty.</span>}
                        </div>
                    </div>
                    <div className={cssStyles.estimateRowContainer}>
                        <div className={cssStyles.estimateRow}>
                            <div className={cssStyles.estimateCol_lt}>
                                <span>How many miles will you have to drive (one-way) to get to this property?</span>
                            </div>
                            <div className={cssStyles.estimateCol_rt}>
                                <input type="text"
                                       className={isDistanceInvalid ? `browser-default ${cssStyles.estimateInputInvalid}` : `browser-default ${cssStyles.estimateInput}`} value={distance}
                                       onChange={onDistanceChangeHandler}/>
                                <div className={cssStyles.estimateUnit}>miles</div>
                            </div>
                        </div>
                        <div className={cssStyles.estimateErrMsgContainer}>
                            {isDistanceInvalid && <span className={cssStyles.estimateErrMsg}>Distance can't be empty and should be whole number from 0 - 200.</span>}
                        </div>
                    </div>
                    <div className={cssStyles.estimateRowContainer}>
                        <div className={cssStyles.estimateRow}>
                            <div className={cssStyles.estimateCol_lt}>
                                <span>How large is this property?</span>
                            </div>
                            <div className={cssStyles.estimateCol_rt}>
                                <input type="text"
                                       className={isPropertySizeInvalid ? `browser-default ${cssStyles.estimateInputInvalid}` : `browser-default ${cssStyles.estimateInput}`}
                                       value={propertySize}
                                       onChange={onPropertySizeChangeHandler}/>
                                <div className={cssStyles.estimateUnit}>ft</div>
                            </div>
                        </div>
                        <div className={cssStyles.estimateErrMsgContainer}>
                            {isPropertySizeInvalid && <span className={cssStyles.estimateErrMsg}>Property size can't be empty and should be whole number from 300 - 30000.</span>}
                        </div>
                    </div>
                    <div className={cssStyles.estimateRowContainer}>
                        <div className={cssStyles.estimateRow}>
                            <div className={cssStyles.estimateCol_lt}>
                                <span>How old is this property?</span>
                            </div>
                            <div className={cssStyles.estimateCol_rt}>
                                <input type="text"
                                       className={isPropertyAgeInvalid ?  `browser-default ${cssStyles.estimateInputInvalid}` : `browser-default ${cssStyles.estimateInput}`}
                                       value={propertyAge}
                                       onChange={onPropertyAgeChangeHandler}/>
                                <div className={cssStyles.estimateUnit}>years</div>
                            </div>
                        </div>
                        <div className={cssStyles.estimateErrMsgContainer}>
                            {isPropertyAgeInvalid && <span className={cssStyles.estimateErrMsg}>Property age can't be empty and should be whole number from 0 - 200.</span>}
                        </div>
                    </div>
                    <div className={cssStyles.estimateRowContainer}>
                        <div className={cssStyles.estimateRow}>
                            <div className={cssStyles.estimateCol_lt}>
                                <span>WDI</span>
                            </div>
                            <div className={cssStyles.estimateCol_rt}>
                                <select className={`browser-default ${cssStyles.estimateSelect}`} value={WDI} onChange={onWDIChangeHandler}>
                                    <option value="">Choose Value</option>
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className={cssStyles.estimateRowContainer}>
                        <div className={cssStyles.estimateRow}>
                            <div className={cssStyles.estimateCol_lt}>
                                <span>Radon</span>
                            </div>
                            <div className={cssStyles.estimateCol_rt}>
                                <select className={`browser-default ${cssStyles.estimateSelect}`} value={radon} onChange={onRadonChangeHandler}>
                                    <option value="">Choose Value</option>
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className={cssStyles.estimateButtonContainer}>
                        <button onClick={onViewEstimateClicked}>VIEW ESTIMATE</button>
                    </div>
                </div>
            </div>
        )
    }

    const onSaveSettingsClicked = async () => {
        const minPrice = condoBasePrice
        const maxPrice = multiFamilyBasePrice + Math.ceil((100 - 5)/5) * mileageBase + Math.ceil((4000 - 2000)/500) * sizeBase + Math.ceil((100-5)/5) * ageBase + specialBuffer
        if(isEditMode) {
            const token = await InspectorFirebase.getIdToken();
            const calculatorParams = JSON.stringify({
                basePrice: {
                    singleFamilyBasePrice,
                    multiFamilyBasePrice,
                    condoBasePrice
                },
                mileageBase,
                ageBase,
                sizeBase,
                specialBuffer,
                WDIBase,
                radonBase,
                priceRangeMax,
                priceRangeMin
            });

            const body = {
                calculatorParams
            };

            await updateInspectorCalculatorParams(token, body)
        }
        setIsEditMode(
          (prevState => {return !prevState})
        )
        setPriceRangeMin(minPrice)
        setPriceRangeMax(maxPrice)
    }

    const getSettingsButtonText = () => {
        return isEditMode ? 'SAVE SETTINGS' : 'CHANGE SETTINGS'
    }

    const showSettingsPanel = () => {
        if(isEditMode) {
            return(
                <div className={cssStyles.settingsContainer}>
                    <div className={cssStyles.title3Container}>
                        <span>Settings <i className="tiny material-icons"
                                          onMouseOver={toggleToolTipVisibility('settings')}
                                          onMouseLeave={toggleToolTipVisibility('settings')}>help</i>
                        </span>
                        <span>
                            { isToolTipVisible.settings && showToolTip('settings', 'settingsToolTipStyle', true) }
                        </span>
                    </div>
                    <div className={cssStyles.settingInputsContainer}>
                        <div className={cssStyles.settingsRow}>
                            <div className={cssStyles.settingsCol_lt} style={{paddingTop: '20px', display: 'flex', flexDirection:'column'}}>
                                <span>Base <i className="tiny material-icons"
                                              onMouseOver={toggleToolTipVisibility('basePrice')}
                                              onMouseLeave={toggleToolTipVisibility('basePrice')}>help</i>
                                </span>
                                <span>Price</span>
                                <span>
                                    { isToolTipVisible.basePrice && showToolTip('basePrice', 'basePriceToolTipStyle', true) }
                                </span>
                            </div>
                            <div className={cssStyles.settingsCol_rt} style={{paddingTop: '20px'}}>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <input type="text" className="browser-default" value={condoBasePrice} onChange={onCondoBasePriceChangeHandler}/>
                                    <span>Condo</span>
                                </div>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <input type="text" className="browser-default" value={singleFamilyBasePrice} onChange={onSingleFamilyBasePriceChangeHandler}/>
                                    <span>Single Family</span>
                                </div>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <input type="text" className="browser-default" value={multiFamilyBasePrice} onChange={onMultiFamilyBasePriceChangeHandler}/>
                                    <span>Multi Family</span>
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.settingsRow}>
                            <div className={cssStyles.settingsCol_lt} style={{paddingTop: '25px'}}>
                                <span>Mileage <i className="tiny material-icons"
                                                 onMouseOver={toggleToolTipVisibility('mileage')}
                                                 onMouseLeave={toggleToolTipVisibility('mileage')}>help</i>
                                </span>
                                <span>
                                    { isToolTipVisible.mileage && showToolTip('mileage', 'mileageToolTipStyle') }
                                </span>
                            </div>
                            <div className={cssStyles.settingsCol_rt} style={{paddingTop: '25px'}}>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <input type="text" className="browser-default" value={mileageBase} onChange={onMileageBaseChangeHandler}/>
                                    <span>per 5 miles</span>
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.settingsRow}>
                            <div className={cssStyles.settingsCol_lt}>
                                <span>Age <i className="tiny material-icons"
                                             onMouseOver={toggleToolTipVisibility('age')}
                                             onMouseLeave={toggleToolTipVisibility('age')}>help</i>
                                </span>
                                <span>
                                    { isToolTipVisible.age && showToolTip('age', 'ageToolTipStyle') }
                                </span>
                            </div>
                            <div className={cssStyles.settingsCol_rt}>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <input type="text" className="browser-default" value={ageBase} onChange={onAgeBaseChangeHandler}/>
                                    <span>per 5 year</span>
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.settingsRow}>
                            <div className={cssStyles.settingsCol_lt}>
                                <span>Size</span>
                            </div>
                            <div className={cssStyles.settingsCol_rt}>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <input type="text" className="browser-default" value={sizeBase} onChange={onSizeBaseChangeHandler}/>
                                    <span>per extra 500 sqft</span>
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.settingsRow}>
                            <div className={cssStyles.settingsCol_lt} style={{display: 'flex', flexDirection:'column'}}>
                                <span>Special <i className="tiny material-icons" onMouseOver={toggleToolTipVisibility('special')} onMouseLeave={toggleToolTipVisibility('special')}>help</i></span>
                                <span>Case Buffer</span>
                                <span>
                                    { isToolTipVisible.special && showToolTip('special', 'specialToolTipStyle') }
                                </span>
                            </div>
                            <div className={cssStyles.settingsCol_rt}>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <input type="text" className="browser-default" value={specialBuffer} onChange={onSpecialBufferChangeHandler}/>
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.settingsRow}>
                            <div className={cssStyles.settingsCol_lt} style={{paddingTop: '25px'}}>
                                <span>WDI</span>
                            </div>
                            <div className={cssStyles.settingsCol_rt} style={{paddingTop: '25px'}}>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <input type="text" className="browser-default" value={WDIBase} onChange={onWDIBaseChangeHandler}/>
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.settingsRow}>
                            <div className={cssStyles.settingsCol_lt} style={{paddingBottom: '20px'}}>
                                <span>Radon</span>
                            </div>
                            <div className={cssStyles.settingsCol_rt} style={{paddingBottom: '20px'}}>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <input type="text" className="browser-default" value={radonBase} onChange={onRadonBaseChangeHandler}/>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={cssStyles.settingsButtonContainer}>
                        <button className="browser-default" onClick={onSaveSettingsClicked}>{getSettingsButtonText()}</button>
                    </div>
                </div>
            )
        } else {
            return(
                <div className={cssStyles.settingsContainer}>
                    <div className={cssStyles.title3Container}>
                        <span>Settings <i className="tiny material-icons"
                                          onMouseOver={toggleToolTipVisibility('settings')}
                                          onMouseLeave={toggleToolTipVisibility('settings')}>help</i>
                        </span>
                        <span>
                            { isToolTipVisible.settings && showToolTip('settings', 'settingsToolTipStyle', true) }
                        </span>
                    </div>
                    <div className={cssStyles.settingInputsContainer}>
                        <div className={cssStyles.settingsRow}>
                            <div className={cssStyles.settingsCol_lt} style={{paddingTop: '20px', display: 'flex', flexDirection:'column'}}>
                                <span>Base <i className="tiny material-icons"
                                              onMouseOver={toggleToolTipVisibility('basePrice')}
                                              onMouseLeave={toggleToolTipVisibility('basePrice')}>help</i>
                                </span>
                                    <span>Price</span>
                                <span>
                                    { isToolTipVisible.basePrice && showToolTip('basePrice', 'basePriceToolTipStyle', true) }
                                </span>
                            </div>
                            <div className={cssStyles.settingsCol_rt} style={{paddingTop: '20px'}}>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <span className={cssStyles.displayOnly}>{condoBasePrice}</span>
                                    <span>Condo</span>

                                </div>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <span className={cssStyles.displayOnly}>{singleFamilyBasePrice}</span>
                                    <span>Single Family</span>
                                </div>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <span className={cssStyles.displayOnly}>{multiFamilyBasePrice}</span>
                                    <span>Multi Family</span>
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.settingsRow}>
                            <div className={cssStyles.settingsCol_lt} style={{paddingTop: '25px'}}>
                                <span>Mileage <i className="tiny material-icons"
                                                 onMouseOver={toggleToolTipVisibility('mileage')}
                                                 onMouseLeave={toggleToolTipVisibility('mileage')}>help</i>
                                </span>
                                <span>
                                    { isToolTipVisible.mileage && showToolTip('mileage', 'mileageToolTipStyle') }
                                </span>
                            </div>
                            <div className={cssStyles.settingsCol_rt} style={{paddingTop: '25px'}}>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <span className={cssStyles.displayOnly}>{parseFloat(mileageBase).toFixed(2)}</span>
                                    <span>per 5 miles</span>
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.settingsRow}>
                            <div className={cssStyles.settingsCol_lt}>
                                <span>Age <i className="tiny material-icons"
                                             onMouseOver={toggleToolTipVisibility('age')}
                                             onMouseLeave={toggleToolTipVisibility('age')}>help</i>
                                </span>
                                <span>
                                    { isToolTipVisible.age && showToolTip('age', 'ageToolTipStyle') }
                                </span>
                            </div>
                            <div className={cssStyles.settingsCol_rt}>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <span className={cssStyles.displayOnly}>{parseFloat(ageBase).toFixed(2)}</span>
                                    <span>per 5 year</span>
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.settingsRow}>
                            <div className={cssStyles.settingsCol_lt}>
                                <span>Size</span>
                            </div>
                            <div className={cssStyles.settingsCol_rt}>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <span className={cssStyles.displayOnly}>{parseFloat(sizeBase).toFixed(2)}</span>
                                    <span>per extra 500 sqft</span>
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.settingsRow}>
                            <div className={cssStyles.settingsCol_lt} style={{display: 'flex', flexDirection:'column'}}>
                                <span>Special <i className="tiny material-icons" onMouseOver={toggleToolTipVisibility('special')} onMouseLeave={toggleToolTipVisibility('special')}>help</i></span>
                                <span>Case Buffer</span>
                                <span>
                                    { isToolTipVisible.special && showToolTip('special', 'specialToolTipStyle') }
                                </span>
                            </div>
                            <div className={cssStyles.settingsCol_rt}>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <span className={cssStyles.displayOnly}>{specialBuffer}</span>
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.settingsRow}>
                            <div className={cssStyles.settingsCol_lt} style={{paddingTop: '25px'}}>
                                <span>WDI</span>
                            </div>
                            <div className={cssStyles.settingsCol_rt} style={{paddingTop: '25px'}}>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <span className={cssStyles.displayOnly}>{WDIBase}</span>
                                </div>
                            </div>
                        </div>
                        <div className={cssStyles.settingsRow}>
                            <div className={cssStyles.settingsCol_lt} style={{paddingBottom: '20px'}}>
                                <span>Radon</span>
                            </div>
                            <div className={cssStyles.settingsCol_rt} style={{paddingBottom: '20px'}}>
                                <div className={cssStyles.singleInputGroup}>
                                    <span>$</span>
                                    <span className={cssStyles.displayOnly}>{radonBase}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cssStyles.settingsButtonContainer}>
                        <button className="browser-default" onClick={onSaveSettingsClicked}>{getSettingsButtonText()}</button>
                    </div>
                </div>
            )
        }
    }

    const toggleShowPriceRange = async () => {
        const token = await InspectorFirebase.getIdToken();

        const body = {
            showPriceRange: !shouldShowPriceRange
        };
        await updateShowPriceRange(token, body);
        setShouldShowPriceRange(!shouldShowPriceRange);
    };

    return(
        <div className={cssStyles.pageContainer}>
            <div className={cssStyles.pageTitleContainer}>
                <img src={require('../../../assets/pricing-icon.png')}/>
                <span>Home Inspection Price Calculator</span>
            </div>
            <div className={cssStyles.bodyContainer}>
                <div className={cssStyles.firstRow}>
                    <span>Enter your price settings on the right to calculate your price range for customers to understand how much you charge compared to others.</span>
                    <span>Your price range will be shown/hidden on your profile based on your choice below.</span>
                    <div style={{marginTop: "10px"}}>
                        <div className="switch">
                            <label>
                                Hide
                                <input onClick={toggleShowPriceRange} type="checkbox" checked={shouldShowPriceRange} />
                                <span className="lever" />
                                Show
                            </label>
                        </div>
                    </div>
                </div>
                <div className={cssStyles.secondRow}>
                    <div className={cssStyles.leftCol}>
                        { showPriceRange() }
                        { showEstimatePanel() }
                    </div>
                    <div className={cssStyles.rightCol}>
                        { showSettingsPanel() }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InspectorFeeCalculator;