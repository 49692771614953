import React from 'react'

export default (props) => {
  return <div style={{ width: '100%' }}>
        {
    props.type === "inspector" ? 
    <>
    <h4>Inspection Compensation</h4>
    <h6 style={{ margin: '40px auto', width:'50%' }}>{props.inspectorbannerInfo.body}</h6>
    </>:
    <>
    <h4>Choose your house type.</h4>
    <h6 style={{ margin: '40px auto', width:'50%' }}>{props.bannerInfo.body}</h6>
    </>
        }
    {
    props.type === "inspector" ? <></>:
      <a href={props.bannerInfo.buttonLink} className='btn py-1 px-4 housky-primary-complimentary-bg housky-secondary-text' style={{height:'auto'}}>Get Started</a>
    }
  </div>
}