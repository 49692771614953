import React from "react";
import styles from './InspectorSchoolsPage.module.css';

export default function() {
	return (
		<div className={styles.container}>
			<div className={styles.title}>
				New York Inspector Schools
			</div>
			<div className={styles.school}>
				<div>
					ASKUS at <a href="http://www.askusconsultingservices.com" target="_blank">http://www.askusconsultingservices.com</a>
				</div>
				<div className={styles.paragraph}>
					{`
						Providing initial licensure training and continuing education classes."ASKUS Consulting Home Inspection Training School wants our students to be successful professional inspectors. 
						To do this, we not only teach the proscribed New York State Curriculum, but also include information and material on effective business operation and incorporate years of field experience into your instruction." Ask for Tom.
					`}
				</div>
			</div>
			<div className={styles.school}>
				<div>
					Pest Management Sciences at <a href="https://www.pestcontrolschoolny.com" target="_blank">www.pestcontrolschoolny.com</a>
				</div>
				<div className={styles.paragraph}>
					{`
						Since 1984, Pest Control School, Supplies and Services has provided the residents of New York City with superior education and certification prep for the N.Y.S. 
						Department of Environmental Conservation exam. We offer a wide variety of courses in order for you to reach your 30 hours of education in pest management. 
						Our classes focus on different pests as well as other large components of pest control such as pesticide law and more.
						Pest Management Sciences, Inc. also provides a 30-hour termite inspection training conducted by Shweta Advani at Molloy College.
					`}
				</div>
				<div className={styles.paragraph}>
					7c Termite classes specifically focused on home inspections are now being held in person and live online so even if you are not in the city you can take advantage of the <b>20% discount all InterNACHI members receive when you sign up for training with Pest Management Services.</b>
					Please visit their website for more information regarding this course.
				</div>
				<div className={styles.paragraph}>
					{`
						Please call 718-205-0557, if you have any questions.
					`}
				</div>
			</div>
			<div className={styles.school}>
				<div>
					C.A.T.S at <a href="https://www.catsofbuffalo.com" target="_blank">www.catsofbuffalo.com</a>
				</div>
				<div className={styles.paragraph}>
					{`
						From their website: "We have developed a philosophy at CATS over our years of teaching home inspection that a 3 or 4 week course is inadequate to truly prepare anyone to be a competent, professional home inspector.
					`}
				</div>
				<div className={styles.paragraph}>
					{`
						Our program allows for complete training, not only in the systems of a home but in the planning of your small business. Four months of classroom learning accompanied by practice home inspections throughout the program gives our graduates the confidence and established plan to be successful."
					`}
				</div>
			</div>
			<div className={styles.school}>
				<div>
					American Building Inspection and Training at <a href="http://www.abitcoinc.com/serv01.php" target="_blank">http://www.abitcoinc.com/serv01.php</a>
				</div>
				<div className={styles.paragraph}>
					{`
						From their website: "The purchase of a home is probably the largest single investment you will ever make. We at American Building Inspection and Training Co., Inc. are dedicated to providing you with the information you need to make a sound decision in the home purchasing process. 
					`}
				</div>
				<div className={styles.paragraph}>
					{`
						Don't trust your biggest purchase of a lifetime to a company with less experience. 
						We have been in the Home Inspection business since 1984 and have provided home inspector training to Home Inspectors since 1995. We keep up with the latest changes in the Building Codes and will alert you to those issues you should be made aware of for safety. 
						Let our knowledge, training and experience protect Your American Dream."
					`}
				</div>
			</div>
			<div className={styles.school}>
				<div>
					NY Home Inspector School at <a href="http://www.NYHIS.SCHOOL" target="_blank">www.NYHIS.SCHOOL</a>
				</div>
				<div className={styles.paragraph}>
					{`
						Providing initial licensure training and continuing education classes. "At the NY Home Inspector School we go above and beyond NY State training guidelines and show you how to set up your business and how to network and market with real estate professionals. 
						You will be taught by successful Home Inspectors how the business side of the industry works, giving you all the tools you need to become a successful business owner."
					`}
				</div>
			</div>
		</div>
	);
}