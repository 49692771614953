import React from 'react';
import cssStyles from './InspectorHomePage.module.css'
import {Link, useHistory} from 'react-router-dom';

const InspectorHomeBanner = () => {

    const history = useHistory();

    const goToSignUpPage = () => {
        history.push('/signup');
    };

    return(
        <div>
            <div className={cssStyles.bannerContainer}>
                <div className={cssStyles.textContainer}>
                    <span>Housky sources business to skilled inspectors and helps them build their reputation in the industry.</span>
                    <Link to="/signup"><button className="browser-default">SIGN UP TODAY</button></Link>
                </div>
                <div className={cssStyles.iconContainer}>
                    <img src={require('../../../assets/InspectorPageMainIconNew.png')}/>
                </div>
            </div>
        </div>
    )
}

export default InspectorHomeBanner;