import AuthContext from "../../contexts/auth";
import Brand from "../../assets/HouskyBrand.png";
import BrandWhite from "../../assets/housky_logo-06-smaller.png";
import { Link } from "react-router-dom";
import Materialize from "materialize-css/dist/js/materialize.min.js";
import React from "react";
import { inspectorApp as firebase } from "../../firebase";
import DomainSelector from "../GeneralComponents/DomainSelector";

class Navbar extends React.Component {
  static contextType = AuthContext;
  state = {
    isSticky: false,
  };
  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    const elems = document.querySelectorAll(".sidenav");
    Materialize.Sidenav.init(elems, { edge: "left" });
  };

  handleScroll = () => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/prereg" ||
      window.location.pathname === "/promotions" ||
      window.location.pathname === "/pricing" ||
      window.location.pathname === "/inspector_records" ||
      window.location.pathname === "/user_records"
    ) {
      if (window.scrollY > 100) {
        this.setState({
          isSticky: true,
        });
      } else {
        this.setState({ isSticky: false });
      }
    }
  };

  toLogout = (e) => {
    Materialize.toast({ html: "Logged out.", classes: "rounded" });
    firebase.auth().signOut();
    this.setState({ userEmail: "" });
  };

  CheckLog = () => {
    if (this.context.authStatusCheck) {
      if (this.context.user) {
        return (
          <li>
            <Link onClick={this.toLogout} to="/login" className="nav-links nav-links-button">
              Log Out
            </Link>
          </li>
        );
      } else {
        return (
          <React.Fragment>
            <li>
              <Link className={`nav-links`} to="/login">
                LOG IN
              </Link>
            </li>
          </React.Fragment>
        );
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <nav
          id="inspector-nav"
          className={`${this.state.isSticky ? "sticked-nav" : "inspector-nav"}`}
          style={{
            boxShadow: "none",
            zIndex: "10",
            backgroundColor: `${window.location.pathname === "/login" ? "#104B69" : ""}`,
          }}
        >
          <div className="nav-wrapper">
            <Link to="/">
              <img
                alt="Housky"
                className="brand-logo"
                src={
                  this.state.isSticky
                    ? Brand
                    : window.location.pathname === "" ||
                      window.location.pathname === "promotions" ||
                      window.location.pathname === "pricing" ||
                      window.location.pathname === "inspector_records" ||
                      window.location.pathname === "user_records" ||
                      window.location.pathname === "login"
                    ? BrandWhite
                    : Brand
                }
                style={{ height: "100%" }}
              />
            </Link>

            <a href="!#" data-target="mobile-nav" className="sidenav-trigger m-0">
              <i className="material-icons" style={{ color: `black` }}>
                menu
              </i>
            </a>
            <ul className="right hide-on-med-and-down nav-links-container">
              {this.context.authStatusCheck && this.context.user ? (
                <React.Fragment>
                  <DomainSelector domain={this.props.domain} setDomain={this.props.setDomain} />
                  <li
                    className="navbar-link-items mouse-pointer"
                    style={{
                      color:
                        window.location.pathname === "" ||
                        window.location.pathname === "promotions" ||
                        window.location.pathname === "pricing" ||
                        window.location.pathname === "inspector_records" ||
                        window.location.pathname === "inspector_list" ||
                        window.location.pathname === "user_records" ||
                        window.location.pathname === "login"
                          ? ""
                          : "black",
                    }}
                    onMouseEnter={() => {
                      document.getElementById("records").classList.add("info-box-active");
                    }}
                    onMouseLeave={() => {
                      document.getElementById("records").classList.remove("info-box-active");
                    }}
                  >
                    <div>
                      <p style={{ margin: 0, padding: 0 }}>Records</p>
                      <i
                        className="material-icons"
                        style={{ position: "relative", marginTop: "-80%", marginLeft: "85%" }}
                      >
                        arrow_drop_down
                      </i>
                    </div>
                    <div id="records" className="more-info-box z-depth-2" style={{ marginTop: "-2%" }}>
                      <Link
                        to="/user_records"
                        style={{
                          borderBottom: window.location.pathname === "/user_records" ? "2px solid #546A76" : "none",
                        }}
                        className="navbar-link-items black-text"
                      >
                        User Records
                      </Link>
                      <Link
                        to="/inspector_records"
                        className="navbar-link-items black-text"
                        style={{
                          borderBottom:
                            window.location.pathname === "/inspector_records" ? "2px solid #546A76" : "none",
                        }}
                      >
                        Inspector Records
                      </Link>
                      <Link
                        to="/inspector_list"
                        style={{
                          borderBottom: window.location.pathname === "/inspector_list" ? "2px solid #546A76" : "none",
                        }}
                        className="navbar-link-items black-text"
                      >
                        Inspector List
                      </Link>
                      <Link
                        to="/pendingJobs"
                        className="navbar-link-items black-text"
                        style={{
                          borderBottom: window.location.pathname === "/open_jobs" ? "2px solid #546A76" : "none",
                        }}
                      >
                        Pending Jobs
                      </Link>
                    </div>
                  </li>

                  <li>
                    <Link
                      to="/prereg"
                      className={` navbar-link-items`}
                      style={{
                        color:
                          window.location.pathname === "" ||
                          window.location.pathname === "promotions" ||
                          window.location.pathname === "pricing" ||
                          window.location.pathname === "inspector_records" ||
                          window.location.pathname === "user_records" ||
                          window.location.pathname === "login"
                            ? ""
                            : "black",
                      }}
                    >
                      Pre-Register
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/promotions"
                      className={` navbar-link-items`}
                      style={{
                        color:
                          window.location.pathname === "" ||
                          window.location.pathname === "promotions" ||
                          window.location.pathname === "pricing" ||
                          window.location.pathname === "inspector_records" ||
                          window.location.pathname === "user_records" ||
                          window.location.pathname === "login"
                            ? ""
                            : "black",
                      }}
                    >
                      Promotions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/pricing"
                      className={` navbar-link-items`}
                      style={{
                        color:
                          window.location.pathname === "" ||
                          window.location.pathname === "promotions" ||
                          window.location.pathname === "pricing" ||
                          window.location.pathname === "inspector_records" ||
                          window.location.pathname === "user_records" ||
                          window.location.pathname === "login"
                            ? ""
                            : "black",
                      }}
                    >
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/tools"
                      className={` navbar-link-items`}
                      style={{ color:"black" }}
                    >
                      Tools
                    </Link>
                  </li>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <DomainSelector domain={this.props.domain} setDomain={this.props.setDomain} />
                </React.Fragment>
              )}
              {this.CheckLog()}
            </ul>
          </div>
        </nav>

        <ul className="sidenav" id="mobile-nav">
          <li>
            <Link to="/">Home</Link>
          </li>
          {this.context.authStatusCheck && this.context.user ? (
            <React.Fragment>
              <li>
                <Link to="/promotions">Promotions</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/inspector_records">Inspector Records</Link>
              </li>
              <li>
                <Link to="/inspector_list">Inspector List</Link>
              </li>
              <li>
                <Link to="/user_records">User Records</Link>
              </li>
              <li>
                <Link to="/tools">Tools</Link>
              </li>
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          {this.CheckLog()}
        </ul>
      </React.Fragment>
    );
  }
}

export default Navbar;
