import React, { Children } from 'react'
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";

export default class CalendarSchedule extends React.Component {

  selectDate = ({ children, value }) =>
    React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: (this.props.events[0] && moment(value).format("dddd, MMMM Do YYYY") === this.props.events[0].date) ||
          (this.props.events[1] && moment(value).format("dddd, MMMM Do YYYY") === this.props.events[1].date) ||
          (this.props.events[2] && moment(value).format("dddd, MMMM Do YYYY") === this.props.events[2].date)||
          (this.props.events[3] && moment(value).format("dddd, MMMM Do YYYY") === this.props.events[3].date)||
          (this.props.events[4] && moment(value).format("dddd, MMMM Do YYYY") === this.props.events[4].date)||
          (this.props.events[5] && moment(value).format("dddd, MMMM Do YYYY") === this.props.events[5].date)||
          (this.props.events[6] && moment(value).format("dddd, MMMM Do YYYY") === this.props.events[6].date)||
          (this.props.events[7] && moment(value).format("dddd, MMMM Do YYYY") === this.props.events[7].date)||
          (this.props.events[8] && moment(value).format("dddd, MMMM Do YYYY") === this.props.events[8].date)
          ? '#66cccc' :
          value < new Date() ? 'lightgray' : '',
        cursor: value < new Date() ? 'default' : 'pointer',
      },
    });

  weather = ({ date, label }) => {
    let dateUnix = moment(date).format('x');
    let icon = null;
    let unix = dateUnix.substring(0, dateUnix.length - 3);
    let condition = false;
    if (this.props.forecast && this.props.forecast.daily && this.props.forecast.daily.data) {
      condition = this.props.forecast.daily.data.find(event => {
        icon = event.icon
        return unix === moment(event.time).format('x')
      }) !== undefined;
    }
    return (<>
      <span style={{ cursor: 'pointer', paddingRight: '5px' }}>{label}</span>
      <div style={{ height: '70px', position: 'relative', cursor: 'pointer', top: '-20px' }}
        onClick={() => {
          this.props.handleCalendarAdd(date)
        }} className={`valign-wrapper`}>
        <div className={date < new Date() ? 'invalid-date' : ''}></div>
        {
          condition ?
            <img className='forecast iconForecast' src={require(`../../../assets/weather/ios11-weather-${icon}-icon.png`)} alt={icon} />
            : <></>
        }
      </div>
    </>);
  }

  render() {
    const localizer = momentLocalizer(moment)
    return (
      <div className='col s12 p-0 pt-3' style={{ marginBottom: '58px' }}>
        <Calendar
          localizer={localizer}
          events={[]}
          views={['month']}
          selectable={false}
          // onSelectSlot={(e) => {this.props.handleCalendarAdd(e.start)}}
          components={{
            dateCellWrapper: this.selectDate,
            month: { dateHeader: this.weather },
          }}
          defaultView={'month'}
          drilldownView={null}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
        />
      </div>
    )
  }
}