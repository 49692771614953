import React, { useState } from "react";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import './UpdatePhotoModal.css';
import {inspectorApp as inspectorFirebase} from "../../../firebase";
import { updateInspectorUserImage } from "../../../services/service";
import Materialize from "materialize-css/dist/js/materialize.min.js";

export default function(props) {
  const [ src, setSrc ] = useState(require("../../../assets/blank-profile-picture.png"));
  const [ crop, setCrop ] = useState({
    unit: 'px',
    width: 100,
    aspect: 1 / 1,
  });
  const [ imageRef, setImageRef ] = useState(null);
  const [ image, setImage ] = useState(null);
  const [ imageName, setImageName ] = useState('default');

  const handlePictureSelected = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]))
    setImageName(e.target.files[0].name);
  }

  const onImageLoad = (image) => {
    setImage(image);
  }

  const onCropComplete = (crop) => {
    if (image && crop.width && crop.height) {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      
      ctx.drawImage(
        image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
      )

      canvas.toBlob(blob => {
        setImageRef(blob);
      })
    }
  }

  const showPreview = ()=> {
    return (
      <div>
        <ReactCrop
          className="imagePreview"
          src={src}
          crop={crop}
          keepSelection={true}
          onChange={newCrop => setCrop(newCrop)}
          onImageLoaded={onImageLoad}
          onComplete={onCropComplete}
        />
        <div>
          { src.name }
        </div>
      </div>
    )
  }

  const uploadImageToStorage = async () => {
    const root = inspectorFirebase.storage().ref();
    const profileImage = root.child(
      `${props.inspectorEmail.toLowerCase()}/profile/${imageName}`
    );
    try {
      const snapshot = await profileImage.put(imageRef);
      const url = await snapshot.ref.getDownloadURL();
      return url; /// return an url that will be used in the next step to update firebase image
    } catch (err) {
      console.log(err);
    }
  };

  const updateFireBaseImage = async (photoUrl) => {
    try {
      let user = inspectorFirebase.auth().currentUser;
      await user.updateProfile({
          photoURL: photoUrl
      })
    } catch (err) {
      console.log(err);
    }
  };

  const onUpload = async () => {
    let fireBaseImageUrl = '';
    if(src) {
      try {
        fireBaseImageUrl = await uploadImageToStorage();
        await updateFireBaseImage(fireBaseImageUrl);
        Materialize.toast({
          html: "Profile image has been updated.",
          classes: "rounded",
        });
      } catch (err) {
        console.log(err);
      }
    }

    const requestBody = {
      inspectorid: props.inspectorId,
      photoid: fireBaseImageUrl,
      token: props.token
    };
    await updateInspectorUserImage(requestBody);
    /// TODO:
    /// 1. Set Photo preview in the account component - done
    /// 2. fix the name issue above - done
    /// 3. Add Update firebase image here as well - done
    /// 4. Set photo to default pic when opening up the modal - done
    /// 5. maybe add a tooltip when hovering over the avatar
    /// 6. mobile size, disable upload photo modal

    props.toggleUpdatePhotoModalVisibility();
  }

  if(props.isUpdatePhotoModalVisible){
    return (
      <div className="overlay">
        <div className="modalContainer">
          <h5 className="modalHeader">Upload New Profile Image</h5>
          
          <div className="photoContainer">
            {showPreview()}
          </div>
          
          <div className="modelFooter">
            <label htmlFor="upload"><a className="waves-effect waves-light btn">Select Photo</a></label>
            <input type="file" id="upload" style={{display: 'none'}} onChange={handlePictureSelected}/>
            <a className="waves-effect waves-light btn" onClick={onUpload}>
              Upload
            </a>
            <a className="waves-effect waves-light red btn" onClick={props.toggleUpdatePhotoModalVisibility}>
              Cancel
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    return null
  }}