import React from 'react'
import PricingTable from '../../components/Pricing/PricingTable'
import './Pricing.css'
import PricingBanner from '../../components/Pricing/PricingBanner'
import { getPrices } from '../../services/service'

export default class MainPricing extends React.Component {
  state = {
    houseTypes: [
      {
        title: 'Condo',
        total: '',
        wdi: '',
        radon: '',
      },
      {
        title: 'Town House',
        total: '',
        wdi: '',
        radon: '',
      },
      {
        title: 'Single Family',
        total: '',
        wdi: '',
        radon: '',
      },
      {
        title: 'Two-Three Family',
        total: '',
        subtotal: '',
        wdi: '',
        radon: '',
      },
      {
        title: 'Mansion',
        total: '',
        wdi: '',
        radon: '',
      }
    ],
    inspection_features: [
      "Structural and foundation",
      "Electrical systems",
      "Plumbing systems",
      "Roof & attics",
      "Basement & crawl spaces",
      "Gutters & Drainage",
      "Walls, floors, & decks",
      "Property & site",
      "Furnace & Heating",
      "Air conditioning",
      "Plumbing fixtures",
      "Water heaters",
      "Lights and receptacles",
      "Window & doors",
      "Built-in appliances"
    ],
    bannerInfo: {
      body: 'We offer straight forward pricing with no hidden fees. What you see below is what you will pay. Nothing more.',
      buttonLink: 'https://user.housky.io/signup'
    },
    inspectorbannerInfo: {
      body: 'We offer straight forward pricing. We collect a flat transaction fee of $30 per inspection and pass on the rest to you. Current payout for inspections are shown below.',
      buttonLink: 'https://inspector.housky.io/signup'
    },
    type: 'user'
  }

  componentDidMount = () => {
    window.scrollTo(0,0)
    if (window.location.href.split(".")[0].split("/")[2] === "inspector")
      this.setState({
        type:'inspector'
      })
    window.gtag('event', 'page_view', {
      page_location: 'https://inspector.housky.io/pricing',
      page_title: 'pricing',

    });
    getPrices()
      .then((res) => {
        let prices = {}
        res.map((e)=>{
          return prices[e.house_type] = {
            title: e.house_type,
            total:Math.ceil((((e.price + Number.parseInt(e.margin)) * parseFloat(e.processing_fee) ) * Number.parseInt(e.beta) * Number.parseInt(e.inspection_quality))),
            wdi: Math.ceil(e.wdi * parseFloat(e.processing_fee)),
            radon: Math.ceil(e.radon * parseFloat(e.processing_fee))
          }
        })
        
        return prices
      })
      .then((res)=>{
        let curr = [...this.state.houseTypes]
        for(let i = 0; i < curr.length; i ++){
          if(curr[i].title === res[curr[i].title].title){
            curr[i].total = res[curr[i].title].total
            curr[i].wdi = res[curr[i].title].wdi
            curr[i].radon = res[curr[i].title].radon
          }
        }
        return curr
      })
      .then((houses)=>{
        this.setState({houseTypes: houses})
      })
  }
  render() {
    return <>
      <div id='navOffset' style={{backgroundColor:`${window.location.href.split(".")[0].split("/")[2] === "inspector" ? '#3C4456': '#104B69'}`}}></div>
      <div id='PricingBanner' className='row center valign-wrapper mb-0' style={{backgroundColor:`${window.location.href.split(".")[0].split("/")[2] === "inspector" ? '#3C4456': '#104B69'}`}}>
        <PricingBanner bannerInfo={this.state.bannerInfo} inspectorbannerInfo={this.state.inspectorbannerInfo} type={this.state.type}/>
      </div>
      <div className='row' style={{ margin: '20px' }}>
        <h3 className='center'>Inspection Features</h3>
          <div className='container' style={{ display: 'flex', flexWrap: 'wrap' }}>
            {
              this.state.inspection_features.map((e, i) => {
                return <p key={i} className='col s6 m4'>
                  <label>
                    <input type="checkbox" checked="checked" disabled="disabled" />
                    <span className='inspector-features' style={{ cursor: 'default' }}>{e}</span>
                  </label>
                </p>
              })
            }
        </div>
      </div>
      <PricingTable houseTypes={this.state.houseTypes} type={this.state.type}/>
    </>
  }
}